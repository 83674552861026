import React, { memo } from 'react'
import { NavDropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Ellipses from '../Ellipses';

const SettingsDropdown = memo(({
    identifier,
    handleViewClick,
    handleEditClicked,
    handleClickDelete,
    drop,
    isEditable,
    isDeletable,
}) => {
    return (
        <div id="settings-dropdown">
            <NavDropdown
                title={<Ellipses />}
                id="collasible-nav-dropdown"
                drop={drop}
            >
                <NavDropdown.Item
                    onClick={handleViewClick}
                    data-tag="allowRowEvents"
                    id={identifier}
                >
                    View
                </NavDropdown.Item>
                <NavDropdown.Item
                    onClick={handleEditClicked}
                    // data-tag="allowRowEvents"
                    id={identifier}
                    disabled={isEditable}
                >
                    Edit
                </NavDropdown.Item>
                <NavDropdown.Item
                    onClick={handleClickDelete}
                    // data-tag="allowRowEvents"
                    id={identifier}
                    disabled={isDeletable}
                >
                    Delete
                </NavDropdown.Item>
            </NavDropdown>
        </div>
    );
});

export default SettingsDropdown;

SettingsDropdown.propTypes = {
    identifier: PropTypes.string.isRequired,
    handleViewClick: PropTypes.func.isRequired,
    handleEditClicked: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isDeletable: PropTypes.bool.isRequired,
    drop: PropTypes.string,
};

SettingsDropdown.defaultProps = {
    drop: ''
};
