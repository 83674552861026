import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types';

import { Modal, Row, Col, Image, Button, Form } from 'react-bootstrap';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';

const CityFilters = memo(({
    show, handleClose, applyChanges, allFilters, clearAll
}) => {

    const [option, setOption] = useState('basicrate');

    const [status, setStatuses] = useState([
        { label: 'Active', value: 'active', isChecked: false },
        { label: 'Inactive', value: 'inactive', isChecked: false },
    ]);
    const [selectedStatus, setSelectedStatus] = useState(
        allFilters?.status ? allFilters.status : null
    );

    const [basicRate, setbasicRate] = useState({
        minimumBasicRate:
            allFilters?.basicRate?.minimumBasicRate ||
            '',
        maximumBasicRate:
            allFilters?.basicRate?.maximumBasicRate ||
            ''
    });

    const [primeRate, setprimeRate] = useState({
        minimumPrimeRate:
            allFilters?.primeRate?.minimumPrimeRate ||
            '',
        maximumPrimeRate:
            allFilters?.primeRate?.maximumPrimeRate ||
            ''
    });

    const [specialRate, setspecialRate] = useState({
        minimumSpecialRate:
            allFilters?.specialRate?.minimumSpecialRate ||
            '',
        maximumSpecialRate:
            allFilters?.specialRate?.maximumSpecialRate ||
            ''
    });

    const [holdingFee, setholdingFee] = useState({
        minimumHoldingFee:
            allFilters?.holdingFee?.minimumHoldingFee ||
            '',
        maximumHoldingFee:
            allFilters?.holdingFee?.maximumHoldingFee ||
            ''
    });

    const [filters, setfilters] = useState(null);

    const removeAllBasicRate = () => {
        setbasicRate({
            minimumBasicRate: '',
            maximumBasicRate: ''
        });
    };

    const removeAllPrimeRate = () => {
        setprimeRate({
            minimumPrimeRate: '',
            maximumPrimeRate: ''
        });
    };

    const removeAllSpecialRate = () => {
        setspecialRate({
            minimumSpecialRate: '',
            maximumSpecialRate: ''
        });
    };

    const removeAllHoldingFee = () => {
        setholdingFee({
            minimumHoldingFee: '',
            maximumHoldingFee: ''
        });
    };

    const removeAllStatus = () => {
        setStatuses(
            status.map((d) => {
                const tempStatus = d;
                if (selectedStatus) {
                    tempStatus.isChecked = false;
                    // selectedDurations.splice(selectedDurations.indexOf(d), 1);
                }
                return tempStatus;
            })
        );
        setSelectedStatus(null);
    };

    const onClearAll = () => {
        removeAllBasicRate();
        removeAllPrimeRate();
        removeAllSpecialRate();
        removeAllHoldingFee();
        removeAllStatus();
        setfilters(null);
        clearAll();
    };

    useEffect(() => {
        if (basicRate.minimumBasicRate || basicRate.maximumBasicRate) {
            setfilters((oldFilters) => ({ ...oldFilters, basicRate }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('basicRate'))) {
            delete filters?.basicRate;
            setfilters({ ...filters });
        }
    }, [basicRate]);

    useEffect(() => {
        if (primeRate.minimumPrimeRate || primeRate.maximumPrimeRate) {
            setfilters((oldFilters) => ({ ...oldFilters, primeRate }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('primeRate'))) {
            delete filters?.primeRate;
            setfilters({ ...filters });
        }
    }, [primeRate]);

    useEffect(() => {
        if (specialRate.minimumSpecialRate || specialRate.maximumSpecialRate) {
            setfilters((oldFilters) => ({ ...oldFilters, specialRate }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('specialRate'))) {
            delete filters?.specialRate;
            setfilters({ ...filters });
        }
    }, [specialRate]);

    useEffect(() => {
        if (holdingFee.minimumHoldingFee || holdingFee.maximumHoldingFee) {
            setfilters((oldFilters) => ({ ...oldFilters, holdingFee }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('holdingFee'))) {
            delete filters?.holdingFee;
            setfilters({ ...filters });
        }
    }, [holdingFee]);

    useEffect(() => {
        if (selectedStatus !== null) {
            setfilters((oldFilters) => ({ ...oldFilters, status: selectedStatus }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('status'))) {
            delete filters?.status;
            setfilters({ ...filters });
        }
    }, [selectedStatus]);

    useEffect(() => {
        if (allFilters?.status) {
            setStatuses(
                status.map((st) => {
                    const tempStatus = st;
                    if (allFilters?.status?.value === st.value) {
                        tempStatus.isChecked = true;
                    }
                    return tempStatus;
                })
            )
        }
    }, [allFilters]);

    const onStatusChange = (e, st) => {
        setStatuses(
            status.map((d) => {
                const tempStatus = d;
                if (d.value === st.value) {
                    if (e.target.checked) {
                        tempStatus.isChecked = true;
                        setSelectedStatus(tempStatus);
                    }
                    else {
                        tempStatus.isChecked = false;
                        setSelectedStatus(null);
                    }
                }
                return tempStatus;
            })
        );
    };

    function filterDetailsByOption() {
        switch (option) {
            case 'status':
                return (
                    <div>
                        <Row>
                            <Col md="3" xs="6">
                                <p><strong>Status</strong></p>
                            </Col>
                            <Col md="9" xs="6">
                                <a
                                    className="txt-primary float-right"
                                    role="button"
                                    onClick={removeAllStatus}
                                    onKeyDown={() => { }}
                                    tabIndex={-1}
                                >Deselect All</a>
                            </Col>
                        </Row>
                        <Row>
                            {
                                status.map((st) => {
                                    return (
                                        <Col md="4" xs="6" key={st.value}>
                                            <Form.Group controlId="formBasicCheckbox">
                                                <input
                                                    type="radio"
                                                    className="ftc-radiobutton"
                                                    name='status-radio'
                                                    id={st.value}
                                                    checked={st.isChecked}
                                                    onChange={(e) => {
                                                        onStatusChange(e, st);
                                                    }}
                                                />
                                                <label
                                                    className="position-relative ml-4 pl-2"
                                                    htmlFor={st.value}
                                                >
                                                    {st.label}
                                                </label>
                                            </Form.Group>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </div>
                );

            case 'basicrate':
                return (<div>
                    <Row>
                        <Col md="3" xs="6">
                            <p><strong>Basic Rate</strong></p>
                        </Col>
                        <Col md="9" xs="6">
                            <a
                                className="txt-primary float-right"
                                role="button"
                                onClick={removeAllBasicRate}
                                onKeyDown={() => { }}
                                tabIndex={-1}
                            >Deselect All</a>
                        </Col>
                        <Col md="6" xs="12">
                            <Form.Group controlId="minimumBasicRate">
                                <label
                                    className="txt-light-secondary"
                                >
                                    Enter Minimum Amount
                                </label>
                                <Form.Control
                                    placeholder="0"
                                    type="number"
                                    value={basicRate.minimumBasicRate}
                                    onChange={e => setbasicRate({ ...basicRate, minimumBasicRate: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6" xs="12">
                            <Form.Group controlId="maximumBasicRate">
                                <label
                                    className="txt-light-secondary"
                                >
                                    Enter Maximum Amount
                                </label>
                                <Form.Control
                                    placeholder="0"
                                    type="number"
                                    value={basicRate.maximumBasicRate}
                                    onChange={e => setbasicRate({ ...basicRate, maximumBasicRate: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                );

            case 'specialrate':
                return (<div>
                    <Row>
                        <Col md="3" xs="6">
                            <p><strong>Special Rate</strong></p>
                        </Col>
                        <Col md="9" xs="6">
                            <a
                                className="txt-primary float-right"
                                role="button"
                                onClick={removeAllSpecialRate}
                                onKeyDown={() => { }}
                                tabIndex={-1}
                            >Deselect All</a>
                        </Col>
                        <Col md="6" xs="12">
                            <Form.Group controlId="minimumSpecialRate">
                                <label
                                    className="txt-light-secondary"
                                >
                                    Enter Minimum Amount
                                </label>
                                <Form.Control
                                    placeholder="0"
                                    type="number"
                                    value={specialRate.minimumSpecialRate}
                                    onChange={e => setspecialRate({ ...specialRate, minimumSpecialRate: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6" xs="12">
                            <Form.Group controlId="maximumSpecialRate">
                                <label
                                    className="txt-light-secondary"
                                >
                                    Enter Maximum Amount
                                </label>
                                <Form.Control
                                    placeholder="0"
                                    type="number"
                                    value={specialRate.maximumSpecialRate}
                                    onChange={e => setspecialRate({ ...specialRate, maximumSpecialRate: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                );

            case 'holdingfee':
                return (<div>
                    <Row>
                        <Col md="3" xs="6">
                            <p><strong>Holding Fee</strong></p>
                        </Col>
                        <Col md="9" xs="6">
                            <a
                                className="txt-primary float-right"
                                role="button"
                                onClick={removeAllHoldingFee}
                                onKeyDown={() => { }}
                                tabIndex={-1}
                            >Deselect All</a>
                        </Col>
                        <Col md="6" xs="12">
                            <Form.Group controlId="minimumHoldingFee">
                                <label
                                    className="txt-light-secondary"
                                >
                                    Enter Minimum Amount
                                </label>
                                <Form.Control
                                    placeholder="0"
                                    type="number"
                                    value={holdingFee.minimumHoldingFee}
                                    onChange={e => setholdingFee({ ...holdingFee, minimumHoldingFee: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6" xs="12">
                            <Form.Group controlId="maximumHoldingFee">
                                <label
                                    className="txt-light-secondary"
                                >
                                    Enter Maximum Amount
                                </label>
                                <Form.Control
                                    placeholder="0"
                                    type="number"
                                    value={holdingFee.maximumHoldingFee}
                                    onChange={e => setholdingFee({ ...holdingFee, maximumHoldingFee: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                );

            case 'primerate':
                return (<Row>
                    <Col md="3" xs="6">
                        <p><strong>Prime Rate</strong></p>
                    </Col>
                    <Col md="9" xs="6">
                        <a
                            className="txt-primary float-right"
                            role="button"
                            onClick={removeAllPrimeRate}
                            onKeyDown={() => { }}
                            tabIndex={-1}
                        >Deselect All</a>
                    </Col>
                    <Col md="6" xs="12">
                        <Form.Group controlId="minimumPrimeRate">
                            <label
                                className="txt-light-secondary"
                            >
                                Enter Minimum Amount
                            </label>
                            <Form.Control
                                placeholder="0"
                                type="number"
                                value={primeRate.minimumPrimeRate}
                                onChange={e => setprimeRate({ ...primeRate, minimumPrimeRate: e.target.value })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6" xs="12">
                        <Form.Group controlId="maximumPrimeRate">
                            <label
                                className="txt-light-secondary"
                            >
                                Enter Maximum Amount
                            </label>
                            <Form.Control
                                placeholder="0"
                                type="number"
                                value={primeRate.maximumPrimeRate}
                                onChange={e => setprimeRate({ ...primeRate, maximumPrimeRate: e.target.value })}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                );

            default:
                break;
        }
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Filter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="modal__row">
                    <Desktop>
                        <Col md="3" className="modal__Col_3_Padding border__right sticky-top">
                            <ul className="modal__ul">
                                <li
                                    className={`${option === 'basicrate' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('basicrate')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Basic Rate</span>{' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />{' '}
                                </li>
                                <li
                                    className={`${option === 'primerate' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('primerate')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Prime Rate</span>{' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />
                                </li>
                                <li
                                    className={`${option === 'specialrate' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('specialrate')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Special Rate</span>{' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />
                                </li>
                                <li
                                    className={`${option === 'holdingfee' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('holdingfee')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Holding Fee</span>{' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />
                                </li>
                                <li
                                    className={`${option === 'status' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('status')}
                                    onKeyPress={() => { }}
                                    role="menuitem"
                                >
                                    <span className="modal__list__padding">Status</span> {' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />
                                </li>
                            </ul>
                        </Col>
                    </Desktop>
                    <Mobile>
                        <Col xs="4" className="modal__Col_3_Padding border__right">
                            <ul className="modal__ul">
                                <li
                                    className={`${option === 'basicrate' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('basicrate')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Basic Rate</span>{' '}
                                </li>
                                <li
                                    className={`${option === 'primerate' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('primerate')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Prime Rate</span>{' '}
                                </li>
                                <li
                                    className={`${option === 'specialrate' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('specialrate')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Special Rate</span>{' '}
                                </li>
                                <li
                                    className={`${option === 'holdingfee' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('holdingfee')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Holding Fee</span>{' '}
                                </li>
                                <li
                                    className={`${option === 'status' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('status')}
                                    onKeyPress={() => { }}
                                    role="menuitem"
                                >
                                    <span className="modal__list__padding">Status</span> {' '}
                                </li>
                            </ul>
                        </Col>
                    </Mobile>
                    <Col md="9" xs="8">
                        {filterDetailsByOption()}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={() => {
                    onClearAll();
                    // handleClose();
                }}>
                    Clear All
                </Button>
                <Button
                    variant="default"
                    className="btn-ftc-border-primary"
                    onClick={() => {
                        applyChanges(filters);
                    }}
                >
                    Apply Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default CityFilters

CityFilters.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    applyChanges: PropTypes.func.isRequired,
    allFilters: PropTypes.any.isRequired,
    clearAll: PropTypes.func.isRequired,
};

// CityFilters.defaultProps = {
//     allFilters: {}
// }