import React, { memo, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

import { Button, Row, Col, Image, NavDropdown, Card, Alert, ResponsiveEmbed } from 'react-bootstrap';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import Toaster from '../../../components/Toaster';
import Ellipses from '../../../components/Ellipses';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';

import { deleteClientByClientId, getClientByClientId, updateClientByClientId } from '../../../services/client-service';
import { DateConvert } from '../../../utils/TimesheetManagement';
import { useAuthContext } from '../../../contexts/user-context';
import { validatePermission } from '../../../utils/UserManagement';

const ViewClient = memo(() => {
    const { authUser } = useAuthContext();
    const history = useHistory();
    const { clientId } = useParams();
    const isChefUser = authUser.user.role.rolename === 'Chef';

    const [isAPICalled, setAPICalled] = useState(false);
    const [client, setClient] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    async function getClientDetails() {
        try {
            const response = await trackPromise(getClientByClientId(clientId));
            setClient(response.data.data.client);
            setAPICalled(true);
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    const updateUserStatus = async (status) => {
        try {
            const body = {
                status,
                inactivation_date: new Date(),
            };
            setAPICalled(false);
            const response = await trackPromise(
                updateClientByClientId(clientId, body)
            );
            setClient(response.data.data.client);
            if (response.status === 200) {
                toast.dark(<Toaster
                    icon="notify"
                    message={response.data.message}
                />)
                history.push(`/settings/viewClient/${response.data.data.client.clientId}`);
            }
            setAPICalled(true);
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    const onDeleteClient = async () => {
        try {
            const response = await trackPromise(
                deleteClientByClientId(clientId)
            );
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally {
            setShow(false);
            history.replace('/settings/clients');
        }
    }

    const onEditClient = () => {
        history.push(`/settings/editClient/${clientId}`);
    }

    useEffect(() => {
        getClientDetails();
    }, [clientId]);

    const SettingsDropdown = () => {
        return (
            <div>
                <NavDropdown
                    title={<Ellipses />}
                    id="collasible-nav-dropdown"
                >
                    {(!isChefUser && client.status === 'active') ? (
                        <NavDropdown.Item
                            disabled={!validatePermission(authUser.user.role.permissions, 'client', 'edit')}
                            onClick={() => {
                                updateUserStatus('inactive')
                            }}>Deactivate</NavDropdown.Item>
                    ) : ('')}
                    {(!isChefUser && client.status === 'inactive') ? (
                        <NavDropdown.Item
                            disabled={!validatePermission(authUser.user.role.permissions, 'client', 'edit')}
                            onClick={() => {
                                updateUserStatus('active')
                            }}>Activate</NavDropdown.Item>
                    ) : ('')}
                    <NavDropdown.Item
                        onClick={handleShow}
                        disabled={!validatePermission(authUser.user.role.permissions, 'client', 'delete')}
                    >Delete</NavDropdown.Item>
                </NavDropdown>
            </div>
        );
    };

    return isAPICalled ? (
        <div>
            <div className="sticky-page-header">
                <div className="container">
                    <Desktop>
                        <Row className="ml-5">
                            <Col md="1">
                                <Link to='/settings/clients'>
                                    <a>
                                        <Image
                                            className="imageArrow"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </a>
                                </Link>
                            </Col>
                            <Col md="8">
                                <h5 className="mb-0">
                                    <strong>{client.username}</strong>
                                </h5>
                                <label className="txt-light-secondary mb-0">
                                    Last Updated on {DateConvert(client.updatedAt)}
                                </label>
                            </Col>
                            <Col md="1" className="pr-0">
                                <Button
                                    variant="default"
                                    className="btn-ftc-border-primary ml-5"
                                    disabled={
                                        client.status === 'inactive' ||
                                        !validatePermission(authUser.user.role.permissions, 'client', 'edit')
                                    }
                                    onClick={onEditClient}
                                >
                                    Edit
                                </Button>
                            </Col>
                            <Col md="1">
                                <div className="ml-5">
                                    <SettingsDropdown />
                                </div>
                            </Col>
                        </Row>
                    </Desktop>
                    <Mobile>
                        <div className="container">
                            <Row className="pb-0">
                                <Col xs="1" className="p-0">
                                    <Link to="/settings/clients">
                                        <Image
                                            className="imageArrowMobile mt-1"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </Link>
                                </Col>
                                <Col xs="9" className="mt-1">
                                    <h6 className="m-auto"><strong>{client.username}</strong> </h6>
                                    <p className="txt-light-secondary mx-auto">
                                        Last Updated on {DateConvert(client.updatedAt)}
                                    </p>
                                </Col>
                                <Col xs="1">
                                    <div>
                                        <SettingsDropdown />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Mobile>
                </div>
            </div>
            <Desktop>
                <div className="container-top-8" />
            </Desktop>
            <Mobile>
                <div className="container-top-20" />
            </Mobile>
            <div className="container">
                <Row>
                    <Col md="8" className="mx-auto">
                        <Card className="mt-3">
                            <Card.Body className="pt-0 pb-2">
                                <Row>
                                    <Col md="12" xs="12" className="p-0">
                                        <h5 className="txt-primary"><strong>Client Details</strong></h5>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="3" className="p-0">
                                        <label className="txt-light-secondary">Client Email</label>
                                    </Col>
                                    <Col md="6" xs="9" className="p-0">
                                        <label className="w-100 text-right"><strong>{client.email}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6" className="p-0">
                                        <label className="txt-light-secondary">Client ID</label>
                                    </Col>
                                    <Col md="6" xs="6" className="p-0">
                                        <label className="w-100 text-right"><strong>{client.clientId}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6" className="p-0">
                                        <label className="txt-light-secondary">Client Name</label>
                                    </Col>
                                    <Col md="6" xs="6" className="p-0">
                                        <label className="w-100 text-right"><strong>{client.username}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="pt-3 pb-2">
                                    <Col md="6" xs="6" className="p-0">
                                        <label className="txt-light-secondary">City</label>
                                    </Col>
                                    <Col md="6" xs="6" className="p-0">
                                        <label className="w-100 text-right"><strong>{client?.service_city?.cityname ?? 'N/A'}</strong></label>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="mt-3">
                            <Card.Body className="pt-0 pb-2">
                                <Row>
                                    <Col md="12" xs="12" className="p-0">
                                        <h5 className="txt-primary"><strong>Assigned Employees</strong></h5>
                                    </Col>
                                </Row>
                                {
                                    client.chef.map((ch, index) => {
                                        return (
                                            <Row className={`${index < client.chef.length - 1 ? 'border-bottom' : ''} pt-3 pb-2`}>
                                                <Col md="6" xs="6" className="p-0" >
                                                    <label className="txt-light-secondary">Chef {index + 1}</label>
                                                </Col>
                                                <Col md="6" xs="6" className="p-0">
                                                    <label className="w-100 text-right"><strong>{ch.username}</strong></label>
                                                </Col>
                                            </Row>
                                        );
                                    })
                                }
                            </Card.Body>
                        </Card>
                        <Row>
                            <Col md="12" className="p-0">
                                {/* <embed
                                                    src='https://friendthatcookspersonalchefs.activehosted.com/f/126'
                                                    width='100%'
                                                    height='100%'
                                                /> */}
                                {/* style={{ width: 660, height: 'auto' }} */}
                                <Desktop>
                                    <div className="client__embed-form">
                                        <ResponsiveEmbed aspectRatio='4by3' >
                                            <object
                                                // className='vh-100'
                                                // width='100%'                                                    
                                                type="text/html"
                                                aria-label='Hello'
                                                data={`https://friendthatcookspersonalchefs.activehosted.com/f/126?email=${encodeURIComponent(client.email)}`}
                                            />
                                        </ResponsiveEmbed>
                                    </div>
                                </Desktop>

                                <Mobile>
                                    <div className="client__embed-form">
                                        <ResponsiveEmbed aspectRatio='1by1' >
                                            <object
                                                type="text/html"
                                                aria-label='Hello'
                                                data={`https://friendthatcookspersonalchefs.activehosted.com/f/126?email=${encodeURIComponent(client.email)}`}
                                            />
                                        </ResponsiveEmbed>
                                    </div>
                                </Mobile>

                            </Col>
                        </Row>
                        {/* </Card.Body>
                        </Card> */}

                        <Row className="pt-0">
                            {
                                !isChefUser && (
                                    <Col md="12" className="pr-0 pl-0">
                                        {client.status === 'active' && (
                                            <Alert variant='success'>
                                                <Col md="12" xs="12">
                                                    <span>
                                                        <Image
                                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`}
                                                            fluid
                                                            className="float-right"
                                                        />
                                                        {` `}Active Client
                                                    </span>
                                                </Col>
                                            </Alert>
                                        )}

                                        {client.status === 'inactive' && (
                                            <Alert variant='secondary'>
                                                <Col md="12" xs="12">
                                                    <span>
                                                        <Image
                                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-alert-grey.svg`}
                                                            fluid
                                                            className="float-right"
                                                        />
                                                        {` `}This client has been Inactive since {DateConvert(client.inactivation_date)}
                                                    </span>
                                                </Col>
                                            </Alert>
                                        )}
                                    </Col>

                                )
                            }
                        </Row>
                    </Col>
                </Row>
                <Mobile>
                    <Row className="border-top-shadow  container-top-50">
                        <Col xs={12} className="pr-2">
                            <Button
                                variant="default"
                                className="btn-ftc-border-primary float-right col-3"
                                disabled={
                                    client.status === 'inactive' ||
                                    !validatePermission(authUser.user.role.permissions, 'client', 'edit')
                                }
                                onClick={onEditClient}
                            >
                                Edit
                            </Button>
                        </Col>
                    </Row>
                </Mobile>
            </div>
            {show && <ConfirmAlertModal
                show={show}
                handleClose={handleClose}
                title='Delete Client'
                description='Deleting this Client will erase all data inside this record.
                Are you sure you want to delete this Client?'
                callback={onDeleteClient}
                action='Delete it'
                closeAction='Cancel'
            />}
        </div >
    ) : ('')
});

export default ViewClient;
