import React, { memo, useEffect, useState } from 'react';
import { Col, Form, Row, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
// import * as Scroll from 'react-scroll';
import { trackPromise } from 'react-promise-tracker';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import moment from 'moment';
// import makeAnimated from 'react-select/animated';

import Toaster from '../../../components/Toaster';

import { getAllRolesByNames } from '../../../services/role-service';

import SidebarData from '../../side-navbar/SidebarData';
import { getCustomPageByLocation } from '../../../services/custom_page.service';
import './css/custom_popup.css';

const CustomPopupDescriptionForm = memo(({ formContent, showAccessError }) => {
  // const animatedComponents = makeAnimated();
  // const { scroller } = Scroll;

  const [roles, setRoles] = useState([]);
  const [selectedRoles, setselectedRoles] = useState(
    formContent?.one?.access?.length ? formContent?.one?.access : []
  );

  const [expiresOn, setExpiresOn] = useState(
    formContent?.one?.expiresOn ? formContent?.one?.expiresOn : null
  );

  const [sideBarData, setSideBarData] = useState(SidebarData);

  // const [pageLocations, setPageLocations] = useState([]);
  // const [selectedLocation, setselectedLocation] = useState(null);

  const {
    register,
    formState: { errors },
    setValue,
    control,
  } = useFormContext();

  async function fetchAllRoles() {
    try {
      const response = await trackPromise(getAllRolesByNames());
      setRoles(
        response.data.data.roles.map((role) => {
          const tempRole = role;
          if (formContent?.one?.access?.length > 0) {
            if (
              formContent.one.access.find(
                (selected) => selected._id === role._id
              )
            ) {
              tempRole.isChecked = true;
            }
          } else {
            tempRole.isChecked = false;
          }

          return tempRole;
        })
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function fetchCustomPages() {
    try {
      const response = await trackPromise(getCustomPageByLocation());

      response.data.data.customPages.forEach(async (location) => {
        switch (location._id) {
          case 'tools': {
            const toolsData = sideBarData.findIndex(
              (sidebarObj) => sidebarObj.title === 'Tools'
            );
            if (location.total >= 1) {
              await location.data.map((customPage) => {
                const tempPage = {};
                tempPage.title = customPage.button_label;
                tempPage.path = customPage.route;
                tempPage.icon = (
                  <Image
                    className="ftc-icon-28"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                    fluid
                  />
                );
                if (
                  sideBarData[toolsData].subNav.findIndex(
                    (nav) => nav.path === customPage.route
                  ) === -1
                ) {
                  sideBarData[toolsData].subNav.push(tempPage);
                }
                return tempPage;
              });
            }
            break;
          }

          case 'reports': {
            const reportsData = sideBarData.findIndex(
              (sidebarObj) => sidebarObj.title === 'Reports'
            );
            if (location.total >= 1) {
              await location.data.map((customPage) => {
                const tempPage = {};
                tempPage.title = customPage.button_label;
                tempPage.path = customPage.route;
                tempPage.icon = (
                  <Image
                    className="ftc-icon-28"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                    fluid
                  />
                );
                if (
                  sideBarData[reportsData].subNav.findIndex(
                    (nav) => nav.path === customPage.route
                  ) === -1
                ) {
                  sideBarData[reportsData].subNav.push(tempPage);
                }
                return tempPage;
              });
            }
            break;
          }

          case 'forms': {
            const formsData = sideBarData.findIndex(
              (sidebarObj) => sidebarObj.title === 'Forms'
            );
            if (location.total >= 1) {
              await location.data.map((customPage) => {
                const tempPage = {};
                tempPage.title = customPage.button_label;
                tempPage.path = customPage.route;
                tempPage.icon = (
                  <Image
                    className="ftc-icon-28"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                    fluid
                  />
                );
                if (
                  sideBarData[formsData].subNav.findIndex(
                    (nav) => nav.path === customPage.route
                  ) === -1
                ) {
                  sideBarData[formsData].subNav.push(tempPage);
                }
                return tempPage;
              });
            }
            break;
          }

          default:
            break;
        }
      });
      setSideBarData(
        sideBarData.map((sidebar) => {
          const tempSidebar = {};
          tempSidebar.label = (
            <>
              {sidebar.icon} {sidebar.title}
            </>
          );
          // if there are nested pages then format them also
          if (sidebar?.subNav?.length) {
            tempSidebar.options = sidebar?.subNav?.map((navs) => {
              const newNav = {};
              newNav.label = (
                <>
                  {navs.icon} {navs.title}
                </>
              );
              newNav.value = navs.path;
              if (newNav.value === formContent?.one?.popup_location) {
                setValue('popup_location', newNav, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }
              return newNav;
            });
          } else {
            tempSidebar.value = sidebar.path;
            if (tempSidebar.value === formContent?.one?.popup_location) {
              setValue('popup_location', tempSidebar, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }
          }

          return tempSidebar;
        })
      );
      // setSideBarData(sideBarData);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const removeAllRoles = () => {
    setRoles(
      roles.map((role) => {
        const tempRole = role;
        if (selectedRoles.find((selected) => selected._id === role._id)) {
          tempRole.isChecked = false;
          selectedRoles.splice(
            selectedRoles.findIndex((selected) => selected._id === role._id),
            1
          );
        }
        return tempRole;
      })
    );
    setselectedRoles(selectedRoles);
  };

  useEffect(() => {
    fetchCustomPages();
    fetchAllRoles();
  }, []);


  useEffect(() => {
    if (formContent?.one === null) {
      removeAllRoles();
    }
  }, [formContent]);

  useEffect(() => {
    if (selectedRoles.length) {
      setValue('access', selectedRoles, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue('access', [], { shouldDirty: false, shouldValidate: true });
    }
  }, [selectedRoles]);

  useEffect(() => {
    if (expiresOn) {
      setValue('expiresOn', expiresOn, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue('expiresOn', null, { shouldDirty: false, shouldValidate: true });
    }
  }, [expiresOn]);

  const minDate = new Date();

  const onRoleChange = (e, role, indexKey) => {
    const newRoles = [...roles];
    const findSelectedIndex = selectedRoles
      .map((searchRole) => searchRole._id)
      .indexOf(role._id);

    const newSelectedRoles = [...selectedRoles];

    if (e.target.checked) {
      newRoles[indexKey].isChecked = true;
      newSelectedRoles.push(role);
    } else {
      newRoles[indexKey].isChecked = false;
      newSelectedRoles.splice(findSelectedIndex, 1);
    }
    setRoles(newRoles);
    setselectedRoles(newSelectedRoles);
  };

  // const onMenuOpen = (elementName) => {
  //   scroller.scrollTo(elementName, {
  //     duration: 1500,
  //     delay: 100,
  //     smooth: true,
  //     offset: 50, // Scrolls to element + 50 pixels down the page
  //   });
  // };

  return (
    <div>
      <Row>
        <Col md="12">
          <Form.Group controlId="formBasicText">
            <label className="txt-light-secondary">Pop Up Title</label>
            <Form.Control {...register('title')} type="text" placeholder="" />
            {errors.title && (
              <p className="text-danger">{errors.title.message}</p>
            )}
          </Form.Group>
        </Col>
      </Row>
      {roles.length ? (
        <Row>
          <Col md="12" xs="12">
            <label className="txt-light-secondary">Accessible by </label>
          </Col>
          {roles.map((role, index) => {
            return (
              <Col md="3" xs="4" key={role._id}>
                <Form.Group controlId="formBasicCheckbox">
                  <input
                    type="checkbox"
                    className="ftc-checkbox"
                    id={role._id}
                    checked={role.isChecked}
                    onChange={(e) => {
                      onRoleChange(e, role, index);
                    }}
                  />
                  <Form.Label
                    data-content={roles.rolename}
                    className="position-relative ml-4 pl-2"
                  >
                    {role.rolename}
                  </Form.Label>
                </Form.Group>
              </Col>
            );
          })}

          {errors.access && showAccessError && (
            <Col md="12">
              <p className="text-danger">{errors.access.message}</p>
            </Col>
          )}
        </Row>
      ) : (
        ''
      )}
      <Row>
        <Col md="12">
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="txt-light-secondary">
              Select Location
            </Form.Label>
            <Controller
              render={({ name, field: { onChange, value } }) => (
                <Select
                  className="popup-directory"
                  name={name}
                  id="popupDirName"
                  isMulti={false}
                  closeMenuOnSelect
                  // components={animatedComponents}
                  options={sideBarData}
                  // value={selectedLocation}
                  value={value === undefined ? null : value}
                  onChange={(option) => {
                    onChange(option);
                  }}
                  styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
                  onMenuOpen={() => {
                    // onMenuOpen('popupDirName');
                  }}
                  menuShouldScrollIntoView
                />
              )}
              name="popup_location"
              control={control}
            />
            {errors.popup_location && (
              <p className="text-danger">{errors.popup_location.message}</p>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form.Group controlId="formBasicText">
            <label className="txt-light-secondary">Pop-up Expires On</label>
            <Controller
              render={({ field: { ref, name, onChange } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                  <KeyboardDatePicker
                    name={name}
                    clearable
                    placeholder="Select Date"
                    value={expiresOn || null}
                    onChange={(e) => {
                      onChange(
                        moment(e)
                          // .utc()
                          // .utcOffset(0)
                          .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                          })
                          .toDate()
                      );
                      setExpiresOn(
                        moment(e)
                          .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                          })
                          .toDate()
                      );
                    }}
                    format="MM/dd/yyyy"
                    // ref={ref}
                    innerRef={ref}
                    // showTodayButton
                    // disabled={disabled}
                    InputProps={{
                      readOnly: true,
                    }}
                    keyboardIcon={
                      <Image
                        className="ftc-logo-24"
                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-calendar.svg`}
                      />
                    }
                    // disableFuture={disableFuture}
                    minDate={minDate}
                    minDateMessage=""
                    clearLabel={false}
                  />
                </MuiPickersUtilsProvider>
              )}
              name="expiresOn"
              control={control}
              defaultValue={null}
            />
            {showAccessError && errors.expiresOn && (
              <p className="text-danger">{errors.expiresOn.message}</p>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="border-bottom" />
        </Col>
      </Row>
    </div>
  );
});

export default CustomPopupDescriptionForm;

CustomPopupDescriptionForm.propTypes = {
  formContent: PropTypes.any.isRequired,
  showAccessError: PropTypes.bool.isRequired,
};
