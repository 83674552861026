import React, { memo, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
// import { useMediaQuery } from 'react-responsive';

import { openDB } from 'idb';
import { Image, Row, Col } from 'react-bootstrap';
import './css/sideBar.css';

// import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
import { RiArrowUpSFill } from 'react-icons/ri';
import { trackPromise } from 'react-promise-tracker';

import packageJson from '../../../package.json';
import SidebarData from './SidebarData';
import SubMenu from './SubMenu';
import Toaster from '../../components/Toaster';
import ConfirmAlertModal from '../../components/ConfirmAlertModal/ConfirmAlertModal';

import MobileSidebarIcon from '../../components/MobileSidebarIcon/MobileSidebarIcon';
import { Desktop, Mobile } from '../media-queries/mediaQueries';

import { useAuthContext } from '../../contexts/user-context';
import { getCustomPageByLocation } from '../../services/custom_page.service';
import useBoolean from '../../utils/CustomHooks/useBoolean';
import { reimbursementAccessible } from '../../services/reimbursement_reason.services';

// import SideNav from './SideNav';

// const Nav = styled.div`
//   background: black;
//   height: 80px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// `;
// const NavIcon = styled.div`
//     width: 12rem;
//     height: 3rem;
//     margin-top: 11px;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
// `;

const NavIcon = styled(Link)`
  margin-left: 1rem;
  font-size: 2rem;
  height: 3rem;
  margin-top: 11px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  overflow-y: scroll;
  transition: 350ms;
  z-index: 101;
  top: 0;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;
const userIcon = `${process.env.PUBLIC_URL}/assets/user-icon.png `;
const Sidebar = memo(({ isSidebarOpen, closeSidebar }) => {
  const { authUser, setAuthUser } = useAuthContext();
  const history = useHistory();
  const locationHook = useLocation();

  const [sidebar, setSidebar] = useState(isSidebarOpen);
  const [sideBarData, setSideBarData] = useState(SidebarData);
  // show modal
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [isReimbursementAccessible,setIsReimbursementAccessible] = useState();

  // setSidebar
  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  // showLogoutDropdown
  const [showLogoutDropdown, setLogoutDropdown] = useState(false);

  const toggleLogoutDropdown = (event) => {
    if (event.target.id === 'logout-btn') return;
    setLogoutDropdown(!showLogoutDropdown);
  };

  const onLogout = async () => {
    try {
      const dbs = await openDB('order', 1, {
        upgrade(db) {
          // Create a store of objects
          const store = db.createObjectStore('order_requests', {
            // The 'uniqueId' property of the object will be the key.
            keyPath: 'uniqueId',
            // If it isn't explicitly set, create a value by auto incrementing.
            // autoIncrement: true,
          });
          // Create an index on the 'date' property of the objects.
          store.createIndex('uniqueId', 'uniqueId');
        },
      });
      const value = await dbs.getAll('order_requests');
      // console.log('Values for offline timesheets:', { value });
      // check if offline timesheets exists then user can not logout
      if (value.length) {
        showModal();
      } else {
        localStorage.clear();
        // clear indexedDB from if user is getting logged out
        const allDBs = await window.indexedDB.databases();
        allDBs.forEach((db) => {
          window.indexedDB.deleteDatabase(db.name);
        });

        setAuthUser({});
      }
    } catch (error) {
      if (error.name === 'NotFoundError') {
        localStorage.clear();
        const allDBs = await window.indexedDB.databases();
        allDBs.forEach((db) => {
          window.indexedDB.deleteDatabase(db.name);
        });
        setAuthUser({});
      }
    }
  };

  const onTimesheetsClick = () => {
    // if we are on the same page then close the sidebar otherwise redirect it
    switch (locationHook.pathname) {
      case '/timesheet':
        showSidebar();
        hideModal();
        break;

      default:
        history.push('/timesheet');
    }
  };

  const onLinkSelect = (selectedLink) => {
    if (locationHook.pathname === selectedLink) {
      showSidebar();
      closeSidebar();
    }
  };

  async function fetchCustomPages() {
    try {
      const response = await getCustomPageByLocation();

      const updatedSideBarData = SidebarData;

      const toolsData = updatedSideBarData.findIndex(
        (sidebarObj) => sidebarObj.title === 'Tools'
      );
      updatedSideBarData[toolsData].subNav = [];

      const reportsData = updatedSideBarData.findIndex(
        (sidebarObj) => sidebarObj.title === 'Reports'
      );

      updatedSideBarData[reportsData].subNav = [
        {
          title: 'Hours',
          path: '/reports/hours',
          icon: (
            <Image
              className="ftc-logo-28"
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icons-timesheet.svg`}
              fluid
            />
          ),
        },
        {
          title: 'Payroll',
          path: '/reports/payroll',
          icon: (
            <Image
              className="ftc-logo-28"
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-money.svg`}
              fluid
            />
          ),
        },
      ];

      const formsData = updatedSideBarData.findIndex(
        (sidebarObj) => sidebarObj.title === 'Forms'
      );

      updatedSideBarData[formsData].subNav = [];

      await response.data.data.customPages.forEach(async (location) => {
        switch (location._id) {
          case 'tools': {
            if (location.total >= 1) {
              await location.data.map((customPage) => {
                const tempPage = {};
                tempPage.title = customPage.button_label;
                tempPage.path = customPage.route;
                tempPage.icon = (
                  <Image
                    className="svg__sub-icon__size"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                    fluid
                  />
                );

                if (
                  updatedSideBarData[toolsData].subNav.findIndex(
                    (nav) => nav.path === customPage.route
                  ) === -1
                ) {
                  updatedSideBarData[toolsData].subNav.push(tempPage);
                }

                return tempPage;
              });
            }
            break;
          }

          case 'reports': {
            if (location.total >= 1) {
              await location.data.map((customPage) => {
                const tempPage = {};
                tempPage.title = customPage.button_label;
                tempPage.path = customPage.route;
                tempPage.icon = (
                  <Image
                    className="svg__sub-icon__size"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                    fluid
                  />
                );
                if (
                  updatedSideBarData[reportsData].subNav.findIndex(
                    (nav) => nav.path === customPage.route
                  ) === -1
                ) {
                  updatedSideBarData[reportsData].subNav.push(tempPage);
                }
                return tempPage;
              });
              // console.log({ newReports });
            }
            break;
          }

          case 'forms': {
            if (location.total >= 1) {
              await location.data.map((customPage) => {
                const tempPage = {};
                tempPage.title = customPage.button_label;
                tempPage.path = customPage.route;
                tempPage.icon = (
                  <Image
                    className="svg__sub-icon__size"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                    fluid
                  />
                );
                if (
                  updatedSideBarData[formsData].subNav.findIndex(
                    (nav) => nav.path === customPage.route
                  ) === -1
                ) {
                  updatedSideBarData[formsData].subNav.push(tempPage);
                }
                return tempPage;
              });
              // console.log({ newReports });
            }
            break;
          }

          default:
            break;
        }
      });

      setSideBarData(updatedSideBarData);
    } catch (error) {
      console.log(error);
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function isReimbursementVisible(){
    try {
      const role = authUser.user.role._id;
      const response = await trackPromise(reimbursementAccessible(role));
      setIsReimbursementAccessible(response.data.data.accessible);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={
            error?.response?.data?.message ?? 'Something went wrong!!!'
          }
        />
      );
    }
  }

  useEffect(() => {
    fetchCustomPages();
    isReimbursementVisible();
  }, []);

  useEffect(() => {
    setSidebar(isSidebarOpen);
    // showSidebar();
  }, [isSidebarOpen]);

  return (
    <>
      <div>
        <Desktop>
          <SidebarNav
            className={`DesktopView ${
              !sidebar ? 'SidebarHide' : 'SidebarShow'
            }`}
            sidebar={!sidebar}
          >
            <SidebarWrap className="sticky-top overflow-auto">
              <div className="sticky-top-logo bg-white">
                <NavIcon to="/" className=" desktop__nav__icon__width mt-0">
                  <Image
                    className="img-fluid sidebar__logo__size"
                    src={`${process.env.PUBLIC_URL}/assets/sidebar-logo.svg`}
                  />
                </NavIcon>
                <hr />
              </div>

              {sideBarData.map((item) => {
                return <SubMenu item={item} isReimbursementAccessible={isReimbursementAccessible} />;
              })}

              <div className="sidebar__logo__margin">
                <div
                  onClick={toggleLogoutDropdown}
                  onBlur={() => setLogoutDropdown(false)}
                  onKeyDown={() => {}}
                  tabIndex={0}
                  role="button"
                  className="sidebar-username"
                >
                  <div className="user-info">
                    <div>
                      <Image
                        className="user__icon__size"
                        src={
                          authUser.user.profile_picture === ''
                            ? userIcon
                            : authUser.user.profile_picture_url
                        }
                        onError={(e) => {
                          e.target.src = userIcon; // Set fallback image if src image fails to load
                        }}
                      />
                    </div>

                    <div className="username">{authUser.user.username}</div>

                    <div>
                      {showLogoutDropdown ? (
                        <RiArrowUpSFill size="20px" />
                      ) : (
                        <Image
                          className="svg__icon__size__arrow__logout"
                          src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                        />
                      )}
                    </div>
                  </div>
                  {showLogoutDropdown && (
                    <div
                      className="dropdown-content"
                      id="logout-btn"
                      onClick={onLogout}
                      role="presentation"
                    >
                      Logout
                    </div>
                  )}
                </div>
                <div className="version">Version {packageJson.version}</div>
              </div>
            </SidebarWrap>
          </SidebarNav>
        </Desktop>
        {sidebar ? (
          <Mobile>
            <SidebarNav
              className={`MobileView ${
                !sidebar ? 'SidebarHide' : 'SidebarShow'
              }`}
              sidebar={!sidebar}
            >
              <SidebarWrap className="sidebar__width sticky-top overflow-auto">
                <div className="sticky-top-logo bg-white overflow-x-hidden">
                  <Row className="mobile__nav__icon__width  pt-0 pb-0">
                    <Col xs="10" className="pl-0">
                      <NavIcon to="/">
                        <Image
                          className="img-fluid ftc-sidebar-header-logo"
                          src={`${process.env.PUBLIC_URL}/assets/sidebar-logo.svg`}
                        />
                      </NavIcon>
                    </Col>
                    <Col xs="2" className="mt-3">
                      {sidebar ? (
                        <div className="float-right">
                          <a
                            role="button"
                            onClick={() => {
                              showSidebar();
                              closeSidebar();
                            }}
                            onKeyDown={showSidebar}
                            tabIndex={0}
                          >
                            <Image
                              className="ftc-logo-24"
                              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
                            />
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                  <hr className="mb-0 pb-0" />
                </div>
                <div className="mt-3">
                  {sideBarData.map((item) => {
                    return <SubMenu callback={onLinkSelect} item={item} isReimbursementAccessible={isReimbursementAccessible}/>;
                  })}
                </div>

                <div className="sidebar__logo__margin">
                  <div
                    onClick={toggleLogoutDropdown}
                    onBlur={() => setLogoutDropdown(false)}
                    onKeyDown={() => {}}
                    tabIndex={0}
                    role="button"
                    className="sidebar-username"
                  >
                    <div className="user-info">
                      <div>
                        <Image
                          className="user__icon__size"
                          src={
                            authUser.user.profile_picture === ''
                              ? userIcon
                              : authUser.user.profile_picture_url
                          }
                          onError={(e) => {
                            e.target.src = userIcon; // Set fallback image if src image fails to load
                          }}
                        />
                      </div>

                      <div className="username">{authUser.user.username}</div>

                      <div>
                        {showLogoutDropdown ? (
                          <RiArrowUpSFill size="24px" />
                        ) : (
                          <Image
                            className="svg__icon__size__arrow__logout"
                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                          />
                        )}
                      </div>
                    </div>
                    {showLogoutDropdown && (
                      <div
                        className="dropdown-content"
                        id="logout-btn"
                        onClick={onLogout}
                        role="presentation"
                      >
                        Logout
                      </div>
                    )}
                  </div>
                  <div className="version">Version {packageJson.version}</div>
                </div>
              </SidebarWrap>
            </SidebarNav>
          </Mobile>
        ) : (
          <a
            role="button"
            onClick={showSidebar}
            onKeyDown={showSidebar}
            tabIndex={0}
          >
            <MobileSidebarIcon />
          </a>
        )}

        {isVisible && (
          <ConfirmAlertModal
            show={isVisible}
            handleClose={hideModal}
            title="Can't Sign Out"
            description="Please connect to the Internet to sync your offline Timesheets with server."
            callback={onTimesheetsClick}
            action="Offline Timesheets"
            closeAction="Okay"
          />
        )}
      </div>
    </>
  );
});

export default Sidebar;

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool,
  closeSidebar: PropTypes.func,
};

Sidebar.defaultProps = {
  isSidebarOpen: false,
  closeSidebar: () => {},
};
