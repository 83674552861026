import React, { useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// import * as Scroll from 'react-scroll';

const useStyles = makeStyles(() => ({
    root: {
        //     // display: 'flex',
        //     // flexWrap: 'wrap',
        //     // '& > *': {
        //     //     margin: theme.spacing(100),
        //     //     width: theme.spacing(100),
        //     //     height: theme.spacing(100),
        // },
    },
}));


const PrivacyPolicy = () => {
    const classes = useStyles();
    // const { scroller } = Scroll;

    // const onMenuOpen = (elementName) => {
    //     scroller.scrollTo(elementName, {
    //         duration: 1500,
    //         delay: 100,
    //         smooth: false,
    //         offset: 0, // Scrolls to element + 50 pixels down the page      
    //     });
    // };

    useEffect(() => {
        // onMenuOpen('scroll');
    }, [])

    return (
        <div id="scroll">
            <div className="container mt-5 mb-4">
                <div className={classes.root}>
                    <Paper elevation={3} className="p-4">
                        <Row>
                            <Col md="12">
                                <h1 className="txt-primary text-center">Privacy Policy</h1>
                            </Col>
                        </Row>

                        <p className="font-size">This privacy policy outlines how Friend That Cooks uses and protects any information that you give Friend That Cooks when you use this website.</p>
                        <p className="font-size">Friend That Cooks is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>
                        <p className="font-size">Friend That Cooks may change this policy from time to time by updating this page. This policy is effective from January 1st, 2021.</p>
                        <h4 className="txt-primary">What we collect</h4>
                        <p className="font-size">We may collect the following information:</p>
                        <ul>
                            <li>Name</li>
                            <li>Contact information including email address</li>
                            <li>Demographic information such as postcode, preferences and interests</li>
                            <li>Other information relevant to customer surveys and/or offers</li>
                        </ul>



                        <h4 className="txt-primary"> What we do with the information we gather</h4>
                        <p className="font-size">Information collected helps us to understand your needs and provide you with better service, and in particular for the following reasons:</p>
                        <ul>
                            <li>Internal record keeping.</li>
                            <li>We may use the information to improve our products and services.</li>
                            <li>We may periodically send promotional email about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
                            <li>We may contact you by email, phone, mail.</li>
                            <li><strong>We will never distribute, lease, or sell your information.</strong></li>
                        </ul>


                        <h4 className="txt-primary">Security</h4>
                        <p className="font-size">We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>


                        <h4 className="txt-primary">How we use cookies</h4>
                        <p className="font-size">A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>

                        <p className="font-size">We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>

                        <p className="font-size">Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>

                        <p className="font-size">You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer.</p>


                        <h4 className="txt-primary">Controlling your personal information</h4>
                        <p className="font-size">You may choose to restrict the collection or use of your personal information in the following ways:</p>
                        <ul>
                            <li>
                                Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes.
                            </li>
                            <li>
                                If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by emailing us at <a href="mailto:personalchefs@friendthatcooks.com">personalchefs@friendthatcooks.com</a>.
                            </li>
                            <li>
                                <strong>We will not sell, distribute or lease your personal information to third parties unless required by law. We will not use your personal information to send you promotional information about third parties.</strong>
                            </li>
                        </ul>
                        <p className="font-size">If you believe that any information you have provided about yourself is inaccurate or incomplete and would like your information updated, please email us at
                            <a href="mailto:personalchefs@friendthatcooks.com"> personalchefs@friendthatcooks.com</a>.</p>
                    </Paper>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
