// import React, { createContext, Component } from 'react';

// export const UsersContext = createContext();

// class UsersContextProvider extends Component {
//     state = {
//         userRole: 'Admin',
//         id: '1234',
//         token: 'KSJEksderojewfLWKWDFHWJDJFNJUEFNE'
//     }

//     render() {
//         return (
//             <UsersContext.Provider value={{...this.state}}>
//                 {this.props.children}
//             </UsersContext.Provider>
//         );
//     }
// }

// export default UsersContextProvider;

import { createContext, useContext } from 'react';

export const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}
