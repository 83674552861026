import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const getAllTimesheets = (pagination, page, employee, client, queryString) => {
    return API.get(`${API_ENDPOINTS.TIMESHEETS.Timesheets}?pagination=${pagination}&page=${page}&employee=${employee}&client=${client}${queryString}`);
}

const createTimesheet = (body) => {
    return API.post(API_ENDPOINTS.TIMESHEETS.Timesheets, body);
}

const getTimesheetByTimesheetId = (timesheetId) => {
    return API.get(`${API_ENDPOINTS.TIMESHEETS.Timesheets}/${timesheetId}`);
}

const deleteTimesheetById = (timesheetId) => {
    return API.del(`${API_ENDPOINTS.TIMESHEETS.Timesheets}/${timesheetId}`)
}

const updateTimesheetById = (timesheetId, body) => {
    return API.put(`${API_ENDPOINTS.TIMESHEETS.Timesheets}/${timesheetId}`, body);
}

const updatePaymentStatus = (timesheetId, body) => {
    return API.put(`${API_ENDPOINTS.TIMESHEETS.UpdatePaymentStatus}/${timesheetId}`, body);
}

export {
    getAllTimesheets,
    createTimesheet,
    getTimesheetByTimesheetId,
    deleteTimesheetById,
    updateTimesheetById,
    updatePaymentStatus
};

