import React from 'react';
import {
    // Button, 
    Row, Col, Image,
    // InputGroup, 
    // Form,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SomethingWentWrong = () => {
    return (
      <div>
        <div className="text-center mt-5">
          <Row>
            <Col md="12" xs="12" className="mb-5">
              <div>
                <Image
                  src={`${process.env.PUBLIC_URL}/assets/svg-icons/group-2971.svg`}
                  fluid
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12" xs="12" className="mb-5">
              <div>
                <Image
                  src={`${process.env.PUBLIC_URL}/assets/svg-icons/505-illustrations.svg`}
                  fluid
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12" xs="12" className="mb-2">
              <h2 className="txt-primary">
                <strong>Oops! Something went wrong.</strong>
              </h2>
            </Col>
            <Col md="12" xs="12">
              <p>
                We apologize for the internal server error. We are pushing our
                best efforts to get this fixed.
              </p>
            </Col>
            <Col md="12" xs="12">
              <Link to="/">
                <button type="button" className="btn btn-ftc-primary">
                  Go back
                </button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    );
}

export default SomethingWentWrong;
