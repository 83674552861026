import React, { memo } from 'react';
import { Row, Form, Col, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import SettingsDropdown from '../../../../components/SettingsDropdown/SettingsDropdown';

import './CitiesMobileTable.css'
import { getUserStatusLabel } from '../../../../utils/UserManagement';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});
const CitiesMobileTable = memo(({
    city,
    handleViewClick,
    handleEditClicked,
    handleClickDelete,
    callback,
    isEditable,
    isDeletable,
}) => {
    const classes = useStyles();

    return (
        <div className="mt-3" onClick={(event) => {
            const settingsDropDown = event.target.closest('#settings-dropdown');
            if (!settingsDropDown) {
                callback(city)
            }
        }} onKeyDown={() => { }} role="button" tabIndex={0}>
            <Card className={`${classes.root} mt-2`}>
                <CardContent>
                    <Row className="pl-0 pb-0 pt-0 border-bottom">
                        <Col xs="8" className="pl-1 mt-2">
                            <Form.Group className="mb-2" controlId="formBasicServiceCity">
                                <Image
                                    className="mr-2 ml-1 align-top ftc-logo-24"
                                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-location-red.svg`}
                                />
                                <Form.Label><strong>{city.cityname}</strong></Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs="4" className="p-0">
                            <div>
                                <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                    <Form.Label className={city.status === 'active' ? 'label-active' : 'label-inactive'}>
                                        <div className={city.status === 'active' ? 'active-ellipse' : 'inactive-ellipse'} />
                                        {` `}{getUserStatusLabel(city.status)}
                                    </Form.Label>
                                </Form.Group>
                            </div>
                        </Col>
                        <Col xs="12" className="pr-0 ml-2">
                            <span className="ellipse__cities__mobile float-right">
                                <SettingsDropdown
                                    identifier={city._id}
                                    handleViewClick={handleViewClick}
                                    handleEditClicked={handleEditClicked}
                                    handleClickDelete={handleClickDelete}
                                    isEditable={isEditable}
                                    isDeletable={isDeletable}
                                />
                            </span>
                        </Col>
                    </Row>
                    <Row className="border-bottom pl-0 pb-1" >
                        <Col xs="6">
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <label className="mb-0 txt-light-secondary" >
                                    Basic Rate
                                </label>
                                <p className="mb-1">$ {city.basic_rate.toFixed(2)}</p>
                            </Form.Group>

                        </Col>
                        <Col xs="6">
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <Form.Label className="mb-0 txt-light-secondary" >
                                    Prime Rate
                                </Form.Label>
                                <p className="mb-1">$ {city.prime_rate.toFixed(2)}
                                </p>
                            </Form.Group>

                        </Col>

                    </Row>
                    <Row className="border-bottom pl-0" >

                        <Col xs="6">
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <Form.Label className="mb-0 txt-light-secondary" >
                                    Special Rate
                                </Form.Label>
                                <p className="mb-1">$ {city.special_rate.toFixed(2)}
                                </p>
                            </Form.Group>
                        </Col>
                        <Col xs="6">
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <Form.Label className="mb-0 txt-light-secondary" >
                                    Holding Fee
                                </Form.Label>
                                <p className="mb-1">$ {city.holding_fee.toFixed(2)}
                                </p>
                            </Form.Group>

                        </Col>
                    </Row>

                    <Row className="border-bottom pl-0">
                        <Col xs="6">
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <Form.Label className="mb-0 txt-light-secondary" >
                                    EMPL Share
                                </Form.Label>
                                <p className="mb-1">$ {city?.empl_share?.toFixed(2) ?? '0.00'}
                                </p>
                            </Form.Group>

                        </Col>
                    </Row>
                </CardContent>
            </Card >
        </div>
    )
});

export default CitiesMobileTable

CitiesMobileTable.propTypes = {
    city: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    handleViewClick: PropTypes.func.isRequired,
    handleEditClicked: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isDeletable: PropTypes.bool.isRequired,
};