import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const createReimbursementReason = (body) =>
{
    return API.post(`${API_ENDPOINTS.REIMBURSEMENT_REASONS.ReimbursementReasons}`, body);
}

const getAllReimbursementReasonsName = () =>
{
  return API.get(`${API_ENDPOINTS.NON_BILLABLES.NonBillablesByName}`);
};

const getAllReimbursementReasons = (pagination, page, reason) =>
{
  return API.get(`${API_ENDPOINTS.REIMBURSEMENT_REASONS.ReimbursementReasons}?pagination=${pagination}&page=${page}&reason=${reason}`);
};

const getReimbursementReasonById = (reimbursementReasonId) =>
{
  return API.get(`${API_ENDPOINTS.REIMBURSEMENT_REASONS.ReimbursementReasons}/${reimbursementReasonId}`);
};

const updateReimbursementReason = (reimbursementReasonId, body) =>
{
  return API.put(`${API_ENDPOINTS.REIMBURSEMENT_REASONS.ReimbursementReasons}/${reimbursementReasonId}`, body);
}

const deleteReimbursementReasonById = (reimbursementReasonId) =>
{
  return API.del(`${API_ENDPOINTS.REIMBURSEMENT_REASONS.ReimbursementReasons}/${reimbursementReasonId}`);
}

const getReimbursementReasonByAccess = (roleId) =>
{
  return API.get(`${API_ENDPOINTS.REIMBURSEMENT_REASONS.ReimbursementReasonByName}/${roleId}`);
};

const reimbursementAccessible = (roleId) =>{
    return API.get(`${API_ENDPOINTS.REIMBURSEMENT_REASONS.ReimbursementAccessible}/${roleId}`)
}

export
{
    createReimbursementReason,
    getAllReimbursementReasonsName,
    getAllReimbursementReasons,
    getReimbursementReasonById,
    updateReimbursementReason,
    deleteReimbursementReasonById,
    getReimbursementReasonByAccess,
    reimbursementAccessible
};