import React, { memo, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useHistory, Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Row, Col, Image, NavDropdown, Card, } from 'react-bootstrap';

import { trackPromise } from 'react-promise-tracker';
import Ellipses from '../../../components/Ellipses';
import Toaster from '../../../components/Toaster';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';

import { deleteRoleByID, getRoleByID } from '../../../services/role-service';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';
import { DateConvert } from '../../../utils/TimesheetManagement';
import { getPermissionLabel, validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';

const ViewUserRole = memo(() =>
{
    const { authUser } = useAuthContext();
    const history = useHistory();
    const { roleId } = useParams();

    const [isAPICalled, setAPICalled] = useState(false);
    const [role, setRole] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onEditRole = () =>
    {
        history.push(`/settings/editUserRole/${roleId}`);
    };

    async function getRoleDetails()
    {
        try
        {
            const response = await trackPromise(getRoleByID(roleId));
            setRole(response.data.data.role);
            setAPICalled(true);
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    const getPermissionImage = (permissions, type) =>
    {
        if (permissions.includes(type))
        {
            return `${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`
        }
        
        return `${process.env.PUBLIC_URL}/assets/svg-icons/icon-clear-grey.svg`;
        
    };

    const onDeleteRole = async () =>
    {
        try
        {
            const response = await trackPromise(deleteRoleByID(roleId));
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
            history.replace('/settings/userroles');
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally
        {
            handleClose();
        }
    }

    useEffect(() =>
    {
        getRoleDetails();
    }, [roleId]);

    const SettingsDropdown = () =>
    {
        return (<div>
            <NavDropdown
                title={<Ellipses />}
                id="collasible-nav-dropdown"
            >
                <NavDropdown.Item
                    onClick={handleShow}
                    disabled={!validatePermission(authUser.user.role.permissions, 'user_access', 'delete')}
                >Delete
          </NavDropdown.Item>
            </NavDropdown>
        </div>);
    };
    
    return isAPICalled ? (
        <div>
            <div className="sticky-page-header">
                <div className="container">
                    <Desktop>
                        <Row className="ml-5">
                            <Col md="1">
                                <Link to='/settings/userroles'>
                                    <a>
                                        <Image
                                            className="imageArrow"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </a>
                                </Link>
                            </Col>
                            <Col md="8">
                                <h5 className="mb-0">
                                    <strong>{role.rolename}</strong>
                                </h5>
                                <p className="txt-light-secondary mb-0">
                                    Last Updated on {DateConvert(role.updatedAt)}
                                </p>
                            </Col>
                            <Col md="1" className="pr-0">
                                <Button variant="default" className="btn-ftc-border-primary ml-5"
                                    onClick={onEditRole}
                                    disabled={!validatePermission(authUser.user.role.permissions, 'user_access', 'edit')}
                                >
                                    Edit
                            </Button>
                            </Col>
                            <Col md="1">
                                <div className="ml-5">
                                    <SettingsDropdown />
                                </div>
                            </Col>
                        </Row>
                    </Desktop>
                    <Mobile>
                        <div className="container">
                            <Row className="pb-0">
                                <Col xs="1" className="p-0">
                                    <Link to="/settings/userroles">
                                        <Image
                                            className="imageArrowMobile mt-1"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </Link>
                                </Col>
                                <Col xs="9" className="mt-1">
                                    <h6 className="m-auto"><strong>{role.rolename}</strong> </h6>
                                    <p className="txt-light-secondary mx-auto">
                                        Last Updated on {DateConvert(role.updatedAt)}
                                    </p>
                                </Col>
                                <Col xs="1">
                                    <div>
                                        <SettingsDropdown />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Mobile>
                </div>
            </div>
            <Desktop>
                <div className="container-top-8" />
            </Desktop>
            <Mobile>
                <div className="container-top-20" />
            </Mobile>
            <div className="container mt-5">
                <Row>
                    <Col md="8" className="mx-auto">
                        <Desktop>
                            <Card>
                                <Card.Body className="pt-0 pb-2">
                                    <Row>
                                        <Col md="12">
                                            <h5 className="txt-primary"><strong>Role Name</strong></h5>
                                        </Col>
                                    </Row>
                                    <Row className="pb-2">
                                        <Col md="6" xs="6">
                                            <label className="txt-light-secondary">Access Role Label</label>
                                        </Col>
                                        <Col md="6" xs="6">
                                            <label htmlFor={role.rolename} className="float-right"><strong>{role.rolename}</strong></label>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Desktop>
                        <Mobile>
                            <Card>
                                <Card.Body className="p-0">
                                    <Row className="pb-0">
                                        <Col xs="12">
                                            <h5 className="txt-primary"><strong>Role Name</strong></h5>
                                        </Col>
                                    </Row>
                                    <Row className="pb-0">
                                        <Col xs="6">
                                            <label className="txt-light-secondary">Access Role Label</label>
                                        </Col>
                                        <Col xs="6">
                                            <label className="float-right"><strong>{role.rolename}</strong></label>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Mobile>
                        <Card className="mt-3">
                            <Card.Body className="pt-0 pb-2">
                                <Desktop>
                                    <Row>
                                        <Col md="12">
                                            <h5 className="txt-primary"><strong>Assigned Privilages</strong></h5>
                                        </Col>
                                    </Row>
                                    <Row className="border-bottom pb-2">
                                        <Col md="6">
                                            <label>Section</label>
                                        </Col>
                                        <Col>
                                            <label>New</label>
                                        </Col>
                                        <Col>
                                            <label>View</label>
                                        </Col>
                                        <Col>
                                            <label>Edit</label>
                                        </Col>
                                        <Col>
                                            <label>Delete</label>
                                        </Col>
                                    </Row>
                                    {Object.keys(role.permissions).map((permission, index) =>
                                    {
                                        return permission !== '_id' ? (
                                            <>
                                                <Row className={Object.keys(role.permissions).length - 2 === index ? `pb-0` : `border-bottom pb-0`}>
                                                    <Col md="6">
                                                        <label><strong>{getPermissionLabel(permission)}</strong></label>
                                                    </Col>
                                                    <Col>
                                                        <Image
                                                            src={getPermissionImage(role.permissions[permission], 'add')}
                                                            fluid
                                                        />{' '}
                                                    </Col>
                                                    <Col>
                                                        <Image
                                                            src={getPermissionImage(role.permissions[permission], 'view')}
                                                            fluid
                                                        />{' '}
                                                    </Col>
                                                    <Col>
                                                        <Image
                                                            src={getPermissionImage(role.permissions[permission], 'edit')}
                                                            fluid
                                                        />{' '}
                                                    </Col>
                                                    <Col>
                                                        <Image
                                                            src={getPermissionImage(role.permissions[permission], 'delete')}
                                                            fluid
                                                        />{' '}
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : ('')
                                    })}
                                        
                                    
                                                                        
                                </Desktop>
                                <Mobile>
                                    <Row>
                                        <Col xs="12" className="p-0">
                                            <h5 className="txt-primary"><strong>Assigned Privileges</strong></h5>
                                        </Col>
                                    </Row>

                                    {Object.keys(role.permissions).map((permission, index) =>
                                    {
                                        return permission !== '_id' ? (
                                            <Row className={Object.keys(role.permissions).length - 2 === index ? `` : `border-bottom`}>
                                                <Col xs="12" className="p-0">
                                                    <label><strong>{getPermissionLabel(permission)}</strong></label>
                                                </Col>
                                                <Col xs="3" className="p-0">
                                                    <label> <Image
                                                        src={getPermissionImage(role.permissions[permission], 'add')}
                                                        fluid
                                                    />New</label>
                                                </Col>
                                                <Col xs="3" className="p-0">
                                                    <label> <Image
                                                        src={getPermissionImage(role.permissions[permission], 'view')}
                                                        fluid
                                                    />View</label>
                                                </Col>
                                                <Col xs="3" className="p-0">
                                                    <label> <Image
                                                        src={getPermissionImage(role.permissions[permission], 'edit')}
                                                        fluid
                                                    />Edit</label>
                                                </Col>
                                                <Col xs="3" className="p-0">
                                                    <label> <Image
                                                        src={getPermissionImage(role.permissions[permission], 'delete')}
                                                        fluid
                                                    />Delete</label>
                                                </Col>
                                            </Row>

                                        ) : ('')
                                    })}
                                </Mobile>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Mobile>
                    <Row className="border-top-shadow">
                        <Col xs={12} className="pr-3">
                            <Button
                                variant="default"
                                onClick={onEditRole}
                                disabled={!validatePermission(authUser.user.role.permissions, 'user_access', 'edit')}
                                className="float-right btn-ftc-border-primary col-3"
                            >
                                Edit
                            </Button>
                        </Col>
                    </Row>
                </Mobile>
            </div>
            {show && <ConfirmAlertModal
                show={show}
                handleClose={handleClose}
                title='Delete User Role'
                description='Deleting this User Role will erase all data inside this record.
                Are you sure you want to delete this User Role?'
                callback={onDeleteRole}
                action='Delete it'
                closeAction='Cancel'
            />}
        </div>
    ) : ('')
});

export default ViewUserRole
