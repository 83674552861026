import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const getAllCities = (pagination, page, cityname, queryString) =>
{    
    return API.get(`${API_ENDPOINTS.CITIES.Cities}?pagination=${pagination}&page=${page}&cityname=${cityname}${queryString}`);
};

const getCityById = (cityId) =>
{
    return API.get(`${API_ENDPOINTS.CITIES.Cities}/${cityId}`);
}

const updateCityById = (cityId, body) =>
{
    return API.put(`${API_ENDPOINTS.CITIES.Cities}/${cityId}`, body);
}

const getAllCitiesByName = () => {
    return API.get(API_ENDPOINTS.CITIES.AllCitiesByName);
}

const getAllCitiesForReport = () => {
    return API.get(API_ENDPOINTS.CITIES.AllCitiesForReport);
}

const createCity = (body) =>
{
    return API.post(API_ENDPOINTS.CITIES.Cities, body);
};

const deleteCityById = (cityId) => {
    return API.del(`${API_ENDPOINTS.CITIES.Cities}/${cityId}`);
}

export
{
    getAllCities,
    createCity,
    getAllCitiesByName,
    getAllCitiesForReport,
    getCityById,
    updateCityById,
    deleteCityById
};