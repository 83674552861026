import React, { memo, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap'
import { Controller, useFormContext, } from 'react-hook-form';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
// import * as Scroll from 'react-scroll';

import { trackPromise } from 'react-promise-tracker';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import Toaster from '../../../components/Toaster';

import { getAllUsersByNames } from '../../../services/users-service';
import { getClientsByChef, getClientsByCity } from '../../../services/client-service';
import { useAuthContext } from '../../../contexts/user-context';

// getClientsByCity

const animatedComponents = makeAnimated();

const BasicDetails = memo(({
  formContent, timesheetId, isAddMode, showCityError
}) => {
  const { authUser } = useAuthContext();

  // const { scroller } = Scroll;

  const [employees, setemployees] = useState([]);
  const [selectedEmployee, setselectedEmployee] = useState(
    formContent?.one?.employee ? formContent.one.employee : null
  );

  const [cities, setcities] = useState([]);
  const [selectedCity, setselectedCity] = useState(
    formContent?.one?.service_city ? formContent.one.service_city : null
  );

  const [clients, setclients] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [selectedClient, setselectedClient] = useState(
    formContent?.one?.client ? formContent?.one.client : null
  );
  const [isChefCityAPICalled, setChefCityAPICalled] = useState(false);

  const { control,
    formState: { errors },
    trigger, setValue } = useFormContext();


  async function getAllEmployees() {
    try {
      const response = await trackPromise(getAllUsersByNames());
      setemployees(response.data.data.users);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function getAllClientsByChef() {
    try {
      setChefCityAPICalled(false);
      if (navigator.onLine) {
        const response = await getClientsByChef(selectedEmployee._id);
        if (response.status === 200) {
          setAllClients(response.data.data.clients);
          setChefCityAPICalled(true);
        }
        // warn users that no client is associated with this user
        else if (response.status === 204) {
          setAllClients([]);
          setChefCityAPICalled(true);
        }
      }
      else {
        // Cache Data in Local Storage        
        const apiOfflineClientsData = JSON.parse(localStorage.getItem('clients'));
        setAllClients(apiOfflineClientsData);
        setChefCityAPICalled(true);
      }

    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function getAllClientsByCity() {
    try {
      const response = await trackPromise(getClientsByCity(selectedCity._id));
      if (response.status === 200) {
        setclients(response.data.data.clients);
      }
      // warn users that no client is associated with this user
      else if (response.status === 204) {
        setclients([]);
        toast.dark(
          <Toaster
            icon="warning"
            message={`No client(s) associated with ${selectedEmployee.username}.`}
          />
        );
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  useEffect(() => {
    switch (authUser.user.role.rolename) {
      case 'Admin':
      case 'Manager':
        getAllEmployees();
        break;

      case 'Chef':
      case 'Office':
        setemployees([authUser.user]);
        if (isAddMode) {
          setselectedEmployee(authUser.user);
        }
        break;

      case 'Accountant':
        setemployees([authUser.user]);
        break;

      default:
        break;
    }

  }, []);

  useEffect(() => {
    // console.log({ formContent });
    if (formContent?.one === null) {
      setselectedEmployee(null);
      setselectedCity(null);
      setselectedClient(null);
      setcities([]);
      setclients([]);
    }
  }, [formContent]);

  useEffect(() => {
    if (selectedEmployee) {
      // set value on change for employee
      setValue('employee', selectedEmployee, { shouldDirty: true, shouldValidate: true });
      if (formContent?.one?.employee._id !== selectedEmployee._id) {
        setselectedCity(null);
        setclients([]);
        setselectedClient(null);
      }

      setcities(selectedEmployee.service_city.filter((city) => city.status !== 'inactive'));
      if (selectedEmployee.role.rolename === 'Chef' && authUser.user.role.rolename !== 'Office') {
        getAllClientsByChef();
      }
      // else {
      //   console.log('eemployee is not chef')
      // }
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (selectedCity) {
      if (formContent?.one?.service_city._id !== selectedCity._id) {
        // console.log('city not same')
        setselectedClient(null);
        // assign new city rates if city has been changed in Edit Mode
        if (!isAddMode) {
          setValue('non_billable_rate', selectedCity.non_billable_rate, { shouldDirty: true, shouldValidate: true });
          setValue('basic_rate', selectedCity.basic_rate, { shouldDirty: true, shouldValidate: true })
          setValue('prime_rate', selectedCity.prime_rate, { shouldDirty: true, shouldValidate: true })
          setValue('special_rate', selectedCity.special_rate, { shouldDirty: true, shouldValidate: true })
          setValue('holding_fee', selectedCity.holding_fee, { shouldDirty: true, shouldValidate: true })
        }
      }

      setValue('service_city', selectedCity, { shouldDirty: true, shouldValidate: true });
      if (isAddMode) {
        setValue('non_billable_rate', selectedCity.non_billable_rate, { shouldDirty: true, shouldValidate: true });
        setValue('basic_rate', selectedCity.basic_rate, { shouldDirty: true, shouldValidate: true })
        setValue('prime_rate', selectedCity.prime_rate, { shouldDirty: true, shouldValidate: true })
        setValue('special_rate', selectedCity.special_rate, { shouldDirty: true, shouldValidate: true })
        setValue('holding_fee', selectedCity.holding_fee, { shouldDirty: true, shouldValidate: true })
        setValue('empl_share', selectedCity.empl_share, { shouldDirty: true, shouldValidate: true })
      }
      // else {
      //   console.log('edit mode of city selection');
      // }
      // fetch cities if user role is not chef
      if (selectedEmployee.role.rolename !== 'Chef' && authUser.user.role.rolename !== 'Office') {
        getAllClientsByCity();
      }
      else if (selectedEmployee.role.rolename === 'Chef') {
        if (isChefCityAPICalled) {
          const filteredClients = allClients?.filter((client) => {
            return client.service_city._id === selectedCity._id
          });
          if (!filteredClients?.length) {
            setclients([]);
            if (formContent?.one?.service_city._id !== selectedCity._id) {
              toast.dark(
                <Toaster
                  icon="warning"
                  message={`No client(s) associated with ${selectedEmployee.username}.`}
                />
              );
            }

          }
          else {
            setclients(filteredClients);
          }
        }
      }
    }
    else {
      setValue('service_city', null, { shouldDirty: false, shouldValidate: true });
    }
  }, [selectedCity, allClients, isChefCityAPICalled]);

  useEffect(() => {
    if (selectedClient) {
      setValue('client', selectedClient, { shouldDirty: true, shouldValidate: true });
    }
    else {
      setValue('client', null, { shouldDirty: false, shouldValidate: true });
    }
  }, [selectedClient]);

  useEffect(() => {
    if (cities.length === 1) {
      setselectedCity(cities[0]);
    }
  }, [cities]);

  const onBlurEmployee = () => {
    if (!selectedEmployee) {
      trigger('employee');
    }
  }

  const onBlurCity = () => {
    if (!selectedCity) {
      trigger('service_city');
    }
  }

  // const onMenuOpen = (elementName) => {

  //   scroller.scrollTo(elementName, {
  //     duration: 1500,
  //     delay: 100,
  //     smooth: true,
  //     offset: 50, // Scrolls to element + 50 pixels down the page      
  //   });
  // };


  return (
    <div className="container">
      <Row>
        <Col md="8" className="mx-auto ">
          <Row>
            <Col md="12">
              {timesheetId !== null ? (<label>
                Timesheet ID: <strong>{timesheetId}</strong>
              </label>) : ('')}
            </Col>
            <Col md="6" xs="12" >
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="txt-light-secondary">Employee Name <span className="txt-primary">*</span></Form.Label>
                <Controller
                  render={({ field: { name } }) => (
                    <Select
                      // defaultMenuIsOpen                          
                      openMenuOnFocus={false}
                      id="employeeName"
                      styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                      name={name}
                      isMulti={false}
                      closeMenuOnSelect
                      components={animatedComponents}
                      options={employees}
                      getOptionLabel={option => option.username}
                      getOptionValue={option => option._id}
                      value={selectedEmployee}
                      onChange={(option) => {
                        setselectedEmployee(option);
                      }}
                      onBlur={() => {
                        onBlurEmployee();
                      }}
                      onMenuOpen={() => {
                        // onMenuOpen('employeeName');
                      }}
                      menuShouldScrollIntoView
                    />)}
                  name="employee"
                  control={control}
                />
                {errors.employee && (
                  <p className="text-danger">
                    {errors.employee.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md="6" xs="12">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="txt-light-secondary">Service City <span className="txt-primary">*</span></Form.Label>
                <Controller
                  render={({ field: { name } }) => (
                    <Select
                      // autoFocus='false'
                      // openMenuOnFocus='false'
                      name={name}
                      id='cityName'
                      isMulti={false}
                      closeMenuOnSelect
                      components={animatedComponents}
                      options={cities}
                      getOptionLabel={option => option.cityname}
                      getOptionValue={option => option._id}
                      value={selectedCity}
                      onChange={(option) => {
                        setselectedCity(option);
                      }}
                      onBlur={() => {
                        onBlurCity();
                      }}
                      onMenuOpen={() => {
                        // onMenuOpen('cityName');
                      }}
                      menuShouldScrollIntoView
                    />)}
                  name="service_city"
                  control={control}
                />
                {(showCityError && errors.service_city) && (
                  <p className="text-danger">
                    {errors.service_city.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            {
              authUser.user.role.rolename !== 'Office' && (
                <>
                  <Col md="6" xs="12" className="mt-1" >
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="txt-light-secondary">Client Name</Form.Label>
                      <Controller
                        render={({ field: { name } }) => (
                          <Select
                            // menuIsOpen                          
                            name={name}
                            isMulti={false}
                            // openMenuOnFocus={false}
                            // openMenuOnClick
                            id='clientName'
                            styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                            closeMenuOnSelect
                            components={animatedComponents}
                            options={clients}
                            getOptionLabel={option => option.username}
                            getOptionValue={option => option._id}
                            value={selectedClient}
                            onChange={(option) => {
                              setselectedClient(option);
                            }}
                            onMenuOpen={() => {
                              // onMenuOpen('clientName')
                            }}
                            menuShouldScrollIntoView
                          />)}
                        name="client"
                        control={control}
                      // defaultValue={selectedRole}
                      />
                      {errors.client && (
                        <p className="text-danger">
                          {errors.client.message}
                        </p>
                      )}
                    </Form.Group>

                  </Col>

                  <Col md="6" xs="12" />
                  {selectedClient && <Col md="6" xs="12" className="mt-3">
                    <label>
                      Client ID: <strong>{selectedClient.clientId}</strong>
                    </label>
                  </Col>}

                </>

              )
            }


          </Row>
          <div className="ml-2 border-bottom" />
        </Col>
      </Row>
    </div>

  )
});

export default BasicDetails

BasicDetails.propTypes = {
  formContent: PropTypes.any.isRequired,
  timesheetId: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
  showCityError: PropTypes.bool.isRequired,
};

BasicDetails.defaultProps = {
  timesheetId: null
};