import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { Button, Col, Image, Modal, Row, Form } from 'react-bootstrap';
import Styled from 'styled-components';
import Select from 'react-select';
import { trackPromise } from 'react-promise-tracker';

import Toaster from '../../../components/Toaster';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';
import SidebarData from '../../side-navbar/SidebarData';

// import './css/custom_popup.css';

import { getAllRolesByNames } from '../../../services/role-service';
import { getCustomPageByLocation } from '../../../services/custom_page.service';

const StyledSelect = Styled(Select)`
// #react-select-3-option-0 {
// .css-6kawnw-MenuList >  css-yt9ioa-option:first-child {
//   padding-left: 12px !important;
// }

div#react-select-5-option-0.css-yt9ioa-option {
    padding-left: 12px !important;
}

.css-yt9ioa-option, .css-1n7v3ny-option {
  padding-left: 50px !important;
}

.css-18ng2q5-group {
  text-transform: capitalize;
  font-size: 100%;
  font-weight: 700;
}

`;

const CustomPopupFilters = memo(({
    show, handleClose, applyChanges, allFilters, clearAll
}) => {
    const [option, setOption] = useState('location');

    const [roles, setRoles] = useState([]);
    const [selectedRoles, setselectedRoles] = useState([]);

    const [sideBarData, setSideBarData] = useState(SidebarData);
    const [selectedLocations, setSelectedLocations] = useState(
        allFilters?.locations?.length ? allFilters.locations : []
    );

    const [filters, setfilters] = useState(null);

    async function fetchAllRoles() {
        try {
            const response = await trackPromise(getAllRolesByNames());
            setRoles(
                response.data.data.roles.map((role) => {
                    const tempRole = role;
                    if (allFilters?.roles?.length > 0) {
                        if (allFilters.roles.find((selected) => selected._id === role._id)) {
                            tempRole.isChecked = true;
                        }
                    }
                    else {
                        tempRole.isChecked = false;
                    }
                    return tempRole;
                })
            );
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    async function fetchCustomPages() {
        try {
            const response = await trackPromise(getCustomPageByLocation());

            await response.data.data.customPages.forEach(async (location) => {
                switch (location._id) {
                    case 'tools': {
                        const toolsData = sideBarData.findIndex((sidebarObj) => sidebarObj.title === 'Tools');
                        if (location.total >= 1) {
                            await location.data.map((customPage) => {
                                const tempPage = {};
                                tempPage.title = customPage.button_label;
                                tempPage.path = customPage.route;
                                tempPage.icon = (
                                    <Image
                                        className="ftc-icon-28"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                                        fluid
                                    />
                                );
                                if (sideBarData[toolsData]?.subNav.findIndex((nav) => nav.path === customPage.route) === -1) {
                                    sideBarData[toolsData].subNav.push(tempPage);
                                }
                                return tempPage;
                            });
                        }
                        break;
                    }

                    case 'reports': {
                        const reportsData = sideBarData.findIndex((sidebarObj) => sidebarObj.title === 'Reports');
                        if (location.total >= 1) {
                            await location.data.map((customPage) => {
                                const tempPage = {};
                                tempPage.title = customPage.button_label;
                                tempPage.path = customPage.route;
                                tempPage.icon = (
                                    <Image
                                        className="ftc-icon-28"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                                        fluid
                                    />
                                );
                                if (sideBarData[reportsData]?.subNav.findIndex((nav) => nav.path === customPage.route) === -1) {
                                    sideBarData[reportsData].subNav.push(tempPage);
                                }
                                return tempPage;
                            });
                        }
                        break;
                    }

                    case 'forms':
                        {
                            const formsData = sideBarData.findIndex((sidebarObj) => sidebarObj.title === 'Forms');
                            if (location.total >= 1) {
                                await location.data.map((customPage) => {
                                    const tempPage = {};
                                    tempPage.title = customPage.button_label;
                                    tempPage.path = customPage.route;
                                    tempPage.icon = (
                                        <Image
                                            className="ftc-icon-28"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                                            fluid
                                        />
                                    );
                                    if (sideBarData[formsData]?.subNav.findIndex((nav) => nav.path === customPage.route) === -1) {
                                        sideBarData[formsData].subNav.push(tempPage);
                                    }
                                    return tempPage;
                                });
                            }
                            break;
                        }

                    default:
                        break;
                }
            });
            setSideBarData(
                sideBarData.map((sidebar) => {
                    const tempSidebar = {};
                    tempSidebar.label = (
                        <>
                            {sidebar.icon} {sidebar.title}
                        </>
                    );
                    // if there are nested pages then format them also
                    if (sidebar?.subNav?.length) {
                        tempSidebar.options = sidebar?.subNav?.map((navs) => {
                            const newNav = {};
                            newNav.label = (
                                <>
                                    {navs.icon} {navs.title}
                                </>
                            )
                            newNav.value = navs.path;
                            // if (newNav.value === formContent?.one?.popup_location)
                            // {
                            //     setValue('popup_location', newNav, { shouldDirty: true, shouldValidate: true });
                            // }
                            return newNav;
                        });
                    }
                    else {
                        tempSidebar.value = sidebar.path;
                        // if (tempSidebar.value === formContent?.one?.popup_location)
                        // {
                        //     setValue('popup_location', tempSidebar, { shouldDirty: true, shouldValidate: true });
                        // }
                    }

                    return tempSidebar;
                })
            );
            // setSideBarData(sideBarData);

        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    useEffect(() => {
        fetchCustomPages();
        fetchAllRoles();
    }, []);

    useEffect(() => {
        if (selectedRoles.length) {
            setfilters((oldFilters) => ({ ...oldFilters, roles: selectedRoles }));
        }
        else {
            delete filters?.roles;
            setfilters({ ...filters });
        }
    }, [selectedRoles]);

    useEffect(() => {
        if (selectedLocations.length) {
            setfilters((oldFilters) => ({ ...oldFilters, locations: selectedLocations }));
        }
        else {
            delete filters?.locations;
            setfilters({ ...filters });
        }
    }, [selectedLocations]);

    useLayoutEffect(() => {        
        if (allFilters?.roles?.length) {
            setselectedRoles(allFilters.roles);
        }

    }, [allFilters]);

    const onRoleChange = (e, role, indexKey) => {
        const newRoles = [...roles];
        const findSelectedIndex = selectedRoles.map((searchRole) => searchRole._id).indexOf(role._id);

        const newSelectedRoles = [...selectedRoles];

        if (e.target.checked) {
            newRoles[indexKey].isChecked = true;
            newSelectedRoles.push(role);
        }
        else {
            newRoles[indexKey].isChecked = false;
            newSelectedRoles.splice(findSelectedIndex, 1);
        }
        setRoles(newRoles);
        setselectedRoles(newSelectedRoles);
    }

    const removeAllRoles = () => {
        setRoles(
            roles.map((role) => {
                const tempRole = role;
                if (selectedRoles.find((selected) => selected._id === role._id)) {
                    tempRole.isChecked = false;
                    selectedRoles.splice(selectedRoles.findIndex((selected) => selected._id === role._id), 1);
                }
                return tempRole;
            })
        );
        setselectedRoles([]);
    }

    const removeAllLocations = () => {
        setSelectedLocations([])
    }

    const onClearAll = () => {
        removeAllRoles();
        removeAllLocations();
        setfilters(null);
        clearAll();
    };

    function filterDetailsByOption() {
        switch (option) {
            case 'role':

                return (<div>
                    <Row>
                        <Col md="3" xs="6">
                            <label>Role</label>
                        </Col>
                        <Col md="9" xs="6">
                            <a
                                className="txt-primary float-right"
                                role="button"
                                onClick={removeAllRoles}
                                onKeyDown={() => { }}
                                tabIndex={-1}
                            >
                                Deselect All
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        {
                            roles.map((role, index) => {
                                return (
                                    <Col md="4" xs="12">
                                        <Form.Group controlId="formBasicCheckbox">
                                            <input
                                                type="checkbox"
                                                className="ftc-checkbox"
                                                id={role._id}
                                                checked={role.isChecked}
                                                onChange={(e) => {
                                                    onRoleChange(e, role, index);
                                                }}
                                            />
                                            <label
                                                className="position-relative ml-4 pl-2"
                                                htmlFor={role._id}
                                            >
                                                {role.rolename}
                                            </label>
                                        </Form.Group>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
                );

            case 'location':
                return (
                    <div>
                        <Row>
                            <Col md="3" xs="6">
                                <label>Popup Location</label>
                            </Col>
                            {/* <Col md="9" xs="6">
                                <a
                                    className="txt-primary float-right"
                                    role="button"
                                    onClick={removeAllLocations}
                                    onKeyDown={() => { }}
                                    tabIndex={-1}
                                >
                                    Deselect All
                                </a>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col md="12">
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="txt-light-secondary">Select Directory</Form.Label>
                                    <StyledSelect
                                        // defaultMenuIsOpen    
                                        className='popup-directory'
                                        name='popup_location'
                                        isMulti
                                        closeMenuOnSelect={false}
                                        // components={animatedComponents}
                                        options={sideBarData}
                                        value={selectedLocations}
                                        onChange={(value) => {
                                            setSelectedLocations(value);
                                        }}
                                    />

                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                );
            default:
                break;
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="modal__row">
                    <Desktop>
                        <Col md="3" className="modal__Col_3_Padding border__right sticky-top">
                            <ul className="modal__ul">
                                <li
                                    className={`${option === 'location' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('location')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Location</span>{' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />{' '}
                                </li>
                                <li
                                    className={`${option === 'role' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('role')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Role</span>{' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />
                                </li>
                            </ul>
                        </Col>
                    </Desktop>
                    <Mobile>
                        <Col xs="4" className="modal__Col_3_Padding border__right">
                            <ul className="modal__ul">
                                <li
                                    className={`${option === 'location' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('location')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Location</span>{' '}
                                </li>
                                <li
                                    className={`${option === 'role' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('role')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Role</span>{' '}
                                </li>
                            </ul>
                        </Col>
                    </Mobile>
                    <Col md="9" xs="8">
                        {filterDetailsByOption()}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    onClick={() => {
                        onClearAll();
                    }}
                >
                    Clear All
                </Button>
                <Button
                    variant="default"
                    className="btn-ftc-border-primary"
                    onClick={() => {
                        applyChanges(filters);
                    }}
                >
                    Apply Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default CustomPopupFilters

CustomPopupFilters.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    applyChanges: PropTypes.func.isRequired,
    allFilters: PropTypes.any.isRequired,
    clearAll: PropTypes.func.isRequired,
};