import React, { useState, memo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import Modal from 'react-modal';
import moment from 'moment';
import { toast } from 'react-toastify';
import { openDB } from 'idb';
import TablePagination from '@material-ui/core/TablePagination';
import {
  Button,
  Row,
  Col,
  Image,
  InputGroup,
  FormControl,
  Form,
} from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import Sidebar from '../../side-navbar/Sidebar';
import Toaster from '../../../components/Toaster';
import DataTableComponent from '../../../components/DataTableComponent';
import SettingsDropdown from '../../../components/SettingsDropdown/SettingsDropdown';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import RenderCustomPopup from '../../../components/RenderCustomPopup';
import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';
import NoCustomPageComponent from '../../../components/NoCustomPageComponent/NoCustomPageComponent';

import UserFilters from './UserFilters';
import TableMobileView from './mobile-layouts/UsersTableMobileView';
import AvatarImage from '../../../assets/images/avatar.svg';
import {
  deleteUserByEmployeeId,
  getAllUsers,
} from '../../../services/users-service';
import {
  getUserStatusLabel,
  validatePermission,
} from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';
import useQuery from '../../../utils/CustomHooks/useQuery';
import {
  showExportButton
} from '../../../utils/TimesheetManagement';
import exportIcon from '../../../assets/images/exportIcon.svg';
import { exportToCSV } from '../../../utils/ExportDataToCSV';
import Loader from '../../../assets/images/loader.svg';

import '../css/users.css';
// import useRouter from '../../../utils/CustomHooks/useRouter';

const Users = memo(() => {
  const { authUser } = useAuthContext();
  const URLQuery = useQuery();
  const location = useLocation();

  const isIndeterminate = (indeterminate) => indeterminate;
  const selectableRowsComponentProps = { indeterminate: isIndeterminate };

  const [show, setShow] = useState(false);
  const [isAPICalled, setAPICalled] = useState(false);

  const [users, setUsers] = useState([]);
  const [userCount, setUserCount] = useState({
    totalCount: 0,
    filterCount: 0,
  });
  const [employee, setemployee] = useState(URLQuery.get('employee') ?? '');
  const [searchQuery, setsearchQuery] = useState(
    URLQuery.get('employee') ?? ''
  );
  const [exportAPICalled,setExportAPICalled] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filters, setfilters] = useState(null);
  const [query, setquery] = useState('');

  const [selectedRow, setselectedRow] = useState();
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState({
    employeeId: '',
    username: '',
    lastUpdate: '',
  });
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const customStyles = {
    content: {
      top: modalPosition.top + 70,
      left: modalPosition.left + 125,
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      padding: '10px',
    },
  };

  const handleProfilePictureClick = (image, name, updatedAt, eid, event) => {
    setSelectedImage(image);

    setSelectedUserDetails({
      employeeId: eid,
      username: name,
      lastUpdate: updatedAt,
    });
    const rect = event.target.getBoundingClientRect();
    const position = {
      top: rect.top + window.pageYOffset,
      left: rect.left + window.pageXOffset + rect.width, // Adjust left position to place the modal beside the image
    };
    setModalPosition(position);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setSelectedUserDetails({
      employeeId: '',
      username: '',
      lastUpdate: '',
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseConfirm = () => setConfirmAlert(false);

  const DateConvert = (date) => {
    // console.log({date});
    return moment(date).utcOffset(0).format('MM/DD/YYYY');
    // return moment.utc(date).format('MM/DD/YYYY');
    // const d = new Date(date);
    // return `${d.toLocaleDateString('en-US', {
    //     day: '2-digit',
    //     month: '2-digit',
    //     year: 'numeric',
    // })}`;
  };

  const history = useHistory();

  const onAddNew = () => {
    history.push('/settings/addNewUser');
  };

  const handleRowClicked = (row) => {
    history.push(`/settings/viewUser/${row.employeeId}`);
  };

  const handleViewClick = (state) => {
    history.push(`/settings/viewUser/${state.target.id}`);
  };

  const handleClickDelete = (state) => {
    setselectedRow(state.target.id);
    setConfirmAlert(true);
  };

  const handleEditClicked = (state) => {
    history.push(`/settings/editUser/${state.target.id}`);
  };

  const displayCities = (cities) => {
    switch (true) {
      case cities.length === 1:
        return cities.map((c) => c.cityname ?? 'N/A');

      case cities.length >= 2:
        return cities
          .slice(0, 1)
          .map((c) => c.cityname ?? 'N/A')
          .join(', ');

      default:
        break;
    }
    return null;
  };

  const displayCitiesFilter = () => {
    switch (true) {
      case filters.cities.length === 1:
        return filters.cities.map((c) => c.cityname ?? 'N/A');

      // case filters.cities.length === 2:
      //   return filters.cities.map((c) => c.cityname ?? 'N/A').join(', ');

      case filters.cities.length >= 2:
        return filters.cities
          .slice(0, 1)
          .map((c) => c.cityname ?? 'N/A')
          .join(', ');
      default:
        break;
    }
  };

  const displayRolesFilter = () => {
    switch (true) {
      case filters.roles.length === 1:
        return filters.roles.map((c) => c.rolename ?? 'N/A');

      case filters.roles.length === 2:
      case filters.roles.length === 3:
        return filters.roles.map((c) => c.rolename ?? 'N/A').join(', ');

      case filters.roles.length >= 4:
        return filters.roles
          .slice(0, 3)
          .map((c) => c.rolename ?? 'N/A')
          .join(', ');
      default:
        break;
    }
  };

  const columns = [
    {
      name: '',
      selector: (row) => {
        const { profile_picture_url: profilePicture } = row;
        const profilePic =
          profilePicture === undefined ? AvatarImage : profilePicture;
        // const profilePic = profilePicture ? AvatarImage : profilePicture;
        return (
          <>
            <Image
              src={profilePic}
              alt="Profile Picture"
              className="small__profile__pic"
              onError={(e) => {
                e.target.src = AvatarImage; // Set fallback image if src image fails to load
              }}
              onMouseEnter={(event)=>{
                handleProfilePictureClick(
                  profilePic,
                  row.username,
                  row.updatedAt,
                  row.employeeId,
                  event
                )
              }}
              onMouseLeave={() => {
                setIsModalOpen(false);
              }}
              onClick={(event) =>
                handleProfilePictureClick(
                  profilePic,
                  row.username,
                  row.updatedAt,
                  row.employeeId,
                  event
                )
              }
            />
          </>
        );
      },
      width: '70px',
    },
    {
      name: 'Username',
      selector: 'username',
      sortable: true,
    },
    {
      name: 'Service City',
      selector: (row) => {
        const { service_city: cities } = row;
        return (
          <>
            {displayCities(cities)}
            {cities.length > 1 && (
              <>
                <span className="txt-primary"> +{cities.length - 1}</span>{' '}
              </>
            )}
            {!cities.length && <>N/A</>}
          </>
        );
      },
      sortable: true,
    },
    {
      name: 'Role',
      selector: (row) => row.role?.rolename ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => {
        const { status } = row;
        return (
          <div>
            <label
              className={
                status === 'active' ? 'label-active' : 'label-inactive'
              }
            >
              <div
                className={
                  status === 'active' ? 'active-ellipse' : 'inactive-ellipse'
                }
              />
              {` `}
              {getUserStatusLabel(status)}
            </label>
          </div>
        );
      },
    },
    {
      name: '',
      selector: 'Setting',
      sortable: true,
      right: true,
    },
    {
      // eslint-disable-next-line no-underscore-dangle
      cell: (row) => {
        const { employeeId, status } = row;
        return (
          <label>
            <SettingsDropdown
              identifier={employeeId}
              handleViewClick={handleViewClick}
              handleEditClicked={handleEditClicked}
              handleClickDelete={handleClickDelete}
              drop="left"
              isEditable={
                status === 'inactive' ||
                !validatePermission(
                  authUser.user.role.permissions,
                  'user',
                  'edit'
                )
              }
              isDeletable={
                !validatePermission(
                  authUser.user.role.permissions,
                  'user',
                  'delete'
                )
              }
            />
          </label>
        );
      },
      allowOverflow: true,
      button: true,
      width: '56px', // custom width for icon button
    },
  ];

  async function fetchAllPagesUsers() {
    try {
      setExportAPICalled(true);
      const response = await trackPromise(
        getAllUsers(userCount.totalCount, 0, searchQuery, query)
      );
      const transformedData = response.data.data.users.map(item => ({
        employeeId: item.employeeId,
        username: item.username,
        email: item.email,
        isTrainer: item.trainer ? 'Yes' : 'No',
        serviceCity: displayCities(item.service_city),
        role: item.role?.rolename,
        hire: moment(item.work_history.hire_date).format('MM/DD/YYYY'),
        lastRaiseDate: moment(item.work_history.last_raise_date).format('MM/DD/YYYY'),
        terminationDate: item.work_history?.termination_date ? moment(item.work_history.termination_date).format('MM/DD/YYYY') : 'Not Specified',
        currentWageHour: item.work_history?.wage_per_hr ? item.work_history?.wage_per_hr?.toFixed(2) : '0.00',
        currentSalaryWeek: item.work_history.salary_per_wk ? item.work_history.salary_per_wk.toFixed(2) : '0.00',
        status: item.status,
        
    }));
    exportToCSV(transformedData,`user_data_${DateConvert(new Date())}`);
    setExportAPICalled(false);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
      setExportAPICalled(false);
    }

  }

  async function fetchAllUsers() {
    try {
      const response = await trackPromise(
        getAllUsers(rowsPerPage, page, searchQuery, query)
      );
      setUsers(response.data.data.users);
      setUserCount({
        totalCount: response.data.data.totalCount,
        filterCount: response.data.data.filterCount,
      });
      setAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function onFilterChanges() {
    let queryString = '';

    if (filters?.cities && filters.cities.length) {
      filters.cities.forEach((city) => {
        queryString += `&cities[]=${city._id}`;
      });
    }

    if (filters?.roles && filters.roles.length) {
      filters.roles.forEach((role) => {
        queryString += `&roles[]=${role._id}`;
      });
    }

    if (filters?.status && filters.status) {
      queryString += `&status[]=${filters.status.value}`;
    }

    setquery(queryString);
    const dbs = await openDB('filters', 1, {
      upgrade(db) {
        // Create a store of objects
        const store = db.createObjectStore('filters_data', {
          // The 'moduleName' property of the object will be the key.
          keyPath: 'moduleName',
          // If it isn't explicitly set, create a value by auto incrementing.
          // autoIncrement: true,
        });
        // Create an index on the 'date' property of the objects.
        store.createIndex('moduleName', 'moduleName');
      },
    });

    const checkKeyExists = await dbs.getKey(
      'filters_data',
      IDBKeyRange.only('user')
    );

    // will update existing record or else create a new
    if (checkKeyExists !== undefined) {
      if (filters !== null && Object.keys(filters).length) {
        await dbs.put('filters_data', {
          moduleName: 'user',
          filterObj: filters,
        });
      } else {
        // if all filters are empty then also remove its row from idb
        await dbs.delete('filters_data', IDBKeyRange.only('user'));
      }
    } else if (filters !== null && Object.keys(filters).length) {
      await dbs.add('filters_data', {
        moduleName: 'user',
        filterObj: filters,
      });
    }
    setPage(0);
  }

  async function getFilters() {
    try {
      const dbs = await openDB('filters', 1, {
        upgrade(db) {
          // Create a store of objects
          const store = db.createObjectStore('filters_data', {
            // The 'moduleName' property of the object will be the key.
            keyPath: 'moduleName',
            // If it isn't explicitly set, create a value by auto incrementing.
            // autoIncrement: true,
          });
          // Create an index on the 'date' property of the objects.
          store.createIndex('moduleName', 'moduleName');
        },
      });
      const filtersData = await dbs.get(
        'filters_data',
        IDBKeyRange.only('user')
      );
      if (filtersData?.filterObj) {
        setfilters(filtersData.filterObj);
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    if (!searchQuery.length) {
      history.push({
        pathname: location.pathname,
        search: '',
      });
    } else {
      history.push({
        pathname: location.pathname,
        search: qs.stringify({ employee: searchQuery }),
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    fetchAllUsers();
  }, [rowsPerPage, page, query, searchQuery]);

  useEffect(() => {
    onFilterChanges();
  }, [filters]);

  const onSearchClick = () => {
    setsearchQuery(employee);
    setPage(0);
  };

  const onDeleteUser = async () => {
    try {
      const response = await trackPromise(deleteUserByEmployeeId(selectedRow));
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setConfirmAlert(false);
      fetchAllUsers();
    }
  };

  const onApplyChanges = (filtersData) => {
    handleClose();
    setfilters(filtersData);
  };

  const clearAllFilters = () => {
    setfilters(null);
  };

  const removeFilter = (filterObj) => {
    delete filters[filterObj];
    setfilters({ ...filters });
  };

  const displaySelectedFilters = () => {
    return filters !== null && Object.keys(filters).length > 0 ? (
      <Row className="group_filter">
        {filters?.cities && filters.cities.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">City:</span>{' '}
            {displayCitiesFilter()}
            {filters.cities.length > 1 && (
              <>
                <span className="txt-primary">
                  {' '}
                  +{filters.cities.length - 1}
                </span>{' '}
              </>
            )}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('cities');
              }}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.roles && filters.roles.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Role:</span>{' '}
            {displayRolesFilter()}
            {filters.roles.length > 3 && (
              <>
                <span className="txt-primary">
                  {' '}
                  +{filters.roles.length - 3}
                </span>{' '}
              </>
            )}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('roles');
              }}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.status ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Status:</span>{' '}
            {filters.status.label}{' '}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('status');
              }}
            />
          </span>
        ) : (
          ''
        )}

        <Col>
          <Button
            variant="link"
            onClick={clearAllFilters}
            className="float-right txt-primary"
          >
            Clear Filter
          </Button>
        </Col>
      </Row>
    ) : (
      ''
    );
  };

  return (
    <div>
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar />
        </Mobile>
        {isAPICalled && !userCount.totalCount ? (
          <NoCustomPageComponent
            title="Users"
            onAddNew={onAddNew}
            addNewText='Start adding your first ”User"'
            isAddNew={validatePermission(
              authUser.user.role.permissions,
              'user',
              'add'
            )}
          />
        ) : (
          <>
            <Desktop>
              <Col md="10">
                <div className="container">
                  <Row className="sticky-dashboard-header">
                    <Col md={4} lg={5}>
                      <h3>
                        <strong>Users</strong>
                      </h3>
                    </Col>
                    <Col>
                      <Button
                        className="float-right"
                        variant="default"
                        onClick={handleShow}
                      >
                        {' '}
                        <Image
                          src={
                            filters !== null && Object.keys(filters).length > 0
                              ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                              : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                          }
                          fluid
                        />{' '}
                        Filter
                      </Button>
                    </Col>
                    <Col md={3} className="pl-0">
                      <InputGroup className="mb-3">
                        <FormControl
                          placeholder="Search Employees"
                          aria-label="Employees"
                          aria-describedby="basic-addon1"
                          value={employee}
                          onChange={(e) => setemployee(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setsearchQuery(event.target.value);
                              setPage(0);
                            }
                          }}
                        />
                        <InputGroup.Prepend>
                          <Button
                            variant="default"
                            className="btn-ftc-primary-light"
                            onClick={onSearchClick}
                          >
                            Search
                          </Button>
                        </InputGroup.Prepend>
                      </InputGroup>
                    </Col>
                    {
                      showExportButton(authUser.user.role.rolename) && (
                        <Col xl={1/2} className="mr-3">
                    <span className="float-left">
                          <button
                            type="button"
                            className="btn btn-ftc-primary-light-border"
                            onClick={() => fetchAllPagesUsers()}
                          >
                            {
                              exportAPICalled ? (
                                <><Image src={Loader} height="18px" width="18px" /></>
                              ) : (<><Image
                                src={exportIcon}
                              />{' '}
                                  Export</>)
                            }
                          </button>
                        </span>
                    </Col>
                      )
                    }
                    {validatePermission(
                      authUser.user.role.permissions,
                      'user',
                      'add'
                    ) && (
                      <div className="pr-4">
                        <button
                          type="button"
                          className="btn btn-ftc-primary float-right"
                          onClick={onAddNew}
                        >
                          Add New
                        </button>
                      </div>
                    )}
                  </Row>

                  <div className="container-top-8">
                    {displaySelectedFilters()}
                    <Row>
                      <Col md="12" className="pr-0">
                        {isAPICalled ? (
                          <>
                            <Modal
                              isOpen={isModalOpen}
                              onRequestClose={closeModal}
                              style={customStyles}
                              contentLabel="Selected Image Modal"
                            >
                              <div
                                className="d-flex"
                                // style={customStyles}
                                onMouseLeave={() => {
                                  setIsModalOpen(false);
                                }}
                              >
                              {selectedImage && (
                                <img
                                  src={selectedImage}
                                  alt="Selected"
                                  height="131px"
                                  width="145px"
                                  className="modal__image"
                                  onError={(e) => {
                                    e.target.src = AvatarImage; // Set fallback image if src image fails to load
                                  }}
                                />
                              )}
                              <div className="pl-2 d-flex flex-column justify-content-between">
                                <div>
                                  <h5 className="mb-0">
                                    <strong>
                                      {selectedUserDetails.username}
                                    </strong>
                                  </h5>
                                  <div className="txt-light-secondary">
                                    Last Updated on{' '}
                                    {DateConvert(
                                      selectedUserDetails.lastUpdate
                                    )}
                                  </div>
                                </div>
                                <Button
                                  variant="default"
                                  className="float-right btn-ftc-border-primary w-75"
                                  onClick={() => {
                                    history.push(
                                      `/settings/viewUser/${selectedUserDetails.employeeId}`
                                    );
                                  }}
                                >
                                  View Profile
                                </Button>
                              </div>
                              </div>
                            </Modal>
                            <DataTableComponent
                              // title="Movie List"
                              highlightOnHover
                              pointerOnHover
                              noHeader
                              columns={columns}
                              data={users}
                              // defaultSortField="username"
                              pagination
                              selectableRowsComponentProps={
                                selectableRowsComponentProps
                              }
                              paginationServer
                              paginationTotalRows={userCount.filterCount}
                              handleRowClicked={handleRowClicked}
                              paginationPerPage={rowsPerPage}
                              onChangePage={(p) => {
                                setPage(p - 1);
                                // look for better solution later
                                // setPage(p)
                              }}
                              onChangeRowsPerPage={(rws, pg) => {
                                setRowsPerPage(rws);
                                setPage(pg);
                              }}
                            />

                            <TablePagination
                              labelRowsPerPage="Rows per page:"
                              component="div"
                              count={Number.parseInt(userCount.filterCount, 10)}
                              page={page}
                              onChangePage={(event, newPage) => {
                                setPage(newPage);
                              }}
                              defaultPage={0}
                              rowsPerPage={rowsPerPage}
                              onChangeRowsPerPage={(event) => {
                                setRowsPerPage(
                                  parseInt(event.target.value, 10)
                                );
                                setPage(0);
                              }}
                              ActionsComponent={TablePaginationActions}
                            />
                          </>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Desktop>
            <Mobile>
              <Col>
                <div className="container pr-0 container-top-20">
                  <Row className="border-bottom sticky-dashboard-header-mobile ">
                    <Col xs="9" className="ml-4">
                      <h3>Users</h3>
                    </Col>
                    <Col xs="2">
                      <Image
                        className="ftc-logo-40"
                        src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                      />{' '}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6" className="pr-1 pl-1">
                      <Button
                        variant="default"
                        onClick={handleShow}
                        className="btn-block"
                      >
                        {' '}
                        <Image
                          src={
                            filters !== null && Object.keys(filters).length > 0
                              ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                              : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                          }
                        />{' '}
                        Filter
                      </Button>
                    </Col>
                    {validatePermission(
                      authUser.user.role.permissions,
                      'user',
                      'add'
                    ) && (
                      <Col xs="6" className="pr-1 pl-1">
                        <button
                          type="button"
                          className="btn btn-ftc-primary btn-block"
                          onClick={onAddNew}
                        >
                          Add New
                        </button>
                      </Col>
                    )}
                  </Row>
                  <Row className="pt-0 pb-0">
                    <Col xs="12" className="pl-1 pr-1">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        value={employee}
                        onChange={(e) => setemployee(e.target.value)}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            setsearchQuery(event.target.value);
                            setPage(0);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="pr-0 pb-0 pr-2">
                    {
                      showExportButton(authUser.user.role.rolename) && (
                        <>
                        <Col xs="6" className="pr-1 pl-1">
                          <></>
                        </Col>
                        <Col xs="6" className="pr-1 pl-1">
                    <span>
                          <button
                            type="button"
                            className="btn btn-ftc-primary-light-border btn-block float-right"
                            onClick={() => fetchAllPagesUsers()}
                          >
                            {
                              exportAPICalled ? (
                                <><Image src={Loader} height="18px" width="18px" /></>
                              ) : (<><Image
                                src={exportIcon}
                              />{' '}
                                  Export</>)
                            }
                          </button>
                        </span>
                    </Col>
                    </>
                      )
                    }
                    </Row>
                  <Row>
                    {users.map((user) => {
                      return (
                        <Col className="mt-3 pl-1 pr-1">
                          <TableMobileView
                            user={user}
                            callback={handleRowClicked}
                            handleViewClick={handleViewClick}
                            handleEditClicked={handleEditClicked}
                            handleClickDelete={handleClickDelete}
                            isEditable={
                              user.status === 'inactive' ||
                              !validatePermission(
                                authUser.user.role.permissions,
                                'user',
                                'edit'
                              )
                            }
                            isDeletable={
                              !validatePermission(
                                authUser.user.role.permissions,
                                'user',
                                'delete'
                              )
                            }
                          />
                        </Col>
                      );
                    })}
                  </Row>
                  {isAPICalled && !userCount.filterCount && (
                    <div className="text-center">
                      <p>There are no records to display.</p>
                    </div>
                  )}
                  <Row>
                    <Col xs="12">
                      <TablePagination
                        className="mobile__pagination"
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage=""
                        component="div"
                        count={Number.parseInt(userCount.filterCount, 10)}
                        page={page}
                        // onChangePage={handleChangePage}
                        onChangePage={(event, newPage) => {
                          setPage(newPage);
                        }}
                        defaultPage={0}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={(event) => {
                          setRowsPerPage(parseInt(event.target.value, 10));
                          setPage(0);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Mobile>
          </>
        )}
        {show && (
          <UserFilters
            show={show}
            handleClose={handleClose}
            allFilters={filters}
            applyChanges={onApplyChanges}
            clearAll={clearAllFilters}
          />
        )}

        {showConfirmAlert && (
          <ConfirmAlertModal
            show={showConfirmAlert}
            handleClose={handleCloseConfirm}
            title="Delete User"
            description="Deleting this User will erase all data inside this record.
                Are you sure you want to delete this User?"
            callback={onDeleteUser}
            action="Delete it"
            closeAction="Cancel"
          />
        )}
      </Row>
      <RenderCustomPopup />
    </div>
  );
});

export default Users;
