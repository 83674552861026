import React, { memo, useEffect, useState } from 'react';
import { Redirect, useHistory, Link } from 'react-router-dom';

import { Container, Row, Col, Form, Image, Alert } from 'react-bootstrap';
// import FormControl from 'react-bootstrap/FormControl';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

import LoginGoogle from '../../components/LoginGoogle/LoginGoogle';
import Toaster from '../../components/Toaster';

import { userLogin } from '../../services/users-service';

import { useAuthContext } from '../../contexts/user-context';

import './login.css';
// import { getAllNonBillablesByAccess } from '../../services/non_billable.service';
// import { getClientsByChef } from '../../services/client-service';

const Login = memo(() => {
  const [isPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const [imageVariant, setImageVariant] = useState('');

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required!')
      .email('Email must be a valid email'),
    password: yup.string().required('Password is required!'),
  });

  const getYear = new Date();
  const currentYear = getYear.getFullYear();
  const history = useHistory();
  const { authUser, setAuthUser } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitting },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const email = watch('email');
  const password = watch('password');

  const handleCheckboxClick = () => {
    setShowPassword(!isPassword);
  };

  useEffect(() => {
    if (isSubmitted) {
      setAlertMessage('');
      setAlertVariant('');
      setShowAlert(false);
      setImageVariant('');
    }
  }, [email, password]);

  const onLogin = async (data) => {
    try {
      if (isPassword) {
        handleCheckboxClick();
      }
      const response = await trackPromise(userLogin(data));
      if (response.status === 200) {
        setAuthUser(response.data.data.user, response.data.data.token);

        toast.dark(<Toaster icon="notify" message={response.data.message} />);
        history.replace('/');
      }
    } catch (error) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        setAlertMessage(error.response.data.message);
        setAlertVariant('danger');
        setShowAlert(true);
        setImageVariant('icon-alert-red');
      } else if (error?.response?.status === 500) {
        setAlertMessage(error.response.data.message);
        setAlertVariant('danger');
        setShowAlert(true);
        setImageVariant('icon-alert-red');
      } else if (error?.response?.status === 404) {
        setAlertMessage(error.response.data.message);
        setAlertVariant('warning');
        setShowAlert(true);
        setImageVariant('icon-warning');
      }
    }
  };

  return authUser.token ? (
    <Redirect to={{ pathname: '/' }} />
  ) : (
    <div className="Login">
      <div className="margin-page">
        <Container className="containers">
          <Row className="mobile__view__padding p-0">
            <Col md="6" className="image__column__padding">
              <div className="crop">
                <Image
                  className="image__view"
                  src={`${process.env.PUBLIC_URL}/assets/chef.jpg`}
                  fluid
                />
              </div>
            </Col>
            <Col md="5" className="mobile__view__margin">
              <br />
              <Container>
                <Row>
                  <Col md="12">
                    <div className="d-flex justify-content-center">
                      <Image
                        className="ftw__logo"
                        src={`${process.env.PUBLIC_URL}/assets/ftw_logo_2.svg`}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="d-flex justify-content-center">
                      <label className="header__label">
                        Login using Email & Password
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Form onSubmit={handleSubmit(onLogin)}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Text className="text-muted">Email</Form.Text>
                        <Form.Control
                          {...register('email')}
                          type="email"
                          placeholder="Enter email"
                        />
                        {errors.email && (
                          <p className="text-danger">{errors.email.message}</p>
                        )}
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Text className="text-muted">Password</Form.Text>
                        <Form.Control
                          {...register('password')}
                          type={isPassword === true ? 'text' : 'password'}
                          placeholder="Enter password"
                        />
                        {errors.password && (
                          <p className="text-danger">
                            {errors.password.message}
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group controlId="formBasicCheckbox">
                        <input
                          type="checkbox"
                          className="ftc-checkbox"
                          id="show_password"
                          name="show_password"
                          checked={isPassword}
                          onChange={handleCheckboxClick}
                        />
                        <label
                          className="position-relative ml-4 pl-2"
                          data-content="Show Password"
                        >
                          Show Password
                        </label>
                      </Form.Group>
                      {showAlert && (
                        <>
                          <Row>
                            <Col xs="12" className="pl-0 pr-0">
                              <Alert
                                className="border-0"
                                variant={alertVariant}
                                onClose={() => setShowAlert(false)}
                              >
                                <Image
                                  className="ftc-logo-24 mr-1 float-left"
                                  src={`${process.env.PUBLIC_URL}/assets/svg-icons/${imageVariant}.svg`}
                                />

                                <span>{alertMessage}</span>
                              </Alert>
                            </Col>
                          </Row>
                        </>
                      )}
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-block btn-ftc-primary" /* block */
                      >
                        Login
                      </button>
                      <hr />
                      <LoginGoogle />
                      {/* <div className="mt-2">
                          <span className="primary__text__size">
                            Forgot your password?{' '}
                            <a href="#contact" className="primary-color">
                              Contact Support
                          </a>
                          </span>
                        </div> */}
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="text-center">
        <p className="primary__text__size">
          Copyright © {currentYear} Friend That Cooks, LLC. All Rights Reserved.
          |
          <Link to="/terms-of-service">
            <span className="txt-primary"> Terms of Service </span>
          </Link>
          |
          <Link to="/privacy-policy">
            <span className="txt-primary"> Privacy Policy </span>
          </Link>
        </p>
      </footer>
    </div>
  );
});

export default Login;
