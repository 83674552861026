import React, { memo, } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';

import { useFormContext } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



const CustomPopupContentForm = memo(() =>
{

    const { formState: { errors, }, watch, setValue } = useFormContext();
    
    const content = watch('content');

    return (
        <div>
            <Row>
                <Col md="12">
                    <label className="txt-light-secondary">
                        Content Type
                    </label>
                </Col>
                <Col md="3">
                    <Button variant="default"
                        className='ftc-radiobutton-border-checked pb-0 btn-border-round'
                    // onClick={handleShow}
                    >
                        <input
                            type="radio"
                            className="ftc-radiobutton-secondary "
                            id="textcontent"
                            name="textcontent"
                            checked
                        />
                        <Form.Label
                            // htmlFor={rate.value} 
                            className="position-relative ml-4 pl-2">
                            Text Content
                                </Form.Label>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <label className="txt-light-secondary">
                        Content
                    </label>
                                        
                    <CKEditor
                        editor={ClassicEditor}
                        data={content}
                        onReady={(editor) =>
                        {
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) =>
                        {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            setValue('content', data, { shouldDirty: true, shouldValidate: true });
                        }}
                        onBlur={(event, editor) =>
                        {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) =>
                        {
                            console.log('Focus.', editor);
                        }}
                    />
                                        
                </Col>
                {errors.content && (
                    <Col md="12">
                        <p className="text-danger">
                            {errors.content.message}
                        </p>
                    </Col>
                )}
            </Row>
        </div>
    );
       
});

export default CustomPopupContentForm
