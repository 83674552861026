import React, { memo, useEffect, useState } from 'react';
import { Link, Prompt, useHistory, useParams, } from 'react-router-dom';
// import * as Scroll from 'react-scroll';

import { Row, Col, Image, Button, Form, Alert, NavDropdown, } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { trackPromise } from 'react-promise-tracker';

import Toaster from '../../../components/Toaster';

import '../css/viewUserDetails.css';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';
import { DateConvert } from '../../../utils/TimesheetManagement';

import { createClient, deleteClientByClientId, getClientByClientId, updateClientByClientId } from '../../../services/client-service';
import { getAllCitiesByName } from '../../../services/city-service';
import { getAllChefsByCity } from '../../../services/users-service';
import Ellipses from '../../../components/Ellipses';
import { validatePermission } from '../../../utils/UserManagement';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import { useAuthContext } from '../../../contexts/user-context';

const animatedComponents = makeAnimated();

const ManageClient = memo(() => {
    const { authUser } = useAuthContext();
    const history = useHistory();

    // const { scroller } = Scroll;

    const { clientId } = useParams();
    const isAddMode = !clientId;
    const [isAPICalled, setIsAPICalled] = useState(false);

    const [client, setClient] = useState(null);

    const [cities, setCities] = useState([]);
    const [selectedCity, setselectedCity] = useState(null);

    const [chefs, setChefs] = useState([]);
    const [selectedChefs, setselectedChefs] = useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isSubmitSuccessful, setisSubmitSuccessful] = useState(false);

    const validationSchema = yup.object({
        email: yup.string().required('Email is required.').email('Email is not valid.'),
        username: yup.string().required('Client name is required.'),
        service_city: yup.mixed().required('Service City is required.'),
        chef: yup.array().min(1, 'Please select at least 1 chef.').max(5, 'You can select maximum 5 chefs')
            .required('Chefs are required.').nullable(),
        // status: yup.string().required('Status is required').default('active')
    });

    const { handleSubmit, register,
        formState: { errors, isValid, isSubmitted, isSubmitting, dirtyFields },
        reset, trigger, control, setValue } =
        useForm({
            resolver: yupResolver(validationSchema),
            shouldUnregister: false,
            mode: 'onChange',
            shouldFocusError: true,
        });

    async function getClientDetails() {
        try {
            const response = await trackPromise(getClientByClientId(clientId));
            setClient(response.data.data.client);
            reset({ ...response.data.data.client }, { keepDirty: true });
            setselectedCity(response.data.data.client.service_city);
            setselectedChefs(response.data.data.client.chef);
            setIsAPICalled(true);
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    async function fetchAllCities() {
        try {
            const response = await trackPromise(getAllCitiesByName());
            setCities(response.data.data.cities);
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    async function fetchAllChefs(cityId) {
        try {
            const response = await trackPromise(getAllChefsByCity(cityId));
            setChefs(response.data.data.users);
            if (!response.data.data.users.length) {
                toast.dark(
                    <Toaster
                        icon="warning"
                        message={`Chefs not found associated with ${selectedCity.cityname} City.`}
                    />
                );
            }
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    const updateUserStatus = async (status) => {
        try {
            const body = {
                status,
                inactivation_date: new Date(),
            };
            setIsAPICalled(false);
            const response = await trackPromise(
                updateClientByClientId(clientId, body)
            );
            setClient(response.data.data.client);
            if (response.status === 200) {
                toast.dark(<Toaster
                    icon="notify"
                    message={response.data.message}
                />)
                history.push(`/settings/viewClient/${response.data.data.client.clientId}`);
            }
            setIsAPICalled(true);
        } catch (error) {
            if (error?.response?.status === 400) {
                if (error.response.data.data.error.errors.email) {
                    toast.dark(
                        <Toaster
                            icon="warning"
                            message={`${error.response.data.data.error.errors.email.message}`}
                        />,
                        { autoClose: 5000, hideProgressBar: true }
                    );
                }
                if (error.response.data.data.error.errors.username) {
                    toast.dark(
                        <Toaster
                            icon="warning"
                            message={`${error.response.data.data.error.errors.username.message}`}
                        />,
                        { autoClose: 5000, hideProgressBar: true }
                    );
                }
            } else {
                toast.dark(
                    <Toaster
                        icon="error"
                        message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                    />
                );
            }

        }
    };

    const onDeleteClient = async () => {
        try {
            const response = await trackPromise(
                deleteClientByClientId(clientId)
            );
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally {
            setShow(false);
            history.replace('/settings/clients');
        }
    }

    useEffect(() => {
        fetchAllCities();
    }, []);

    useEffect(() => {
        if (!isAddMode) {
            getClientDetails();
        }
        else {
            setIsAPICalled(true);
        }
    }, [isAddMode]);

    useEffect(() => {
        if (selectedCity) {
            setValue('service_city', selectedCity, { shouldDirty: true, shouldValidate: true });
            fetchAllChefs(selectedCity._id);
            if (!isAddMode && client.service_city._id !== selectedCity._id) {
                setselectedChefs([]);
            }
            else if (isAddMode) {
                setselectedChefs([]);
            }
        }
        else {
            setValue('service_city', null, { shouldDirty: false, shouldValidate: true });
        }
    }, [selectedCity]);

    useEffect(() => {
        if (selectedChefs.length) {
            setValue('chef', selectedChefs, { shouldDirty: true, shouldValidate: true });
        }
        else {
            setValue('chef', null, { shouldDirty: false, shouldValidate: true });
        }
    }, [selectedChefs]);

    const handleReset = () => {
        reset({});
        setselectedCity(null);
        setselectedChefs([]);
    }

    const onSubmit = async (data) => {
        try {
            if (isValid) {
                if (isAddMode) {
                    const response = await trackPromise(createClient(data));
                    if (response.status === 201) {
                        toast.dark(<Toaster
                            icon="notify"
                            message={response.data.message}
                        />);
                        setisSubmitSuccessful(true);
                        history.push(`/settings/viewClient/${response.data.data.client.clientId}`);
                    }
                }
                else {
                    const response = await trackPromise(
                        updateClientByClientId(clientId, data)
                    );
                    if (response.status === 200) {
                        toast.dark(<Toaster
                            icon="notify"
                            message={response.data.message}
                        />);
                        setisSubmitSuccessful(true);
                        history.push(`/settings/viewClient/${response.data.data.client.clientId}`);
                    }
                }
            }
        } catch (error) {
            if (error.response?.data?.data?.error?.errors) {
                if (error.response?.data?.data?.error?.errors?.username) {
                    toast.dark(
                        <Toaster
                            icon="warning"
                            message={`${error.response.data.data.error.errors.username.message}`}
                        />,
                        { autoClose: 5000, hideProgressBar: true }
                    );
                }
                if (error.response?.data?.data?.error?.errors?.email) {
                    toast.dark(
                        <Toaster
                            icon="warning"
                            message={`${error.response.data.data.error.errors.email.message}`}
                        />,
                        { autoClose: 5000, hideProgressBar: true }
                    );
                }
            }
            else {
                toast.dark(
                    <Toaster
                        icon="error"
                        message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                    />
                );
            }

        }
    };

    const onBlurCity = () => {
        if (!selectedCity) {
            trigger('service_city');
        }
    }

    const onBlurChef = () => {
        if (!selectedChefs.length) {
            trigger('chef');
        }
    }

    // const onMenuOpen = (elementName) =>
    // {

    //     scroller.scrollTo(elementName, {
    //         duration: 1500,
    //         delay: 100,
    //         smooth: true,
    //         offset: 50, // Scrolls to element + 50 pixels down the page      
    //     });
    // };

    const SettingsDropdown = () => {
        return (<div>
            <NavDropdown
                title={<Ellipses />}
                id="collasible-nav-dropdown"
            >
                {client.status === 'active' ? (
                    <NavDropdown.Item
                        disabled={!validatePermission(authUser.user.role.permissions, 'client', 'edit')}
                        onClick={() => {
                            updateUserStatus('inactive')
                        }}
                    >
                        Deactivate
                    </NavDropdown.Item>
                ) : ('')}
                {client.status === 'inactive' ? (
                    <NavDropdown.Item
                        disabled={!validatePermission(authUser.user.role.permissions, 'client', 'edit')}
                        onClick={() => {
                            updateUserStatus('active')
                        }}>
                        Activate
                    </NavDropdown.Item>
                ) : ('')}
                <NavDropdown.Item
                    onClick={handleShow}
                    disabled={!validatePermission(authUser.user.role.permissions, 'client', 'delete')}
                >
                    Delete
                </NavDropdown.Item>
            </NavDropdown>
        </div>);
    };

    return isAPICalled ? (
        <div>
            <div className="sticky-page-header">
                <div className="container">
                    <Desktop>
                        <Row className="ml-5">
                            <Col md="1">
                                <Link to="/settings/clients">
                                    <a
                                        onKeyPress={() => { }}
                                        role="link"
                                        tabIndex="0"
                                    // onClick={onHandleBack}
                                    >
                                        <Image
                                            className="imageArrow"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </a>
                                </Link>
                            </Col>
                            <Col md="8" classname="pl-0">
                                {
                                    isAddMode ? (
                                        <h5 className="mt-1">
                                            Untitled Client
                                        </h5>) : (
                                        <>
                                            <h5>
                                                {client.username}
                                            </h5>
                                            <span className="txt-light-secondary">
                                                Last Updated on {DateConvert(client.updatedAt)}
                                            </span>
                                        </>
                                    )
                                }

                            </Col>
                            <Col md="1" className="pr-0 ml-5">
                                <Button
                                    variant="default"
                                    onClick={handleReset}
                                    className="float-right btn-ftc-border-primary"
                                >
                                    Reset
                                </Button>
                            </Col>
                            {
                                !isAddMode ? (
                                    <Col md="1">
                                        <div className="ml-2">
                                            <SettingsDropdown />
                                        </div>
                                    </Col>
                                ) : ('')
                            }
                        </Row>
                    </Desktop>
                    <Mobile>
                        <div className="container">
                            <Row className="pb-2">
                                <Col xs="1" className="p-0">
                                    <Link to="/settings/clients">
                                        <Image
                                            className="imageArrowMobile mt-1"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </Link>
                                </Col>
                                <Col xs="8" className="mt-1">
                                    {
                                        isAddMode ? (
                                            <h6 className="m-auto"><strong>Untitled Client</strong> </h6>) : (
                                            <>
                                                <h6 className="m-auto"><strong>{client.username}</strong> </h6>
                                                <span className="txt-light-secondary mx-auto">
                                                    Last Updated on {DateConvert(client.updatedAt)}

                                                </span>
                                            </>
                                        )
                                    }
                                </Col>
                                <Col xs="1">
                                    <Image
                                        onClick={handleReset}
                                        className="ftc-logo-24  mt-2"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-reset.svg`}
                                    />
                                </Col>
                                {
                                    !isAddMode ? (
                                        <Col xs="1">
                                            <div>
                                                <SettingsDropdown />
                                            </div>
                                        </Col>
                                    ) : ('')
                                }
                            </Row>
                        </div>
                    </Mobile>
                </div>
            </div>
            <Desktop>
                <div className="container-top-8" />
            </Desktop>
            <Mobile>
                <div className="container-top-20" />
            </Mobile>
            <div className="container">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <Row>
                            <Col md="8" className="mx-auto">
                                <div>
                                    <Row>
                                        <Col md="12">
                                            <h5><strong>Client Details</strong></h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" xs="12">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label className="txt-light-secondary">Client Email</Form.Label>
                                                <Form.Control
                                                    {...register('email')}
                                                    type="email"
                                                    placeholder="Enter Client Email"
                                                />
                                                {
                                                    !isAddMode && (
                                                        <p className="mt-2">
                                                            Client ID: <strong>{client.clientId}</strong>
                                                        </p>
                                                    )
                                                }
                                                {errors.email && (
                                                    <p className="text-danger">
                                                        {errors.email.message}
                                                    </p>
                                                )}
                                            </Form.Group>

                                        </Col>
                                        {/* </Row>
                                    <Row> */}
                                        <Col md="6" xs="12">

                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label className="txt-light-secondary">Client Name</Form.Label>
                                                <Form.Control
                                                    {...register('username')}
                                                    type="text"
                                                    placeholder="Enter Client Name"
                                                />
                                                {errors.username && (
                                                    <p className="text-danger">
                                                        {errors.username.message}
                                                    </p>
                                                )}
                                            </Form.Group>

                                        </Col>
                                        <Col md="6" xs="12">

                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label className="txt-light-secondary">City</Form.Label>
                                                <Controller
                                                    render={({ field: { name } }) => (<Select
                                                        name={name}
                                                        id='cityName'
                                                        isMulti={false}
                                                        closeMenuOnSelect
                                                        components={animatedComponents}
                                                        options={cities}
                                                        getOptionLabel={option => option.cityname}
                                                        getOptionValue={option => option._id}
                                                        value={selectedCity}
                                                        onChange={(option) => {
                                                            setselectedCity(option);
                                                        }}
                                                        onBlur={() => {
                                                            onBlurCity();
                                                        }}
                                                        styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                                                        onMenuOpen={() => {
                                                            // onMenuOpen('cityName');
                                                        }}
                                                        menuShouldScrollIntoView
                                                    />)}
                                                    name="service_city"
                                                    control={control}
                                                // defaultValue={selectedRole}
                                                />
                                                {(errors.service_city && isSubmitted) && (
                                                    <p className="text-danger">
                                                        {errors.service_city.message}
                                                    </p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <div>
                                                <h5 className="d-inline">
                                                    <strong>Assigned Employees  </strong>
                                                </h5>
                                                <label>(Min 1 and Max 5)</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">

                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label className="txt-light-secondary">Select Chefs</Form.Label>
                                                <Controller
                                                    render={({ field: { name, } }) => (<Select
                                                        name={name}
                                                        id="chefName"
                                                        isMulti
                                                        closeMenuOnSelect
                                                        components={animatedComponents}
                                                        options={chefs}
                                                        getOptionLabel={(option) => option.username}
                                                        // {(option) => `${option.username} (Chef ${selectedChefs.findIndex((ch) => ch.username === option.username) + 1})`}
                                                        getOptionValue={option => option._id}
                                                        value={selectedChefs}
                                                        // value={value}
                                                        onChange={(option) => {
                                                            if (option.length > 5) {
                                                                toast.dark(
                                                                    <Toaster
                                                                        icon="warning"
                                                                        message='You can select maximum of 5 chefs!'
                                                                    />,
                                                                );
                                                            }
                                                            else {
                                                                setselectedChefs(option);
                                                            }
                                                        }}
                                                        onBlur={() => {
                                                            onBlurChef();
                                                        }}
                                                        backspaceRemovesValue
                                                        styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                                                        onMenuOpen={() => {
                                                            // onMenuOpen('chefName');
                                                        }}
                                                        menuShouldScrollIntoView
                                                    />)}
                                                    name="chef"
                                                    control={control}
                                                // defaultValue={selectedRole}
                                                />
                                                {(errors.chef && isSubmitted) && (
                                                    <p className="text-danger">
                                                        {errors.chef.message}
                                                    </p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <Alert variant='success'>
                                                <Col md="12">
                                                    <span>
                                                        <Image
                                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`}
                                                            fluid
                                                        />
                                                        {` `}Active User
                                                    </span>
                                                </Col>
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="border-bottom" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <button type="submit" disabled={isSubmitting} className="btn btn-ftc-primary float-right">
                                                Save
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>

            <Prompt
                when={isSubmitSuccessful ? false : Object.keys(dirtyFields).length}
                message='You have unsaved changes, are you sure you want to leave?'
            />

            {show && <ConfirmAlertModal
                show={show}
                handleClose={handleClose}
                title='Delete Client'
                description='Deleting this Client will erase all data inside this record.
                Are you sure you want to delete this Client?'
                callback={onDeleteClient}
                action='Delete it'
                closeAction='Cancel'
            />}

        </div>
    ) : ('')
});

export default ManageClient
