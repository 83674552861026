import React, { useState } from 'react';

const getFileExtension = (url) => {
  const pathParts = url.split('?')[0].split('/');
  const fileName = pathParts[pathParts.length - 1];
  const fileParts = fileName.split('.');
  return fileParts[fileParts.length - 1].toLowerCase();
};

function ReceiptList({ receipts }) {
  const [activeReceipt, setActiveReceipt] = useState(receipts[0]);

  return (
    <div>
      <ReceiptsList
        receipts={receipts}
        onReceiptClick={setActiveReceipt}
        activeReceipt={activeReceipt}
      />
      <ActiveReceipt receipt={activeReceipt} />
    </div>
  );
}

function ReceiptsList({ receipts, onReceiptClick, activeReceipt }) {
  return (
    <div className="receipts-list d-flex overflow-x-scroll custom-scrollbar pb-2">
      {receipts.map((receipt, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          tabIndex={0}
          role="button"
          onClick={() => onReceiptClick(receipt)}
          className={`receipt-item d-flex justify-content-between flex-column ${
            activeReceipt === receipt ? 'active' : ''
          }`}
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
              onReceiptClick(receipt);
            }
          }}
        >
          {getFileExtension(receipt) === 'pdf' ? (
            // Display the first page of the selected PDF
            // <img src={receipt} alt={`Receipt ${index + 1}`} height="85px" />
            <iframe src={receipt} title="PDF Viewer" width="100px" height="85px" />
          ) : (
            <img src={receipt} alt={`Receipt ${index + 1}`} height="85px" />
          )}
          <div className="d-flex justify-content-center">
            Receipt {index + 1}
          </div>
        </div>
      ))}
    </div>
  );
}

function ActiveReceipt({ receipt }) {
  return (
    <div className="active-receipt">
      {getFileExtension(receipt) === 'pdf' ? (
        // Display all pages of the selected PDF
        <iframe src={receipt} title="PDF Viewer" width="100%" height="500px" />
      ) : (
        <img src={receipt} alt="Receipt" width="440px" />
      )}
    </div>
  );
}

export default ReceiptList;
