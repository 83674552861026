import React, { memo } from 'react'
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ConfirmAlertModal = memo(({
    show, handleClose, title, description, callback, action, closeAction
}) => {
    return (
        <Modal show={show} centered onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {/* <strong>Delete Timesheet</strong> */}
                    <strong>{title}</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container pt-2 pl-0 pr-0">
                    <label>
                        {/* Deleting this timesheet will erase all data inside this record.
                        Are you sure you want to delete this timesheet? */}
                        {description}
                    </label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-ftc-border-primary"
                    onClick={handleClose}
                >
                    {/* Cancel */}
                    {closeAction}
                </Button>
                <Button variant="primary" className="btn-ftc-primary" onClick={callback}>
                    {/* Delete */}
                    {action}
                </Button>
            </Modal.Footer>
        </Modal>

    );
});

export default ConfirmAlertModal;

ConfirmAlertModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
    action: PropTypes.string.isRequired,
    closeAction: PropTypes.string,
};

ConfirmAlertModal.defaultProps = {
    closeAction: 'Cancel'
};

