import React, { memo, useEffect, useState } from 'react';
import { Row, Col, Form, Button, Card, Image } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { toast } from 'react-toastify';
import moment from 'moment';
import { trackPromise } from 'react-promise-tracker';
// import * as Scroll from 'react-scroll';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { Controller, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent, DialogTitle, Slide } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';

import Sidebar from '../../side-navbar/Sidebar';
import DatePickerInput from '../../../components/DatePickerInput';
import NoReportsData from '../../../components/NoReportsData';
import Toaster from '../../../components/Toaster';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import { getAllUsersByCity } from '../../../services/users-service';
import { getAllCitiesForReport } from '../../../services/city-service';
import { generateHoursReport } from '../../../services/hours-report.service';
import {
  getPeriodsByYear,
  getPeriodsByYearAndDate,
  getPeriodsLtYear,
} from '../../../services/period-service';
import {
  DateConvert,
  fullDateConvert,
  minutesToDecimalHour,
} from '../../../utils/TimesheetManagement';
import RenderCustomPopup from '../../../components/RenderCustomPopup';
import { useAuthContext } from '../../../contexts/user-context';

import '../css/hours.css';

const animatedComponents = makeAnimated();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Hours = memo(() => {
  const { authUser } = useAuthContext();
  // const [selectedDate, handleDateChange] = useState(new Date());

  // const { scroller } = Scroll;

  const isMobile = useMediaQuery({ maxWidth: 991 });

  const [isAPIcalled, setIsAPIcalled] = useState(false);

  const [employees, setemployees] = useState([]);
  const [selectedEmployee, setselectedEmployee] = useState(null);

  const [cities, setcities] = useState([]);
  const [selectedCity, setselectedCity] = useState(null);

  const allPeriods = ['Custom', 'Week', 'Period'];
  const [selectedDatePeriod, setselectedDatePeriod] = useState('Week');
  const [showPeriodDates, setshowPeriodDates] = useState({
    startDate: '',
    endDate: '',
  });

  const allWeekOptions = [
    {
      value: 'current',
      label: 'Current Week',
    },
    {
      value: 'previous',
      label: 'Previous Week',
    },
    {
      value: 'last_2_week',
      label: 'Last 2 Weeks',
    },
  ];
  const [selectedWeek, setSelectedWeek] = useState(null);

  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setselectedPeriod] = useState(null);

  const [allYears, setallYears] = useState([]);
  const [selectedYear, setselectedYear] = useState(null);

  const [reportData, setReportData] = useState(null);
  const [responseData, setresponseData] = useState(null);
  const [employeesSatus, setEmployeesSatus] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const validationSchema = yup.object({
    employee: yup
      .mixed('Employee is required!')
      .required('Employee is required.'),
    service_city: yup
      .mixed('Service city is required.')
      .required('Service city is required.'),
    startDate: yup.string().nullable().required('Start Date is Required.'),
    endDate: yup.string().nullable().required('End Date is required'),
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitting },
    control,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  async function fetchAllCities() {
    try {
      const response = await trackPromise(getAllCitiesForReport());
      setcities(response.data.data.cities);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function getAllEmployeesByCity() {
    try {
      const response = await trackPromise(getAllUsersByCity(selectedCity._id));
      if (response.status === 200) {
        setemployees(response.data.data.users);
      } else if (response.status === 204) {
        setemployees([]);
        toast.dark(
          <Toaster
            icon="warning"
            message={`No Employee(s) associated with ${selectedCity.cityname}.`}
          />
        );
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function fetchAllPeriodsYears() {
    try {
      const response = await trackPromise(
        getPeriodsLtYear(new Date().getFullYear())
      );
      setallYears(response.data.data.periods);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function fetchAllPeriods() {
    try {
      let response;
      if (selectedYear) {
        response = await trackPromise(
          getPeriodsByYear(selectedYear.year, new Date())
        );
      } else {
        response = await trackPromise(
          getPeriodsByYearAndDate(new Date().getFullYear(), new Date())
        );
      }
      const totalPeriods = response.data.data.periods.length;
      setPeriods(
        response.data.data.periods.map((period, index) => {
          const tempPeriod = period;
          tempPeriod.label = `Period ${totalPeriods - index} (${DateConvert(
            period.start_date
          )} - ${DateConvert(period.end_date)})`;
          tempPeriod.value = period.period_index;
          return tempPeriod;
        })
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const onSubmitForm = async (data) => {

    setIsAPIcalled(true);
    try {
      const body = {
        employee: data.employee._id,
        service_city: data.service_city._id,
        startDate: data.startDate,
        endDate: data.endDate,
      };
      const response = await trackPromise(generateHoursReport(body));
      setReportData(response.data.data.report); // response.data.data.report?.benefitHours[0]?.totalTimeTaken
      setEmployeesSatus(response?.data?.data?.employeeStatus?.status);
      setresponseData(response.data.data.bodyObj);
      if (response.status === 200) {
        toast.dark(<Toaster icon="notify" message={response.data.message} />);
      }
      setIsAPIcalled(true);
      if (isMobile) {
        handleClickOpen();
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  };
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();
  useEffect(() => {
    (async () => {
      await fetchAllCities();
      await fetchAllPeriods();
    })();
  }, []);

  useEffect(() => {
    if (selectedEmployee) {
      // set value on change for employee
      setValue('employee', selectedEmployee, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue('employee', null, { shouldDirty: true, shouldValidate: true });
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (cities.length) {
      switch (authUser.user.role.rolename) {
        case 'Chef':
        case 'Office':
          setselectedCity(authUser.user.service_city[0]);
          break;

        default:
          break;
      }
    }
  }, [cities]);

  useEffect(() => {
    if (selectedCity) {
      switch (authUser.user.role.rolename) {
        case 'Chef':
        case 'Office':
          setemployees([authUser.user]);
          setselectedEmployee(authUser.user);
          break;

        default:
          setemployees([]);
          setselectedEmployee(null);
          getAllEmployeesByCity();
          break;
      }
      setValue('service_city', selectedCity, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue('service_city', null, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedCity]);

  useEffect(() => {
    setValue('startDate', null, { shouldDirty: true, shouldValidate: true });
    setValue('endDate', null, { shouldDirty: true, shouldValidate: true });

    setSelectedWeek(null);
    setselectedPeriod(null);
    setselectedYear(null);
    setshowPeriodDates({
      startDate: '',
      endDate: '',
    });

    if (selectedDatePeriod === 'Period') {
      fetchAllPeriodsYears();
    }
  }, [selectedDatePeriod]);

  useEffect(() => {
    if (selectedYear) {
      setselectedPeriod(null);
      setshowPeriodDates({
        startDate: '',
        endDate: '',
      });
      setValue('startDate', null, { shouldDirty: true, shouldValidate: true });
      setValue('endDate', null, { shouldDirty: true, shouldValidate: true });
      fetchAllPeriods();
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedWeek) {
      switch (selectedWeek.value) {
        case 'current': {
          const startOfWeek = moment()
            // .utc()
            .startOf('week')
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          const endOfWeek = moment()
            // .utc()
            .endOf('week')
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();

          setValue('startDate', startOfWeek, {
            shouldDirty: true,
            shouldValidate: true,
          });
          setValue('endDate', endOfWeek, {
            shouldDirty: true,
            shouldValidate: true,
          });
          break;
        }
        case 'previous': {
          const startOfPrevWeek = moment()
            // .utc()
            .subtract(1, 'weeks')
            .startOf('week')
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          const endOfPrevWeek = moment()
            // .utc()
            .subtract(1, 'weeks')
            .endOf('week')
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();

          setValue('startDate', startOfPrevWeek, {
            shouldDirty: true,
            shouldValidate: true,
          });
          setValue('endDate', endOfPrevWeek, {
            shouldDirty: true,
            shouldValidate: true,
          });
          break;
        }

        case 'last_2_week': {
          const lastWeekStart = moment()
            // .utc()
            .subtract(2, 'weeks')
            .startOf('week')
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          const lastWeekEnd = moment()
            // .utc()
            .subtract(1, 'weeks')
            .endOf('week')
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();

          setValue('startDate', lastWeekStart, {
            shouldDirty: true,
            shouldValidate: true,
          });
          setValue('endDate', lastWeekEnd, {
            shouldDirty: true,
            shouldValidate: true,
          });
          break;
        }

        default:
          break;
      }
    }
  }, [selectedWeek]);

  useEffect(() => {
    if (selectedPeriod) {
      setshowPeriodDates({
        startDate: moment
          .utc(selectedPeriod.start_date)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        endDate: moment
          .utc(selectedPeriod.end_date)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
      });
      setValue('startDate', selectedPeriod.start_date, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('endDate', selectedPeriod.end_date, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedPeriod]);

  const handleReset = () => {
    reset({}, { keepValues: false });
    setIsAPIcalled(false);
    setselectedCity(null);
    setselectedEmployee(null);
    setemployees([]);

    setselectedDatePeriod('Week');
    setSelectedWeek(null);
    setselectedPeriod(null);
    setselectedYear(null);

    setshowPeriodDates({
      startDate: '',
      endDate: '',
    });
  };

  const onPeriodChange = (e, periodName) => {
    if (e.target.checked) {
      setselectedDatePeriod(periodName);
    } else {
      setselectedDatePeriod(null);
    }
  };

  // const onMenuOpen = (elementName) => {
  //   scroller.scrollTo(elementName, {
  //     duration: 1500,
  //     delay: 100,
  //     smooth: true,
  //     offset: 50, // Scrolls to element + 50 pixels down the page
  //   });
  // };

  return (
    <div>
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar />
        </Mobile>
        {/* <Desktop> */}
        <Col xl="4" lg="5" className="border-right pr-0">
          <div className="container">
            <Form onSubmit={handleSubmit(onSubmitForm)}>
              <Desktop>
                <Row>
                  <Col md="12">
                    <h4>
                      <strong>Generate Hours Report</strong>
                    </h4>
                  </Col>
                </Row>
              </Desktop>
              <Mobile>
                <div className="container">
                  <Row className="border-bottom sticky-dashboard-header-mobile">
                    <Col xs="9" className="ml-2">
                      <h5 className="mt-1">
                        <strong>Generate Hours Report</strong>
                      </h5>
                    </Col>
                    <Col xs="2" className="pr-0">
                      <Image
                        className="ftc-logo-40 float-right"
                        src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                      />{' '}
                    </Col>
                  </Row>
                  <div className="container-top-20" />
                </div>
              </Mobile>
              <Row>
                <Col md="12">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="txt-light-secondary">
                      City
                    </Form.Label>
                    <Controller
                      render={({ field: { name } }) => (
                        <Select
                          name={name}
                          isMulti={false}
                          closeMenuOnSelect
                          components={animatedComponents}
                          options={cities}
                          getOptionLabel={(option) => option.cityname}
                          getOptionValue={(option) => option._id}
                          value={selectedCity}
                          onChange={(option) => {
                            setselectedCity(option);
                          }}
                        // onBlur={() => {
                        //     onBlurEmployee();
                        // }}
                        />
                      )}
                      name="service_city"
                      control={control}
                    />
                    {errors.service_city && isSubmitted && (
                      <p className="text-danger">
                        {errors.service_city.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="txt-light-secondary">
                      Employee Name
                    </Form.Label>
                    <Controller
                      render={({ field: { name } }) => (
                        <Select
                          name={name}
                          isMulti={false}
                          closeMenuOnSelect
                          components={animatedComponents}
                          options={employees}
                          getOptionLabel={(option) => option.username}
                          getOptionValue={(option) => option._id}
                          value={selectedEmployee}
                          onChange={(option) => {
                            setselectedEmployee(option);
                          }}
                        // onBlur={() => {
                        //     onBlurEmployee();
                        // }}
                        />
                      )}
                      name="employee"
                      control={control}
                    />
                    {errors.employee && isSubmitted && (
                      <p className="text-danger">{errors.employee.message}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <label className="txt-light-secondary">Choose Period</label>
                </Col>
                {allPeriods.map((period) => {
                  return (
                    <Col sm="4">
                      <Form.Group>
                        <Button
                          variant="default"
                          className={
                            period === selectedDatePeriod
                              ? `ftc-radiobutton-border-checked btn-border-round`
                              : `ftc-radiobutton-border-unchecked btn-border-round`
                          }
                        >
                          <input
                            type="radio"
                            className="ftc-radiobutton-secondary "
                            id={period}
                            name="period"
                            onChange={(e) => {
                              onPeriodChange(e, period);
                            }}
                            checked={period === selectedDatePeriod}
                          />
                          <Form.Label
                            htmlFor={period}
                            className="position-relative ml-4 pl-2"
                          >
                            {period}
                          </Form.Label>
                        </Button>
                      </Form.Group>
                    </Col>
                  );
                })}
                {selectedDatePeriod === 'Week' && (
                  <Col md="12">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="txt-light-secondary">
                        Week
                      </Form.Label>
                      <Select
                        name="week_options"
                        id="weekOptionScroll"
                        isMulti={false}
                        closeMenuOnSelect
                        components={animatedComponents}
                        options={allWeekOptions}
                        value={selectedWeek}
                        onChange={(option) => {
                          setSelectedWeek(option);
                        }}
                        styles={{
                          menu: (styles) => ({ ...styles, zIndex: 999 }),
                        }}
                        onMenuOpen={() => {
                          // onMenuOpen('weekOptionScroll');
                        }}
                        menuShouldScrollIntoView
                      />
                    </Form.Group>
                  </Col>
                )}
                {selectedDatePeriod === 'Period' && (
                  <>
                    <Col md="12">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="txt-light-secondary">
                          Period Year
                        </Form.Label>
                        <Select
                          name="period_year_options"
                          id="periodYearOptionScroll"
                          isMulti={false}
                          closeMenuOnSelect
                          components={animatedComponents}
                          options={allYears}
                          value={selectedYear}
                          getOptionLabel={(option) => option.year}
                          getOptionValue={(option) => option.year}
                          onChange={(option) => {
                            setselectedYear(option);
                          }}
                          styles={{
                            menu: (styles) => ({ ...styles, zIndex: 999 }),
                          }}
                          onMenuOpen={() => {
                            // onMenuOpen('periodYearOptionScroll');
                          }}
                          menuShouldScrollIntoView
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="txt-light-secondary">
                          Period
                        </Form.Label>
                        <Select
                          name="period_options"
                          id="periodOptionScroll"
                          isMulti={false}
                          closeMenuOnSelect
                          components={animatedComponents}
                          options={periods}
                          value={selectedPeriod}
                          onChange={(option) => {
                            setselectedPeriod(option);
                          }}
                          styles={{
                            menu: (styles) => ({ ...styles, zIndex: 999 }),
                          }}
                          onMenuOpen={() => {
                            // onMenuOpen('periodOptionScroll');
                          }}
                          menuShouldScrollIntoView
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col md="12">
                  <Form.Label className="txt-light-secondary">
                    Date Begin
                  </Form.Label>
                </Col>
                <Col md="12">
                  <Controller
                    render={({ field: { ref, name, value, onChange } }) => (
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={enLocale}
                      >
                        <DatePickerInput
                          name={name}
                          ref={ref}
                          handleDateChange={(e) => {
                            onChange(
                              moment(e)
                                // .utc()
                                // .utcOffset(0)
                                .set({
                                  hour: 0,
                                  minute: 0,
                                  second: 0,
                                  millisecond: 0,
                                })
                                .toDate()
                            );
                          }}
                          selectedDate={
                            selectedDatePeriod === 'Period'
                              ? showPeriodDates.startDate
                              : value
                          }
                          disabled={
                            selectedDatePeriod === 'Week' ||
                            selectedDatePeriod === 'Period'
                          }
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    name="startDate"
                    control={control}
                    defaultValue={null}
                  />
                  {errors.startDate && isSubmitted && (
                    <p className="text-danger">{errors.startDate.message}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Form.Label className="txt-light-secondary">
                    Date End
                  </Form.Label>
                </Col>
                <Col md="12">
                  <Controller
                    render={({ field: { ref, name, value, onChange } }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePickerInput
                          name={name}
                          ref={ref}
                          handleDateChange={(e) => {
                            onChange(
                              moment(e)
                                // .utc()
                                // .utcOffset(0)
                                .set({
                                  hour: 0,
                                  minute: 0,
                                  second: 0,
                                  millisecond: 0,
                                })
                                .toDate()
                            );
                          }}
                          selectedDate={
                            selectedDatePeriod === 'Period'
                              ? showPeriodDates.endDate
                              : value
                          }
                          disabled={
                            selectedDatePeriod === 'Week' ||
                            selectedDatePeriod === 'Period'
                          }
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    name="endDate"
                    control={control}
                    defaultValue={null}
                  />
                  {errors.endDate && isSubmitted && (
                    <p className="text-danger">{errors.endDate.message}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="3" xs="3">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-ftc-primary"
                  >
                    Submit
                  </button>
                </Col>
                <Col md="3" xs="3">
                  <button
                    type="button"
                    className="btn btn-ftc-border-primary"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Desktop>
          <Col xl="6" lg="5" xs="11" className="mx-auto pr-0">
            {!isAPIcalled ? (
              <NoReportsData />
            ) : (
              <div className="ml-3">
                <Row>
                  <Col md="6">
                    <h4>
                      <strong>Hours Report Data</strong>
                    </h4>
                    <label>Current Status:</label>&nbsp;
                    <label className="txt-primary">
                      {employeesSatus}
                      <Image
                        ref={setTriggerRef}
                        className="ml-2 svgHover"
                        width="14" height="14"
                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/information-Red.svg`}
                      />
                      {visible && (
                        <div
                          ref={setTooltipRef}
                          {...getTooltipProps({ className: 'tooltip-container' })}
                        >
                          This is your current status calculated using the previous completed 12 weeks timesheet records. It may not be reflective of the time period selected in this report.
                          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                        </div>
                      )}
                    </label><br />
                    <label className="txt-primary">
                      {fullDateConvert(responseData?.startDate)} -{' '}
                      {fullDateConvert(responseData?.endDate)}
                    </label>
                  </Col>
                  {/* <Col md="6">
                                            <button type="button" className="btn btn-ftc-border-secondary float-right">Export as PDF</button>
                                        </Col> */}
                </Row>
                <Row className="pr-0">
                  <Col md="12">
                    <label className="mb-0">
                      <strong>Hours Report</strong>
                    </label>
                    <Card className="mt-3">
                      <Card.Body className="pt-0 pb-2">
                        <Row className="border-bottom pt-3 pb-2">
                          <Col md="6" xs="6">
                            <label>Regular (Hours)</label>
                          </Col>
                          <Col md="6" xs="6">
                            <label className="float-right txt-primary">
                              <strong>
                                {minutesToDecimalHour(
                                  reportData?.regularHours[0]?.totalTimeTaken
                                )}{' '}
                                Hrs
                              </strong>
                            </label>
                          </Col>
                        </Row>
                        <Row className="border-bottom pt-3 pb-2">
                          <Col md="6" xs="6">
                            <label>Flex Pay (Hours)</label>
                          </Col>
                          <Col md="6" xs="6">
                            <label className="float-right txt-primary">
                              <strong>
                                {minutesToDecimalHour(
                                  reportData?.flexPayHours[0]?.totalTimeTaken
                                )}{' '}
                                Hrs
                              </strong>
                            </label>
                          </Col>
                        </Row>
                        <Row className="border-bottom pt-3 pb-2">
                          <Col md="6" xs="6">
                            <label>Holiday (Hours)</label>
                          </Col>
                          <Col md="6" xs="6">
                            <label className="float-right txt-primary">
                              <strong>
                                {minutesToDecimalHour(
                                  reportData?.holidayHours[0]
                                    ?.totalTimeTaken
                                )}{' '}
                                Hrs
                              </strong>
                            </label>
                          </Col>
                        </Row>
                        <Row className="border-bottom pt-3 pb-2">
                          <Col md="6" xs="6">
                            <label>Trainee (Hours)</label>
                          </Col>
                          <Col md="6" xs="6">
                            <label className="float-right txt-primary">
                              <strong>
                                {minutesToDecimalHour(
                                  reportData?.traineeHours[0]?.totalTimeTaken
                                )}{' '}
                                Hrs
                              </strong>
                            </label>
                          </Col>
                        </Row>
                        <Row className="border-bottom pt-3 pb-2">
                          <Col md="6" xs="6">
                            <label>Trainer (Hours)</label>
                          </Col>
                          <Col md="6" xs="6">
                            <label className="float-right txt-primary">
                              <strong>
                                {minutesToDecimalHour(
                                  reportData?.trainerHours[0]?.totalTimeTaken
                                )}{' '}
                                Hrs
                              </strong>
                            </label>
                          </Col>
                        </Row>
                        <Row className="border-bottom pt-3 pb-2">
                          <Col md="6" xs="6">
                            <label>Overtime (Hours)</label>
                          </Col>
                          <Col md="6" xs="6">
                            <label className="float-right txt-primary">
                              <strong>
                                {minutesToDecimalHour(
                                  reportData?.overTimeHours[0]?.totalTimeTaken
                                )}{' '}
                                Hrs
                              </strong>
                            </label>
                          </Col>
                        </Row>
                        <Row className="border-bottom pt-3 pb-2">
                          <Col md="6" xs="6">
                            <label>Special Rate (Hours)</label>
                          </Col>
                          <Col md="6" xs="6">
                            <label className="float-right txt-primary">
                              <strong>
                                {minutesToDecimalHour(
                                  reportData?.spcialRateHours[0]?.totalTimeTaken
                                )}{' '}
                                Hrs
                              </strong>
                            </label>
                          </Col>
                        </Row>
                        <Row className="border-bottom pt-3 pb-2">
                          <Col md="6" xs="6">
                            <label>Holding Fee (Hours)</label>
                          </Col>
                          <Col md="6" xs="6">
                            <label className="float-right txt-primary">
                              <strong>
                                {
                                  reportData?.holdingFeeHours[0]
                                    ?.holdingFeeNumber
                                }{' '}
                                Hrs
                              </strong>
                            </label>
                          </Col>
                        </Row>
                        <Row className="pt-3 pb-2">
                          <Col md="6" xs="6">
                            <label>Benefit (Hours)</label>
                          </Col>
                          <Col md="6" xs="6">
                            <label className="float-right txt-primary">
                              <strong>
                                {minutesToDecimalHour(
                                  reportData?.benefitHours[0]?.totalTimeTaken
                                )}{' '}
                                Hrs
                              </strong>
                            </label>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="pr-1">
                    <label className="mb-0">
                      <strong>Charges Report</strong>
                    </label>
                    <Card className="mt-3">
                      <Card.Body className="pt-0 pb-2">
                        <Row className="border-bottom pt-3 pb-2">
                          <Col md="6" xs="6">
                            <label>Holding Fees (Dollar $)</label>
                          </Col>
                          <Col md="6" xs="6">
                            <label className="float-right txt-primary">
                              <strong>
                                ${' '}
                                {reportData?.holdingFees[0]?.totalTimeTaken?.toFixed(
                                  2
                                ) ?? '0.00'}
                              </strong>
                            </label>
                          </Col>
                        </Row>
                        <Row className="pt-3 pb-2">
                          <Col md="6" xs="6">
                            <label>Tips (Dollar $)</label>
                          </Col>
                          <Col md="6" xs="6">
                            <label className="float-right txt-primary">
                              <strong>
                                ${' '}
                                {reportData?.tips[0]?.totalTimeTaken?.toFixed(
                                  2
                                ) ?? '0.00'}
                              </strong>
                            </label>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Desktop>
        <Mobile>
          {isAPIcalled ? (
            <div>
              <Dialog
                className="mobile__dialog"
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
                fullWidth="true"
              >
                <DialogTitle className="pb-0">
                  <div className="float-right">
                    <Image
                      onClick={handleCloseDialog}
                      className="ftc-logo-24"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
                      fluid
                    />
                  </div>
                </DialogTitle>
                <DialogContent>
                  <div>
                    <Row>
                      <Col xs="8">
                        <h4>Hours Report</h4>
                        <label>Current Status:</label>&nbsp;
                        <label className="txt-primary">
                          {employeesSatus}
                          <Image
                            ref={setTriggerRef}
                            className="ml-2 ml-1"
                            width="14" height="14"
                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/information-Red.svg`}
                          />
                          {visible && (
                            <div
                              ref={setTooltipRef}
                              {...getTooltipProps({ className: 'tooltip-container' })}
                            >
                              This is your current status calculated using the previous completed 12 weeks timesheet records. It may not be reflective of the time period selected in this report.
                              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                            </div>
                          )}
                        </label>
                        <p className="txt-primary">
                          {fullDateConvert(responseData?.startDate)} -{' '}
                          {fullDateConvert(responseData?.endDate)}
                        </p>
                      </Col>
                    </Row>
                    <Row className="row__colour">
                      <Col xs="6">
                        <p className="txt-light-secondary">Employee Name</p>
                      </Col>
                      <Col xs="6">
                        <p className="float-right">
                          {selectedEmployee?.username}
                        </p>
                      </Col>
                      <Col xs="6">
                        <p className="txt-light-secondary">City</p>
                      </Col>
                      <Col xs="6">
                        <p className="float-right">{selectedCity?.cityname}</p>
                      </Col>
                      <Col xs="6">
                        <p className="txt-light-secondary">Date Begin</p>
                      </Col>
                      <Col xs="6">
                        <p className="float-right">
                          {fullDateConvert(responseData?.startDate)}
                        </p>
                      </Col>
                      <Col xs="6">
                        <p className="txt-light-secondary">Date End</p>
                      </Col>
                      <Col xs="6">
                        <p className="float-right">
                          {fullDateConvert(responseData?.endDate)}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <label className="mb-0">
                          <strong>Hours Report</strong>
                        </label>
                        <Card className="mt-3">
                          <Card.Body className="pt-0 pb-2">
                            <Row className="border-bottom pt-3 pb-2">
                              <Col xs="8" className="pr-0 pl-0">
                                <label>Regular (Hours)</label>
                              </Col>
                              <Col xs="4" className="pr-0 pl-0">
                                <label className="float-right txt-primary">
                                  <strong>
                                    {minutesToDecimalHour(
                                      reportData?.regularHours[0]
                                        ?.totalTimeTaken
                                    )}{' '}
                                    Hrs
                                  </strong>
                                </label>
                              </Col>
                            </Row>
                            <Row className="border-bottom pt-3 pb-2">
                              <Col xs="8" className="pr-0 pl-0">
                                <label>Flex Pay (Hours)</label>
                              </Col>
                              <Col xs="4" className="pr-0 pl-0">
                                <label className="float-right txt-primary">
                                  <strong>
                                    {minutesToDecimalHour(
                                      reportData?.flexPayHours[0]
                                        ?.totalTimeTaken
                                    )}{' '}
                                    Hrs
                                  </strong>
                                </label>
                              </Col>
                            </Row>
                            <Row className="border-bottom pt-3 pb-2">
                              <Col xs="8" className="pr-0 pl-0">
                                <label>Holiday (Hours)</label>
                              </Col>
                              <Col xs="4" className="pr-0 pl-0">
                                <label className="float-right txt-primary">
                                  <strong>
                                    {minutesToDecimalHour(
                                      reportData?.holidayHours[0]
                                        ?.totalTimeTaken
                                    )}{' '}
                                    Hrs
                                  </strong>
                                </label>
                              </Col>
                            </Row>
                            <Row className="border-bottom pt-3 pb-2">
                              <Col xs="8" className="pr-0 pl-0">
                                <label>Trainee (Hours)</label>
                              </Col>
                              <Col xs="4" className="pr-0 pl-0">
                                <label className="float-right txt-primary">
                                  <strong>
                                    {minutesToDecimalHour(
                                      reportData?.traineeHours[0]
                                        ?.totalTimeTaken
                                    )}{' '}
                                    Hrs
                                  </strong>
                                </label>
                              </Col>
                            </Row>
                            <Row className="border-bottom pt-3 pb-2">
                              <Col xs="8" className="pr-0 pl-0">
                                <label>Trainer (Hours)</label>
                              </Col>
                              <Col xs="4" className="pr-0 pl-0">
                                <label className="float-right txt-primary">
                                  <strong>
                                    {minutesToDecimalHour(
                                      reportData?.trainerHours[0]
                                        ?.totalTimeTaken
                                    )}{' '}
                                    Hrs
                                  </strong>
                                </label>
                              </Col>
                            </Row>
                            <Row className="border-bottom pt-3 pb-2">
                              <Col xs="8" className="pr-0 pl-0">
                                <label>Overtime (Hours)</label>
                              </Col>
                              <Col xs="4" className="pr-0 pl-0">
                                <label className="float-right txt-primary">
                                  <strong>
                                    {minutesToDecimalHour(
                                      reportData?.overTimeHours[0]
                                        ?.totalTimeTaken
                                    )}{' '}
                                    Hrs
                                  </strong>
                                </label>
                              </Col>
                            </Row>
                            <Row className="border-bottom pt-3 pb-2">
                              <Col xs="8" className="pr-0 pl-0">
                                <label>Special Rate (Hours)</label>
                              </Col>
                              <Col xs="4" className="pr-0 pl-0">
                                <label className="float-right txt-primary">
                                  <strong>
                                    {minutesToDecimalHour(
                                      reportData?.spcialRateHours[0]
                                        ?.totalTimeTaken
                                    )}{' '}
                                    Hrs
                                  </strong>
                                </label>
                              </Col>
                            </Row>
                            <Row className="border-bottom pt-3 pb-2">
                              <Col xs="8" className="pr-0 pl-0">
                                <label>Holding Fee (Hours)</label>
                              </Col>
                              <Col xs="4" className="pr-0 pl-0">
                                <label className="float-right txt-primary">
                                  <strong>
                                    {
                                      reportData?.holdingFeeHours[0]
                                        ?.holdingFeeNumber
                                    }{' '}
                                    Hrs
                                  </strong>
                                </label>
                              </Col>
                            </Row>
                            <Row className="pt-3 pb-2">
                              <Col xs="8" className="pr-0 pl-0">
                                <label>Benefit (Hours)</label>
                              </Col>
                              <Col xs="4" className="pr-0 pl-0">
                                <label className="float-right txt-primary">
                                  <strong>
                                    {minutesToDecimalHour(
                                      reportData?.benefitHours[0]
                                        ?.totalTimeTaken
                                    )}{' '}
                                    Hrs
                                  </strong>
                                </label>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <label className="mb-0">
                          <strong>Charges Report</strong>
                        </label>
                        <Card className="mt-3">
                          <Card.Body className="pt-0 pb-2">
                            <Row className="border-bottom pt-3 pb-2">
                              <Col xs="8" className="pr-0 pl-0">
                                <label>Holding Fees (Dollar $)</label>
                              </Col>
                              <Col xs="4" className="pr-0 pl-0">
                                <label className="float-right txt-primary">
                                  <strong>
                                    ${' '}
                                    {reportData?.holdingFees[0]?.totalTimeTaken?.toFixed(
                                      2
                                    ) ?? '0.00'}
                                  </strong>
                                </label>
                              </Col>
                            </Row>
                            <Row className="pt-3 pb-2">
                              <Col xs="8" className="pr-0 pl-0">
                                <label>Tips (Dollar $)</label>
                              </Col>
                              <Col xs="4" className="pr-0 pl-0">
                                <label className="float-right txt-primary">
                                  <strong>
                                    ${' '}
                                    {reportData?.tips[0]?.totalTimeTaken?.toFixed(
                                      2
                                    ) ?? '0.00'}
                                  </strong>
                                </label>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          ) : (
            ''
          )}
        </Mobile>
      </Row>
      <RenderCustomPopup />
    </div>
  );
});

export default Hours;
