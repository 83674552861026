import React, { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAuthContext } from '../../contexts/user-context';
import { validatePermission } from '../../utils/UserManagement';

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #f5e7e8;
    border-right: 4px solid #a50808;
    cursor: pointer;
    text-decoration: none;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  color: #a50808;
  font-size: 14px;

  &:hover {
    color: #a50808;
  }
`;

const DropDownLink = styled(Link)`
  background: ##f5e7e8;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  &:hover {
    background: #f5e7e8;
    border-right: 4px solid #a50808;
    text-decoration: none;
    cursor: pointer;
  }
`;

const SubMenu = memo(({ item, callback, isReimbursementAccessible }) => {
  const { authUser } = useAuthContext();

  const location = useLocation();

  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);
  const authorizedRoles = ["Admin","Manager"];

  const shouldDisplayNav = () => {
    switch (item.title) {
      case 'Timesheet':
        return true;

      case 'Settings': {
        switch (authUser.user.role.rolename) {
          case 'Admin':
            return true;

          case 'Manager':
            return true;

          case 'Chef':
            return true;

          case 'Office':
          case 'Accountant':
            return false;

          default:
            return true;
        }
      }

      case 'Reports':
        return true;

      case 'Forms':
        return true;

      case 'Tools':
        return true;

      case 'Reimbursement':
        return isReimbursementAccessible ||authorizedRoles.includes(authUser.user.role.rolename);

      default:
        break;
    }
  };

  const checkSubMenuSelected = (pathName) => {
    return location.pathname === pathName;
  };

  const shouldDisplaySubNav = (itemName) => {
    switch (itemName) {
      case 'Users':
        return validatePermission(
          authUser.user.role.permissions,
          'user',
          'view'
        );

      case 'User Roles':
        return validatePermission(
          authUser.user.role.permissions,
          'user_access',
          'view'
        );

      case 'Non-Billable':
        return validatePermission(
          authUser.user.role.permissions,
          'non_billable',
          'view'
        );

      case 'Clients':
        return validatePermission(
          authUser.user.role.permissions,
          'client',
          'view'
        );

      case 'Cities':
        return validatePermission(
          authUser.user.role.permissions,
          'city',
          'view'
        );

      case 'Custom Pages':
        return validatePermission(
          authUser.user.role.permissions,
          'custom_page',
          'view'
        );

      case 'Custom Pop Ups':
        return validatePermission(
          authUser.user.role.permissions,
          'custom_popup',
          'view'
        );

      case 'Pay Period Setup':
        return authUser.user.role.rolename === 'Admin';

      case 'Reimbursement':
        return authUser.user.role.rolename === 'Admin';

      case 'Payroll':
        return authUser.user.role.rolename === 'Admin';

      default:
        return true;
    }
  };

  return (
    <>
      {shouldDisplayNav() ? (
        <>
          <SidebarLink
            className={`${
              checkSubMenuSelected(item.path) ? 'selected-sidebar-item' : ''
            }`}
            to={item?.subNav?.length ? '#' : item.path}
            onClick={() => {
              // item.subNav && showSubnav
              if (item?.subNav?.length) {
                showSubnav();
              }
              callback(item.path);
            }}
          >
            <div>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </div>
            <div>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
          </SidebarLink>
          {subnav &&
            item.subNav.map((subNavItem) => {
              return shouldDisplaySubNav(subNavItem.title) ? (
                <DropDownLink
                  className={`${
                    checkSubMenuSelected(subNavItem.path)
                      ? 'selected-sidebar-submenu-item'
                      : ''
                  }`}
                  onClick={() => {
                    callback(subNavItem.path);
                  }}
                  to={subNavItem.path}
                >
                  {subNavItem.icon}
                  <SidebarLabel>{subNavItem.title}</SidebarLabel>
                </DropDownLink>
              ) : (
                ''
              );
            })}
        </>
      ) : (
        ''
      )}
    </>
  );
});

SubMenu.propTypes = {
  item: PropTypes.object.isRequired,
  callback: PropTypes.func,
};

SubMenu.defaultProps = {
  callback: () => {},
};

export default SubMenu;
