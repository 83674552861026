

// import PropTypes from 'prop-types';
// import Badge from 'react-bootstrap/Badge';
import React, { useEffect } from 'react'
import { Row, Col, Image } from 'react-bootstrap';
import { isAndroid, isIOS } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Mobile } from '../../media-queries/mediaQueries';
import Errors from '../../../constants/SquareErrorDescriptions'
// import MobileSidebarIcon from '../../components/MobileSidebarIcon/MobileSidebarIcon';

const PaymentProcessingUI = () => {
    return (<div>
        <Row>
            <Col xs="12" className="text-center">
                <Image
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/dish-loader.svg`}
                />
            </Col>
        </Row>
        <Row>
            <Col xs="12" className="text-center">
                <p className="font-24">
                    <strong>Please be patient while your payment is being
                        processed.</strong>
                </p>
            </Col>
            <Col xs="12" className="text-center">
                Please do not press “back” or refresh the window.
            </Col>
        </Row>
    </div>)
}
function delay(delayInms) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(2);
        }, delayInms);
    });
}
const PaymentHoldingPage = () => {

    const history = useHistory()
    const DELAY_IN_MS = 2500;
    const redirectToPaymentFail = (timesheetId, errorMessage = "Something went wrong") => {

        history.replace(`/viewTimesheet/${timesheetId}`, {
            from: 'paymentHolding',
            success: false,
            error: errorMessage
        })
    }
    const redirectToPaymentSuccess = async (timesheetId) => {
        await delay(DELAY_IN_MS)
        history.replace(`/viewTimesheet/${timesheetId}`, {
            success: true,
            from: 'paymentHolding'
        })
    }

    useEffect(async () => {

        const { location } = history
        const { search } = location
        const transactionData = queryString.parse(search);

        // initialize constants based on device
        let clientTransactionIdKey;
        let transactionIdKey;
        let errorFieldKey;
        let timesheetIdKey;
        let transactionInfo;

        if (isAndroid) {            
            transactionInfo = transactionData;
            timesheetIdKey = "com.squareup.pos.REQUEST_METADATA"

            // If successful, Square Point of Sale returns the following parameters.
            clientTransactionIdKey = "com.squareup.pos.CLIENT_TRANSACTION_ID";
            transactionIdKey = "com.squareup.pos.SERVER_TRANSACTION_ID";


            // If there's an error, Square Point of Sale returns the following parameters.
            errorFieldKey = "com.squareup.pos.ERROR_CODE";

        }
        else if (isIOS) {            

            const data = decodeURI(transactionData.data);            
            transactionInfo = JSON.parse(data);

            timesheetIdKey = "state"

            // If successful, Square Point of Sale returns the following parameters.
            clientTransactionIdKey = "client_transaction_id";
            transactionIdKey = "transaction_id";

            // If there's an error, Square Point of Sale returns the following parameters.
            errorFieldKey = "error_code";
        }
        else {
            // unsupported device -> Redirect from here
            redirectToPaymentFail();
        }


        const transactionId = transactionInfo[transactionIdKey]
        const clientTransactionId = transactionInfo[clientTransactionIdKey]
        const timesheetId = transactionInfo[timesheetIdKey]
        const errorCode = transactionInfo[errorFieldKey]

        // handle success or error 
        if (errorCode) {
            // Error
            const error = isAndroid ? Errors.ANDROID[errorCode] : Errors.IOS[errorCode]
            redirectToPaymentFail(timesheetId, error);

        } else if (transactionId || clientTransactionId) {
            redirectToPaymentSuccess(timesheetId)
        }
        else {
            redirectToPaymentFail(timesheetId);
        }



    }, [])



    return (
        <div>
            <div className="container">
                <Mobile>
                    <Row className="container-top-50">
                        <Col md="7">
                            <PaymentProcessingUI />
                        </Col>
                    </Row>
                </Mobile>
            </div>
        </div>
    )
}



export default PaymentHoldingPage