import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import {
  // Button,
  Row,
  Col,
  Image,
  Form,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import TablePagination from '@material-ui/core/TablePagination';
import { trackPromise } from 'react-promise-tracker';

import Toaster from '../../../components/Toaster';
import DataTableComponent from '../../../components/DataTableComponent';
import TableMobileView from './mobile-layouts/UserRoleTableView';
import Sidebar from '../../side-navbar/Sidebar';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import SettingsDropdown from '../../../components/SettingsDropdown/SettingsDropdown';
import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';
import RenderCustomPopup from '../../../components/RenderCustomPopup';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import { DateConvert,showExportButton } from '../../../utils/TimesheetManagement';
import { deleteRoleByID, getAllRoles } from '../../../services/role-service';
import { validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';
import useQuery from '../../../utils/CustomHooks/useQuery';
import NoCustomPageComponent from '../../../components/NoCustomPageComponent/NoCustomPageComponent';
import exportIcon from '../../../assets/images/exportIcon.svg';
import { exportToCSV } from '../../../utils/ExportDataToCSV';
import Loader from '../../../assets/images/loader.svg';

const UserRole = memo(() => {
  const { authUser } = useAuthContext();
  const URLQuery = useQuery();
  const location = useLocation();

  const isIndeterminate = (indeterminate) => indeterminate;
  const selectableRowsComponentProps = { indeterminate: isIndeterminate };

  const [isAPICalled, setAPICalled] = useState(false);
  const [roles, setRoles] = useState([]);
  const [rolesCount, setRolesCount] = useState({
    totalCount: 0,
    filterCount: 0,
  });
  const [rolename, setRoleName] = useState(URLQuery.get('rolename') ?? '');
  const [searchQuery, setsearchQuery] = useState(
    URLQuery.get('rolename') ?? ''
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [exportAPICalled,setExportAPICalled] = useState(false);

  const [selectedRow, setselectedRow] = useState();
  const [showConfirmAlert, setConfirmAlert] = useState(false);

  const handleCloseConfirm = () => setConfirmAlert(false);

  const history = useHistory();

  const onAddNew = () => {
    history.push('/settings/addNewUserRole');
  };

  const handleRowClicked = (row) => {
    history.push(`/settings/viewUserRole/${row._id}`);
  };

  const handleViewClick = (state) => {
    history.push(`/settings/viewUserRole/${state.target.id}`);
  };

  const handleClickDelete = (state) => {
    setselectedRow(state.target.id);
    setConfirmAlert(true);
  };

  const handleEditClicked = (state) => {
    history.push(`/settings/editUserRole/${state.target.id}`);
  };

  const columns = [
    {
      name: 'Role Name',
      selector: 'rolename',
      sortable: true,
    },
    {
      name: 'Modified on',
      selector: (row) => DateConvert(row.updatedAt),
      sortable: true,
    },
    {
      cell: (row) => {
        const { _id } = row;
        return (
          <SettingsDropdown
            identifier={_id}
            handleViewClick={handleViewClick}
            handleEditClicked={handleEditClicked}
            handleClickDelete={handleClickDelete}
            drop="left"
            isEditable={
              !validatePermission(
                authUser.user.role.permissions,
                'user_access',
                'edit'
              )
            }
            isDeletable={
              !validatePermission(
                authUser.user.role.permissions,
                'user_access',
                'delete'
              )
            }
          />
        );
      },
      allowOverflow: true,
      button: true,
      width: '56px', // custom width for icon button
    },
  ];

  async function fetchAllPagesRoles() {
    try {
        setExportAPICalled(true);
        const response = await trackPromise(
          getAllRoles(rolesCount.totalCount, 0, searchQuery)
        );
        const transformedData = response.data.data.roles.map(item => ({
            roleName: item.rolename,
            modifiedOn: DateConvert(item.updatedAt),
            city: item.permissions.city,
            client: item.permissions.client,
            customPage: item.permissions.custom_page,
            customePopup:item.permissions.custom_popup,
            hoursReport: item.permissions.hours_report,
            nonBillable: item.permissions.non_billable,
            payrollReport: item.permissions.payroll_report,
            reimbursement: item.permissions.reimbursement,
            timesheet: item.permissions.timesheet,
            user: item.permissions.user,
            userAccess: item.permissions.user_access,
            // reimbursement_setup: item.permissions.reimbursement_setup
      }));
      exportToCSV(transformedData,`user_roles_data_${DateConvert(new Date())}`);
      setExportAPICalled(false);
      } catch (error) {
        toast.dark(
          <Toaster
            icon="error"
            message={error?.response?.data?.message ?? 'Something went wrong!!!'}
          />
        );
        setExportAPICalled(false);
      }
  }

  async function fetchAllRoles() {
    try {
      const response = await trackPromise(
        getAllRoles(rowsPerPage, page, searchQuery)
      );
      setRoles(response.data.data.roles);
      setRolesCount({
        totalCount: response.data.data.totalCount,
        filterCount: response.data.data.filterCount,
      });
      setAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const onDeleteRole = async () => {
    try {
      const response = await trackPromise(deleteRoleByID(selectedRow));
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setConfirmAlert(false);
      fetchAllRoles();
    }
  };

  useEffect(() => {
    if (!searchQuery.length) {
      history.push({
        pathname: location.pathname,
        search: '',
      });
    } else {
      history.push({
        pathname: location.pathname,
        search: qs.stringify({ rolename: searchQuery }),
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    fetchAllRoles();
  }, [rowsPerPage, page, searchQuery]);

  return (
    <div>
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar />
        </Mobile>
        {isAPICalled && !rolesCount.totalCount ? (
          <NoCustomPageComponent
            title="User Roles"
            onAddNew={onAddNew}
            addNewText="Start adding your first ”User Role”"
            isAddNew={validatePermission(
              authUser.user.role.permissions,
              'user_access',
              'add'
            )}
          />
        ) : (
          <>
            <Desktop>
              <Col md="10">
                <div className="container">
                  <Row className="border-bottom sticky-dashboard-header">
                  <Col lg={authUser.user.role.rolename=== "Admin" ?  6 : 7} md={4}>
                      <h3>
                        <strong>Users Roles</strong>
                      </h3>
                    </Col>
                    <Col lg={3} md={2} className="pr-0">
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control
                          // type="email"
                          className="ml-0"
                          placeholder="Search Roles..."
                          aria-label="Rolename"
                          aria-describedby="basic-addon1"
                          value={rolename}
                          onChange={(e) => setRoleName(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setsearchQuery(event.target.value);
                              setPage(0);
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {showExportButton(authUser.user.role.rolename) && (
                      <Col xl={1 / 2} className="mr-3 ml-3">
                        <span className="float-left">
                          <button
                            type="button"
                            className="btn btn-ftc-primary-light-border"
                            onClick={() => fetchAllPagesRoles()}
                            
                          >
                            {
                              exportAPICalled ? (
                                <><Image src={Loader} height="18px" width="18px" /></>
                              ) : (<><Image
                                src={exportIcon}
                              />{' '}
                                  Export</>)
                            }
                          </button>
                        </span>
                      </Col>
                    )}
                    {validatePermission(
                      authUser.user.role.permissions,
                      'user_access',
                      'add'
                    ) && (
                      <Col md={1.5} className="pr-4">
                        <button
                          type="button"
                          className="btn btn-ftc-primary float-right"
                          onClick={onAddNew}
                        >
                          Add New
                        </button>
                      </Col>
                    )}
                  </Row>
                  <div className="container-top-7" />
                  <Row>
                    <Col md="12" className="pr-0">
                      {isAPICalled ? (
                        <>
                          <DataTableComponent
                            highlightOnHover
                            pointerOnHover
                            noHeader
                            columns={columns}
                            data={roles}
                            pagination
                            selectableRowsComponentProps={
                              selectableRowsComponentProps
                            }
                            paginationServer
                            paginationTotalRows={rolesCount.filterCount}
                            handleRowClicked={handleRowClicked}
                            paginationPerPage={rowsPerPage}
                            onChangePage={(p) => {
                              setPage(p - 1);
                              // look for better solution later
                              // setPage(p)
                            }}
                            onChangeRowsPerPage={(rws, pg) => {
                              setRowsPerPage(rws);
                              setPage(pg);
                            }}
                          />{' '}
                          <TablePagination
                            labelRowsPerPage="Rows per page:"
                            component="div"
                            count={Number.parseInt(rolesCount.filterCount, 10)}
                            page={page}
                            onChangePage={(event, newPage) => {
                              setPage(newPage);
                            }}
                            defaultPage={0}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={(event) => {
                              setRowsPerPage(parseInt(event.target.value, 10));
                              setPage(0);
                            }}
                            ActionsComponent={TablePaginationActions}
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Desktop>
            <Mobile>
              <Col>
                <div className="container pr-0">
                  <Row className="border-bottom sticky-dashboard-header-mobile">
                    <Col xs="9" className="ml-4">
                      <h3>User Roles</h3>
                    </Col>
                    <Col xs="1" sm="2">
                      <Image
                        className="ftc-logo-40 "
                        src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                      />{' '}
                    </Col>
                  </Row>
                  <Row className="container-top-20 pb-0">
                    <Col xs="8" className="pl-1 pr-1">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        value={rolename}
                        onChange={(e) => setRoleName(e.target.value)}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            setsearchQuery(event.target.value);
                            setPage(0);
                          }
                        }}
                      />
                    </Col>
                    {validatePermission(
                      authUser.user.role.permissions,
                      'user_access',
                      'add'
                    ) && (
                      <Col xs="4" className="pl-1 pr-1">
                        <button
                          type="button"
                          className="btn btn-ftc-primary float-right"
                          onClick={onAddNew}
                        >
                          Add New
                        </button>
                      </Col>
                    )}
                  </Row>
                  <Row className="pr-0 pb-0 pr-2">
                      {showExportButton(authUser.user.role.rolename) && (
                        <>
                          <Col xs="8" className="pr-1 pl-1">
                            <></>
                          </Col>
                          <Col xs="4" className="pr-1 pl-1">
                            <span>
                              <button
                                type="button"
                                className="btn btn-ftc-primary-light-border btn-block float-right"
                                onClick={() => fetchAllPagesRoles()}
                              >
                                {
                              exportAPICalled ? (
                                <><Image src={Loader} height="18px" width="18px" /></>
                              ) : (<><Image
                                src={exportIcon}
                              />{' '}
                                  Export</>)
                            }
                              </button>
                            </span>
                          </Col>
                        </>
                      )}
                    </Row>
                  <Row>
                    {roles.map((role) => {
                      return (
                        <Col md={6} className="mt-3 pl-1 pr-1">
                          <TableMobileView
                            role={role}
                            callback={handleRowClicked}
                            handleViewClick={handleViewClick}
                            handleEditClicked={handleEditClicked}
                            handleClickDelete={handleClickDelete}
                            isEditable={
                              !validatePermission(
                                authUser.user.role.permissions,
                                'user_access',
                                'edit'
                              )
                            }
                            isDeletable={
                              !validatePermission(
                                authUser.user.role.permissions,
                                'user_access',
                                'delete'
                              )
                            }
                          />
                        </Col>
                      );
                    })}
                  </Row>
                  {isAPICalled && !rolesCount.filterCount && (
                    <div className="text-center">
                      <p>There are no records to display.</p>
                    </div>
                  )}
                  <Row>
                    <Col xs="12">
                      <TablePagination
                        className="mobile__pagination"
                        labelRowsPerPage=""
                        ActionsComponent={TablePaginationActions}
                        component="div"
                        count={Number.parseInt(rolesCount.filterCount, 10)}
                        page={page}
                        // onChangePage={handleChangePage}
                        onChangePage={(event, newPage) => {
                          setPage(newPage);
                        }}
                        defaultPage={0}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={(event) => {
                          setRowsPerPage(parseInt(event.target.value, 10));
                          setPage(0);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Mobile>
          </>
        )}
        {showConfirmAlert && (
          <ConfirmAlertModal
            show={showConfirmAlert}
            handleClose={handleCloseConfirm}
            title="Delete User"
            description="Deleting this User will erase all data inside this record. Are you sure you want to delete this User?"
            callback={onDeleteRole}
            action="Delete it"
            closeAction="Cancel"
          />
        )}
      </Row>
      <RenderCustomPopup />
    </div>
  );
});

export default UserRole;
