import React, { memo, useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Image, Modal, Row, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import { getAllCitiesByName } from '../../../services/city-service';
import { getAllRolesByNames } from '../../../services/role-service';

import Toaster from '../../../components/Toaster';

const UserFilters = memo(({
    show, handleClose, applyChanges, allFilters, clearAll
}) => {
    const [option, setOption] = useState('role');

    const [cities, setcities] = useState([]);
    const [selectedCities, setselectedCities] = useState([]);
    const [searchCity, setsearchCity] = useState('');

    const [roles, setRoles] = useState([]);
    const [selectedRoles, setselectedRoles] = useState([]);

    const [status, setStatuses] = useState([
        { label: 'Active', value: 'active', isChecked: false },
        { label: 'Inactive', value: 'inactive', isChecked: false },
    ]);
    const [selctedStatus, setSelectedStatus] = useState(
        allFilters?.status ? allFilters.status : null
    );

    const [filters, setfilters] = useState(null);

    const filteredCities = cities.filter((city) => {
        return city.cityname.toLowerCase().includes(searchCity.toLowerCase())
    });

    const onCityChange = (e, city) => {
        const newCities = [...cities];
        const findSelectedIndex = selectedCities.map((searchRole) => searchRole._id).indexOf(city._id);
        const findCityFromIndex = cities.map((searchRole) => searchRole._id).indexOf(city._id);

        const newSelectedCities = [...selectedCities];

        if (e.target.checked) {
            newCities[findCityFromIndex].isChecked = true;
            newSelectedCities.push(city);
        }
        else {
            newCities[findCityFromIndex].isChecked = false;
            newSelectedCities.splice(findSelectedIndex, 1);
        }
        setcities(newCities);
        setselectedCities(newSelectedCities);
    };

    const onRoleChange = (e, role, indexKey) => {
        const newRoles = [...roles];
        const findSelectedIndex = selectedRoles.map((searchRole) => searchRole._id).indexOf(role._id);        

        const newSelectedRoles = [...selectedRoles];

        if (e.target.checked) {
            newRoles[indexKey].isChecked = true;
            newSelectedRoles.push(role);
        }
        else {
            newRoles[indexKey].isChecked = false;
            newSelectedRoles.splice(findSelectedIndex, 1);
        }
        setRoles(newRoles);
        setselectedRoles(newSelectedRoles);
    }

    const onStatusChange = (e, st) => {
        setStatuses(
            status.map((d) => {
                const tempStatus = d;
                if (d.value === st.value) {
                    if (e.target.checked) {
                        tempStatus.isChecked = true;
                        setSelectedStatus(tempStatus);
                    }
                    else {
                        tempStatus.isChecked = false;
                        setSelectedStatus(null);
                    }
                }
                return tempStatus;
            })
        );
    };

    const removeAllCities = () => {
        setsearchCity('');
        setcities(
            cities.map((c) => {
                const tempCity = c;
                if (selectedCities.find((selected) => selected._id === c._id)) {
                    tempCity.isChecked = false;
                    selectedCities.splice(selectedCities.findIndex((selected) => selected._id === c._id), 1);
                }
                return tempCity;
            })
        );
        setselectedCities([]);
    };

    const removeAllRoles = () => {
        setRoles(
            roles.map((role) => {
                const tempRole = role;
                if (selectedRoles.find((selected) => selected._id === role._id)) {
                    tempRole.isChecked = false;
                    selectedRoles.splice(selectedRoles.findIndex((selected) => selected._id === role._id), 1);
                }
                return tempRole;
            })
        );
        setselectedRoles([]);
    }

    const removeAllStatus = () => {

        setStatuses(
            status.map((d) => {
                const tempStatus = d;
                if (selctedStatus) {
                    tempStatus.isChecked = false;
                    // selectedDurations.splice(selectedDurations.indexOf(d), 1);
                }
                return tempStatus;
            })
        );
        setSelectedStatus(null);

    };

    const onClearAll = () => {
        removeAllCities();
        removeAllRoles();
        removeAllStatus();
        setfilters(null);
        clearAll();
    };

    async function fetchAllCities() {
        try {
            const response = await trackPromise(getAllCitiesByName());
            setcities(
                response.data.data.cities.map((city) => {
                    const tempCity = city;
                    if (allFilters?.cities?.length > 0) {
                        if (allFilters.cities.find((selected) => selected._id === city._id)) {

                            tempCity.isChecked = true;
                        }
                    }
                    else {
                        tempCity.isChecked = false;
                    }
                    return tempCity;
                })
            );
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    async function fetchAllRoles() {
        try {
            const response = await trackPromise(getAllRolesByNames());
            setRoles(
                response.data.data.roles.map((role) => {
                    const tempRole = role;
                    if (allFilters?.roles?.length > 0) {
                        if (allFilters.roles.find((selected) => selected._id === role._id)) {
                            tempRole.isChecked = true;
                        }
                    }
                    else {
                        tempRole.isChecked = false;
                    }
                    return tempRole;
                })
            );
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    useEffect(() => {
        fetchAllCities();
        fetchAllRoles();
    }, []);        

    useEffect(() => {
        if (selectedCities.length) {
            setfilters((oldFilters) => ({ ...oldFilters, cities: selectedCities }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('cities'))) {
            delete filters?.cities;
            setfilters({ ...filters });
        }
    }, [selectedCities]);

    useEffect(() => {
        if (selectedRoles.length) {
            setfilters((oldFilters) => ({ ...oldFilters, roles: selectedRoles }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('roles'))) {
            delete filters?.roles;
            setfilters({ ...filters });
        }
    }, [selectedRoles]);

    useEffect(() => {
        if (selctedStatus !== null) {
            setfilters((oldFilters) => ({ ...oldFilters, status: selctedStatus }));
        }
        else {
            delete filters?.status;
            setfilters({ ...filters });
        }
    }, [selctedStatus]);

    useLayoutEffect(() => {

        if (allFilters?.cities?.length) {
            // setselectedCities(prevCities => [...prevCities, ...allFilters.cities]);
            setselectedCities(allFilters.cities);
        }
        if (allFilters?.roles?.length) {
            setselectedRoles(allFilters.roles);
        }

        if (allFilters?.status) {
            setStatuses(
                status.map((st) => {
                    const tempStatus = st;
                    if (allFilters?.status?.value === st.value) {
                        tempStatus.isChecked = true;
                    }
                    return tempStatus;
                })
            )
        }
    }, [allFilters]);

    function filterDetailsByOption() {
        switch (option) {
            case 'city':
                return (
                    <div>
                        <Row key="city">
                            <Col md="3" xs="6">
                                <Form.Label>Cities</Form.Label>
                            </Col>
                            <Col md="9" xs="6">
                                <a
                                    className="txt-primary float-right"
                                    role="button"
                                    onClick={removeAllCities}
                                    onKeyDown={() => { }}
                                    tabIndex={-1}
                                >Deselect All</a>
                            </Col>

                            <Col md="12" xs="12">
                                <Form.Group controlId="formGroupSearch">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search"
                                        value={searchCity}
                                        onChange={(e) => setsearchCity(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            {
                                // showCities ? (
                                filteredCities.map((city, index) => {
                                    return (
                                        <Col md="4" key={city._id}>
                                            <Form.Group controlId="formBasicCheckbox">
                                                <input
                                                    type="checkbox"
                                                    className="ftc-checkbox"
                                                    id={index + 1}
                                                    checked={city.isChecked}
                                                    onChange={(e) => {
                                                        onCityChange(e, city, index);
                                                    }}
                                                />
                                                <label className="position-relative ml-4 pl-2" htmlFor={city._id}>{city.cityname}</label>
                                            </Form.Group>
                                        </Col>
                                    );
                                })
                                // ) : ('')
                            }
                        </Row>
                    </div>
                );

            case 'status':
                return (
                    <div>
                        <Row>
                            <Col md="3" xs="6">
                                <label>Status</label>
                            </Col>
                            <Col md="9" xs="6">
                                <a
                                    className="txt-primary float-right"
                                    role="button"
                                    onClick={removeAllStatus}
                                    onKeyDown={() => { }}
                                    tabIndex={-1}
                                >Deselect All</a>
                            </Col>
                        </Row>
                        <Row>
                            {
                                status.map((st) => {
                                    return (
                                        <Col md="4" xs="6">
                                            <Form.Group controlId="formBasicCheckbox">
                                                <input
                                                    type="radio"
                                                    className="ftc-radiobutton"
                                                    name='status-radio'
                                                    id={st.value}
                                                    checked={st.isChecked}
                                                    onChange={(e) => {
                                                        onStatusChange(e, st);
                                                    }}
                                                />
                                                <label
                                                    className="position-relative ml-4 pl-2"
                                                    htmlFor={st.value}
                                                >
                                                    {st.label}
                                                </label>
                                            </Form.Group>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </div>
                );

            case 'role':
                return (
                    <div>
                        <Row key='roles'>
                            <Col md="3" xs="6">
                                <label>Roles</label>
                            </Col>
                            <Col md="9" xs="6">
                                <a
                                    className="txt-primary float-right"
                                    role="button"
                                    onClick={removeAllRoles}
                                    onKeyDown={() => { }}
                                    tabIndex={-1}
                                >Deselect All</a>
                            </Col>
                        </Row>
                        <Row>
                            {
                                roles.map((role, index) => {
                                    return (
                                        <Col md="4" xs="12" key={role._id}>
                                            <Form.Group controlId="formBasicCheckbox">
                                                <input
                                                    type="checkbox"
                                                    className="ftc-checkbox"
                                                    id={role._id}
                                                    checked={role.isChecked}
                                                    onChange={(e) => {
                                                        onRoleChange(e, role, index);
                                                    }}
                                                />
                                                <label
                                                    className="position-relative ml-4 pl-2"
                                                    htmlFor={role._id}
                                                >
                                                    {role.rolename}
                                                </label>
                                            </Form.Group>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                );
            default:
                break;
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Filter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="modal__row">
                    <Desktop>
                        <Col md="3" className="modal__Col_3_Padding border__right sticky-top">
                            <ul className="modal__ul">
                                <li
                                    className={`${option === 'role' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('role')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Role</span>{' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />
                                </li>
                                <li
                                    className={`${option === 'city' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('city')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">City</span>{' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />{' '}
                                </li>
                                <li
                                    className={`${option === 'status' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('status')}
                                    onKeyPress={() => { }}
                                    role="menuitem"
                                >
                                    <span className="modal__list__padding">Status</span> {' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />
                                </li>
                            </ul>
                        </Col>
                    </Desktop>
                    <Mobile>
                        <Col md="3" xs="4" className="modal__Col_3_Padding border__right modal-filters">
                            <ul className="modal__ul">
                                <li
                                    className={`${option === 'role' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('role')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Role</span>{' '}
                                </li>
                                <li
                                    className={`${option === 'city' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('city')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">City</span>{' '}
                                </li>
                                <li
                                    className={`${option === 'status' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('status')}
                                    onKeyPress={() => { }}
                                    role="menuitem"
                                >
                                    <span className="modal__list__padding">Status</span> {' '}
                                </li>
                            </ul>
                        </Col>
                    </Mobile>
                    <Col md="9" xs="8">
                        {filterDetailsByOption()}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={() => {
                    onClearAll();
                    // handleClose();
                }}>
                    Clear All
                </Button>
                <Button
                    variant="default"
                    className="btn-ftc-border-primary"
                    onClick={() => {
                        applyChanges(filters);
                    }}
                >
                    Apply Changes
                </Button>
            </Modal.Footer>
        </Modal>


    )
});

export default UserFilters

UserFilters.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    applyChanges: PropTypes.func.isRequired,
    allFilters: PropTypes.any.isRequired,
    clearAll: PropTypes.func.isRequired,
};