import React, { memo, useEffect, useState } from 'react'
import { Alert, Col, Form, Image, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Controller, useFormContext, } from 'react-hook-form';
import Select from 'react-select';
// import * as Scroll from 'react-scroll';

import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

import Toaster from '../../../components/Toaster';

import { getAllRolesByNames } from '../../../services/role-service';
import { getAllCitiesByName } from '../../../services/city-service';

const animatedComponents = makeAnimated();

const UserBasicDetails = memo(({ formContent, employeeId, showCitiesError }) => {

  // const { scroller } = Scroll;

  const [selectedRole, setSelectedRole] = useState(formContent.one?.role ? formContent.one.role : null);
  const [selectedCities, setSelectedCities] = useState(formContent.one?.service_city?.length ? formContent.one.service_city : []);
  // const [isTrainer, setisTrainer] = useState(formContent.one?.trainer === true);

  const { register, control, formState: { errors }, setValue, trigger, reset } = useFormContext();

  const [roles, setRoles] = React.useState([]);

  const [cities, setCities] = useState([]);

  async function fetchAllRoles() {
    try {
      const response = await trackPromise(getAllRolesByNames());
      setRoles(response.data.data.roles);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function fetchAllCities() {
    try {
      const response = await trackPromise(getAllCitiesByName());
      setCities(response.data.data.cities);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  useEffect(() => {
    fetchAllRoles();
    fetchAllCities();
    reset({ ...formContent.one }, { errors: true, keepDirty: false });
  }, []);

  useEffect(() => {
    if (!Object.keys(formContent).length) {
      setSelectedRole(null);
      setSelectedCities([]);
    }
  }, [formContent]);

  useEffect(() => {
    if (selectedRole) {
      setValue('role', selectedRole, { shouldDirty: true, shouldValidate: true });
    }
  }, [selectedRole]);

  useEffect(() => {
    if (selectedCities.length) {
      setValue('service_city', selectedCities, { shouldDirty: true, shouldValidate: true });
    }
    else {
      setValue('service_city', [], { shouldDirty: false, shouldValidate: true });
    }
  }, [selectedCities]);

  // useEffect(() =>
  // {
  //   setValue('trainer', isTrainer, { shouldDirty: true, shouldValidate: true });
  // }, [isTrainer]);

  // const handleCheckboxClick = () =>
  // {
  //   setisTrainer(!isTrainer);
  // };

  const onBlurCities = () => {
    if (!selectedCities.length) {
      trigger('service_city');
    }
  }

  const onBlurRole = () => {
    if (!selectedRole) {
      trigger('role');
    }
  }

  // const onMenuOpen = (elementName) =>
  // {

  //   scroller.scrollTo(elementName, {
  //     duration: 1500,
  //     delay: 100,
  //     smooth: true,      
  //     offset: 50, // Scrolls to element + 50 pixels down the page      
  //   });
  // };

  return (
    <div className="container">
      <Row>
        <Col md="8" className="mx-auto border-bottom">
          <Row>
            <Col md="12">
              {employeeId !== null ? (<label>
                Employee ID: <strong>{employeeId}</strong>
              </label>) : ('')}
            </Col>
            <Col md="6">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>User Name </Form.Label>
                <Form.Control
                  {...register('username')}
                  type="username"
                  placeholder="Enter your username"
                />
                {errors.username && (
                  <p className="text-danger">
                    {errors.username.message}
                  </p>
                )}
              </Form.Group>

            </Col>
            <Col md="6">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email </Form.Label>
                <Form.Control {...register('email')} type="email" placeholder="Enter email" />
                {errors.email && (
                  <p className="text-danger">
                    {errors.email.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md="6" xs="12">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Role</Form.Label>
                <Controller
                  render={({ field: { name } }) => (<Select
                    // menuIsOpen                    
                    name={name}
                    id="roleName"
                    openMenuOnFocus={false}
                    isMulti={false}
                    closeMenuOnSelect
                    components={animatedComponents}
                    options={roles}
                    getOptionLabel={option => option.rolename}
                    getOptionValue={option => option._id}
                    value={selectedRole}
                    onChange={(option) => {
                      setSelectedRole(option);
                    }}
                    onBlur={() => {
                      onBlurRole();
                    }}
                    styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                    onMenuOpen={() => {
                      // onMenuOpen('roleName');
                    }}
                    menuShouldScrollIntoView
                  />)}
                  name="role"
                  control={control}
                // defaultValue={selectedRole}
                />
                {errors.role && (
                  <p className="text-danger">
                    {errors.role.message}
                  </p>
                )}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Password </Form.Label>
                <Form.Control
                  {...register('password')}
                  type="text"
                  placeholder="Enter password"
                />
                {errors.password && (
                  <p className="text-danger">
                    {errors.password.message}
                  </p>
                )}
              </Form.Group>

            </Col>
            <Col md="12">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Select Service Cities</Form.Label>
                <Controller
                  render={({ field: { name } }) => (<Select
                    name={name}
                    id='cityName'
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={cities}
                    getOptionLabel={option => option.cityname}
                    getOptionValue={option => option._id}
                    value={selectedCities}
                    onChange={(option) => {
                      setSelectedCities(option);
                    }}
                    onBlur={() => {
                      onBlurCities();
                    }}
                    styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                    onMenuOpen={() => {
                      // onMenuOpen('cityName');
                    }}
                    menuShouldScrollIntoView
                  />)}
                  name="service_city"
                  control={control}
                // defaultValue={selectedCities}
                />
                {(showCitiesError && errors.service_city) && (
                  <p className="text-danger">
                    {errors.service_city.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  {...register('trainer')}
                  // checked={isTrainer}
                  // onChange={handleCheckboxClick}
                  type="checkbox"
                  label="This User is Trainer"
                />
              </Form.Group>
            </Col>
            <Col md="12">
              <Alert variant="success">
                <Col md="12">
                  <span>
                    <Image
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`}
                      fluid
                    />
                    {` `}Active User
                  </span>
                </Col>
              </Alert>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
});

export default UserBasicDetails;

UserBasicDetails.propTypes = {
  // cities: PropTypes.array.isRequired,
  // roles: PropTypes.array.isRequired,
  employeeId: PropTypes.string,
  formContent: PropTypes.any.isRequired,
  showCitiesError: PropTypes.bool.isRequired,
};

UserBasicDetails.defaultProps = {
  employeeId: null
};