const getUserStatusLabel = (status) => {
  switch (status) {
    case 'active':
      return 'Active';
    case 'inactive':
      return 'Inactive';
    default:
      return null;
  }
};

const getLocationLabel = (label) => {
  switch (label) {
    case 'forms':
      return 'Forms';

    case 'tools':
      return 'Tools';

    case 'reports':
      return 'Reports';

    default:
      break;
  }
};

const getContentTypeLabel = (label) => {
  switch (label) {
    case 'text':
      return 'Text Content';

    case 'html':
      return 'HTML Content';

    case 'markup':
      return 'Markup';

    default:
      break;
  }
};

const getPermissionLabel = (permission) => {
  switch (permission) {
    case 'timesheet':
      return 'Timesheet';

    case 'user_access':
      return 'User Access';

    case 'user':
      return 'Users';

    case 'city':
      return 'Cities';

    case 'client':
      return 'Clients';

    case 'non_billable':
      return 'Non-Billable';

    case 'custom_page':
      return 'Custom Pages';

    case 'custom_popup':
      return 'Custom Popups';

    case 'hours_report':
      return 'Hours Reports';

    case 'payroll_report':
      return 'Payroll Reports'

    case 'random_page':
      return 'Random Page (Custom)';

    case 'reimbursement':
      return 'Reimbursement';

    case 'reimbursement_setup':
      return 'Reimbursement Setup'

    default:
      return null;
  }
};

function openInNewTab(url) {
  const win = window.open(url, '_blank');
  if (win != null) {
    win.focus();
  }
}

const getLabel = () => {};
const validatePermission = (permissions, moduleName, actionType) => {
  const userPermissions = permissions[moduleName];

  if (userPermissions.includes(actionType)) {
    return true;
  }

  return false;
};

export {
  getUserStatusLabel,
  getPermissionLabel,
  getLocationLabel,
  getContentTypeLabel,
  getLabel,
  openInNewTab,
  validatePermission,
};
