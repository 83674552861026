function htmlDecode(value)
{
    const txt = document.createElement('textarea');
    txt.innerHTML = value;
    return txt.textContent;
};

function htmlEncode(value)
{
    const txt = document.createElement('textarea');
    txt.textContent = value;
    return txt.innerHTML;
    // return $('<textarea/>').text(value).html();
};

export
{
    htmlDecode,
    htmlEncode,
};