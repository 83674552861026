import React, { useState, useEffect, memo } from 'react';
// import PropTypes from 'prop-types';
import { useHistory, Link, useParams, Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';

import { Button, Row, Col, Image, NavDropdown, Form } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import { trackPromise } from 'react-promise-tracker';
// import s3 from '../../../utils/aws-config';
import Toaster from '../../../components/Toaster';
// import CustomDropDown from '../../../components/CustomDropDown'
import Ellipses from '../../../components/Ellipses';
import UserBasicDetails from './UserBasicDetails';
import UserWorkHistory from './UserWorkHistory';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';
import AvatarImage from '../../../assets/images/avatar.svg';
import Loader from '../../../assets/images/loader.svg';

import {
  createUser,
  updateUserByEmployeeId,
  getUserByEmployeeId,
  getUserPassword,
  deleteUserByEmployeeId,
  imagePresignedurl,
} from '../../../services/users-service';
import {
  PASSWORD_REGEXP,
  CRYPTO_SECRET_KEY,
} from '../../../constants/regexp.constants';
import { DateConvert } from '../../../utils/TimesheetManagement';
import { validatePermission } from '../../../utils/UserManagement';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import { useAuthContext } from '../../../contexts/user-context';
import '../css/viewUserDetails.css';

// const BUCKET_NAME = process.env.AWS_BUCKET_NAME;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ManageUser = memo(() => {
  const classes = useStyles();

  const { authUser } = useAuthContext();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const [compiledForm, setCompiledForm] = React.useState({});

  const { employeeId } = useParams();
  const isAddMode = !employeeId;
  const [isAPICalled, setIsAPICalled] = useState(false);

  const [user, setUser] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showCitiesError, setShowCitiesError] = useState(false);

  const [isSubmitSuccessful, setisSubmitSuccessful] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [fileurl, setFileurl] = useState('');
  const MAX_FILE_SIZE = 30 * 1024 * 1024;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file?.size <= MAX_FILE_SIZE) {
      setCompiledForm({ ...compiledForm, profile_picture: undefined });
      setSelectedImage(file);
      setSelectedImageName(file.name);
    } else {
      // Handle error when the file size exceeds the limit
      // console.log('File size exceeds the limit');
      toast.dark(
        <Toaster icon="error" message={` File size exceeds the limit `} />
      );
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setSelectedImageName('');
    setCompiledForm({ ...compiledForm, profile_picture: undefined });
  };

  const validationSchema = [
    // validation for step1
    yup.object({
      username: yup.string().required('Username is required'),
      email: yup
        .string()
        .required('Email is required!')
        .email('Please enter a valid email.'),
      password: yup
        .string()
        .required('Password is required!')
        .min(6, 'Password must contain at least 6 characters.')
        .max(14, 'Password must contain maximum 14 characters.')
        .matches(
          PASSWORD_REGEXP,
          'Password should be a minimum of 6 and a maximum of 14 characters including at least 1 number OR symbol AND 1 capital letter.'
        ),
      role: yup.mixed('Role is required!').required('Role is required!'),
      service_city: yup.array().when('role', {
        is: (val) => val && val.rolename === 'Accountant',
        then: yup.array(),
        otherwise: yup
          .array()
          .min(1, 'Service Cities are required!')
          .required('Service cities are required!'),
      }),
      trainer: yup.boolean(),
    }),
    // validation for step2
    yup.object({
      work_history: yup.object({
        hire_date: yup.string().required('Hire date is required!'),
        wage_per_hr: yup
          .number('Please enter a valid number!')
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          }),
        last_raise_date: yup.string().nullable(),
        salary_per_wk: yup
          .number('Please enter a valid number!')
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          }),
        comments: yup.string(),
        termination_date: yup.string().nullable(),
        flex_pay_hours_limit: yup
          .number('Please enter a valid number!')
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          }),
      }),
    }),
  ];

  const currentValidationSchema = validationSchema[activeStep];

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    trigger,
    formState: { isValid, isSubmitting, dirtyFields },
    watch,
  } = methods;
  const form = watch();

  function getSteps() {
    return ['Basic Details', 'Work History'];
  }

  const steps = getSteps();

  const handleNext = async () => {
    const isStepValid = await trigger();
    setShowCitiesError(true);
    if (isStepValid || isValid) {
      switch (activeStep) {
        case 0:
          setCompiledForm({ ...compiledForm, one: form });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          break;
        case 1:
          setCompiledForm({ ...compiledForm, two: form });
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          break;
        default:
          return null;
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      switch (activeStep) {
        case 1:
          setCompiledForm({ ...compiledForm, two: form });
          break;
        default:
          return 'not a valid step';
      }
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    reset({});
    setCompiledForm({});
  };

  async function getUserDetails() {
    try {
      const [response, passwordRes] = await Promise.all([
        getUserByEmployeeId(employeeId),
        getUserPassword(employeeId),
      ]);
      setUser(response.data.data.user);
      const userObj = response.data.data.user;

      const password = CryptoJS.AES.decrypt(
        passwordRes.data.data.password,
        CRYPTO_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);

      reset({ ...userObj, password });

      const getTerminationDate = (workHistory) => {
        if (
          workHistory?.termination_date === undefined ||
          workHistory?.termination_date === null
        ) {
          return null;
        }
        return workHistory?.termination_date;
      };
      setProfilePictureUrl(userObj.profile_picture_url);
      setCompiledForm({
        one: {
          username: userObj.username,
          email: userObj.email,
          password,
          trainer: userObj.trainer,
          role: userObj.role,
          service_city: userObj.service_city,
        },
        two: {
          // work_history: userObj.work_history
          work_history: {
            hire_date: userObj.work_history.hire_date,
            last_raise_date: userObj.work_history.last_raise_date,
            wage_per_hr: userObj.work_history.wage_per_hr,
            salary_per_wk: userObj.work_history.salary_per_wk,
            termination_date: getTerminationDate(userObj.work_history),
            comments: userObj.work_history.comments,
            equip_reimbursement_limit: userObj.work_history.equip_reimbursement_limit,
            flex_pay_hours_limit: userObj.work_history.flex_pay_hours_limit,
          },
        },
        profile_picture: userObj.profile_picture,
      });
      setIsAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  useEffect(() => {
    if (!isAddMode) {
      getUserDetails();
    } else {
      setIsAPICalled(true);
    }
  }, [isAddMode]);

  // useEffect(() =>
  // {
  //   console.log({ form, });
  // }, [form, errors]);

  const uploadFile = async (file) => {
    if (!file) return null;

    try {
      const response = await trackPromise(
        imagePresignedurl(
          JSON.stringify({
            fileName: file.name,
            fileType: file.type,
          })
        )
      );

      if (response.status === 201) {
        toast.dark(<Toaster icon="notify" message={response.data.message} />);
        // setisSubmitSuccessful(true);
      }

      // Assuming you are using Axios, you can directly access the data property of the response
      const { presignedUrl } = response.data;

      // Use Fetch API to upload the file using the presigned URL
      await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });

      // console.log('Image uploaded successfully:', presignedUrl);
      // Optionally show success message, perform further actions, etc.
      return presignedUrl;
    } catch (error) {
      console.error('Error getting presigned URL or uploading image:', error);
      // Handle error here, show error message, etc.
      return null;
    }
  };

  const onSubmitUser = async (data) => {
    setIsLoading(true);
    try {
      let fileUrl = null; // Initialize fileUrl as null
      let fileName = null;

      if (isAddMode && selectedImage) {
        fileUrl = await uploadFile(selectedImage);
        fileName =
          selectedImageName === ''
            ? ''
            : `${process.env.REACT_APP_S3_PROFILE_PICTURE_PATH}/${selectedImageName}`;
      }

      if (
        compiledForm.profile_picture === undefined ||
        compiledForm.profile_picture === null
      ) {
        fileUrl = await uploadFile(selectedImage);
        fileName =
          selectedImageName === ''
            ? ''
            : `${process.env.REACT_APP_S3_PROFILE_PICTURE_PATH}/${selectedImageName}`;
      } else {
        // eslint-disable-next-line no-unused-vars
        fileUrl = compiledForm.profile_picture;
        fileName = compiledForm.profile_picture;
      }

      const body = {
        username: compiledForm.one.username,
        email: compiledForm.one.email,
        password: compiledForm.one.password,
        trainer: compiledForm.one.trainer,
        role: compiledForm.one.role._id,
        service_city: compiledForm.one.service_city.map((city) => city._id),
        work_history: data.work_history,
        profile_picture: fileName,
      };

      if (isValid) {
        if (isAddMode) {
          const response = await trackPromise(createUser(body));
          if (response.status === 201) {
            toast.dark(
              <Toaster icon="notify" message={response.data.message} />
            );
            setisSubmitSuccessful(true);
            history.push(
              `/settings/viewUser/${response.data.data.user.employeeId}`
            );
          }
          setIsLoading(false);
        } else {
          const response = await trackPromise(
            updateUserByEmployeeId(employeeId, body)
          );
          if (response.status === 200) {
            toast.dark(
              <Toaster icon="notify" message={response.data.message} />
            );
            setisSubmitSuccessful(true);
            history.push(
              `/settings/viewUser/${response.data.data.user.employeeId}`
            );
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        if (error.response.data.data.error.errors.email) {
          toast.dark(
            <Toaster
              icon="warning"
              message={`${error.response.data.data.error.errors.email.message}`}
            />,
            { autoClose: 5000, hideProgressBar: true }
          );
        }
        if (error.response.data.data.error.errors.username) {
          toast.dark(
            <Toaster
              icon="warning"
              message={`${error.response.data.data.error.errors.username.message}`}
            />,
            { autoClose: 5000, hideProgressBar: true }
          );
        }
        setIsLoading(false);
      } else {
        toast.dark(
          <Toaster
            icon="error"
            message={
              error?.response?.data?.message ?? 'Something went wrong!!!'
            }
          />
        );
        setIsLoading(false);
      }
    }
  };

  const updateUserStatus = async (status) => {
    try {
      const body = {
        status,
      };
      setIsAPICalled(false);
      const response = await trackPromise(
        updateUserByEmployeeId(employeeId, body)
      );
      setUser(response.data.data.user);

      if (response.status === 200) {
        toast.dark(<Toaster icon="notify" message={response.data.message} />);
        history.push(
          `/settings/viewUser/${response.data.data.user.employeeId}`
        );
      }
      setIsAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  };

  const onDeleteUser = async () => {
    try {
      const response = await trackPromise(deleteUserByEmployeeId(employeeId));
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setShow(false);
      history.replace('/settings/users');
    }
  };

  const SettingsDropdown = () => {
    return (
      <div>
        <NavDropdown title={<Ellipses />} id="collasible-nav-dropdown">
          {user.status === 'active' ? (
            <NavDropdown.Item
              disabled={
                !validatePermission(
                  authUser.user.role.permissions,
                  'user',
                  'edit'
                )
              }
              onClick={() => {
                updateUserStatus('inactive');
              }}
            >
              Deactivate
            </NavDropdown.Item>
          ) : (
            ''
          )}
          {user.status === 'inactive' ? (
            <NavDropdown.Item
              disabled={
                !validatePermission(
                  authUser.user.role.permissions,
                  'user',
                  'edit'
                )
              }
              onClick={() => {
                updateUserStatus('active');
              }}
            >
              Activate
            </NavDropdown.Item>
          ) : (
            ''
          )}
          <NavDropdown.Item
            onClick={handleShow}
            disabled={
              !validatePermission(
                authUser.user.role.permissions,
                'user',
                'delete'
              )
            }
          >
            Delete
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    );
  };

  const GetStepContent = (step, formContent) => {
    switch (step) {
      case 0:
        return (
          <UserBasicDetails
            employeeId={employeeId}
            showCitiesError={showCitiesError}
            {...{ formContent }}
          />
        );
      case 1:
        return <UserWorkHistory {...{ formContent }} isAddMode={isAddMode} />;
      default:
        return null;
    }
  };

  return isAPICalled ? (
    <div>
      <div className=" sticky-page-header">
        <div className="container">
          <Desktop>
            <Row className="ml-5">
              <Col md="1">
                <Link to="/settings/users">
                  <a>
                    <Image
                      className="imageArrow"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </a>
                </Link>
              </Col>
              <Col md="8">
                {isAddMode ? (
                  <h5>
                    <strong>Add User</strong>
                  </h5>
                ) : (
                  <>
                    <h5 className="mb-0">
                      <strong>Edit User</strong>
                    </h5>
                    <p className="txt-light-secondary mb-0">
                      Last Updated on {DateConvert(user.updatedAt)}
                    </p>
                  </>
                )}
              </Col>
              <Col md="1">
                <Button
                  variant="default"
                  className="btn-ftc-border-primary ml-5"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Col>
              {!isAddMode ? (
                <Col md="1">
                  <div className="ml-5">
                    <SettingsDropdown />
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </Desktop>
          <Mobile>
            <div className="container">
              <Row className="pb-0">
                <Col xs="1" className="p-0">
                  <Link to="/settings/users">
                    <Image
                      className="imageArrowMobile mt-1"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </Link>
                </Col>
                <Col xs="8" className="mt-1">
                  {isAddMode ? (
                    <h6 className="m-auto">
                      <strong>Add User</strong>{' '}
                    </h6>
                  ) : (
                    <>
                      <h6 className="m-auto">
                        <strong>Edit User</strong>{' '}
                      </h6>
                      <p className="txt-light-secondary mx-auto">
                        Last Updated on {DateConvert(user.updatedAt)}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="1">
                  <Image
                    onClick={handleReset}
                    className="ftc-logo-24  mt-2"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-reset.svg`}
                  />
                </Col>
                {!isAddMode ? (
                  <Col xs="1">
                    <div>
                      <SettingsDropdown />
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </div>
          </Mobile>
        </div>
      </div>
      {/* <div className="border-bottom" /> */}
      {/* STEPPER */}
      <Desktop>
        <div className="container-top-8" />
      </Desktop>
      <Mobile>
        <div className="content-vcenter" />
      </Mobile>
      <div className="container">
        <div className={classes.root}>
          <div className="mx-auto col-md-8">
            <Row>
              <Col
                className="col-md-7"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <label htmlFor="avatarimg" className="pl-2">
                  <div className="image__wrapper">
                    {/* {selectedImage ? (
                      <Image
                        src={URL.createObjectURL(selectedImage)}
                        alt="Uploaded Avatar"
                        height="84px"
                        width="84px"
                      />
                    ) : (
                      <Image src={AvatarImage} style={{ padding: '16px' }} />
                    )} */}
                    {compiledForm.profile_picture !== '' &&
                      compiledForm.profile_picture !== undefined ? (
                      <Image
                        src={profilePictureUrl}
                        alt="Uploaded Avatar"
                        height="100px"
                        width="100px"
                        onError={(e) => {
                          e.target.src = AvatarImage; // Set fallback image if src image fails to load
                        }}
                      />
                    ) : selectedImage ? (
                      <Image
                        src={URL.createObjectURL(selectedImage)}
                        alt="Uploaded Avatar"
                        height="100px"
                        width="100px"
                      />
                    ) : (
                      <Image src={AvatarImage} style={{ padding: '16px' }} />
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  accept=".jpeg, .jpg, .png, .heic, image/jpeg, image/jpg, image/png, image/heic"
                  id="avatarimg"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />

                {selectedImageName ? (
                  <div className="pl-2">
                    <div
                      onClick={() => {
                        handleRemoveImage();
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleRemoveImage();
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <a
                        style={{
                          color: 'red',
                          // textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        Remove
                      </a>
                    </div>
                  </div>
                ) : compiledForm.profile_picture !== '' &&
                  compiledForm.profile_picture !== undefined ? (
                  <div className="pl-2">
                    <div
                      onClick={() => {
                        handleRemoveImage();
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleRemoveImage();
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <a
                        style={{
                          color: 'red',
                          // textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        Remove
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="pl-2">
                    <label htmlFor="avatarimg">
                      <a
                        style={{
                          color: '#0095F6',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        Upload Profile picture
                      </a>
                    </label>
                  </div>
                )}
              </Col>
              <Col className="col-md-4 manageStep">
                <Stepper activeStep={activeStep}>
                  {steps.map((label) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Col>
            </Row>
          </div>
          <div>
            <FormProvider {...methods}>
              <Form onSubmit={handleSubmit(onSubmitUser)}>
                <div>
                  <Typography className={classes.instructions}>
                    {GetStepContent(activeStep, compiledForm)}
                  </Typography>
                  <div>
                    <Row>
                      <Col md="8" className="mx-auto">
                        {activeStep !== 0 ? (
                          <Button
                            variant="default"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className="btn-ftc-border-primary"
                          >
                            Back
                          </Button>
                        ) : (
                          ''
                        )}
                        {activeStep === steps.length - 1 ? (
                          <Button
                            className={`${classes.button} float-right btn btn-ftc-primary`}
                            onClick={handleNext}
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isLoading ? (
                              <Image src={Loader} height="35px" width="35px" />
                            ) : (
                              'Submit'
                            )}
                          </Button>
                        ) : (
                          <button
                            type="button"
                            onClick={handleNext}
                            className={`${classes.button} float-right btn btn-ftc-primary`}
                          >
                            Continue
                          </button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
                {/* )} */}
              </Form>
            </FormProvider>
          </div>
        </div>
      </div>
      {show && (
        <ConfirmAlertModal
          show={show}
          handleClose={handleClose}
          title="Delete User"
          description="Deleting this User will erase all data inside this record.
                Are you sure you want to delete this User?"
          callback={onDeleteUser}
          action="Delete it"
          closeAction="Cancel"
        />
      )}

      <Prompt
        when={isSubmitSuccessful ? false : Object.keys(dirtyFields).length}
        message="You have unsaved changes, are you sure you want to leave?"
      />
    </div>
  ) : (
    ''
  );
});

export default ManageUser;
