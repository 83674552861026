import { validatePermission } from "./UserManagement";
import { useAuthContext } from '../contexts/user-context';

const getHomePageOfUser = (role) => {
  switch (role.rolename) {
    case 'Admin':
      return '/landingPage';
    case 'Manager':
      return '/landingPage';
    case 'Chef':
      return '/timesheet';
    case 'Office':
      return '/landingPage';
    case 'Accountant':
      return '/landingPage';
    default:
      return '/landingPage';
  }
};

const shouldDisplayNav = (item, role, isReimbursementAccessible) => {
  const { authUser } = useAuthContext();
  const authorizedRoles = ["Admin","Manager"];
  switch (item.title) {
    case 'Timesheet':
      return true;

    case 'Settings': {
      switch (role.rolename) {
        case 'Admin':
          return true;

        case 'Manager':
          return true;

        case 'Chef':
          return true;

        case 'Office':
        case 'Accountant':
          return false;

        default:
          return true;
      }
    }

    case 'Reports':
      return true;

    case 'Forms':
      return true;

    case 'Tools':
      return true;
    case 'Reimbursement':
      return isReimbursementAccessible ||authorizedRoles.includes(authUser.user.role.rolename);
    default:
      break;
  }
};

const shouldDisplaySubNav = (itemName, role) => {
  switch (itemName) {
    case 'Users':
      return validatePermission(role.permissions, 'user', 'view');

    case 'User Roles':
      return validatePermission(role.permissions, 'user_access', 'view');

    case 'Non-Billable':
      return validatePermission(role.permissions, 'non_billable', 'view');

    case 'Clients':
      return validatePermission(role.permissions, 'client', 'view');

    case 'Cities':
      return validatePermission(role.permissions, 'city', 'view');

    case 'Custom Pages':
      return validatePermission(role.permissions, 'custom_page', 'view');

    case 'Custom Pop Ups':
      return validatePermission(role.permissions, 'custom_popup', 'view');

    case 'Pay Period Setup':
      return role.rolename === 'Admin';

    case 'Reimbursement':
      return role.rolename === 'Admin';

    case 'Payroll':
      return role.rolename === 'Admin';

    default:
      return true;
  }
};

export { getHomePageOfUser, shouldDisplayNav, shouldDisplaySubNav };
