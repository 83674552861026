import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const createReimbursement = (body) => {
  return API.post(API_ENDPOINTS.REIMBURSEMENT.Reimbursements, body);
};

const getReimbursementById = (reimbursementId) => {
  return API.get(
    `${API_ENDPOINTS.REIMBURSEMENT.Reimbursements}/${reimbursementId}`
  );
};

const getReimbursementsByEmployeeId = (employeeId, reasonID) => {
  return API.get(
    `${API_ENDPOINTS.REIMBURSEMENT.ReimbursementsByEmployeeId}/${employeeId}/${reasonID}`
  )
};

const updateReimbursementById = (reimbursementId, body) => {
  return API.put(
    `${API_ENDPOINTS.REIMBURSEMENT.Reimbursements}/${reimbursementId}`,
    body
  );
};

const getAllReimbursements = (pagination, page, employeeName, queryString) => {
  return API.get(
    `${API_ENDPOINTS.REIMBURSEMENT.Reimbursements}?pagination=${pagination}&page=${page}&employee=${employeeName}${queryString}`
  );
};

const deleteReimbursementById = (reimbursementId) => {
  return API.del(
    `${API_ENDPOINTS.REIMBURSEMENT.Reimbursements}/${reimbursementId}`
  );
};

const updatePaymentStatus = (reimbursementId, body) => {
  return API.put(
    `${API_ENDPOINTS.REIMBURSEMENT.UpdatePaymentStatus}/${reimbursementId}`,
    body
  );
};

const imagePresignedurl = (body) => {
  return API.post(API_ENDPOINTS.REIMBURSEMENT.PresignedUrl, body);
};

const getReimbursementsByPeriods = (employeeID, reasonID, startDate, endDate) => {
  return API.get(
    `${API_ENDPOINTS.REIMBURSEMENT.ReimbursementsByPeriods}/${employeeID}/${reasonID}/${startDate}/${endDate}`
  )
};

export {
  createReimbursement,
  getReimbursementById,
  updateReimbursementById,
  getAllReimbursements,
  deleteReimbursementById,
  updatePaymentStatus,
  imagePresignedurl,
  getReimbursementsByEmployeeId,
  getReimbursementsByPeriods

};
