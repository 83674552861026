import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Image, ResponsiveEmbed } from 'react-bootstrap';
import DOMPurify from 'dompurify';

import { Desktop, Mobile } from '../../layouts/media-queries/mediaQueries';
import Sidebar from '../../layouts/side-navbar/Sidebar';
import RenderCustomPopup from '../RenderCustomPopup';

import { htmlDecode } from '../../utils/CustomPageManagement';

const DynamicComponent = memo(({
    customPage
}) => {
    useEffect(() => {
        window.onbeforeunload = function () {
            prompt('Please enter your name')
            return true;
        };

        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const renderContent = () => {
        switch (customPage.contentType) {
            case 'text':
            case 'html':
                return (<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlDecode(customPage.content)) }} />);

            case 'markup':
                return (
                    <>
                        <Desktop>
                            <div >
                                <ResponsiveEmbed aspectRatio='4by3' >
                                    <object
                                        type="text/html"
                                        aria-label='Hello'
                                        data={`${customPage.content}`}
                                    />
                                </ResponsiveEmbed>
                            </div>
                        </Desktop>

                        <Mobile>
                            <div >
                                <ResponsiveEmbed aspectRatio='1by1' >
                                    <object
                                        type="text/html"
                                        aria-label='Hello'
                                        data={`${customPage.content}`}
                                    />
                                </ResponsiveEmbed>
                            </div>
                        </Mobile>
                    </>
                );


            default:
                break;
        }
    }

    return (
        <div>
            <Row className="mr-0">
                <Desktop>
                    <Col md="2">
                        <Sidebar />
                    </Col>
                </Desktop>
                <Mobile>
                    <Sidebar />
                </Mobile>
                <Col md="10">
                    <Desktop>
                        <div className="container">
                            <Row className="border-bottom sticky-dashboard-header pb-3">
                                <Col md="7">
                                    <h3>
                                        <strong>{customPage.page_title}</strong>
                                    </h3>
                                </Col>
                            </Row>
                            <div className="container-top-7" />
                            <Row>
                                <Col md="12">
                                    {renderContent()}
                                </Col>
                            </Row>
                        </div>

                    </Desktop>
                    <Mobile>
                        <div className="container pr-0">
                            <Row className="border-bottom pr-0 sticky-dashboard-header-mobile">
                                <Col xs="9" className="ml-4">
                                    <h5>{customPage.page_title}</h5>
                                </Col>
                                <Col xs="2">
                                    <Image
                                        className="ftc-logo-40"
                                        src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                                    />{' '}
                                </Col>
                            </Row>
                        </div>
                        <div className="container-top-20">

                            <Row>
                                <Col xs="12"
                                    className="pr-1 pl-1"
                                >
                                    {renderContent()}
                                </Col>
                            </Row>

                        </div>

                    </Mobile>
                </Col>
            </Row>
            <RenderCustomPopup />
        </div>
    );
});

export default DynamicComponent;

DynamicComponent.propTypes = {
    customPage: PropTypes.object.isRequired
};
