import React, { memo, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import { useHistory, Link, useParams, Prompt } from 'react-router-dom';
import { Button, Row, Col, Image, NavDropdown, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

import Toaster from '../../../components/Toaster';
import Ellipses from '../../../components/Ellipses/Ellipses';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import {
  createRole,
  deleteRoleByID,
  getRoleByID,
  updateRoleByID,
} from '../../../services/role-service';
import {
  getPermissionLabel,
  validatePermission,
} from '../../../utils/UserManagement';
import { DateConvert } from '../../../utils/TimesheetManagement';
import { useAuthContext } from '../../../contexts/user-context';

const ManageUserRoles = memo(() => {
  // const isDesktopView = useMediaQuery(
  //     { minDeviceWidth: 992 },
  // )
  const { authUser } = useAuthContext();
  const history = useHistory();

  const isMobileView = useMediaQuery({ maxWidth: 767 });

  const { roleId } = useParams();
  const isAddMode = !roleId;
  const [isAPICalled, setIsAPICalled] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [role, setRole] = useState(null);

  const ENUM_PERM = ['none', 'add', 'edit', 'view', 'delete'];

  const validationSchema = yup.object({
    rolename: yup.string().required('Role name is required.'),
    permissions: yup
      .object({
        timesheet: yup.array().min(1, 'User access permission is required.'),
        user_access: yup.array().min(1, 'User access permission is required.'),
        user: yup.array().min(1, 'Users permission is required.'),
        city: yup.array().min(1, 'City permission is required.'),
        client: yup.array().min(1, 'Client permission is required.'),
        non_billable: yup
          .array()
          .min(1, 'Non-Billable permission is required.'),
        custom_page: yup.array().min(1, 'Custom Pages permission is required.'),
        custom_popup: yup
          .array()
          .min(1, 'Custom Popups permission is required.'),
        hours_report: yup
          .array()
          .min(1, 'Hours Reports permission is required.'),
        reimbursement: yup.array().min(1, 'Reimbursement permission required'),
        // random_page: yup.array().min(1, 'Random Page (Custom) permission is required.'),
      })
      .required(),
  });

  const initialPermissions = {
    timesheet: [],
    user_access: [],
    user: [],
    city: [],
    client: [],
    non_billable: [],
    custom_page: [],
    custom_popup: [],
    hours_report: [],
    reimbursement: [],
    payroll_report: [],
    // reimbursement_setup: [],
  };

  const [permissions, setPermissions] = useState();

  const [showPrompt, setshowPrompt] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitted, isValid, isSubmitting, dirtyFields },
    reset,
    watch,
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const form = watch();

  async function getRoleDetails() {
    try {
      const response = await trackPromise(getRoleByID(roleId));
      setRole(response.data.data.role);
      reset(response.data.data.role);
      const roleData = response.data.data.role;
      if (!roleData.permissions.reimbursement) {
        // If it doesn't exist, set it to the initialPermissions value
        roleData.permissions.reimbursement = [
          ...initialPermissions.reimbursement,
        ];
      }
      // setPermissions(response.data.data.role.permissions);
      setPermissions(roleData.permissions);
      setIsAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  useEffect(() => {
    if (!isAddMode) {
      getRoleDetails();
    } else {
      setPermissions(initialPermissions);
      setIsAPICalled(true);
    }
  }, [isAddMode]);

  useEffect(() => {
    if (Object.keys(dirtyFields).length) {
      setshowPrompt(true);
    } else {
      setshowPrompt(false);
    }
  }, [form, errors]);

  useEffect(() => {
    // if we have only assigned initialPermissions then it is not dirty
    if (JSON.stringify(initialPermissions) === JSON.stringify(permissions)) {
      setValue('permissions', permissions, {
        shouldDirty: false,
        shouldValidate: true,
      });
    } else {
      setValue('permissions', permissions, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [permissions]);


  const handleReset = () => {
    reset({});
    setPermissions(initialPermissions);
  };

  const onSubmit = async (data) => {
    try {
      if (isValid) {
        if (isAddMode) {
          const response = await trackPromise(createRole(data));
          if (response.status === 201) {
            toast.dark(
              <Toaster icon="notify" message={response.data.message} />
            );
            setshowPrompt(false);
            history.push(
              `/settings/viewUserRole/${response.data.data.role._id}`
            );
          }
        } else {
          const formData = data;
          formData.permissions._id = role.permissions._id;
          const response = await trackPromise(updateRoleByID(roleId, formData));
          if (response.status === 200) {
            toast.dark(
              <Toaster icon="notify" message={response.data.message} />
            );
            setshowPrompt(false);
            history.push(
              `/settings/viewUserRole/${response.data.data.role._id}`
            );
          }
        }
      }
    } catch (error) {
      if (error.response?.data?.data?.error?.errors?.rolename) {
        toast.dark(
          <Toaster
            icon="warning"
            message={`${error.response.data.data.error.errors.rolename.message}`}
          />,
          { autoClose: 5000, hideProgressBar: true }
        );
      } else {
        toast.dark(
          <Toaster
            icon="error"
            message={
              error?.response?.data?.message ?? 'Something went wrong!!!'
            }
          />
        );
      }
    }
  };

  const onDeleteRole = async () => {
    try {
      const response = await trackPromise(deleteRoleByID(roleId));
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
      history.replace('/settings/userroles');
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      handleClose();
    }
  };

  const validateRolePermission = (e, type, perm) => {
    if (ENUM_PERM.includes(perm)) {
      const newPermissions = [...permissions[type]];

      switch (perm) {
        // if newPermission is none then remove all permissions from existing
        case 'none': {
          if (e.target.checked) {
            newPermissions.length = 0;
            newPermissions.push(perm);
          } else {
            newPermissions.length = 0;
          }
          break;
        }

        default:
          if (e.target.checked && newPermissions.includes('none')) {
            newPermissions.splice(newPermissions.indexOf('none'), 1);
            newPermissions.push(perm);
          } else if (e.target.checked) {
            newPermissions.push(perm);
          } else {
            newPermissions.splice(newPermissions.indexOf(perm), 1);
          }
          break;
      }
      if (newPermissions.length === 0) {
        setError('permissions', {
          type: 'required',
          message: 'Permissions are required.',
        });
      }
      setPermissions({ ...permissions, [type]: newPermissions });
    }
  };

  const SettingsDropdown = () => {
    return (
      <div>
        <NavDropdown title={<Ellipses />} id="collasible-nav-dropdown">
          <NavDropdown.Item
            onClick={handleShow}
            disabled={
              !validatePermission(
                authUser.user.role.permissions,
                'user_access',
                'delete'
              )
            }
          >
            Delete
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    );
  };

  return isAPICalled ? (
    <div>
      <div className=" sticky-page-header">
        <div className="container">
          <Desktop>
            <Row className="ml-5">
              <Col md="1">
                <Link to="/settings/userroles">
                  <a>
                    <Image
                      className="imageArrow"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </a>
                </Link>
              </Col>
              <Col md="8">
                {isAddMode ? (
                  <h5>
                    <strong>New Role</strong>
                  </h5>
                ) : (
                  <>
                    <h5 className="mb-0">
                      <strong>{role.rolename}</strong>
                    </h5>
                    <p className="txt-light-secondary mb-0">
                      Last Updated on {DateConvert(role.updatedAt)}
                    </p>
                  </>
                )}
              </Col>
              <Col md="1" className="pr-0">
                <Button
                  variant="default"
                  className="btn-ftc-border-primary ml-5"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Col>
              {!isAddMode ? (
                <Col md="1">
                  <div className="ml-5">
                    <SettingsDropdown />
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </Desktop>
          <Mobile>
            <div className="container">
              <Row className="pb-1">
                <Col xs="1" className="p-0">
                  <Link to="/settings/userroles">
                    <Image
                      className="imageArrowMobile mt-1"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </Link>
                </Col>
                <Col xs="8" className="mt-1">
                  {isAddMode ? (
                    <h6 className="m-auto">
                      <strong>New Role </strong>{' '}
                    </h6>
                  ) : (
                    <>
                      <h6 className="mb-0">
                        <strong>{role.rolename}</strong>
                      </h6>
                      <p className="txt-light-secondary">
                        Last Updated on {DateConvert(role.updatedAt)}
                      </p>
                    </>
                  )}
                </Col>
                <Col xs="1">
                  <Image
                    onClick={handleReset}
                    className="ftc-logo-24 mt-1"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-reset.svg`}
                  />
                </Col>
                {!isAddMode ? (
                  <Col xs="1">
                    <div>
                      <SettingsDropdown />
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </div>
          </Mobile>
        </div>
      </div>
      <Desktop>
        <div className="container-top-5" />
      </Desktop>
      <Mobile>
        <div className="container-top-15" />
      </Mobile>
      <div className="container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="8" xs="12" className="mx-auto">
              <Row>
                <Col md="12" xs="12">
                  <h5>
                    <strong>Role Name</strong>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col md="12" xs="12">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="txt-light-secondary">
                      Access Role Label
                    </Form.Label>
                    <Form.Control
                      {...register('rolename')}
                      type="text"
                      placeholder="Ex: Admin, Chef, Manager, etc…"
                    />
                    {errors.rolename && (
                      <p className="text-danger">{errors.rolename.message}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="12" xs="12">
                  <h5>
                    <strong>Assign Privileges</strong>
                  </h5>
                </Col>
                <Col xs="12">
                  <p>
                    Define the privileges that should be accessible by this
                    role.
                  </p>
                </Col>
              </Row>
              {Object.keys(permissions).map((permission) => {
                return permission !== '_id' ? (
                  <Row key={permission}>
                    <Col md="4" xs="12">
                      <p>
                        <strong>{getPermissionLabel(permission)}</strong>
                      </p>
                    </Col>
                    <Col xs={`${isMobileView ? '3' : ''}`}>
                      <Form.Group controlId="formBasicCheckbox">
                        <input
                          type="checkbox"
                          className="ftc-checkbox"
                          id="None"
                          name={permission}
                          checked={permissions[permission].includes('none')}
                          onChange={(e) => {
                            validateRolePermission(e, permission, 'none');
                          }}
                        />
                        <label
                          className="position-relative ml-4 pl-2"
                          // data-content="none"
                          htmlFor="None"
                        >
                          None
                        </label>
                      </Form.Group>
                    </Col>
                    <Col xs={`${isMobileView ? '3' : ''}`}>
                      <Form.Group controlId="formBasicCheckbox">
                        <input
                          type="checkbox"
                          className="ftc-checkbox"
                          id="none"
                          name={permission}
                          checked={permissions[permission].includes('add')}
                          onChange={(e) => {
                            validateRolePermission(e, permission, 'add');
                          }}
                        />
                        <label
                          className="position-relative ml-4 pl-2"
                          data-content="none"
                        >
                          New
                        </label>
                      </Form.Group>
                    </Col>
                    <Col xs={`${isMobileView ? '3' : ''}`}>
                      <Form.Group controlId="formBasicCheckbox">
                        <input
                          type="checkbox"
                          className="ftc-checkbox"
                          id="none"
                          name={permission}
                          checked={permissions[permission].includes('view')}
                          onChange={(e) => {
                            validateRolePermission(e, permission, 'view');
                          }}
                        />
                        <label
                          className="position-relative ml-4 pl-2"
                          data-content="none"
                        >
                          View
                        </label>
                      </Form.Group>
                    </Col>
                    <Col xs={`${isMobileView ? '3' : ''}`}>
                      <Form.Group controlId="formBasicCheckbox">
                        <input
                          type="checkbox"
                          className="ftc-checkbox"
                          id="none"
                          name={permission}
                          checked={permissions[permission].includes('edit')}
                          onChange={(e) => {
                            validateRolePermission(e, permission, 'edit');
                          }}
                        />
                        <label
                          className="position-relative ml-4 pl-2"
                          data-content="none"
                        >
                          Edit
                        </label>
                      </Form.Group>
                    </Col>
                    <Col xs={`${isMobileView ? '3' : ''}`}>
                      <Form.Group controlId="formBasicCheckbox">
                        <input
                          type="checkbox"
                          className="ftc-checkbox"
                          id="none"
                          name={permission}
                          checked={permissions[permission].includes('delete')}
                          onChange={(e) => {
                            validateRolePermission(e, permission, 'delete');
                          }}
                        />
                        <label
                          className="position-relative ml-4 pl-2"
                          data-content="none"
                        >
                          Delete
                        </label>
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  ''
                );
              })}
              <Row>
                <Col md="12" xs="12">
                  {errors.permissions && isSubmitted && (
                    <p className="text-danger">
                      Please select all Privileges. If you do not want to assign
                      any privilege please select None.
                    </p>
                  )}
                </Col>
              </Row>
              <div className="border-bottom" />
              <Row>
                <Col className="pr-0">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-ftc-primary float-right"
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
      {show && (
        <ConfirmAlertModal
          show={show}
          handleClose={handleClose}
          title="Delete User"
          description="Deleting this User will erase all data inside this record.
                Are you sure you want to delete this User?"
          callback={onDeleteRole}
          action="Delete it"
          closeAction="Cancel"
        />
      )}
      <Prompt
        when={showPrompt}
        message="You have unsaved changes, are you sure you want to leave?"
      />
    </div>
  ) : (
    ''
  );
});

export default ManageUserRoles;
