import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import { KeyboardTimePicker } from '@material-ui/pickers';
import '../../styles/components/_time-picker-input.scss';

const TimePickerInput = memo(
  ({ selectedTime, handleTimeChange, maskFormat, disabled }) => {
    return (
      <KeyboardTimePicker
        placeholder="Select Time"
        mask={maskFormat}
        value={selectedTime}
        onChange={(date) => handleTimeChange(date)}
        disabled={disabled}
        minutesStep={5}
        keyboardIcon={
          <Image
            className="ftc-logo-24"
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-time.svg`}
          />
        }
        InputProps={{
          readOnly: true,
        }}
        className={disabled ? 'disabled' : {}}
        initialFocusedDate={new Date().setHours(10, 0)}
      />
    );
  }
);

export default TimePickerInput;

TimePickerInput.propTypes = {
  selectedTime: PropTypes.any.isRequired,
  handleTimeChange: PropTypes.func.isRequired,
  maskFormat: PropTypes.string,
};

TimePickerInput.defaultProps = {
  maskFormat: '__:__ _M',
};
