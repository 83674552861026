import React, { memo, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Row, Col, Image, NavDropdown, Card } from 'react-bootstrap';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import Ellipses from '../../../components/Ellipses';
import Toaster from '../../../components/Toaster';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';

import { deleteCustomPageById, getCustomPageById } from '../../../services/custom_page.service';
import { DateConvert } from '../../../utils/TimesheetManagement';
import { getContentTypeLabel, getLocationLabel, validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';

const ViewCustomPages = memo(() =>
{
    const { authUser } = useAuthContext();
    const { pageId } = useParams();
    const history = useHistory();

    const [isAPICalled, setAPICalled] = useState(false);
    const [customPage, setCustomPage] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    async function getCustomPageDetails()
    {
        try
        {
            const response = await getCustomPageById(pageId);
            setCustomPage(response.data.data.customPage);
            setAPICalled(true);
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    useEffect(() =>
    {
        getCustomPageDetails();
    }, [pageId]);

    const onEditCustomPage = () =>
    {
        history.push(`/settings/editCustomPage/${customPage._id}`)
    }

    const SettingsDropdown = () =>
    {
        return (<div>
            <NavDropdown
                title={<Ellipses />}
                id="collasible-nav-dropdown"
            >                
                <NavDropdown.Item
                    onClick={handleShow}
                    disabled={!validatePermission(authUser.user.role.permissions, 'custom_page', 'edit')}
                >Delete</NavDropdown.Item>
            </NavDropdown>
        </div>);
    };

    const onDeleteCustomPage = async () =>
    {
        try
        {
            const response = await deleteCustomPageById(pageId);
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally
        {
            setShow(false);
            history.replace('/settings/custompages', { forceReload: true });
        }
    };

    return isAPICalled ? (
        <div>
            <div className="container">
                <Desktop>
                    <Row className="ml-5">
                        <Col md="1">
                            <Link to='/settings/custompages'>
                                <a>
                                    <Image
                                        className="imageArrow"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                        fluid
                                    />
                                </a>
                            </Link>
                        </Col>
                        <Col md="8">
                            <h5 className="mb-0">
                                <strong>{customPage.button_label}</strong>
                            </h5>
                            <label className="txt-light-secondary mb-0">Last Updated on {DateConvert(customPage.updatedAt)}</label>
                        </Col>
                        <Col md="1" className="pr-0  ml-5">
                            <Button variant="default" className="btn-ftc-border-primary"
                            onClick={onEditCustomPage}
                            disabled={!validatePermission(authUser.user.role.permissions, 'custom_page', 'edit')}
                            >
                                Edit
                            </Button>
                        </Col>
                        <Col md="1">
                            <div className="ml-0">
                                <SettingsDropdown />
                            </div>
                        </Col>
                    </Row>
                </Desktop>
                <Mobile>
                    <div className="container">
                        <Row className="pb-0">
                            <Col xs="1" className="p-0">
                                <Link to="/settings/custompages">
                                    <Image
                                        className="imageArrowMobile mt-1"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                        fluid
                                    />
                                </Link>
                            </Col>
                            <Col xs="9" className="mt-1">
                                <h6 className="m-auto"><strong>{customPage.button_label}</strong> </h6>
                                <p className="txt-light-secondary mx-auto mb-2">
                                    Last Updated on {DateConvert(customPage.updatedAt)}
                                </p>
                            </Col>
                            <Col xs="1">
                                <div>
                                    <SettingsDropdown />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Mobile>
            </div>
            <div className="border-bottom" />
            <div className="container">
                <Row>
                    <Col md="8" className="mx-auto">

                        <Card className="mt-3">
                            <Card.Body className="pt-0 pb-2">
                                <Row>
                                    <Col md="12" xs="12">
                                        <h5 className="txt-primary"><strong>Page Description</strong></h5>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Page Title</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>{customPage.button_label}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Accessible to</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right">
                                            <strong>
                                                {/* {displayAccessRoles(customPage.access)} */}
                                                {customPage?.access?.map((c) => c.rolename ?? 'N/A').join(' | ')}                                                                                                                                                
                                            </strong>
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Location</label>
                                    </Col>
                                    <Col md="6" xs="6" className="text-truncate  d-inline-block">
                                        <label className="float-right"><strong>{getLocationLabel(customPage.page_location)}</strong></label>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="mt-3">
                            <Card.Body className="pt-0 pb-2">
                                <Row>
                                    <Col md="12" xs="12">
                                        <h5 className="txt-primary"><strong>Page Content</strong></h5>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Content Type</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>{getContentTypeLabel(customPage.contentType)}</strong></label>
                                    </Col>
                                </Row>
                                {/* <Row className="pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Content</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>Preview</strong></label>
                                    </Col>
                                </Row> */}
                            </Card.Body>
                        </Card>
                        {/* <Row className="mt-3">
                            <Col md="12" className="pr-0 pl-0">
                                <Alert variant='success'>
                                    <Col md="12">
                                        <span>
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`}
                                                fluid
                                            />
                                            {` `}Active Pop Up
                                            </span>
                                    </Col>
                                </Alert>
                            </Col>
                        </Row> */}
                    </Col>                    
                </Row>
                <Mobile>
                    <Row>
                        <Col xs={12} className="pr-3">
                            <Button
                                variant="default"
                                onClick={onEditCustomPage}
                                disabled={!validatePermission(authUser.user.role.permissions, 'custom_page', 'edit')}
                                className="float-right btn-ftc-border-primary ml-5"
                            >
                                Edit
                            </Button>
                        </Col>
                    </Row>
                </Mobile>
            </div>
            {show && <ConfirmAlertModal
                    show={show}
                    handleClose={handleClose}
                    title='Delete Custom Page'
                    description='Deleting this page will erase all data inside this record.
                    Are you sure you want to delete this Page?'
                    callback={onDeleteCustomPage}
                    action='Delete it'
                    closeAction='Cancel'
                />}
        </div>
    ) : ('')
});

export default ViewCustomPages
