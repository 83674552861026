import React, { memo, useEffect, useState } from 'react';
import { Row, Col, Form, Button, Image } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { toast } from 'react-toastify';
import moment from 'moment';
import { trackPromise } from 'react-promise-tracker';
// import * as Scroll from 'react-scroll';
import 'react-popper-tooltip/dist/styles.css';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';

import Sidebar from '../../side-navbar/Sidebar';
import DatePickerInput from '../../../components/DatePickerInput';
import Toaster from '../../../components/Toaster';
import { getAllUsersByCity } from '../../../services/users-service';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';
import { getAllCitiesForReport } from '../../../services/city-service';
import { generatePayrollReport } from '../../../services/payroll-report.services';
import {
  getPeriodsByYear,
  getPeriodsByYearAndDate,
  getPeriodsLtYear,
} from '../../../services/period-service';
import { DateConvert } from '../../../utils/TimesheetManagement';
import RenderCustomPopup from '../../../components/RenderCustomPopup';
import { useAuthContext } from '../../../contexts/user-context';
import Loader from '../../../assets/images/loader.svg';

import '../css/hours.css';

const animatedComponents = makeAnimated();

const Payroll = memo(() => {
  const { authUser } = useAuthContext();

  // const { scroller } = Scroll;

  const [isAPIcalled, setIsAPIcalled] = useState(false);

  const [cities, setcities] = useState([]);
  const [selectedCity, setselectedCity] = useState(null);

  const allPeriods = ['Custom', 'Week', 'Period'];
  const [selectedDatePeriod, setselectedDatePeriod] = useState('Week');
  const [showPeriodDates, setShowPeriodDates] = useState({
    startDate: '',
    endDate: '',
  });

  const allWeekOptions = [
    {
      value: 'current',
      label: 'Current Week',
    },
    {
      value: 'previous',
      label: 'Previous Week',
    },
    {
      value: 'last_2_week',
      label: 'Last 2 Weeks',
    },
  ];

  const [allWeeks] = useState(allWeekOptions);
  const [selectedWeek, setSelectedWeek] = useState(null);

  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setselectedPeriod] = useState(null);

  const [allYears, setallYears] = useState([]);
  const [selectedYear, setselectedYear] = useState(null);

  const exportToCSV = async (data, startDate, endDate) => {
    try {
      const headerMapping = {
        employeeName: 'Employee Name',
        userRole: 'Employee Role',
        city: 'City',
        // employeeStatus: 'Employee Status',
        regularHours: 'Regular Hours',
        flexPayHours: 'Vacation Flex Pay Hours',
        holidayHours: 'Holiday Hours',
        traineeHours: 'Training Hours',
        trainerHours: 'Trainer Hours',
        bonus: 'Bonus $$',
        overTimeHours: 'Overtime Hours',
        reimburseFuel: 'Reimburse Fuel $$',
        reimburseOther: 'Reimburse Other $$',
        tips: 'Paycheck Tips $$',
        weeklySalary: 'Weekly Salary/Adl Salary $$',
        holdingFees: 'Holding Fee $$',
        healthBonus: 'Health Bonus $$',
        parentalLeavePay: 'Parental Leave Pay $$',
        preTaxDeduction: 'Pre-Tax Deduction',
        postTaxDeduction: 'Post-Tax Deduction',
        // benefitHours: 'Benefit Hours',
      };

      const headerToKeyMapping = {};
      Object.entries(headerMapping).forEach(([key, value]) => {
        headerToKeyMapping[value] = key;
      });

      const headerRow = `${Object.keys(headerMapping)
        .map((key) => headerMapping[key])
        .join(',')}\n`;

      // Header Row
      let csvContent = `data:text/csv;charset=utf-8,${headerRow}`;

      data.forEach((item) => {
        const row = Object.keys(headerMapping)
          .map(
            (key) =>
              `"${
                item[key] !== null && item[key] !== undefined ? item[key] : ''
              }"`
          )

          .join(',');
        csvContent += `${row}\n`;
      });

      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      const startFormatted = moment(startDate).format('DDMMMYYYY');
      const endFormatted = moment(endDate).format('DDMMMYYYY');

      link.setAttribute(
        'download',
        `Payroll_${startFormatted}_${endFormatted}.csv`
      );
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const validationSchema = yup.object({
    startDate: yup.string().nullable().required('Start Date is Required.'),
    endDate: yup.string().nullable().required('End Date is required'),
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitting },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  async function fetchAllCities() {
    try {
      const response = await trackPromise(getAllCitiesForReport());
      setcities(response.data.data.cities);
      setSelectedWeek({
        value: 'current',
        label: 'Current Week',
      });
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function getAllEmployees() {
    try {
      const response = await trackPromise(getAllUsersByCity(selectedCity._id));
      const emps = response.data.data.users;

      // Sort employees by user name in a case-insensitive manner
      emps.sort((a, b) => {
        const nameA = a.username.toLowerCase();
        const nameB = b.username.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function fetchAllPeriodsYears() {
    try {
      const response = await trackPromise(
        getPeriodsLtYear(new Date().getFullYear())
      );
      setallYears(response.data.data.periods);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function fetchAllPeriods() {
    try {
      let response;
      if (selectedYear) {
        response = await trackPromise(
          getPeriodsByYear(selectedYear.year, new Date())
        );
      } else {
        response = await trackPromise(
          getPeriodsByYearAndDate(new Date().getFullYear(), new Date())
        );
      }
      const totalPeriods = response.data.data.periods.length;
      setPeriods(
        response.data.data.periods.map((period, index) => {
          const tempPeriod = period;
          tempPeriod.label = `Period ${totalPeriods - index} (${DateConvert(
            period.start_date
          )} - ${DateConvert(period.end_date)})`;
          tempPeriod.value = period.period_index;
          return tempPeriod;
        })
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const onSubmitForm = async (data) => {
    setIsAPIcalled(true);
    try {
      const timestamp1 = Date.parse(data.startDate);
      const timestamp2 = Date.parse(data.endDate);

      if (timestamp1 > timestamp2) {
        toast.dark(
          <Toaster icon="error" message="Please select correct Date!!!" />
        );
        setIsAPIcalled(false);
        return;
      }

      const reqBody = {
        service_city: data.service_city?._id,
        startDate: data.startDate,
        endDate: data.endDate,
      };

      const response = await trackPromise(generatePayrollReport(reqBody));

      await exportToCSV(response.data.data, data.startDate, data.endDate);

      if (response.status === 200) {
        toast.dark(<Toaster icon="notify" message={response.data.message} />);
      }

      setIsAPIcalled(false);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAllCities();
    })();
  }, []);

  useEffect(() => {
    if (cities.length) {
      switch (authUser.user.role.rolename) {
        case 'Chef':
        case 'Office':
          setselectedCity(authUser.user.service_city[0]);
          break;

        default:
          break;
      }
    }
  }, [cities]);

  useEffect(async () => {
    if (selectedCity) {
      setValue('service_city', selectedCity, {
        shouldDirty: true,
        shouldValidate: true,
      });
      getAllEmployees();
    } else {
      setValue('service_city', null, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedCity]);

  useEffect(() => {
    setValue('startDate', null, { shouldDirty: true, shouldValidate: true });
    setValue('endDate', null, { shouldDirty: true, shouldValidate: true });

    setSelectedWeek(null);
    setselectedPeriod(null);
    setselectedYear(null);
    setShowPeriodDates({
      startDate: '',
      endDate: '',
    });

    if (selectedDatePeriod === 'Period') {
      fetchAllPeriodsYears();
    }
  }, [selectedDatePeriod]);

  useEffect(() => {
    if (selectedYear) {
      setselectedPeriod(null);
      setShowPeriodDates({
        startDate: '',
        endDate: '',
      });
      setValue('startDate', null, { shouldDirty: true, shouldValidate: true });
      setValue('endDate', null, { shouldDirty: true, shouldValidate: true });
      fetchAllPeriods();
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedWeek) {
      switch (selectedWeek.value) {
        case 'current': {
          const startOfWeek = moment()
            // .utc()
            .startOf('week')
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          const endOfWeek = moment()
            .endOf('week')
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();

          setValue('startDate', startOfWeek, {
            shouldDirty: true,
            shouldValidate: true,
          });
          setValue('endDate', endOfWeek, {
            shouldDirty: true,
            shouldValidate: true,
          });
          break;
        }
        case 'previous': {
          const startOfPrevWeek = moment()
            .subtract(1, 'weeks')
            .startOf('week')
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          const endOfPrevWeek = moment()
            .subtract(1, 'weeks')
            .endOf('week')
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();

          setValue('startDate', startOfPrevWeek, {
            shouldDirty: true,
            shouldValidate: true,
          });
          setValue('endDate', endOfPrevWeek, {
            shouldDirty: true,
            shouldValidate: true,
          });
          break;
        }

        case 'last_2_week': {
          const lastWeekStart = moment()
            .subtract(2, 'weeks')
            .startOf('week')
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          const lastWeekEnd = moment()
            .subtract(1, 'weeks')
            .endOf('week')
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();

          setValue('startDate', lastWeekStart, {
            shouldDirty: true,
            shouldValidate: true,
          });
          setValue('endDate', lastWeekEnd, {
            shouldDirty: true,
            shouldValidate: true,
          });
          break;
        }

        default:
          break;
      }
    }
  }, [selectedWeek]);

  useEffect(() => {
    if (selectedPeriod) {
      setShowPeriodDates({
        startDate: moment.utc(selectedPeriod.start_date).format('YYYY-MM-DD'),
        endDate: moment.utc(selectedPeriod.end_date).format('YYYY-MM-DD'),
      });
      setValue('startDate', selectedPeriod.start_date, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('endDate', selectedPeriod.end_date, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedPeriod]);

  const onPeriodChange = (e, periodName) => {
    setselectedDatePeriod(periodName);
  };

  return (
    <div>
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar />
        </Mobile>
        {/* <Desktop> */}
        <Col xl="4" lg="5" className="pr-0">
          <div className="container">
            <Form onSubmit={handleSubmit(onSubmitForm)}>
              <Desktop>
                <Row>
                  <Col md="12">
                    <h4>
                      <strong>Payroll Report</strong>
                    </h4>
                  </Col>
                </Row>
              </Desktop>
              <Mobile>
                <div className="container">
                  <Row className="border-bottom sticky-dashboard-header-mobile">
                    <Col xs="9" className="ml-2">
                      <h5 className="mt-1">
                        <strong>Payroll Report</strong>
                      </h5>
                    </Col>
                    <Col xs="2" className="pr-0">
                      <Image
                        className="ftc-logo-40 float-right"
                        src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                      />{' '}
                    </Col>
                  </Row>
                  <div className="container-top-20" />
                </div>
              </Mobile>

              <Row>
                <Col md="12">
                  <label className="txt-light-secondary">Choose Period</label>
                </Col>
                {allPeriods.map((period) => {
                  return (
                    <Col sm="4">
                      <Form.Group>
                        <Button
                          variant="default"
                          className={
                            period === selectedDatePeriod
                              ? `ftc-radiobutton-border-checked btn-border-round`
                              : `ftc-radiobutton-border-unchecked btn-border-round`
                          }
                        >
                          <input
                            type="radio"
                            className="ftc-radiobutton-secondary "
                            id={period}
                            name="period"
                            onChange={(e) => {
                              onPeriodChange(e, period);
                            }}
                            checked={period === selectedDatePeriod}
                          />
                          <Form.Label
                            htmlFor={period}
                            className="position-relative ml-4 pl-2"
                          >
                            {period}
                          </Form.Label>
                        </Button>
                      </Form.Group>
                    </Col>
                  );
                })}
                {selectedDatePeriod === 'Week' && (
                  <Col md="12">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="txt-light-secondary">
                        Week
                      </Form.Label>
                      <Select
                        name="week_options"
                        id="weekOptionScroll"
                        isMulti={false}
                        closeMenuOnSelect
                        components={animatedComponents}
                        options={allWeeks}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        value={selectedWeek}
                        onChange={(option) => {
                          setSelectedWeek(option);
                        }}
                        styles={{
                          menu: (styles) => ({ ...styles, zIndex: 999 }),
                        }}
                        onMenuOpen={() => {
                          // onMenuOpen('weekOptionScroll');
                        }}
                        menuShouldScrollIntoView
                      />
                    </Form.Group>
                  </Col>
                )}
                {selectedDatePeriod === 'Period' && (
                  <>
                    <Col md="12">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="txt-light-secondary">
                          Period Year
                        </Form.Label>
                        <Select
                          name="period_year_options"
                          id="periodYearOptionScroll"
                          isMulti={false}
                          closeMenuOnSelect
                          components={animatedComponents}
                          options={allYears}
                          value={selectedYear}
                          getOptionLabel={(option) => option.year}
                          getOptionValue={(option) => option.year}
                          onChange={(option) => {
                            setselectedYear(option);
                          }}
                          styles={{
                            menu: (styles) => ({ ...styles, zIndex: 999 }),
                          }}
                          onMenuOpen={() => {
                            // onMenuOpen('periodYearOptionScroll');
                          }}
                          menuShouldScrollIntoView
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="txt-light-secondary">
                          Period
                        </Form.Label>
                        <Select
                          name="period_options"
                          id="periodOptionScroll"
                          isMulti={false}
                          closeMenuOnSelect
                          components={animatedComponents}
                          options={periods}
                          value={selectedPeriod}
                          onChange={(option) => {
                            setselectedPeriod(option);
                          }}
                          styles={{
                            menu: (styles) => ({ ...styles, zIndex: 999 }),
                          }}
                          onMenuOpen={() => {
                            // onMenuOpen('periodOptionScroll');
                          }}
                          menuShouldScrollIntoView
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col md="12">
                  <Form.Label className="txt-light-secondary">
                    Date Begin <span className="txt-primary">*</span>
                  </Form.Label>
                </Col>
                <Col md="12">
                  <Controller
                    render={({ field: { ref, name, value, onChange } }) => (
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={enLocale}
                      >
                        <DatePickerInput
                          name={name}
                          ref={ref}
                          handleDateChange={(e) => {
                            onChange(
                              moment(e)
                                // .utc()
                                // .utcOffset(0)
                                .set({
                                  hour: 0,
                                  minute: 0,
                                  second: 0,
                                  millisecond: 0,
                                })
                                .toDate()
                            );
                          }}
                          selectedDate={
                            selectedDatePeriod === 'Period'
                              ? showPeriodDates.startDate
                              : value
                          }
                          disabled={
                            selectedDatePeriod === 'Week' ||
                            selectedDatePeriod === 'Period'
                          }
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    name="startDate"
                    control={control}
                    defaultValue={null}
                  />
                  {errors.startDate && isSubmitted && (
                    <p className="text-danger">{errors.startDate.message}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Form.Label className="txt-light-secondary">
                    Date End <span className="txt-primary">*</span>
                  </Form.Label>
                </Col>
                <Col md="12">
                  <Controller
                    render={({ field: { ref, name, value, onChange } }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePickerInput
                          name={name}
                          ref={ref}
                          handleDateChange={(e) => {
                            onChange(
                              moment(e)
                                // .utc()
                                // .utcOffset(0)
                                .set({
                                  hour: 0,
                                  minute: 0,
                                  second: 0,
                                  millisecond: 0,
                                })
                                .toDate()
                            );
                          }}
                          selectedDate={
                            selectedDatePeriod === 'Period'
                              ? showPeriodDates.endDate
                              : value
                          }
                          disabled={
                            selectedDatePeriod === 'Week' ||
                            selectedDatePeriod === 'Period'
                          }
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    name="endDate"
                    control={control}
                    defaultValue={null}
                  />
                  {errors.endDate && isSubmitted && (
                    <p className="text-danger">{errors.endDate.message}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="txt-light-secondary">
                      City
                    </Form.Label>
                    <Controller
                      render={({ field: { name } }) => (
                        <Select
                          name={name}
                          isMulti={false}
                          closeMenuOnSelect
                          components={animatedComponents}
                          options={cities}
                          getOptionLabel={(option) => option.cityname}
                          getOptionValue={(option) => option._id}
                          value={selectedCity}
                          onChange={(option) => {
                            setselectedCity(option);
                          }}
                          // onBlur={() => {
                          //     onBlurEmployee();
                          // }}
                        />
                      )}
                      name="service_city"
                      control={control}
                    />
                    {errors.service_city && isSubmitted && (
                      <p className="text-danger">
                        {errors.service_city.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="5" xs="5">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-ftc-primary"
                  >
                    {isAPIcalled ? (
                      <Image src={Loader} height="35px" width="35px" />
                    ) : (
                      'Export to CSV'
                    )}
                  </button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
      <RenderCustomPopup />
    </div>
  );
});

export default Payroll;
