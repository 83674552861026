import React, { memo } from 'react';
import { Row, Form, Col, Image,NavDropdown } from 'react-bootstrap';
// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Ellipses from '../../../../components/Ellipses';

import './reimbursementReasonTable.css';
import { DateConvert } from '../../../../utils/TimesheetManagement';
import IconReimbursementSetup from '../../../../assets/images/icon-reimbursement-setup.svg'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    overflow: 'visible',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const ReimbursementReasonTable = memo(
  ({
    callback,
    reimbursementReason,
    handleEditClicked,
    handleClickDelete,
  }) => {
    const classes = useStyles();

    const displayUserRoles = (userRoles) => {
        return userRoles.map((c) => c.rolename ?? 'N/A').join(', ');
      };

    return (
        <div className="mt-3" onClick={(event) => {
            const settingsDropDown = event.target.closest('#settings-dropdown');
            if (!settingsDropDown) {
                callback(reimbursementReason)
            }
        }} onKeyDown={() => { }} role="button" tabIndex={0}>
        <Card className={`${classes.root} mt-2`}>
          <CardContent>
            <Row className="border-bottom p-0">
              <Col xs="12">
                <Form.Group className="mb-0" controlId="formBasicServiceCity">
                  <Image
                    className="mr-2 align-top ftc-logo-24 float-left"
                    src={IconReimbursementSetup}
                  />
                  {/* <label > */}
                  <strong>{reimbursementReason?.reason}</strong>
                  <p className="mb-1">
                    {DateConvert(reimbursementReason?.updatedAt)}
                  </p>
                  {/* </label> */}
                </Form.Group>
              </Col>
              <Col xs="12" className="pr-0 ml-2">
                <span className="ellipse__nbr__mobile float-right">
                  <div id="settings-dropdown">
                    <NavDropdown
                      title={<Ellipses />}
                      id="collasible-nav-dropdown"
                    >
                      <NavDropdown.Item onClick={handleEditClicked} id={reimbursementReason._id}>
                        Edit
                      </NavDropdown.Item>
                      <NavDropdown.Item id={reimbursementReason._id} onClick={handleClickDelete}>
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </span>
              </Col>
            </Row>
            <Row className="pl-0 pb-2">
              <Col xs="12">
                <Form.Group className="mb-0" controlId="formBasicHours">
                  <Image
                    className="mr-2 align-top ftc-logo-24"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-time.svg`}
                  />
                  <Form.Label>{displayUserRoles(reimbursementReason.access)}</Form.Label>
                </Form.Group>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </div>
    );
  }
);

export default ReimbursementReasonTable;

ReimbursementReasonTable.propTypes = {
  reimbursementReason: PropTypes.object.isRequired,
  handleEditClicked: PropTypes.func.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
};
