import React, { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';

import Toaster from '../Toaster';

import { getCustomPopupByLocation } from '../../services/custom_popup.service';
import { htmlDecode } from '../../utils/CustomPageManagement';

const RenderCustomPopup = memo(() => {
    const location = useLocation();

    const [popups, setPopups] = useState([]);

    async function getAllPopupsByPath() {
        try {
            if (navigator.onLine) {
                const body = {
                    location: location.pathname,
                }
                const response = await getCustomPopupByLocation(body);
                setPopups(response.data.data.customPopups);
            }

        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    const renderPopups = () => {
        try {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            popups.forEach((popup) => {
                const popupExpiryDate = new Date(popup?.expiresOn);
                popupExpiryDate.setHours(0, 0, 0, 0);          
                    if (
                      popupExpiryDate >= currentDate ||
                      popup?.expiresOn === undefined ||
                      popup?.expiresOn === null
                    ) {
                        toast.dark(
                            <>
                                <div>
                                    <h2>{popup.title}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlDecode(popup.content)) }} />
                                </div>
                            </>,
                            { autoClose: false, closeOnClick: false, style: { width: 'initial' } }
                        );
                    }
                    return popup;
            });
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    useEffect(() => {

        const timer = setTimeout(() => {
           getAllPopupsByPath(); 
        }, 1500);

        // getAllPopupsByPath();

        return () => {
            // removing all toasts after page unloads
            toast.dismiss();
            clearTimeout(timer);
        }
    }, []);

    useEffect(() => {
        renderPopups();
    }, [popups]);

    return null;
});

export default RenderCustomPopup
