import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import GoogleLogin from 'react-google-login';
import { toast } from 'react-toastify';

import Toaster from '../Toaster';

import { useAuthContext } from '../../contexts/user-context';

import { userGoogleLogin } from '../../services/users-service';

const GoogleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const LoginGoogle = () => {
  const history = useHistory();

  const { setAuthUser } = useAuthContext();

  const googleResponseSuccess = async (response) => {
      try
      {
        const user = await userGoogleLogin({ tokenId: response.tokenId });
        setAuthUser(user.data.data.user, user.data.data.token);
        toast.dark(<Toaster
          icon="notify"
          message={user.data.message}
        />);
        history.push('/timesheet');
    } catch (error)
    {
        
        toast.dark(<Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!'}
        />);
    }
  };

  const googleResponseError = (response) =>
  {
    if (
      response?.details !== 'Cookies are not enabled in current environment.' &&
      response?.error !== 'popup_closed_by_user'
    )
    {      
      if (response?.reason === 'Account domain does not match hosted_domain specified by gapi.auth2.init.')
      {
        toast.dark(<Toaster
          icon="error"
          message={`You're not authorized to login with this domain!`}
        />);
      }
      else
      {
        toast.dark(<Toaster
          icon="error"
          message={response?.data?.message ?? 'Something went wrong!'}
        />);
      }
    }
    
  };

  return (
    <GoogleLogin
      clientId={GoogleClientId}
      onSuccess={googleResponseSuccess}
      onFailure={googleResponseError}
      cookiePolicy="single_host_origin"
      buttonText="Login with Google"
      hostedDomain={process.env.REACT_APP_HOSTED_DOMAIN}
      // hostedDomain="ginnovat.com"
      render={(renderProps) => {
        return (
          <Button
            variant="light"
            block
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <Image
              className="google__logo__size"
              src={`${process.env.PUBLIC_URL}/assets/glogo.png`}
              fluid
            />
            <span className="google__logo__text">Login with Google</span>
          </Button>
        );
      }}
    />
  );
};

export default LoginGoogle;
