import React, { memo, useEffect, useState } from 'react';
import { Row, Col, Image, NavDropdown } from 'react-bootstrap';
import { openDB } from 'idb';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { trackPromise } from 'react-promise-tracker';

import { RiArrowUpSFill } from 'react-icons/ri';

import ConfirmAlertModal from '../../components/ConfirmAlertModal/ConfirmAlertModal';
import Toaster from '../../components/Toaster';

import { useAuthContext } from '../../contexts/user-context';
import { getCustomPageByLocation } from '../../services/custom_page.service';

import useBoolean from '../../utils/CustomHooks/useBoolean';
import SidebarData from '../side-navbar/SidebarData';
import {
  shouldDisplayNav,
  shouldDisplaySubNav,
} from '../../utils/SidebarManagement';
import { reimbursementAccessible } from '../../services/reimbursement_reason.services';

import { Desktop, Mobile } from '../media-queries/mediaQueries';
import packageJson from '../../../package.json';
import SubMenu from '../side-navbar/SubMenu';
import constant from '../../constant';

const NavIcon = styled(Link)`
  margin-left: 1rem;
  font-size: 2rem;
  height: 3rem;
  margin-top: 11px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  overflow-y: scroll;
  transition: 350ms;
  z-index: 101;
  top: 0;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 20px 0;
  list-style: none;
  height: 0px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const SidebarLabel = styled.span`
  color: #000000;
  font-size: 14px;

  &:hover {
    color: #a50808;
  }
`;

const userIcon = `${process.env.PUBLIC_URL}/assets/user-icon.png `;

const LandingPage = memo(() => {
  const { authUser, setAuthUser } = useAuthContext();
  const history = useHistory();
  const locationHook = useLocation();

  const [isVisible, showModal, hideModal] = useBoolean(false);

  const [sideBarData, setSideBarData] = useState(SidebarData);
  const [menuItems, setMenuItems] = useState([]);

  const [showLogoutDropdown, setLogoutDropdown] = useState(false);
  const [isReimbursementAccessible, setIsReimbursementAccessible] = useState();

  const toggleLogoutDropdown = (event) => {
    if (event.target.id === 'logout-btn') return;
    setLogoutDropdown(!showLogoutDropdown);
  };

  async function isReimbursementVisible() {
    try {
      const role = authUser.user.role._id;
      const response = await trackPromise(reimbursementAccessible(role));
      setIsReimbursementAccessible(response.data.data.accessible);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={
            error?.response?.data?.message ?? constant.SOMETHING_WENT_WRONG
          }
        />
      );
    }
  }

  useEffect(() => {
    isReimbursementVisible()
  }, [])

  const onLogout = async () => {
    try {
      const dbs = await openDB('order', 1, {
        upgrade(db) {
          // Create a store of objects
          const store = db.createObjectStore('order_requests', {
            // The 'uniqueId' property of the object will be the key.
            keyPath: 'uniqueId',
            // If it isn't explicitly set, create a value by auto incrementing.
            // autoIncrement: true,
          });
          // Create an index on the 'date' property of the objects.
          store.createIndex('uniqueId', 'uniqueId');
        },
      });
      const value = await dbs.getAll('order_requests');
      // check if offline timesheets exists then user can not logout
      if (value.length) {
        showModal();
      } else {
        localStorage.clear();
        // clear indexedDB from if user is getting logged out
        const allDBs = await window.indexedDB.databases();
        allDBs.forEach((db) => {
          window.indexedDB.deleteDatabase(db.name);
        });

        setAuthUser({});
      }
    } catch (error) {
      if (error.name === 'NotFoundError') {
        localStorage.clear();
        const allDBs = await window.indexedDB.databases();
        allDBs.forEach((db) => {
          window.indexedDB.deleteDatabase(db.name);
        });
        setAuthUser({});
      }
    }
  };

  const onTimesheetsClick = () => {
    // if we are on the same page then close the sidebar otherwise redirect it
    switch (locationHook.pathname) {
      case '/timesheet':
        hideModal();
        break;

      default:
        history.push('/timesheet');
    }
  };

  async function fetchCustomPages() {
    try {
      const response = await getCustomPageByLocation();

      const updatedSideBarData = SidebarData;

      const toolsData = updatedSideBarData.findIndex(
        (sidebarObj) => sidebarObj.title === 'Tools'
      );
      updatedSideBarData[toolsData].subNav = [];

      const reportsData = updatedSideBarData.findIndex(
        (sidebarObj) => sidebarObj.title === 'Reports'
      );

      updatedSideBarData[reportsData].subNav = [
        {
          title: 'Hours',
          path: '/reports/hours',
          icon: (
            <Image
              className="ftc-logo-30"
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icons-timesheet.svg`}
              fluid
            />
          ),
        },
        {
          title: 'Payroll',
          path: '/reports/payroll',
          icon: (
            <Image
              className="ftc-logo-30"
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-money.svg`}
              fluid
            />
          ),
        },
      ];

      const formsData = updatedSideBarData.findIndex(
        (sidebarObj) => sidebarObj.title === 'Forms'
      );

      updatedSideBarData[formsData].subNav = [];

      await response.data.data.customPages.forEach(async (location) => {
        switch (location._id) {
          case 'tools': {
            if (location.total >= 1) {
              await location.data.map((customPage) => {
                const tempPage = {};
                tempPage.title = customPage.button_label;
                tempPage.path = customPage.route;
                tempPage.icon = (
                  <Image
                    className="ftc-logo-24"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                    fluid
                  />
                );

                if (
                  updatedSideBarData[toolsData].subNav.findIndex(
                    (nav) => nav.path === customPage.route
                  ) === -1
                ) {
                  updatedSideBarData[toolsData].subNav.push(tempPage);
                }

                return tempPage;
              });
            }
            break;
          }

          case 'reports': {
            if (location.total >= 1) {
              await location.data.map((customPage) => {
                const tempPage = {};
                tempPage.title = customPage.button_label;
                tempPage.path = customPage.route;
                tempPage.icon = (
                  <Image
                    className="svg__sub-icon__size"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                    fluid
                  />
                );
                if (
                  updatedSideBarData[reportsData].subNav.findIndex(
                    (nav) => nav.path === customPage.route
                  ) === -1
                ) {
                  updatedSideBarData[reportsData].subNav.push(tempPage);
                }
                return tempPage;
              });
            }
            break;
          }

          case 'forms': {
            if (location.total >= 1) {
              await location.data.map((customPage) => {
                const tempPage = {};
                tempPage.title = customPage.button_label;
                tempPage.path = customPage.route;
                tempPage.icon = (
                  <Image
                    className="ftc-logo-24"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
                    fluid
                  />
                );
                if (
                  updatedSideBarData[formsData].subNav.findIndex(
                    (nav) => nav.path === customPage.route
                  ) === -1
                ) {
                  updatedSideBarData[formsData].subNav.push(tempPage);
                }
                return tempPage;
              });
            }
            break;
          }

          default:
            break;
        }
      });
      setSideBarData(updatedSideBarData);
      setMenuItems(updatedSideBarData);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  useEffect(() => {
    fetchCustomPages();
  }, []);

  return (
    <div>
      <>
        <Desktop>
          <Row className="border-bottom pl-5 pr-1">
            <Col xs="7" md="10">
              <Image
                className="img-fluid ftc-sidebar-header-logo"
                src={`${process.env.PUBLIC_URL}/assets/sidebar-logo.svg`}
              />
            </Col>
            <Col xs="5" md="2" className="border-left">
              <div className=" float-right">
                {/* <Image
                                    className="user__icon__size"
                                    src={`${process.env.PUBLIC_URL}/assets/user-icon.png `}
                                /> */}
                <Image
                  className="user__icon__size"
                  src={
                    authUser.user.profile_picture === ''
                      ? userIcon
                      : authUser.user.profile_picture_url
                  }
                  onError={(e) => {
                    e.target.src = userIcon; // Set fallback image if src image fails to load
                  }}
                />
                <label className="d-inline-flex">
                  {authUser.user.username}
                </label>
                <span className="d-inline-flex">
                  <NavDropdown
                    title={
                      <Image
                        className="ftc-logo-15"
                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                        drop="bottom"
                      />
                    }
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item onClick={onLogout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </span>
              </div>
            </Col>
          </Row>
          <div className="container">
            <Row className="d-flex justify-content-center container-top-5">
              <Col md="8">
                <Row>
                  <Col md="12" className="text-center mb-3">
                    <h4>
                      <strong>Main Menu</strong>
                    </h4>
                  </Col>
                </Row>
                <Row>
                  {menuItems.map((item) => {
                    return (
                      <>
                        {shouldDisplayNav(item, authUser.user.role, isReimbursementAccessible) ? (
                          <>
                            <Col md="6" className="mt-3">
                              <div className="col-custom-padding">
                                <SidebarLink to={item.path}>
                                  <SidebarLabel>
                                    <span className="mr-2">
                                      {item.landingIcon}
                                    </span>{' '}
                                    {item.title}
                                  </SidebarLabel>
                                </SidebarLink>

                                {item?.subNav && item.subNav.length > 0 ? (
                                  <span className="float-right landingPage__dropdown">
                                    <NavDropdown
                                      title={
                                        <Image
                                          className="ftc-logo-20"
                                          src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                          drop="left"
                                        />
                                      }
                                      id="navbarScrollingDropdown"
                                    >
                                      {item.subNav.map((subNavItem) => {
                                        return shouldDisplaySubNav(
                                          subNavItem.title,
                                          authUser.user.role
                                        ) ? (
                                          <NavDropdown.Item
                                            as={Link}
                                            to={subNavItem.path}
                                            style={{
                                              padding:
                                                '0.45rem 2.9rem 0.4rem 1.2rem',
                                            }}
                                            className="landing__page-dropdown-item"
                                          >
                                            {subNavItem.icon}{' '}
                                            <span>{subNavItem.title}</span>
                                          </NavDropdown.Item>
                                        ) : (
                                          ''
                                        );
                                      })}
                                    </NavDropdown>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Col>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </div>
        </Desktop>
        <Mobile>
          <SidebarNav
            className="MobileView SidebarShow"
            style={{
              width: '100%',
            }}
          >
            <SidebarWrap className="sidebar__width sticky-top overflow-auto">
              <div className="sticky-top-logo bg-white overflow-x-hidden">
                <NavIcon to="#">
                  <Row className="mobile__nav__icon__width">
                    <Col xs="10" className="pl-0">
                      <Image
                        className="img-fluid ftc-sidebar-header-logo"
                        src={`${process.env.PUBLIC_URL}/assets/sidebar-logo.svg`}
                      />
                    </Col>
                  </Row>
                </NavIcon>
                <hr className="pb-0 mb-0" />
              </div>
              <div className="mt-3">
                {sideBarData.map((item) => {
                  return <SubMenu item={item} isReimbursementAccessible={isReimbursementAccessible} />;
                })}
              </div>

              <div className="sidebar__logo__margin">
                <div
                  onClick={toggleLogoutDropdown}
                  onBlur={() => setLogoutDropdown(false)}
                  tabIndex={0}
                  onKeyDown={() => { }}
                  role="button"
                  className="sidebar-username"
                >
                  <div className="user-info">
                    <div>
                      <Image
                        className="user__icon__size"
                        src={
                          authUser.user.profile_picture === ''
                            ? userIcon
                            : authUser.user.profile_picture_url
                        }
                        onError={(e) => {
                          e.target.src = userIcon; // Set fallback image if src image fails to load
                        }}
                      />
                    </div>

                    <div className="username">{authUser.user.username}</div>

                    <div>
                      {showLogoutDropdown ? (
                        <RiArrowUpSFill size="24px" />
                      ) : (
                        <Image
                          className="svg__icon__size__arrow__logout"
                          src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                        />
                      )}
                    </div>
                  </div>
                  {showLogoutDropdown && (
                    <div
                      className="dropdown-content"
                      id="logout-btn"
                      onClick={onLogout}
                      role="presentation"
                    >
                      Logout
                    </div>
                  )}
                </div>
                <div className="version">Version {packageJson.version}</div>
              </div>
            </SidebarWrap>
          </SidebarNav>
        </Mobile>
      </>
      {isVisible && (
        <ConfirmAlertModal
          show={isVisible}
          handleClose={hideModal}
          title="Can't Sign Out"
          description="Please connect to the Internet to sync your offline Timesheets with server."
          callback={onTimesheetsClick}
          action="Offline Timesheets"
          closeAction="Okay"
        />
      )}
    </div>
  );
});

export default LandingPage;
