import React, { memo, useEffect, useState } from 'react';
import { Row, Col, Image, Button, Card, Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import Toaster from '../../components/Toaster';
import { Desktop, Mobile } from '../media-queries/mediaQueries';
// import { getCityById } from '../../services/city-service';
import {
  getReimbursementById,
  deleteReimbursementById,
  updateReimbursementById,
} from '../../services/reimbursement-service';
import { DateConvert } from '../../utils/TimesheetManagement';
import ConfirmAlertModal from '../../components/ConfirmAlertModal/ConfirmAlertModal';
import { useAuthContext } from '../../contexts/user-context';
import {
  checkApproveDenyButton,
  checkPaymentButton,
  checkReimbursementDeletable
} from '../../utils/ReimbursementManagement';
import ReceiptList from './receiptList';
import constant from '../../constant';
import ReimbursementReasons from '../../constants/reimbursement_reasons';

const ViewReimbursement = memo(() => {
  const history = useHistory();
  const { reimbursementId } = useParams();
  const { authUser } = useAuthContext();

  const [isAPICalled, setAPICalled] = useState(false);
  const [reimbursement, setReimbursement] = useState(null);
  const [isAcknowledged, setIsAcknowledged] = useState('pending');
  const [paymentStatus, setPaymentStatus] = useState('pending');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [comment, setComment] = useState('');
  const [showReason, setShowReason] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDiningBenefitOptions, setShowDiningBenefitOptions] = useState(false);
  // const [cityName, setCityName] = useState('');

  const isFtc = process.env.REACT_APP_PROJECT_NAME;
  const handleChange = (event) => {
    setComment(event.target.value);
    setErrorMessage('');
  };

  const handleSubmit = async () => {
    const response = await trackPromise(
      getReimbursementById(reimbursement.reimbursementId)
    ); // Fetch the current timesheet data from the server

    if (comment.trim() === '') {
      setErrorMessage('Please enter a comment before submitting.');
    } else {
      if (response.status === 200) {
        const updatedReimbursement = response.data.data.reimbursement;
        
  
        // Check if the timesheet is already acknowledged
        if (updatedReimbursement?.acknowledgedReason !== null) {
          toast.error(
            <Toaster
              icon="error"
              message="This reimbursement is already commented"
            />
          );
        } else {
          // Add the new fields and set their values
          updatedReimbursement.acknowledgedReason = comment;
          updatedReimbursement.reason = updatedReimbursement.reason._id;
          const saveResponse = await trackPromise(
            updateReimbursementById(
              updatedReimbursement.reimbursementId,
              updatedReimbursement
            )
          );
          if (saveResponse.status === 200) {
            setShowReason(true);
            toast.dark(
              <Toaster icon="notify" message="Reason Submitted successfully" />
            );
          }
        }
      }
      setErrorMessage('');
    }
    
  };

  const handlePayment = async () => {
    try {
      
      const response = await trackPromise(
        getReimbursementById(reimbursement.reimbursementId)
      ); // Fetch the current reimbursement data from the server
  
      if (response.status === 200) {
        const updatedReimbursement = response.data.data.reimbursement;
  
        // Check if the reimbursement is already acknowledged
        if (
          updatedReimbursement.payment_status === 'paid'
        ) {
          toast.error(
            <Toaster
              icon="error"
              message={`This Reimbursement is already mark as paid by ${updatedReimbursement.markAsPaidBy.username}`}
            />
          );
          setPaymentStatus(updatedReimbursement.payment_status)
        } else {
          // Add the new fields and set their values
          updatedReimbursement.payment_status = "paid";
          updatedReimbursement.markAsPaidBy = authUser.user._id;
          updatedReimbursement.markAsPaidTimestamp = new Date();
          updatedReimbursement.reason = updatedReimbursement.reason._id;
  
          const saveResponse = await trackPromise(
            updateReimbursementById(
              updatedReimbursement.reimbursementId,
              updatedReimbursement
            )
          );
          if (saveResponse.status === 200) {
            toast.dark(
              <Toaster icon="notify" message="Mark As Paid successfully" />
            );
            setPaymentStatus("paid")
          }
        }
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  };

  async function getReimbursementDetails() {
    try {
      const response = await trackPromise(
        getReimbursementById(reimbursementId)
      );

      setReimbursement(response.data.data.reimbursement);

      if (response.data.data.reimbursement.reason._id === ReimbursementReasons[0]._id && isFtc) {
        setShowDiningBenefitOptions(true);
      }
      
      setIsAcknowledged(response.data.data.reimbursement.reimbursement_status);
      setAPICalled(true);
      setShowReason(
        response.data.data.reimbursement.acknowledgedReason !== null
      );
      setPaymentStatus(
        response.data.data.reimbursement.payment_status === 'paid'
          ? 'paid'
          : 'pending'
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }
  const onDeleteReimbursement = async () => {
    try {
      const response = await trackPromise(
        deleteReimbursementById(reimbursementId)
      );
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setShow(false);
      history.replace('/reimbursement');
    }
  };

  const reimbursementAcknowledge = async (status) => {
    const response = await trackPromise(
      getReimbursementById(reimbursement.reimbursementId)
    ); // Fetch the current reimbursement data from the server

    if (response.status === 200) {
      const updatedReimbursement = response.data.data.reimbursement;

      // Check if the reimbursement is already acknowledged
      if (
        updatedReimbursement.reimbursement_status === 'approved' ||
        updatedReimbursement.reimbursement_status === 'denied'
      ) {
        toast.error(
          <Toaster
            icon="error"
            message={`This Reimbursement is already acknowledged by ${updatedReimbursement.reimbursementAcknowledgedBy.username}`}
          />
        );
        setIsAcknowledged(updatedReimbursement.reimbursement_status);
      } else {
        // Add the new fields and set their values
        updatedReimbursement.reimbursement_status = status;
        updatedReimbursement.reimbursementAcknowledgedBy = authUser.user._id;
        updatedReimbursement.acknowledgedTimestamp = new Date();
        updatedReimbursement.reason = updatedReimbursement.reason._id;

        const saveResponse = await trackPromise(
          updateReimbursementById(
            updatedReimbursement.reimbursementId,
            updatedReimbursement
          )
        );
        if (saveResponse.status === 200) {
          toast.dark(
            <Toaster icon="notify" message="Acknowledged successfully" />
          );
          setIsAcknowledged(status);
        }
      }
    }
  };

  // async function fetchAllCitiesById(cityId) {
  //   try {
  //     const response = await trackPromise(getCityById(cityId._id));
  //     setCityName(response.data.data.city.cityname);
  //   } catch (error) {
  //     toast.dark(
  //       <Toaster
  //         icon="error"
  //         message={error?.response?.data?.message ?? 'Something went wrong!!!'}
  //       />
  //     );
  //   }
  // };

  useEffect(() => {
    getReimbursementDetails();
  }, [reimbursementId,isAcknowledged]);
  const onHandleBack = () => {
    history.push('/reimbursement');
  };
  return isAPICalled ? (
    <div>
      <div className="sticky-page-header">
        <div className="container">
          <Desktop>
            <Row>
              <Col md="1" className="d-flex align-items-center">
                <a
                  onKeyPress={() => {}}
                  role="link"
                  tabIndex="0"
                  onClick={onHandleBack}
                >
                  <Image
                    className="imageArrow"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                    fluid
                  />
                </a>
              </Col>
              <Col md="8" className="pl-0 d-flex align-items-center">
                <h5 className="m-0">
                  <strong>Reimbursement Details</strong>
                </h5>
              </Col>
              <Col md="3">
                <Row>
                  <Col className="pr-0 ml-5">
                    <Button
                      variant="default"
                      // onClick={onEditTimesheet}
                      onClick={handleShow}
                      className="btn-ftc-border-primary ml-5"
                      disabled={
                        !checkReimbursementDeletable(
                          authUser.user.role.rolename,
                          authUser.user.role.permissions,
                          reimbursement.reimbursement_date,
                          reimbursement?.period?.start_date,
                          reimbursement?.period?.end_date,
                          reimbursement.reimbursement_status
                        )
                      }
                    >
                      <strong>Delete</strong>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Desktop>
          <Mobile>
            <div className="container">
              <Row className="border-bottom pl-0 pr-0 pb-2">
                <Col xs="1" className="p-0 d-flex align-items-center">
                  <a
                    onKeyPress={() => {}}
                    role="link"
                    tabIndex="0"
                    onClick={onHandleBack}
                  >
                    <Image
                      className="imageArrowMobile ml-2"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </a>
                </Col>
                <Col xs="7" className="mt-1">
                  <h6 className="m-auto">
                    <strong>Reimbursement Details</strong>
                  </h6>
                </Col>
                <Col xs="4" className="p-0">
                  <Button
                    variant="default"
                    onClick={handleShow}
                    className="btn-ftc-border-primary ml-4"
                      disabled={
                        !checkReimbursementDeletable(
                          authUser.user.role.rolename,
                          authUser.user.role.permissions,
                          reimbursement.reimbursement_date,
                          reimbursement?.period?.start_date,
                          reimbursement?.period?.end_date,
                          reimbursement.reimbursement_status
                        )
                      }
                  >
                    <strong>Delete</strong>
                  </Button>
                </Col>
              </Row>
            </div>
          </Mobile>
        </div>
      </div>
      <Desktop>
        <div className="container-top-8" />
      </Desktop>
      <Mobile>
        <div className="container-top-20" />
        <br />
      </Mobile>
      <div className="container">
        <Row>
          <Col md="5">
            <Card className="mb-4">
              <Card.Body className="pb-0">
                <Row className="border-bottom">
                  <Col md="5" xs="6" className="p-0">
                    <label className="txt-light-secondary">Employee Name:</label>
                  </Col>
                  <Col md="7" xs="6" className="p-0">
                    <label className="float-right">
                      <strong>{reimbursement.employee.username}</strong>
                    </label>
                  </Col>
                </Row>
                <Row className="border-bottom">
                  <Col md="5" xs="4" className="p-0 pt-4">
                    <label className="txt-light-secondary">Reason:</label>
                  </Col>
                  <Col md="7" xs="8" className="p-0 pt-4">
                    <label className="float-right">
                      <strong>{reimbursement.reason.reason}</strong>
                    </label>
                  </Col>
                </Row>
                <Row className="border-bottom">
                  <Col md="5" xs="6" className="p-0 pt-4">
                    <label className="txt-light-secondary">
                    Submission Date:
                    </label>
                  </Col>
                  <Col md="7" xs="6" className="p-0 pt-4">
                    <label className="float-right">
                      <strong>
                        {DateConvert(reimbursement.reimbursement_date)}
                      </strong>
                    </label>
                  </Col>
                </Row>

                <Row className="border-bottom">
                  <Col md="3" xs="2" className="p-0 pt-4">
                    <label className="txt-light-secondary">Period:</label>
                  </Col>
                  <Col md="9" xs="10" className="p-0 pt-4">
                    <label className="float-right">
                      <strong>{`Period-${
                        reimbursement?.period?.period_index
                      } (${DateConvert(
                        reimbursement?.period?.start_date
                      )}) - (${DateConvert(
                        reimbursement?.period?.end_date
                      )})`}</strong>
                    </label>
                  </Col>
                </Row>
                <Row className="border-bottom">
                  <Col md="5" xs="4" className="p-0 pt-4">
                    <label className="txt-light-secondary">Total Amount:</label>
                  </Col>
                  <Col md="7" xs="8" className="p-0 pt-4">
                    <label className="float-right">
                      <strong>$ {reimbursement.total_amount}</strong>
                    </label>
                  </Col>
                </Row>
                {
                  showDiningBenefitOptions ? <>
                    <Row className="border-bottom">
                      <Col md="7" xs="4" className="p-0 pt-4">
                        <label className="txt-light-secondary">Name of Establishment Where Benefits will be Redeemed:</label>
                      </Col>
                      <Col md="5" xs="8" className="p-0 pt-4">
                        <label className="float-right">
                          <strong>{reimbursement.name_of_establishment}</strong>
                        </label>
                      </Col>
                    </Row>
                    <Row className='pt-4 mb-0 pb-0'>
                        <label className="txt-light-secondary">Location of Establishment Where Benefits will be Redeemed</label>
                    </Row>
                    <Row className="border-bottom">
                      <Col md="5" xs="4" className="pt-2 mt-0 p-0">
                        <label className="txt-light-secondary">City:</label>
                      </Col>
                      <Col md="7" xs="8" className="p-0">
                        <label className="float-right">
                          <strong>{reimbursement?.city?.cityname}</strong>
                        </label>
                      </Col>
                    </Row>
                    <Row className="border-bottom">
                      <Col md="5" xs="4" className="p-0 pt-4">
                        <label className="txt-light-secondary">State:</label>
                      </Col>
                      <Col md="7" xs="8" className="p-0 pt-4">
                        <label className="float-right">
                          <strong>{reimbursement.state}</strong>
                        </label>
                      </Col>
                    </Row>
                    <Row className="border-bottom">
                      <Col md="12" xs="12" className="pl-0 pt-4 pb-2">
                        <p className="txt-light-secondary mb-0">What did you learn from this dining experience?:</p>
                        <p className="txt-grey mb-0">{reimbursement.experience}</p>
                      </Col>
                    </Row>
                  </> : <></>
                }
                <Row >
                  <Col md="12" xs="12" className="pl-0 pt-4 pb-2">
                    <p className="txt-light-secondary mb-0">Comment:</p>
                    <p className="txt-grey mb-0">{reimbursement.comments}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Row
                  className={`${
                    isAcknowledged === 'pending' ? 'border-bottom' : 'pb-0'
                  }`}
                >
                  <Col md="3" xs="6" className="p-0 d-flex align-items-center">
                    <label className="txt-light-secondary mb-0 ">Status</label>
                  </Col>
                  <Col md="9" xs="6" className="p-0 d-flex justify-content-end">
                    {checkApproveDenyButton(
                      authUser.user.role.rolename,
                      authUser.user.role.permissions,
                      isAcknowledged, // reimbursement status
                      reimbursementAcknowledge,
                      reimbursement?.reimbursementAcknowledgedBy?.username
                    )}
                  </Col>
                </Row>
                {isAcknowledged === 'denied' ? (
                  <>
                    {showReason ? (
                      <Row className="border-bottom">
                        <Col md="12" xs="12" className="pl-0">
                          <p className="txt-grey mb-0">Comment:</p>
                          <p className="txt-grey mb-0">
                            {reimbursement.acknowledgedReason || comment}
                          </p>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="pt-0 pb-0 border-bottom">
                        <Col md="12" className="p-0">
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="txt-light-secondary">
                              Write Your Comment
                            </Form.Label>
                            <div style={{ position: 'relative' }}>
                              <Form.Control
                                as="textarea"
                                placeholder="Comment.."
                                rows={2}
                                value={comment}
                                onChange={handleChange}
                              />
                              <Button
                                variant="default"
                                className="btn-ftc-border-primary"
                                style={{
                                  position: 'absolute',
                                  top: '8px',
                                  right: '8px',
                                }}
                                onClick={handleSubmit}
                                // onClick={() => reimbursementAcknowledge('deny')}
                              >
                                Submit
                              </Button>
                              {errorMessage && (
                          <p className="text-danger">
                            {errorMessage}
                          </p>
                        )}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <Row className="border-bottom mt-2">
                  <Col md="3" xs="6" className="p-0 d-flex align-items-center">
                    <label className="txt-light-secondary mb-0">Payment:</label>
                  </Col>
                  <Col md="9" xs="6" className="p-0 d-flex justify-content-end">
                    {checkPaymentButton(
                      authUser.user.role.rolename,
                      authUser.user.role.permissions,
                      paymentStatus, // payment status (pending, paid),
                      isAcknowledged, // (pending,approved,deny)
                      handlePayment,
                      reimbursement?.markAsPaidBy?.username
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="7">
            <Card>
              <Card.Body className="pb-0">
              <ReceiptList receipts={reimbursement.receiptsListUrls} />
                
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {show && (
        <ConfirmAlertModal
          show={show}
          handleClose={handleClose}
          title="Delete Reimbursement"
          description= {constant.DELETE_REIMBURSEMENT_POPUP_MESSAGE}
          callback={onDeleteReimbursement}
          action="Delete it"
          closeAction="Cancel"
        />
      )}
    </div>
  ) : (
    ''
  );
});

export default ViewReimbursement;
