const ReimbursementReasons = [
  {
    _id: '65dc6b55c4ea6dc133bebaf8',
    reason: 'Dining Benefit',
  },
  {
    _id: '65dc6b55c4ea6dc133bebaf6',
    reason: 'Equipment Allowance',
  },
  {
    _id: '65dc6b54c4ea6dc133bebaf5',
    reason: 'Fuel Reimbursement',
  },
];

export default ReimbursementReasons;