import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const userLogin = (body) => {
  return API.post(API_ENDPOINTS.AUTH.LOGIN, body);
};

const userGoogleLogin = (body) => {
  return API.post(API_ENDPOINTS.AUTH.GoogleLogin, body);
};

const getAllUsers = (pagination, page, employee, queryString) => {
  return API.get(
    `${API_ENDPOINTS.USERS.Users}?pagination=${pagination}&page=${page}&employee=${employee}${queryString}`
  );
};

const getAllUsersByNames = () => {
  return API.get(API_ENDPOINTS.USERS.AllUsersByName);
};

const getAllChefsByNames = () => {
  return API.get(API_ENDPOINTS.USERS.AllChefsByName);
};

const getAllChefsByCity = (cityId) => {
  return API.get(`${API_ENDPOINTS.USERS.AllChefsByName}/${cityId}`);
};

const getAllUsersByCity = (cityId) => {
  return API.get(`${API_ENDPOINTS.USERS.UsersByCity}/${cityId}`);
};

const createUser = (body) => {
  return API.post(API_ENDPOINTS.USERS.Users, body);
};

const imagePresignedurl = (body) => {
  return API.post(API_ENDPOINTS.USERS.PresignedUrl, body);
};

const getUserByEmployeeId = (employeeId) => {
  return API.get(`${API_ENDPOINTS.USERS.Users}/${employeeId}`);
};

const getUserFlexPayLimit = (employeeId) => {
  return API.get(`${API_ENDPOINTS.USERS.GetUserFlexPayLimit}/${employeeId}`);
};

const updateUserByEmployeeId = (employeeId, body) => {
  return API.put(`${API_ENDPOINTS.USERS.UpdateUserFlexPayLimit}/${employeeId}`, body);
};

const deleteUserByEmployeeId = (employeeId) => {
  return API.del(`${API_ENDPOINTS.USERS.Users}/${employeeId}`);
};

const getUserPassword = (employeeId) => {
  return API.get(`${API_ENDPOINTS.USERS.GetPassword}/${employeeId}`);
};

export {
  userLogin,
  userGoogleLogin,
  getAllUsers,
  createUser,
  getAllUsersByNames,
  getAllChefsByNames,
  getAllChefsByCity,
  getAllUsersByCity,
  getUserByEmployeeId,
  updateUserByEmployeeId,
  deleteUserByEmployeeId,
  getUserPassword,
  imagePresignedurl,
  getUserFlexPayLimit,
};
