import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { Button, Col, Image, Modal, Row, Form } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';

import Toaster from '../../../components/Toaster';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import { getAllRolesByNames } from '../../../services/role-service';


const CustomPageFilters = memo(({
    show, handleClose, applyChanges, allFilters, clearAll
}) => {
    const [option, setOption] = useState('location');

    const [roles, setRoles] = useState([]);
    const [selectedRoles, setselectedRoles] = useState([]);

    const [locations, setLocations] = useState([
        { _id: 1, label: 'Forms', value: 'forms', isChecked: false, },
        { _id: 2, label: 'Reports', value: 'reports', isChecked: false },
        { _id: 3, label: 'Tools', value: 'tools', isChecked: false },
    ]);
    const [selectedLocation, setSelectedLocation] = useState(
        allFilters?.locations?.length ? allFilters.locations : []
    );

    const [filters, setfilters] = useState(null);

    async function fetchAllRoles() {
        try {
            const response = await trackPromise(getAllRolesByNames());
            setRoles(
                response.data.data.roles.map((role) => {
                    const tempRole = role;
                    if (allFilters?.roles?.length > 0) {
                        if (allFilters.roles.find((selected) => selected._id === role._id)) {
                            tempRole.isChecked = true;
                        }
                    }
                    else {
                        tempRole.isChecked = false;
                    }
                    return tempRole;
                })
            );
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    useEffect(() => {
        fetchAllRoles();
    }, []);

    useEffect(() => {
        if (selectedRoles.length) {
            setfilters((oldFilters) => ({ ...oldFilters, roles: selectedRoles }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('roles'))) {
            delete filters?.roles;
            setfilters({ ...filters });
        }
    }, [selectedRoles]);

    useEffect(() => {
        if (selectedLocation.length) {
            setfilters((oldFilters) => ({ ...oldFilters, locations: selectedLocation }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('locations'))) {
            delete filters?.locations;
            setfilters({ ...filters });
        }
    }, [selectedLocation]);

    useLayoutEffect(() => {
        if (allFilters?.roles?.length) {
            setselectedRoles(allFilters.roles);
        }

        if (allFilters?.locations?.length) {
            setLocations(
                locations.map((rate) => {
                    const tempRate = rate;
                    if (allFilters?.locations.find((selected) => selected._id === rate._id)) {
                        tempRate.isChecked = true;
                    }
                    return tempRate;
                })
            )
        }

    }, [allFilters]);

    const onRoleChange = (e, role, indexKey) => {
        const newRoles = [...roles];
        const findSelectedIndex = selectedRoles.map((searchRole) => searchRole._id).indexOf(role._id);

        const newSelectedRoles = [...selectedRoles];

        if (e.target.checked) {
            newRoles[indexKey].isChecked = true;
            newSelectedRoles.push(role);
        }
        else {
            newRoles[indexKey].isChecked = false;
            newSelectedRoles.splice(findSelectedIndex, 1);
        }
        setRoles(newRoles);
        setselectedRoles(newSelectedRoles);
    }

    const onLocationChange = (e, location, indexKey) => {
        const newLocations = [...locations];
        const findSelectedIndex = selectedLocation.map((searchRole) => searchRole._id).indexOf(location._id);

        const newSelectedLocations = [...selectedLocation];

        if (e.target.checked) {
            newLocations[indexKey].isChecked = true;
            newSelectedLocations.push(location);
        }
        else {
            newLocations[indexKey].isChecked = false;
            newSelectedLocations.splice(findSelectedIndex, 1);
        }
        setLocations(newLocations);
        setSelectedLocation(newSelectedLocations);
    }

    const removeAllRoles = () => {
        setRoles(
            roles.map((role) => {
                const tempRole = role;
                if (selectedRoles.find((selected) => selected._id === role._id)) {
                    tempRole.isChecked = false;
                    selectedRoles.splice(selectedRoles.findIndex((selected) => selected._id === role._id), 1);
                }
                return tempRole;
            })
        );
        setselectedRoles([]);
    }

    const removeAllLocations = () => {
        setLocations(
            locations.map((location) => {
                const tempLocation = location;
                if (selectedLocation.find((selected) => selected._id === location._id)) {
                    tempLocation.isChecked = false;
                    selectedLocation.splice(selectedLocation.findIndex((selected) => selected._id === location._id), 1);
                }
                return tempLocation;
            })
        );
        setSelectedLocation([]);
    }

    const onClearAll = () => {
        removeAllRoles();
        removeAllLocations();
        setfilters(null);
        clearAll();
    };

    function filterDetailsByOption() {
        switch (option) {
            case 'role':

                return (<div>
                    <Row>
                        <Col md="3" xs="6">
                            <label>Role</label>
                        </Col>
                        <Col md="9" xs="6">
                            <a
                                className="txt-primary float-right"
                                role="button"
                                onClick={removeAllRoles}
                                onKeyDown={() => { }}
                                tabIndex={-1}
                            >
                                Deselect All
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        {
                            roles.map((role, index) => {
                                return (
                                    <Col md="4" xs="12">
                                        <Form.Group controlId="formBasicCheckbox">
                                            <input
                                                type="checkbox"
                                                className="ftc-checkbox"
                                                id={role._id}
                                                checked={role.isChecked}
                                                onChange={(e) => {
                                                    onRoleChange(e, role, index);
                                                }}
                                            />
                                            <label
                                                className="position-relative ml-4 pl-2"
                                                htmlFor={role._id}
                                            >
                                                {role.rolename}
                                            </label>
                                        </Form.Group>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
                );

            case 'location':
                return (<div>
                    <Row>
                        <Col md="3" xs="6">
                            <label>Location</label>
                        </Col>
                        <Col md="9" xs="6">
                            <a
                                className="txt-primary float-right"
                                role="button"
                                onClick={removeAllLocations}
                                onKeyDown={() => { }}
                                tabIndex={-1}
                            >
                                Deselect All
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        {
                            locations.map((location, index) => {
                                return (
                                    <Col md="4" xs="12">
                                        <Form.Group controlId="formBasicCheckbox">
                                            <input
                                                type="checkbox"
                                                className="ftc-checkbox"
                                                id={location._id}
                                                checked={location.isChecked}
                                                onChange={(e) => {
                                                    onLocationChange(e, location, index);
                                                }}
                                            />
                                            <label
                                                className="position-relative ml-4 pl-2"
                                                htmlFor={location._id}
                                            >
                                                {location.label}
                                            </label>
                                        </Form.Group>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>);

            default:
                break;
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="modal__row">
                    <Desktop>
                        <Col md="3" className="modal__Col_3_Padding border__right sticky-top">
                            <ul className="modal__ul">
                                <li
                                    className={`${option === 'location' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('location')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Location</span>{' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />{' '}
                                </li>
                                <li
                                    className={`${option === 'role' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('role')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Role</span>{' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />
                                </li>
                            </ul>
                        </Col>
                    </Desktop>
                    <Mobile>
                        <Col xs="4" className="modal__Col_3_Padding border__right">
                            <ul className="modal__ul">
                                <li
                                    className={`${option === 'location' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('location')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Location</span>{' '}
                                </li>
                                <li
                                    className={`${option === 'role' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('role')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">Role</span>{' '}
                                </li>
                            </ul>
                        </Col>
                    </Mobile>
                    <Col md="9" xs="8">
                        {filterDetailsByOption()}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    onClick={() => {
                        onClearAll();
                    }}
                >
                    Clear All
                </Button>
                <Button
                    variant="default"
                    className="btn-ftc-border-primary"
                    onClick={() => {
                        applyChanges(filters);
                    }}
                >
                    Apply Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default CustomPageFilters

CustomPageFilters.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    applyChanges: PropTypes.func.isRequired,
    allFilters: PropTypes.any.isRequired,
    clearAll: PropTypes.func.isRequired,
};