import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const getAllHoursCategories = () =>
{
    return API.get(API_ENDPOINTS.HOURS_CATEGORIES.HoursCategories);
};

const createHoursCategory = () => { };

export
{
    getAllHoursCategories,
    createHoursCategory
}