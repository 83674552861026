/* eslint-disable no-unused-expressions */
import React, { memo, useEffect, useState, useRef } from 'react';
import { Link, useHistory, Prompt } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Row, Col, Image, Button, Form, ListGroup } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
// import * as Scroll from 'react-scroll';
import heic2any from 'heic2any';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Desktop, Mobile } from '../media-queries/mediaQueries';
import { useAuthContext } from '../../contexts/user-context';
import { getAllUsersByNames } from '../../services/users-service';
import { getPeriodsByYear } from '../../services/period-service';
import {
  DateConvert,
  minutesToDecimalHour,
} from '../../utils/TimesheetManagement';
import Toaster from '../../components/Toaster';
import {
  createReimbursement,
  imagePresignedurl,
  getReimbursementsByEmployeeId,
  getReimbursementsByPeriods,
} from '../../services/reimbursement-service';
import { generateHoursReport } from '../../services/hours-report.service';
import Loader from '../../assets/images/loader.svg';
import { getAllCitiesByName } from '../../services/city-service';
import StatesData from './states.json';
import { getReimbursementReasonByAccess } from '../../services/reimbursement_reason.services';
import ReimbursementReasons from '../../constants/reimbursement_reasons';

const animatedComponents = makeAnimated();

const fileValidationMsgs = {
  noReceiptAllowed:
    'Sorry, your eligibility for fuel reimbursement receipt upload has not been met.',

  limitReached:
    'You have reached the maximum limit for receipt uploads based on your current eligibility.',

  oneReceiptAllowed:
    'Only one fuel reimbursement receipt upload is allowed according to the current eligibility criteria.',

  twoReceiptsAllowed:
    'You can upload up to two fuel reimbursement receipts based on the current eligibility criteria.',
};

const ManageReimbursement = memo(() => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const { authUser } = useAuthContext();
  const defaultSelectedEmployee = {
    _id: authUser.user._id, // Assuming your user object has an _id field
    username: authUser.user.username, // Assuming your user object has a username field
    service_city: authUser.user.service_city,
    role: authUser.user.role,
  };

  // const { scroller } = Scroll;
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  const isFtc = process.env.REACT_APP_PROJECT_NAME;
  const [employees, setemployees] = useState([]);
  const [selectedEmployee, setselectedEmployee] = useState(
    defaultSelectedEmployee
  );
  const [selectedReasons, setSelectedReasons] = useState(null);
  const [showDiningBenefits, setShowDiningBenefits] = useState(false);
  const [showRemainingLimit, setShowRemainingLimit] = useState(false);
  const [remainingLimit, setRemainingLimit] = useState(0);
  const [isFileSubmitted, setIsFileSubmitted] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [files, setFiles] = useState([]);
  const [isSubmitSuccessful, setisSubmitSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkingUploadEligibility, setCheckingUploadEligibility] =
    useState(false);
  const [reimbursementReason, setReimbursementReason] = useState([]);
  const [totalFiles, setTotalFiles] = useState();
  const [benefitHours, setBenefitHours] = useState();
  const [cities, setCities] = useState([]);
  const [selectedCity, setselectedCity] = useState(null);
  const [selectedState, setselectedState] = useState(null);
  // const [totalAmount, setTotalAmount] = useState('');
  const [comment, setComment] = useState('');
  const [checkFuelValidation, setCheckFuelValidation] = useState(false);

  async function fetchAllCities() {
    try {
      const response = await trackPromise(getAllCitiesByName());
      setCities(response.data.data.cities);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const validationSchema = yup.object().shape({
    employee: yup.mixed().required('Employee is required.'),
    reason: yup.mixed().required('Reason is required.'),
    period: yup.mixed().required('Period is required.'),

    total_amount: yup
      .number()
      .min(0, 'Amount can not be negative!')
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      }),
  });

  if (!isSafari) {
    validationSchema.fields.total_amount =
      validationSchema.fields.total_amount.required('Amount is required.');
  }

  const conditionalSchema = yup.object().shape({
    name_of_establishment: yup
      .string()
      .required('Name of Establishment is required.'),
    city: yup.mixed().required('City is required.'),
    state: yup.mixed().required('State is required.'),
    experience: yup.string().required('Experience is required.'),
  });

  useEffect(() => {
    setFiles([]);
  }, [selectedReasons]);

  useEffect(() => {
    fetchAllCities();
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, dirtyFields },
    reset,
    trigger,
    watch,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(
      showDiningBenefits && isFtc
        ? validationSchema.concat(conditionalSchema)
        : validationSchema
    ),
    shouldUnregister: false,
    mode: 'onChange',
    shouldFocusError: true,
  });
  const form = watch();

  // const onMenuOpen = (elementName) => {
  //   scroller.scrollTo(elementName, {
  //     duration: 1500,
  //     delay: 100,
  //     smooth: true,
  //     offset: 50, // Scrolls to element + 50 pixels down the page
  //   });
  // };

  const onBlurCity = () => {
    if (!selectedCity) {
      trigger('service_city');
    }
  };
  const onBlurState = () => {
    if (!selectedState) {
      trigger('states');
    }
  };

  const onBlurEmployee = () => {
    if (!selectedEmployee) {
      trigger('employee');
    }
  };

  const onBlurReasons = () => {
    if (!selectedReasons) {
      trigger('reason');
    }
  };

  const onBlurPeriod = () => {
    if (!selectedPeriod) {
      trigger('period');
    }
  };

  // const onBlurTotalAmount = () => {
  //   if (!totalAmount) {
  //     trigger('total_amount');
  //   }
  // };

  /* this code is for file upload */
  const checkDisabled =
    authUser.user.role.rolename === 'Admin' ||
    authUser.user.role.rolename === 'Manager';

  const fileInputRef = useRef(null);

  const removeFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  useEffect(() => {
    setFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }, [selectedReasons]);

  const handleKeyPress = (event, callback) => {
    if (event.key === 'Enter') {
      callback();
    }
  };

  // This function modifies the filename to include the upload path and ensures the extension is correct
  const generateFileNameWithPath = (originalName, isHeic) => {
    const basePath = process.env.REACT_APP_S3_RECEIPT_UPLOAD_PATH; // Assuming this is defined in your .env file
    const uuid = uuidv4();
    let extension = originalName.split('.').pop();

    if (isHeic) {
      extension = 'jpg'; // Convert .heic files to .jpg
    }

    return `${basePath}/${uuid}.${extension}`;
  };

  const uploadFile = async (file) => {
    if (!file) return null;

    try {
      let uploadBlob = file; // This will hold the file or blob to be uploaded
      const isHeic = file.type === 'image/heic';
      const uniqueFileNameWithPath = generateFileNameWithPath(
        file.name,
        isHeic
      );

      if (isHeic) {
        // Convert the HEIC file to JPG format using heic2any
        const blob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
        });

        // Replace the original file with the converted blob, maintaining the unique name and path
        uploadBlob = new Blob([blob], { type: 'image/jpeg' });
      }

      // Get presigned URL for uploading the file
      const response = await trackPromise(
        imagePresignedurl(
          JSON.stringify({
            fileName: uniqueFileNameWithPath?.split('/').pop(), // Send the unique path and filename to the server
            fileType: uploadBlob.type,
          })
        )
      );

      if (response.status === 201) {
        toast.dark(<Toaster icon="notify" message={response.data.message} />);
      }

      const { presignedUrl } = response.data;

      // Perform the upload
      await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': uploadBlob.type,
        },
        body: uploadBlob,
      });

      return uniqueFileNameWithPath;
    } catch (error) {
      console.error('Error uploading file:', file.name, error);
      return null; // Optionally, return an error message for UI display
    }
  };
  /* file upload end */

  const getAllBenefit = async (rbody) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const totalBenefitHours = [];
      const reportPromises = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const cityId of rbody?.service_city) {
        const cityBody = {
          employee: rbody.employee,
          service_city: cityId,
          startDate: rbody.startDate,
          endDate: rbody.endDate,
        };

        const report = generateHoursReport(cityBody).then((res) => {
          totalBenefitHours.push(
            res.data.data.report?.benefitHours[0]?.totalTimeTaken
          );
        });

        reportPromises.push(report);
      }

      await Promise.all(reportPromises);

      return totalBenefitHours.reduce((total, current) => total + current, 0);
    } catch (error) {
      throw error;
    }
  };

  const checkBenefitable = async (employee, period) => {
    const serviceCityIds = employee?.service_city.map((city) => city._id);

    const rbody = {
      employee: employee?._id,
      service_city: serviceCityIds, // Pass the array of service_city _ids
      startDate: period?.start_date,
      endDate: period?.end_date,
    };
    const totalBenefitMinutes = await getAllBenefit(rbody);
    const totalBenefitHours = minutesToDecimalHour(totalBenefitMinutes);
    setBenefitHours(totalBenefitHours);

    return totalBenefitHours;
  };

  async function getUserReimbursementsByPeriods(
    employeeId,
    reasonID,
    startDate,
    endDate
  ) {
    try {
      const response = await trackPromise(
        getReimbursementsByPeriods(employeeId, reasonID, startDate, endDate)
      );
      const result = response?.data.data.no_Of_Reimbursements;
      return result;
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  // HERE
  const displayErrorMsg = (message) => {
    toast.dark(<Toaster icon="error" message={message} />, { autoClose: 7000 });
  };

  const validateFuelReceipts = (
    hours,
    submittedReceipts,
    currentUploads,
    addedUploads
  ) => {
    if (hours < 64) {
      displayErrorMsg(fileValidationMsgs.noReceiptAllowed);
      return false;
    }

    if (hours >= 64 && hours <= 119.99) {
      if (submittedReceipts >= 1) {
        displayErrorMsg(fileValidationMsgs.limitReached);
        return false;
      }

      if (currentUploads >= 1 || addedUploads > 1) {
        displayErrorMsg(fileValidationMsgs.oneReceiptAllowed);
        return false;
      }
    }

    if (hours >= 120) {
      if (submittedReceipts >= 2) {
        displayErrorMsg(fileValidationMsgs.limitReached);
        return false;
      }

      if (submittedReceipts === 1) {
        if (currentUploads + addedUploads > 1) {
          displayErrorMsg(fileValidationMsgs.oneReceiptAllowed);
          return false;
        }
      }

      if (submittedReceipts === 0) {
        if (currentUploads + addedUploads > 2) {
          displayErrorMsg(fileValidationMsgs.twoReceiptsAllowed);
          return false;
        }
      }
    }
    return true;
  };

  const onFilesChange = async (event) => {
    const uploadedFiles = Array.from(event.target.files);

    if (checkFuelValidation) {
      if (
        validateFuelReceipts(
          benefitHours,
          totalFiles,
          files.length,
          uploadedFiles.length
        )
      ) {
        setIsFileSubmitted(true);
        setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
      }
    } else {
      setIsFileSubmitted(true);
      setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
    }
  };

  useEffect(async () => {
    if (selectedEmployee && selectedReasons && selectedPeriod) {
      const employeeID = selectedEmployee?._id;
      const reasonID = selectedReasons?._id;
      const startDate = selectedPeriod?.start_date;
      const endDate = selectedPeriod?.end_date;

      const existingReceipts = await getUserReimbursementsByPeriods(
        employeeID,
        reasonID,
        startDate,
        endDate
      );
      setTotalFiles(existingReceipts);

      if (
        selectedReasons._id === ReimbursementReasons[2]._id &&
        isFtc &&
        selectedEmployee?.role.rolename === 'Chef'
      ) {
        setCheckFuelValidation(true);
        setCheckingUploadEligibility(true);
        checkBenefitable(selectedEmployee, selectedPeriod).finally(() =>
          setCheckingUploadEligibility(false)
        );
      } else {
        setCheckFuelValidation(false);
      }
    }
  }, [selectedEmployee, selectedReasons, selectedPeriod]);

  const triggerFileUpload = async () => {
    if (selectedReasons === null) {
      displayErrorMsg('Please select a reason.');
      return false;
    }
    if (selectedPeriod === null) {
      displayErrorMsg('Please select a period.');
      return false;
    }

    fileInputRef.current.click();
  };

  // const onChangeTotalAmount = (e) => {
  //   setTotalAmount(e.target.value);
  //   setValue('total_amount', e.target.value, {
  //     shouldDirty: true,
  //     shouldValidate: true,
  //   });
  // };

  const onSubmit = async (data) => {
    if (selectedReasons._id === ReimbursementReasons[1]._id && isFtc) {
      if (data.total_amount > remainingLimit) {
        toast.dark(<Toaster icon="error" message="Remaining limit exceeded" />);
        return;
      }
    }

    setIsLoading(true);

    if (files.length === 0) {
      toast.dark(
        <Toaster icon="error" message="Please upload at least one file" />
      );
      setIsLoading(false);
      return;
    }

    const fileNames = await Promise.all(files.map((file) => uploadFile(file)));

    const reimbursementBody = form;
    reimbursementBody.fileNames = fileNames;
    reimbursementBody.employee = reimbursementBody.employee._id;
    reimbursementBody.period = reimbursementBody.period._id;
    reimbursementBody.total_amount = data.total_amount;

    if (data.reason._id === ReimbursementReasons[0]._id && isFtc) {
      reimbursementBody.state = selectedState.label;
      reimbursementBody.city = selectedCity._id;
      reimbursementBody.name_of_establishment = data.name_of_establishment;
      reimbursementBody.experience = data.experience;
    } else {
      reimbursementBody.state = 'N/A';
      reimbursementBody.name_of_establishment = 'N/A';
      reimbursementBody.experience = 'N/A';
    }

    reimbursementBody.reason = reimbursementBody.reason._id;
    reimbursementBody.reimbursement_date = new Date();
    if (selectedReasons._id === ReimbursementReasons[2]._id && isFtc) {
      if (isFileSubmitted === false) {
        setIsLoading(false);
        return;
      }
    }
    const response = await trackPromise(createReimbursement(reimbursementBody));
    if (response.status === 201) {
      toast.dark(<Toaster icon="notify" message={response.data.message} />);
      setisSubmitSuccessful(true);
      history.push(
        `/viewReimbursement/${response.data.data.reimbursement.reimbursementId}`
      );
    }
    setIsLoading(true);
  };

  async function getAllEmployees() {
    try {
      const response = await trackPromise(getAllUsersByNames());
      setemployees(response.data.data.users);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function fetchAllEmpolyees() {
    try {
      switch (authUser.user.role.rolename) {
        case 'Admin':
        case 'Manager':
          getAllEmployees();
          break;

        case 'Chef':
        case 'Office':
          setemployees([authUser.user]);
          setselectedEmployee(authUser.user);
          break;

        case 'Accountant':
          setemployees([authUser.user]);
          break;

        default:
          break;
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function fetchAllPeriods() {
    const year = new Date().getFullYear();
    try {
      const response = await trackPromise(getPeriodsByYear(year, new Date()));
      let availablePeriods = response.data.data.periods;
      const totalPeriods = availablePeriods.length;

      const periodStartDate = availablePeriods[0].start_date;
      // const nextDate = moment(periodStartDate).add(1, 'days').format('YYYY-MM-DD');

      const endOfWeek = moment(periodStartDate)
        .utc()
        .endOf('week')
        .utcOffset(0)
        .format('YYYY-MM-DD');
      const endOfWeekPeriod = moment(endOfWeek)
        .add(4, 'days') // Add 3 days to go from Monday to Wednesday(12 pm)
        .format('YYYY-MM-DD');

      const currentDate = moment();
      const nextDay = currentDate.add(1, 'days'); // Add 1 day
      const nextDayDate = nextDay.format('YYYY-MM-DD');

      // Check if currentDate is within the periodStartDate and endOfWeekPeriod range
      const isWithinRange = moment(nextDayDate).isBetween(
        periodStartDate,
        endOfWeekPeriod,
        null,
        '[]'
      );
      // Assuming authUser has a "role" property that can be "admin", "manager", "chef", etc.
      if (!['Admin', 'Manager'].includes(authUser.user.role.rolename)) {
        if (isWithinRange) {
          // If the user is not an admin or manager and the currentDate is within the range,
          // show both the most recent and second most recent periods.
          availablePeriods = availablePeriods.slice(0, 2);
        } else {
          // For other dates or when the currentDate is outside the range, show only the most recent period.
          availablePeriods = [availablePeriods[0]];
        }
      }

      setPeriods(
        availablePeriods.map((period, index) => {
          const tempPeriod = period;
          tempPeriod.label = `Period ${totalPeriods - index} (${DateConvert(
            period.start_date
          )} - ${DateConvert(period.end_date)})`;
          tempPeriod.value = period.period_index;
          return tempPeriod;
        })
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  // eslint-disable-next-line no-unused-vars
  async function fetchAllReimbursementReasons() {
    try {
      const role = selectedEmployee.role._id;
      const response = await trackPromise(getReimbursementReasonByAccess(role));
      setReimbursementReason(response.data.data.reimbursement_reasons);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const limitDecimalPlaces = (e, count) => {
    if (e.target.value.indexOf('.') === -1) {
      return;
    }
    if (e.target.value.length - e.target.value.indexOf('.') > count) {
      e.target.value = parseFloat(e.target.value).toFixed(count);
    }
  };

  useEffect(() => {
    fetchAllEmpolyees();
    fetchAllPeriods();
    // fetchAllReimbursementReasons();
  }, []);

  useEffect(() => {
    if (selectedReasons) {
      setValue('reason', selectedReasons, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedReasons]);

  useEffect(() => {
    if (selectedEmployee) {
      setValue('employee', selectedEmployee, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    fetchAllReimbursementReasons();
  }, [selectedEmployee]);

  useEffect(() => {
    if (selectedCity) {
      setValue('city', selectedCity, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedState) {
      setValue('state', selectedState, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedPeriod) {
      setValue('period', selectedPeriod, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedPeriod]);

  async function fetchAllReimbursementByEmployeeId(employeeId, reasonID) {
    try {
      const response = await trackPromise(
        getReimbursementsByEmployeeId(employeeId, reasonID)
      );
      setRemainingLimit(response?.data?.data?.remainingEquipmentLimit);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const userValidation = (reasonId) => {
    if (selectedEmployee.service_city[0].status === 'active') {
      fetchAllReimbursementByEmployeeId(selectedEmployee._id, reasonId);
    }
  };

  useEffect(() => {
    fetchAllEmpolyees();
    fetchAllPeriods();
    if (selectedReasons?._id === ReimbursementReasons[1]._id) {
      userValidation(selectedReasons._id);
    }
  }, [selectedEmployee, selectedReasons]);

  const resetCommonFields = () => {
    setSelectedPeriod(null);
    setValue('period', null);
    setSelectedReasons(null);
    // setTotalAmount('');
    setValue('total_amount', '');
    setComment('');
    setValue('comments', null);
    setFiles([]);
    setselectedCity(null);
    setselectedState(null);
    setShowDiningBenefits(false);
    setShowRemainingLimit(false);
  };

  const handleReset = () => {
    setselectedEmployee(defaultSelectedEmployee);
    resetCommonFields();
    reset();
  };

  // Reset all fields except employee when employee change
  const handleEmployeeChange = (previousEmployee, currentEmployee) => {
    if (previousEmployee._id !== currentEmployee._id) {
      resetCommonFields();
      reset();
    }
  };

  // Reset all fields except employee and reason when reason change
  const handleReasonChange = (previousReason, currentReason) => {
    if (previousReason === null) {
      return;
    }
    if (previousReason._id !== currentReason._id) {
      if (!checkDisabled) {
        setselectedEmployee(defaultSelectedEmployee);
        resetCommonFields();
        return;
      }
      setselectedEmployee(selectedEmployee);
      resetCommonFields();
    }
  };

  return (
    <div>
      <div className="sticky-page-header">
        <div className="container">
          <Desktop>
            <Row className="ml-5">
              <Col md="1">
                <Link to="/reimbursement">
                  <a onKeyPress={() => {}} role="link" tabIndex="0">
                    <Image
                      className="imageArrow"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </a>
                </Link>
              </Col>
              <Col md="8" classname="pl-0">
                <h5 className="mt-1">Reimbursement</h5>
              </Col>
              <Col md="1" className="pr-0 ml-5">
                <Button
                  variant="default"
                  onClick={handleReset}
                  className="float-right btn-ftc-border-primary"
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Desktop>
          <Mobile>
            <div className="container">
              <Row className="pb-0">
                <Col xs="1" className="p-0">
                  <Link to="/reimbursement">
                    <Image
                      className="imageArrowMobile mt-1"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </Link>
                </Col>
                <Col xs="8" className="mt-1">
                  <h6 className="m-auto pb-3">
                    <strong>Reimbursement</strong>{' '}
                  </h6>
                </Col>
                <Col xs="1">
                  <Image
                    onClick={handleReset}
                    className="ftc-logo-24  mt-2"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-reset.svg`}
                  />
                </Col>
              </Row>
            </div>
          </Mobile>
        </div>
      </div>
      <Desktop>
        <div className="container-top-8" />
      </Desktop>
      <Mobile>
        <div className="container-top-20" />
      </Mobile>
      <div className="container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Row>
              <Col md="8" className="mx-auto">
                <div>
                  <Row>
                    <Col md="12">
                      <h5>
                        <strong>Add Reimbursement</strong>
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="pb-2">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="txt-light-secondary">
                          Employee Name <span className="txt-primary">*</span>
                        </Form.Label>
                        <Controller
                          render={({ field: { name } }) => (
                            <Select
                              // defaultMenuIsOpen
                              openMenuOnFocus={false}
                              id="employeeName"
                              styles={{
                                menu: (styles) => ({
                                  ...styles,
                                  zIndex: 999,
                                }),
                              }}
                              name={name}
                              isMulti={false}
                              closeMenuOnSelect
                              components={animatedComponents}
                              options={employees}
                              getOptionLabel={(option) => option.username}
                              getOptionValue={(option) => option._id}
                              value={selectedEmployee}
                              // defaultValue={selectedEmployee}
                              onChange={(option) => {
                                handleEmployeeChange(selectedEmployee, option);
                                setselectedEmployee(option);
                              }}
                              onBlur={() => {
                                onBlurEmployee();
                              }}
                              onMenuOpen={() => {
                                // onMenuOpen('employeeName');
                              }}
                              menuShouldScrollIntoView
                              isDisabled={!checkDisabled}
                            />
                          )}
                          name="employee"
                          control={control}
                        />
                        {errors.employee && (
                          <p className="text-danger">
                            {errors.employee.message}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md="6" className="pb-2">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="txt-light-secondary">
                          Select Reason <span className="txt-primary">*</span>
                        </Form.Label>
                        <Controller
                          render={({ field: { name } }) => (
                            <Select
                              // defaultMenuIsOpen
                              openMenuOnFocus={false}
                              id="reasonName"
                              styles={{
                                menu: (styles) => ({
                                  ...styles,
                                  zIndex: 999,
                                }),
                              }}
                              name={name}
                              isMulti={false}
                              closeMenuOnSelect
                              components={animatedComponents}
                              // options={reasonData}
                              options={reimbursementReason}
                              getOptionLabel={(option) => option.reason}
                              getOptionValue={(option) => option._id}
                              value={selectedReasons}
                              onChange={(option) => {
                                handleReasonChange(selectedReasons, option);
                                setSelectedReasons(option);
                                if (
                                  option._id === ReimbursementReasons[0]._id &&
                                  isFtc
                                ) {
                                  setShowDiningBenefits(true);
                                  setShowRemainingLimit(false);
                                } else if (
                                  option._id === ReimbursementReasons[1]._id
                                ) {
                                  setShowDiningBenefits(false);
                                  setShowRemainingLimit(true);
                                  userValidation(option._id);
                                } else {
                                  setShowDiningBenefits(false);
                                  setShowRemainingLimit(false);
                                }
                              }}
                              onBlur={() => {
                                onBlurReasons();
                              }}
                              onMenuOpen={() => {
                                // onMenuOpen('reasonName');
                              }}
                              menuShouldScrollIntoView
                            />
                          )}
                          name="reason"
                          control={control}
                        />
                        {errors.reason && (
                          <p className="text-danger">{errors.reason.message}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md="6" className="pb-2">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="txt-light-secondary">
                          Select Period <span className="txt-primary">*</span>
                        </Form.Label>
                        <Controller
                          render={({ field: { name } }) => (
                            <Select
                              // defaultMenuIsOpen
                              openMenuOnFocus={false}
                              id="period"
                              styles={{
                                menu: (styles) => ({
                                  ...styles,
                                  zIndex: 999,
                                }),
                              }}
                              name={name}
                              isMulti={false}
                              closeMenuOnSelect
                              components={animatedComponents}
                              options={periods}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              value={selectedPeriod}
                              onChange={(option) => {
                                setSelectedPeriod(option);
                              }}
                              onBlur={() => {
                                onBlurPeriod();
                              }}
                              onMenuOpen={() => {
                                // onMenuOpen('period');
                              }}
                              menuShouldScrollIntoView
                            />
                          )}
                          name="period"
                          control={control}
                        />
                        {errors.period && (
                          <p className="text-danger">{errors.period.message}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md="6" xs="12" className="b-5">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="txt-light-secondary">
                          Enter Total Amount
                          <span className="txt-primary">*</span>
                        </Form.Label>
                        <div style={{ position: 'relative', width: '100%' }}>
                          {showRemainingLimit && isFtc ? (
                            <span
                              style={{
                                position: 'absolute',
                                right: '5px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                zIndex: 50,
                                color: '#A50907',
                                background: '#fff4f4',
                                padding: '2px 10px 2px 10px',
                                borderRadius: '50px',
                              }}
                            >
                              {isFtc && <>Remaining limit: ${remainingLimit}</>}
                            </span>
                          ) : (
                            <></>
                          )}
                          <Form.Control
                            id="total_amount"
                            name="total_amount"
                            {...register('total_amount')}
                            type="number"
                            step="0.01"
                            placeholder="E.g.: $12.25"
                            min="0"
                            onInput={(e) => limitDecimalPlaces(e, 2)}
                            onKeyPress={(e) => {
                              if (e.key === 'e' || e.key === 'E') {
                                e.preventDefault();
                              }
                            }}
                            required={isSafari}
                          />
                        </div>
                        {errors.total_amount && (
                          <p className="text-danger">
                            {errors.total_amount.message}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                    {showDiningBenefits && isFtc ? (
                      <>
                        <Col md="12" className="pl-3">
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="txt-light-secondary">
                              Name of Establishment Where Benefits will be
                              Redeemed<span className="txt-primary">*</span>
                            </Form.Label>
                            <Form.Control
                              {...register('name_of_establishment')}
                              type="text"
                              placeholder=""
                              rows={4}
                            />
                            {errors.name_of_establishment && (
                              <p className="text-danger">
                                {errors.name_of_establishment.message}
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="12" className="pl-3 mt-14 pt-3 pb-0 mb-0">
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="txt-light-secondary">
                              Location of Establishment Where Benefits will be
                              Redeemed
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md="6" className="pb-2 mt-0 pt-0">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="txt-light-secondary">
                              City <span className="txt-primary">*</span>
                            </Form.Label>
                            <Controller
                              render={({ field: { name } }) => (
                                <Select
                                  // defaultMenuIsOpen
                                  openMenuOnFocus={false}
                                  id="cityName"
                                  styles={{
                                    menu: (styles) => ({
                                      ...styles,
                                      zIndex: 999,
                                    }),
                                  }}
                                  name={name}
                                  isMulti={false}
                                  closeMenuOnSelect
                                  components={animatedComponents}
                                  options={cities}
                                  getOptionLabel={(option) => option.cityname}
                                  getOptionValue={(option) => option._id}
                                  value={selectedCity}
                                  onChange={(option) => {
                                    setselectedCity(option);
                                  }}
                                  onBlur={() => {
                                    onBlurCity();
                                  }}
                                  onMenuOpen={() => {
                                    // onMenuOpen('cityName');
                                  }}
                                  menuShouldScrollIntoView
                                />
                              )}
                              name="service_city"
                              control={control}
                            />
                            {errors.city && (
                              <p className="text-danger">
                                {errors.city.message}
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="6" className="pb-2">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="txt-light-secondary">
                              State <span className="txt-primary">*</span>
                            </Form.Label>
                            <Controller
                              render={({ field: { name } }) => (
                                <Select
                                  // defaultMenuIsOpen
                                  openMenuOnFocus={false}
                                  id="stateName"
                                  styles={{
                                    menu: (styles) => ({
                                      ...styles,
                                      zIndex: 999,
                                    }),
                                  }}
                                  name={name}
                                  isMulti={false}
                                  closeMenuOnSelect
                                  components={animatedComponents}
                                  options={StatesData}
                                  getOptionLabel={(option) => option.label}
                                  getOptionValue={(option) => option.value}
                                  value={selectedState}
                                  onChange={(option) => {
                                    setselectedState(option);
                                  }}
                                  onBlur={() => {
                                    onBlurState();
                                  }}
                                  onMenuOpen={() => {
                                    // onMenuOpen('stateName');
                                  }}
                                  menuShouldScrollIntoView
                                />
                              )}
                              name="stateName"
                              control={control}
                            />
                            {errors.state && (
                              <p className="text-danger">
                                {errors.state.message}
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="12" className="pl-3">
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="txt-light-secondary">
                              What did you learn from this dining experience?
                              <span className="txt-primary">*</span>
                            </Form.Label>
                            <Form.Control
                              {...register('experience')}
                              as="textarea"
                              placeholder="Experience.."
                              rows={4}
                            />
                            <Form.Label className="txt-light-secondary mt-2">
                              Example: New cuisines, new presentation, new
                              techniques, etc.
                            </Form.Label>
                            {errors.experience && (
                              <p className="text-danger">
                                {errors.experience.message}
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                    <Col md="12" className="pl-3">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label className="txt-light-secondary">
                          Write Your Comment
                        </Form.Label>
                        <Form.Control
                          {...register('comments')}
                          as="textarea"
                          placeholder="Comment.."
                          rows={4}
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                        />
                        {errors.comments && (
                          <p className="text-danger">
                            {errors.comments.message}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="pl-3">
                      <Form.Label className="txt-light-secondary">
                        Upload Receipts <span className="txt-primary">*</span>
                      </Form.Label>

                      <Row className="receipts-box pl-3 col-md-12">
                        <ListGroup horizontal className="flex-wrap gx-10">
                          {files.map((file, index) => (
                            <ListGroup.Item
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              className="file-entry"
                            >
                              {file.name}
                              <Button
                                variant="link"
                                className="remove-icon"
                                onClick={() => removeFile(index)}
                                onKeyPress={(e) =>
                                  handleKeyPress(e, () => removeFile(index))
                                }
                              >
                                X
                              </Button>
                            </ListGroup.Item>
                          ))}

                          {checkingUploadEligibility ? (
                            'Checking eligibility...'
                          ) : (
                            <>
                              <Button
                                variant="link"
                                className="plus-icon"
                                onClick={triggerFileUpload}
                                onKeyPress={handleKeyPress}
                              >
                                +
                              </Button>
                              <input
                                type="file"
                                accept=".pdf, .heic, .jpg, .jpeg, .png"
                                multiple
                                onChange={onFilesChange}
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                              />
                            </>
                          )}
                        </ListGroup>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="border-bottom" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <button
                        type="submit"
                        // type="button"
                        // onClick={handleSubmit(onSubmit)}
                        // onClick={handleSubmit}
                        disabled={isSubmitting}
                        className="btn btn-ftc-primary float-right"
                      >
                        {isLoading ? (
                          <Image src={Loader} height="35px" width="35px" />
                        ) : (
                          'Submit'
                        )}
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <Prompt
        when={isSubmitSuccessful ? false : Object.keys(dirtyFields).length}
        message="You have unsaved changes, are you sure you want to leave?"
      />
    </div>
  );
});

export default ManageReimbursement;
