import React,{useEffect,useState} from 'react'
import {
    Button,
    Row,
    Col,
    Image,
    Form,
    NavDropdown
  } from 'react-bootstrap';
import { Link, Prompt, useHistory,useParams } from 'react-router-dom';
import { useForm, } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';
import { getAllRolesByNames } from '../../../services/role-service';
import { createReimbursementReason,getReimbursementReasonById, updateReimbursementReason, deleteReimbursementReasonById } from '../../../services/reimbursement_reason.services';
import Toaster from '../../../components/Toaster';
import Ellipses from '../../../components/Ellipses';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';

const ManageReimbursementSetup = () => {

    const [showPrompt, setshowPrompt] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setselectedRoles] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [rolesAPI, setrolesAPI] = useState(false);
    const [reimbursementReason,setReimbursementReason] = useState([]);
    const [isAPICalled, setIsAPICalled] = useState(false);

    const history = useHistory();
    const { reimbursementReasonId } = useParams();
    const isAddMode = !reimbursementReasonId;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const validationSchema = yup.object({
        reason: yup.string().required('Reason is required.'),
        // access: yup.array().min(1, 'Please select at least one access Level').required('Access Level is required').nullable()
    });

    const { handleSubmit, register,
        formState: { errors,isValid, isSubmitted, isSubmitting, dirtyFields },
        setValue, watch, reset } =
        useForm({
            resolver: yupResolver(validationSchema),
            mode: 'onChange',
        });

    const form = watch();

    async function fetchAllRoles()
    {
        try
        {
            const response = await trackPromise(getAllRolesByNames());            
            setRoles(
                response.data.data.roles.map((role) =>
                {
                    const tempRole = role;
                    // if (nonBillable?.access?.length > 0)
                    // {
                    //     if (nonBillable.access.find((selected) => selected._id === role._id))
                    //     {
                    //         tempRole.isChecked = true;
                    //     }
                    // }
                    // else
                    // {
                    tempRole.isChecked = false;
                    // }
                    return tempRole;

                })
            );
            setrolesAPI(true);
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    async function getReimbursementReasonDetails()
    {
        try
        {
            const response = await trackPromise(getReimbursementReasonById(reimbursementReasonId));
            setReimbursementReason(response.data.data.reimbursement_reason);
            reset(response.data.data.reimbursement_reason);
            setIsAPICalled(true);
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    const onDeleteReimubrsementReason = async () => {
        try {
            const response = await trackPromise(
                deleteReimbursementReasonById(reimbursementReasonId)
            );
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
            history.replace('/settings/reimbursement-setup');
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally {
            handleClose();
        }
    };

    useEffect(() =>
    {
        if (reimbursementReason && rolesAPI)
        {
            setRoles(
                roles.map((role) =>
                {
                    const tempRole = role;
                    if (reimbursementReason?.access?.length > 0)
                    {
                        if (reimbursementReason.access.find((selected) => selected._id === role._id))
                        {
                            tempRole.isChecked = true;
                            selectedRoles.push(role);
                        }
                    }
                    else
                    {
                        tempRole.isChecked = false;
                    }
                    return tempRole;
                })
            );
            setselectedRoles(selectedRoles);
        }
    }, [reimbursementReason, rolesAPI]);

    useEffect(() =>
    {
        (async () =>
        {
            await fetchAllRoles();
        })();
    }, []);

    useEffect(() =>
    {
        if (Object.keys(dirtyFields).length)
        {
            setshowPrompt(true);
        }
        else
        {
            setshowPrompt(false);
        }
    }, [form, errors]);

    useEffect(() =>
    {
        if (!isAddMode)
        {
            getReimbursementReasonDetails();
        }
        else
        {
            setIsAPICalled(true);
        }
    }, [isAddMode]);

    useEffect(() =>
    {
        if (selectedRoles.length)
        {
            setValue('access', selectedRoles, { shouldDirty: true, shouldValidate: true });
        }
        else
        {
            setValue('access', null, { shouldDirty: false, shouldValidate: true });
        }
    }, [selectedRoles]);

    const onRoleChange = (e, role) =>
    {
        setRoles(
            roles.map((c) =>
            {
                const tempRole = c;
                if (c._id === role._id)
                {
                    if (e.target.checked && selectedRoles.indexOf(role) === -1)
                    {
                        tempRole.isChecked = true;
                        selectedRoles.push(role);
                    }
                    else
                    {
                        tempRole.isChecked = false;
                        selectedRoles.splice(selectedRoles.indexOf(role), 1);
                    }
                }
                return tempRole;
            })
        );
        setselectedRoles([].concat(selectedRoles));
    }

    const handleReset = () =>
    {
        reset({});
        setRoles(
            roles.map((role) =>
            {
                const tempRole = role;
                if (selectedRoles.find((selected) => selected._id === role._id))
                {
                    tempRole.isChecked = false;
                    selectedRoles.splice(selectedRoles.findIndex((selected) => selected._id === role._id), 1);
                }
                return tempRole;
            })
        );
        setselectedRoles([]);
    }

    const onSubmit = async (data) =>
    {
        try
        {
            if (isValid)
            {
                if (isAddMode)
                {
                    const response = await trackPromise(
                        createReimbursementReason(data)
                    );
                    if (response.status === 201)
                    {
                        toast.dark(<Toaster
                            icon="notify"
                            message={response.data.message}
                        />);
                        setshowPrompt(false);
                        history.push(`/settings/reimbursement-setup`);
                    }
                }
                else{
                    if (data.access === null) {
                        // eslint-disable-next-line no-param-reassign
                        data.access = [];
                      }
                    const response = await trackPromise(
                        updateReimbursementReason(reimbursementReasonId, data)
                    );
                    if (response.status === 200)
                    {
                        toast.dark(<Toaster
                            icon="notify"
                            message={response.data.message}
                        />);
                        setshowPrompt(false);
                        history.push(`/settings/reimbursement-setup`);
                    }
                }
                
            }
        }catch (error)
        {
            if (error.response?.data?.data?.error?.errors)
            {
                if (error.response?.data?.data?.error?.errors?.title)
                {
                    toast.dark(
                        <Toaster
                            icon="warning"
                            message={`${error.response.data.data.error.errors.title.message}`}
                        />,
                        { autoClose: 5000, hideProgressBar: true }
                    );
                }
            }
            else
            {
                toast.dark(
                    <Toaster
                        icon="error"
                        message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                    />
                );
            }
        }
    }

  return isAPICalled ? (
    <div>
        <div className="sticky-page-header">
                <div className="container">
                    <Desktop>
                        <Row className="ml-5">
                            <Col md="1">
                                <Link to="/settings/reimbursement-setup">
                                    <a
                                        onKeyPress={() => { }}
                                        role="link"
                                        tabIndex="0"
                                    // onClick={onHandleBack}
                                    >
                                        <Image
                                            className="imageArrow"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </a>
                                </Link>
                            </Col>
                            <Col md="8" classname="pl-0">
                            <h3>Reimbursement Setup</h3>
                            </Col>
                            <Col md="1" className="pr-0 ml-5">
                                <Button
                                    variant="default"
                                    onClick={handleReset}
                                    className="float-right btn-ftc-border-primary"
                                >
                                    Reset
                                </Button>
                            </Col>
                            {
                                !isAddMode ? (
                                    <Col md="1">
                                    <div className="ml-0">
                                        <NavDropdown title={<Ellipses />} id="collasible-nav-dropdown">
                                            <NavDropdown.Item
                                                onClick={handleShow}
                                                // disabled={!validatePermission(authUser.user.role.permissions, 'non_billable', 'delete')}
                                            >
                                                Delete</NavDropdown.Item>
                                        </NavDropdown>
                                    </div>
                                </Col>
                                ) : ('')
                            }
                        </Row>
                    </Desktop>
                    <Mobile>
                        <div className="container">
                            <Row className="pb-0">
                                <Col xs="1" className="p-0">
                                    <Link to="/settings/reimbursement-setup">
                                        <Image
                                            className="imageArrowMobile mt-1"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </Link>
                                </Col>
                                <Col xs="8" className="mt-1">
                                <h3>Reimbursement Setup</h3>
                                </Col>
                                <Col xs="1">
                                    <Image
                                        onClick={handleReset}
                                        className="ftc-logo-24  mt-2"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-reset.svg`}
                                    />
                                </Col>
                                {
                                    !isAddMode ? (
                                        <Col xs="1">
                                            <div>
                                                <NavDropdown title={<Ellipses />} id="collasible-nav-dropdown">
                                                    <NavDropdown.Item
                                                        onClick={handleShow}
                                                    >
                                                        Delete</NavDropdown.Item>
                                                </NavDropdown>
                                            </div>
                                        </Col>
                                    ) : ('')
                                }
                            </Row>
                        </div>
                    </Mobile>
                </div>
            </div>
            <Desktop>
                <div className="container-top-8" />
            </Desktop>
            <Mobile>
                <div className="container-top-20" />
            </Mobile>
            <div className="container">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <Row>
                            <Col md="8" className="mx-auto">
                                <div>
                                <Row >
                                    <Col md="12">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="txt-light-secondary">Reason <span className="txt-primary">*</span></Form.Label>
                                            <Form.Control
                                                {...register('reason')}
                                                type="text"
                                                placeholder=""
                                            />
                                            {errors.reason && (
                                                <p className="text-danger">
                                                    {errors.reason.message}
                                                </p>
                                            )}
                                        </Form.Group>

                                    </Col>
                                </Row>
                                {
                                        roles.length ? <Row>
                                            <Col md="12" xs="12">
                                                <Form.Label className="txt-light-secondary">Accessible by</Form.Label>
                                            </Col>
                                            {
                                                roles.map((role) =>
                                                {
                                                    return (
                                                        <Col md="3" xs="4" key={role._id}>
                                                            <Form.Group controlId="formBasicCheckbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="ftc-checkbox"
                                                                    id={role._id}
                                                                    checked={role.isChecked}
                                                                    onChange={(e) =>
                                                                    {
                                                                        onRoleChange(e, role);
                                                                    }}
                                                                />
                                                                <label data-content={roles.rolename} className="position-relative ml-4 pl-2" >{role.rolename}</label>
                                                            </Form.Group>
                                                        </Col>
                                                    );
                                                })
                                            }
                                            {(errors.access && isSubmitted) && (
                                                <Col md="12">
                                                    <p className="text-danger">
                                                        {errors.access.message}
                                                    </p>
                                                </Col>
                                            )}
                                        </Row> : ''
                                    }
                                    <Row>
                                        <Col>
                                            <div className="border-bottom" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <button type="submit" disabled={isSubmitting} className="btn btn-ftc-primary float-right ">
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>

            {show && <ConfirmAlertModal
                show={show}
                handleClose={handleClose}
                title='Delete Reimbursement Reason'
                description='Deleting this Reimbursement Reason will erase all data inside this record.
                Are you sure you want to delete this Reimbursement Reason?'
                callback={onDeleteReimubrsementReason}
                action='Delete it'
                closeAction='Cancel'
            />}

            <Prompt
                when={showPrompt}
                message='You have unsaved changes, are you sure you want to leave?'
            />
    </div>
  ) : ('')
}

export default ManageReimbursementSetup