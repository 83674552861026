import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const getAllNonBillablesName = () =>
{
  return API.get(`${API_ENDPOINTS.NON_BILLABLES.NonBillablesByName}`);
};

const getAllNonBillablesByAccess = (roleId) =>
{
  return API.get(`${API_ENDPOINTS.NON_BILLABLES.NonBillablesByName}/${roleId}`);
};

const getAllNonBillables = (pagination, page, reason) =>
{
  return API.get(`${API_ENDPOINTS.NON_BILLABLES.NonBillables}?pagination=${pagination}&page=${page}&reason=${reason}`);
};

const createNonBillable = (body) =>
{
    return API.post(`${API_ENDPOINTS.NON_BILLABLES.NonBillables}`, body);
}

const updateNonBillable = (nonBillId, body) =>
{
  return API.put(`${API_ENDPOINTS.NON_BILLABLES.NonBillables}/${nonBillId}`, body);
}

const getNonBillableById = (nonBillId) =>
{
  return API.get(`${API_ENDPOINTS.NON_BILLABLES.NonBillables}/${nonBillId}`);
};

const deleteNonBillableById = (nonBillId) =>
{
  return API.del(`${API_ENDPOINTS.NON_BILLABLES.NonBillables}/${nonBillId}`);
}

export
{
  getAllNonBillablesName,
  getAllNonBillables,
  createNonBillable,
  updateNonBillable,
  getNonBillableById,
  deleteNonBillableById,
  getAllNonBillablesByAccess
};