/* eslint-disable react/react-in-jsx-scope */
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { validatePermission } from "./UserManagement";
import '../layouts/Timesheet/css/timesheet.css';
import { Mobile } from '../layouts/media-queries/mediaQueries';

const getAcknowledgeStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'Pending';
    case 'approved':
      return 'Approved';
    case 'denied':
      return 'Denied';
    default:
      return null;
  }
};

const checkApproveDenyButton = (
  roleName,
  permissions,
  status,
  reimbursementAcknowledge,
  acknowledgedBy
) => {
  const showButtons = (
    <>
      <Button
        variant="primary"
        className="btn-ftc-primary  mr-2"
        onClick={() => reimbursementAcknowledge('approved')}
      >
        Approve
      </Button>
      <Button
        variant="default"
        className="btn-ftc-border-primary"
        onClick={() => reimbursementAcknowledge('denied')}
      >
        Deny
      </Button>
    </>
  );
  const approveDenyLabel = (
    <div title={acknowledgedBy} className="hover-text">
      <Mobile><span className="tooltip-text" id="bottom">{acknowledgedBy}</span></Mobile>
      <label
        className={
          status === 'denied'
            ? 'label-deny'
            : status === 'approved'
            ? 'label-active'
            : 'label-pending'
        }
      >
        <span
          className={
            // eslint-disable-next-line no-nested-ternary
            status === 'denied'
              ? 'deny-ellipse'
              : status === 'approved'
              ? 'active-ellipse'
              : 'pending-ellipse'
          }
        />
        {` `}
        {getAcknowledgeStatus(status)}
      </label>
    </div>
  );
  switch (roleName) {
    case 'Admin':
      if (status === 'pending') {
        return showButtons;
      }
      if (status === 'approved' || status === 'denied') {
        return approveDenyLabel;
      }
      break;

    case 'Manager':
      if (status === 'pending') {
        return showButtons;
      }
      if (status === 'approved' || status === 'denied') {
        return approveDenyLabel;
      }
      break;

    case 'Office':
      return approveDenyLabel;

    case 'Chef':
      return approveDenyLabel;

    case 'Accountant':
      return approveDenyLabel;

    default:
      return validatePermission(permissions, 'reimbursement', 'edit');
  }
};

const getPaymentStatusLabel = (status) => {
  switch (status) {
    case 'pending':
      return 'Pending';
    case 'paid':
      return 'Paid';
    default:
      return null;
  }
};

const checkPaymentButton = (
  roleName,
  permissions,
  status,
  isAcknowledged,
  handlePayment,
  markAsPaidBy
) => {
  const isDisabled = isAcknowledged === 'pending' || isAcknowledged === 'denied';
  const showButton = (
    <>
      <Button
        variant="primary"
        className="btn-ftc-primary  mr-2"
        onClick={handlePayment}
        disabled={isDisabled}
      >
        Mark As a Paid
      </Button>
    </>
  );
  const paymentLabel = (
    <div title={markAsPaidBy} className="hover-text">
      <Mobile><span className="tooltip-text" id="bottom">{markAsPaidBy}</span></Mobile>
      <label
        className={status === 'pending' ? 'label-pending' : 'label-active'}
      >
        <span
          className={
            status === 'pending' ? 'pending-ellipse' : 'active-ellipse'
          }
        />
        {` `}
        {getPaymentStatusLabel(status)}
      </label>
    </div>
  );
  switch (roleName) {
    case 'Admin':
      if (status === 'pending') {
        return showButton;
      }
      if (status === 'paid') {
        return paymentLabel;
      }
      break;

    case 'Manager':
      if (status === 'pending') {
        return showButton;
      }
      if (status === 'paid') {
        return paymentLabel;
      }
      break;

    case 'Office':
      return paymentLabel;

    case 'Chef':
      return paymentLabel;

    case 'Accountant':
      return paymentLabel;

    default:
      return validatePermission(permissions, 'reimbursement', 'edit');
  }
};

const checkReimbursementDeletable = (roleName, permissions,reimbursementDate,periodStartDate, periodEndDate, reimbursementStatus) => {
  switch (roleName) {
    case 'Admin':
      return true;
    
    case 'Manager':{
      if(reimbursementStatus==="approved" || reimbursementStatus==="denied"){
        return false;
      }
      return true;
    }

    case 'Chef':
    case 'Accountant':
    case 'Office':{
      if(reimbursementStatus==="approved" || reimbursementStatus==="denied"){
        return false;
      }
      // eslint-disable-next-line no-else-return
      else {
        const endOfWeek = moment(periodEndDate)
          .utc()
          .endOf('week')
          .utcOffset(0)
          .format('YYYY-MM-DD');
        const endOfWeekPeriod = moment(endOfWeek)
          .add(3, 'days') // Add 3 days to go from Monday to Wednesday
          .format('YYYY-MM-DD');
        const currentDate = moment().format('YYYY-MM-DD');

        // Check if currentDate is within the periodStartDate and endOfWeekPeriod range
        const isWithinRange = moment(currentDate).isBetween(periodStartDate, endOfWeekPeriod, null, '[]');
        const isBetweenPeriod = moment(reimbursementDate)
        .isBetween(periodStartDate, periodEndDate, 'day', '[]');

      return isBetweenPeriod || isWithinRange;
      }
    }

    default:
      return validatePermission(permissions, 'reimbursement', 'delete');
  }
};

export {
  checkApproveDenyButton,
  getPaymentStatusLabel,
  checkPaymentButton,
  checkReimbursementDeletable,
};
