import React, { memo } from 'react';
import { Row, Form, Col, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import SettingsDropdown from '../../../../components/SettingsDropdown/SettingsDropdown';
import './MobileCustomPopups.css'
import { DateConvert } from '../../../../utils/TimesheetManagement';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        height: '100%'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const MobileCustomPopups = memo(({
    customPopup,
    handleViewClick,
    handleEditClicked,
    handleClickDelete,
    callback,
    isEditable,
    isDeletable,
}) => {
    const classes = useStyles();

    const displayAccess = (roles) => {
        if (roles.length) {
            if (roles.length === 1) {
                return roles.map((c) => c.rolename ?? 'N/A');
            }
            if (roles.length === 2) {
                return roles.map((c) => c.rolename ?? 'N/A').join(' | ');
            }
            if (roles.length >= 3) {
                const str = roles.slice(0, 2).map((c) => c.rolename ?? 'N/A').join(' | ');
                // const left = roles.length - 2;
                return `${str}`;
            }

        }
        return null;
    };

    const additionalDate = new Date(customPopup?.expiresOn);
    additionalDate.setDate(additionalDate.getDate() + 1);

    return (
        <div className='h-100' onClick={(event) => {
            const settingsDropDown = event.target.closest('#settings-dropdown');
            if (!settingsDropDown) {
                callback(customPopup)
            }
        }} onKeyDown={() => { }} role="button" tabIndex={0}>
            <Card className={`${classes.root} mt-2`}>
                <CardContent>
                    <Row className="border-bottom p-0" >
                        <Col xs="8"
                        >
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <Image
                                    className="mr-2 align-top mt-0 ftc-logo-24 image-top--2"
                                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom-red.svg`}
                                />
                                <Form.Label >
                                    {customPopup.title}
                                </Form.Label>
                            </Form.Group>

                        </Col>

                        <Col xs="12" className="ml-2 mt-1">
                            <span className="ellipse__custompage__mobile float-right">
                                <SettingsDropdown
                                    identifier={customPopup._id}
                                    handleViewClick={handleViewClick}
                                    handleEditClicked={handleEditClicked}
                                    handleClickDelete={handleClickDelete}
                                    isEditable={isEditable}
                                    isDeletable={isDeletable}
                                />
                            </span>
                        </Col>

                    </Row>
                    <Row className="pl-0 pb-2 border-bottom">
                        <Col xs="12">
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <p className="mb-0 txt-light-secondary">Location</p>
                                <Form.Label className="mb-0">
                                    {customPopup.popup_location}
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="pl-0 pb-2 border-bottom">
                        <Col xs="12">
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <p className="mb-0 txt-light-secondary">Expiers On</p>
                                <Form.Label className="mb-0">
                                {customPopup?.expiresOn===null || customPopup?.expiresOn===undefined ? 'N/A' : DateConvert(additionalDate)}
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="pl-0 pb-2">
                        <Col xs="12">
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <p className="mb-0 txt-light-secondary">Accessible by</p>
                                <Form.Label className="mb-0">
                                    {displayAccess(customPopup.access)}
                                    {
                                        customPopup.access.length > 2 && (
                                            <>
                                                {` `} |  <span className="txt-primary"> +{customPopup.access.length - 2}</span>{' '}
                                            </>
                                        )
                                    }
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </CardContent>
            </Card >
        </div>
    )
});

export default MobileCustomPopups

MobileCustomPopups.propTypes = {
    customPopup: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    handleViewClick: PropTypes.func.isRequired,
    handleEditClicked: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isDeletable: PropTypes.bool.isRequired,
};