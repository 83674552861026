
import { React, createContext, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { openDB } from 'idb';
import { PropTypes } from 'prop-types';

import useNavigator from '../utils/CustomHooks/useNavigator'
import { createTimesheet } from '../services/timesheet.service';
import Toaster from '../components/Toaster';

export const OfflineTimesheetSyncContext = createContext();
export function useOfflineTimesheetSyncContext() {
    return useContext(OfflineTimesheetSyncContext);
}
export const OfflineTimesheetSyncContextProvider = ({ children }) => {


    const isOnline = useNavigator();
    const [reload, setReload] = useState(false)
    const [isSyncing, setSync] = useState(false)

    function delay(delayInms) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(2);
            }, delayInms);
        });
    }
    function getOrderdbs() {
        return openDB('order', 1, {
            upgrade(db) {
                // Create a store of objects
                const store = db.createObjectStore('order_requests', {
                    // The 'uniqueId' property of the object will be the key.
                    keyPath: 'uniqueId',
                    // If it isn't explicitly set, create a value by auto incrementing.
                    // autoIncrement: true,
                });
                // Create an index on the 'uniqueId' property of the objects.
                store.createIndex('uniqueId', 'uniqueId');
            },
        });
    }
    const sync = async () => {
        const dbs = await openDB('order', 1, {
            upgrade(db) {
                // Create a store of objects
                const store = db.createObjectStore('order_requests', {
                    // The 'uniqueId' property of the object will be the key.
                    keyPath: 'uniqueId',
                    // If it isn't explicitly set, create a value by auto incrementing.
                    // autoIncrement: true,
                });
                // Create an index on the 'date' property of the objects.
                store.createIndex('uniqueId', 'uniqueId');
            },
        });
        const timesheetsToSync = await dbs.getAll('order_requests');
        setSync(true);

        for (let i = 0; i < timesheetsToSync.length; i += 1) {
            const timesheet = timesheetsToSync[i];
            try {
                // sync with server
                await delay(200);

                await createTimesheet(timesheet);

                // delete from idb

                const orderDbs = await openDB('order', 1, {
                    upgrade(db) {
                        // Create a store of objects
                        const store = db.createObjectStore('order_requests', {
                            // The 'uniqueId' property of the object will be the key.
                            keyPath: 'uniqueId',
                            // If it isn't explicitly set, create a value by auto incrementing.
                            // autoIncrement: true,
                        });
                        // Create an index on the 'uniqueId' property of the objects.
                        store.createIndex('uniqueId', 'uniqueId');
                    },
                });
                await orderDbs.delete('order_requests', timesheet.uniqueId);

            }
            catch (error) {
                const errors = error?.response?.data?.data?.error?.errors;
               

                try {
                    if (errors?.uniqueId
                        && errors.uniqueId.name === "ValidatorError"
                        && errors.uniqueId.kind === "unique"
                        && errors.uniqueId.value) {

                        const orderDbs = await getOrderdbs();
                        await orderDbs.delete('order_requests', errors.uniqueId.value);
                        
                    }
                    else {
                        toast.dark(
                            <Toaster
                                icon="error"
                                message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                            />
                        );
                    }
                }
                catch (err) {
                    toast.dark(
                        <Toaster
                            icon="error"
                            message='Something went wrong!!!'
                        />
                    );
                }

            }

        }
        setSync(false);
        setReload(!reload)
    }
    useEffect(() => {
        if (isOnline) {
            sync()
        }
    }, [isOnline])

    return <OfflineTimesheetSyncContext.Provider value={{ reload, isSyncing, sync }}>
        {children}
    </OfflineTimesheetSyncContext.Provider>
}
OfflineTimesheetSyncContextProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
