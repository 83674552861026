/* eslint-disable no-unused-vars */
import React,{useEffect,useState} from 'react';
import { Row, Col, Image,Form,NavDropdown } from 'react-bootstrap';
import { useHistory,useLocation } from 'react-router-dom';
import { TablePagination } from '@material-ui/core';
import { trackPromise } from 'react-promise-tracker';
import qs from 'query-string';
import { toast } from 'react-toastify';
import useQuery from '../../../utils/CustomHooks/useQuery';
import Sidebar from '../../side-navbar/Sidebar';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';
import Toaster from '../../../components/Toaster';
import { getAllReimbursementReasons,deleteReimbursementReasonById } from '../../../services/reimbursement_reason.services';
import DataTableComponent from '../../../components/DataTableComponent';
import { DateConvert,showExportButton } from '../../../utils/TimesheetManagement';
import SettingsDropdown from '../../../components/SettingsDropdown/SettingsDropdown';
import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import Ellipses from '../../../components/Ellipses';
import TableMobileView from './mobile-layouts/reimbursementReasonTable';
import RenderCustomPopup from '../../../components/RenderCustomPopup';
import ReimbursementReasons from '../../../constants/reimbursement_reasons';

const ReimbursementSetup = () => {
    const history = useHistory();
    const URLQuery = useQuery();
    const location = useLocation();
    const isIndeterminate = (indeterminate) => indeterminate;
    const selectableRowsComponentProps = { indeterminate: isIndeterminate };

    const [page, setPage] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [reasonName, setReasonName] = useState(URLQuery.get('reason') ?? '');
    const [searchQuery, setsearchQuery] = useState(
        URLQuery.get('reason') ?? ''
    );
    const [isAPICalled, setAPICalled] = useState(false);
    const [reimbursementReasons, setReimbursementReasons] = useState([]);
    const [reimbursementReasonCount, setReimbursementReasonCount] = useState({
        totalCount: 0,
        filterCount: 0
    });
    const [selectedRow, setselectedRow] = useState();
    const [showConfirmAlert, setConfirmAlert] = useState(false);

    const handleCloseConfirm = () => setConfirmAlert(false);
    const onAddNew = () => {
        history.push('/settings/manageReimbursementReason');
    };

    const handleEditClicked = (state) => {
        history.push(`/settings/editReimbursementReason/${state.target.id}`);
    };

    const isDisabled = (row, reasonData) => {
        return reasonData.some(item => item._id === row);
    };

    const handleClickDelete = (state) => {
        setselectedRow(state.target.id);
        setConfirmAlert(true);
    };

    async function fetchAllReimbursementReasons() {
        try {
            const response = await trackPromise(
                getAllReimbursementReasons(rowsPerPage, page, searchQuery)
            );
            setReimbursementReasons(response.data.data.reimbursement_reasons);
            setReimbursementReasonCount({ totalCount: response.data.data.totalCount, filterCount: response.data.data.filterCount });
            setAPICalled(true);
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    const handleRowClicked = (row) => {
        history.push(`/settings/editReimbursementReason/${row._id}`);
      };
    
    const onDeleteReimubrsementReason = async () => {
        try {
            const response = await trackPromise(
                deleteReimbursementReasonById(selectedRow)
            );
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally {
            setConfirmAlert(false);
            fetchAllReimbursementReasons();
        }
    };

    const handleViewClicked = (state) => {
        history.push(`/settings/viewNonbillable/${state.target.id}`);
    };

    

    const displayUserRoles = (userRoles) => {
        return userRoles.map((c) => c.rolename ?? 'N/A').join(', ');
        // switch (true) {
        //   case userRoles.length === 1:
        //     return userRoles.map((c) => c.rolename ?? 'N/A');
    
        //   case userRoles.length >= 2:
        //     return userRoles
        //       .slice(0, 1)
        //       .map((c) => c.rolename ?? 'N/A')
        //       .join(', ');
    
        //   default:
        //     break;
        // }
        // return null;
      };

    const columns = [
        {
            name: 'Reimbursement Reason',
            selector: 'reason',
            sortable: true,
            width: '300px',
        },
        {
            name: 'Accessible By',
            selector: (row) => {
                const { access: userRoles } = row;
                return (
                  <>
                    {displayUserRoles(userRoles)}
                    {/* {userRoles.length > 1 && (
                      <>
                        <span className="txt-primary"> +{userRoles.length - 1}</span>{' '}
                      </>
                    )} */}
                    {!userRoles.length && <>N/A</>}
                  </>
                );
              },
            // sortable: true,
            
        },
        {
            cell: (row) => {
                const { _id } = row;
                return (
                    <div id="settings-dropdown">
                        <NavDropdown title={<Ellipses />} id="collasible-nav-dropdown">
                            <NavDropdown.Item
                                onClick={handleEditClicked}
                                id={_id}
                            >
                                Edit</NavDropdown.Item>
                            <NavDropdown.Item
                                id={_id}
                                onClick={handleClickDelete}
                                // eslint-disable-next-line react/destructuring-assignment
                                disabled={isDisabled(row?._id, ReimbursementReasons)}
                            >
                                Delete</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                );
            },
            allowOverflow: true,
            button: true,
            width: '56px', // custom width for icon button
        }
    ];

    useEffect(() => {
        if (!searchQuery.length) {
            history.push({
                pathname: location.pathname,
                search: '',
            });
        } else {
            history.push({
                pathname: location.pathname,
                search: qs.stringify({ reason: searchQuery }),
            });
        }
    }, [searchQuery]);

    useEffect(() => {
        fetchAllReimbursementReasons();
    }, [rowsPerPage, page, searchQuery]);

  return (
    <div>
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar />
        </Mobile>
        <Desktop>
          <Col md="10">
            <div className="container">
              <Row className="border-bottom sticky-dashboard-header">
                <Col md={7} lg={7} className="mr-5">
                  <h3>
                    <strong>Reimbursement Setup</strong>
                  </h3>
                </Col>
                <Col lg={3} md={3} className="pr-0">
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control
                            type="text"
                            className="ml-0"
                            placeholder="Search Reasons"
                            aria-label="resaons"
                            aria-describedby="basic-addon1"
                            value={reasonName}
                            onChange={(e) => setReasonName(e.target.value)}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    setsearchQuery(event.target.value);
                                    setPage(0);
                                }
                            }}
                        />
                    </Form.Group>
                </Col>
                <Col md={1.5} className="pr-3 pl-3">
                  <button
                    type="button"
                    className="btn btn-ftc-primary float-right"
                    onClick={onAddNew}
                  >
                    Add New
                  </button>
                </Col>
              </Row>
              <div className="container-top-7">
                    <Row>
                        <Col md="12">
                            {isAPICalled ?
                                (<><DataTableComponent
                                    highlightOnHover
                                    pointerOnHover
                                    noHeader
                                    columns={columns}
                                    data={reimbursementReasons}
                                    pagination
                                    selectableRowsComponentProps={selectableRowsComponentProps}
                                    paginationServer
                                    paginationTotalRows={reimbursementReasonCount.filterCount}
                                    handleRowClicked={handleRowClicked}
                                    paginationPerPage={rowsPerPage}
                                    onChangePage={(p) => {
                                        setPage(p - 1);
                                        // look for better solution later
                                        // setPage(p)
                                    }}
                                    onChangeRowsPerPage={(rws, pg) => {
                                        setRowsPerPage(rws);
                                        setPage(pg);
                                    }}
                                />
                                    <TablePagination
                                        labelRowsPerPage='Rows per page:'
                                        component="div"
                                        count={Number.parseInt(reimbursementReasonCount.filterCount, 10)}
                                        page={page}
                                        onChangePage={(event, newPage) => {
                                            setPage(newPage);
                                        }}
                                        defaultPage={0}
                                        rowsPerPage={rowsPerPage}
                                        onChangeRowsPerPage={(event) => {
                                            setRowsPerPage(parseInt(event.target.value, 10));
                                            setPage(0);
                                        }}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </>) : ('')
                            }
                        </Col>
                    </Row>
                </div>
            </div>
          </Col>
        </Desktop>
        <Mobile>
          <Col>
            <div className="container pr-0">
              <Row className="border-bottom pr-0 sticky-dashboard-header-mobile">
                <Col xs="9" className="ml-4">
                  <h3>Reimbursement Setup</h3>
                </Col>
                <Col xs="2">
                  <Image
                    className="ftc-logo-40"
                    src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                  />{' '}
                </Col>
              </Row>
              <div className="container-top-30">
                <Row>
              <Col xs="8" className="pl-1 pr-1">
                    <Form.Control
                        type="text"
                        placeholder="Search"
                        value={reasonName}
                        onChange={(e) => setReasonName(e.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                setsearchQuery(event.target.value);
                                setPage(0);
                            }
                        }}
                    />
                </Col>
                <Col xs="4" className=" pl-1 pr-1">
                    <button
                        type="button"
                        className="btn btn-ftc-primary float-right"
                        onClick={onAddNew}
                    >
                        Add New
                    </button>
                </Col>
                </Row>

                <Row className="pt-0">
                    {
                        reimbursementReasons.map((reimbursementReason) => {
                            return (<Col md={6} className="mt-3 pl-1 pr-1">
                                <TableMobileView
                                    reimbursementReason={reimbursementReason}
                                    callback={handleRowClicked}
                                    // handleViewClick={handleViewClicked}
                                    handleEditClicked={handleEditClicked}
                                    handleClickDelete={handleClickDelete}
                                    isEditable={false}
                                    isDeletable={false}
                                />
                            </Col>);
                        })
                    }
                </Row>
                {
                    (isAPICalled && !reimbursementReasonCount.filterCount) && (
                        <div className="text-center">
                            <p>There are no records to display.</p>
                        </div>
                    )
                }
                <Row>
                    <Col xs="12">
                        <TablePagination
                            className="mobile__pagination"
                            labelRowsPerPage=''
                            ActionsComponent={TablePaginationActions}
                            component="div"
                            count={Number.parseInt(reimbursementReasonCount.filterCount, 10)}
                            page={page}
                            // onChangePage={handleChangePage}
                            onChangePage={(event, newPage) => {
                                setPage(newPage);
                            }}
                            defaultPage={0}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={(event) => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Mobile>
      </Row>
      {
                showConfirmAlert && <ConfirmAlertModal
                    show={showConfirmAlert}
                    handleClose={handleCloseConfirm}
                    title='Delete Reimbursement Reason'
                    description='Deleting this Reimbursement Reason will erase all data inside this record.
                Are you sure you want to delete this Reimbursement Reason?'
                    callback={onDeleteReimubrsementReason}
                    action='Delete it'
                    closeAction='Cancel'
                />
            }
    <RenderCustomPopup />
        
    </div>
  );
};
export default ReimbursementSetup;
