import React, { memo, useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useFormContext, } from 'react-hook-form';
import PropTypes from 'prop-types';
import { trackPromise } from 'react-promise-tracker';

import { toast } from 'react-toastify';
import Toaster from '../../../components/Toaster';

import { getAllRolesByNames } from '../../../services/role-service';
import { getLocationLabel } from '../../../utils/UserManagement';

const CustomPageDescriptionForm = memo(({
    formContent, showAccessError
}) =>
{

    // const animatedComponents = makeAnimated();

    const [roles, setRoles] = useState([]);
    const [selectedRoles, setselectedRoles] = useState(
        formContent?.one?.access?.length ? formContent?.one?.access : []
    );

    const PageLocations = [
        'forms',
        'reports',
        'tools'
    ];

    const { register, formState: { errors }, setValue, getValues } = useFormContext();

    async function fetchAllRoles()
    {
        try
        {
            const response = await trackPromise(getAllRolesByNames());            
            setRoles(
                response.data.data.roles.map((role) =>
                {
                    const tempRole = role;
                    if (formContent?.one?.access?.length > 0)
                    {
                        if (formContent.one.access.find((selected) => selected._id === role._id))
                        {
                            tempRole.isChecked = true;
                        }
                    }
                    else
                    {
                        tempRole.isChecked = false;
                    }
                    
                    return tempRole;

                })
            );            
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    const removeAllRoles = () =>
    {
        setRoles(
            roles.map((role) =>
            {
                const tempRole = role;
                if (selectedRoles.find((selected) => selected._id === role._id))
                {
                    tempRole.isChecked = false;
                    selectedRoles.splice(selectedRoles.findIndex((selected) => selected._id === role._id), 1);
                }
                return tempRole;
            })
        );
        setselectedRoles(selectedRoles);
    }

    useEffect(() =>
    {
        fetchAllRoles();
    }, []);
    

    useEffect(() =>
    {
        if (formContent?.one === null)
        {
            removeAllRoles();
        }
    }, [formContent]);

    useEffect(() =>
    {               
        if (selectedRoles.length)
        {
            setValue('access', selectedRoles, { shouldDirty: true, shouldValidate: true });
        }
        else
        {
            setValue('access', null, { shouldDirty: false, shouldValidate: true });
        }
    }, [selectedRoles]);

    const onRoleChange = (e, role, indexKey) =>
    {
        const newRoles = [...roles];
        const findSelectedIndex = selectedRoles.map((searchRole) => searchRole._id).indexOf(role._id);

        const newSelectedRoles = [...selectedRoles];

        if (e.target.checked)
        {            
            newRoles[indexKey].isChecked = true;
            newSelectedRoles.push(role);
        }
        else
        {
            newRoles[indexKey].isChecked = false;
            newSelectedRoles.splice(findSelectedIndex, 1);
        }
        setRoles(newRoles);
        setselectedRoles(newSelectedRoles);        
    }

    return (
        <div>
            <Row >
                <Col md="6" xs="12">
                    <Form.Group controlId="formBasicText">
                        <Form.Label className="txt-light-secondary">Button Label</Form.Label>
                        <Form.Control
                            {...register('button_label')}
                            type="text"
                            placeholder=""
                        />
                        {errors.button_label && (
                            <p className="text-danger">
                                {errors.button_label.message}
                            </p>
                        )}
                    </Form.Group>
                </Col>
                <Col md="6" xs="12">
                    
                    <Form.Group controlId="formBasicText">
                        <Form.Label className="txt-light-secondary">Page Title</Form.Label>
                        <Form.Control
                            {...register('page_title')}
                            type="text"
                            placeholder=""
                        />
                        {errors.page_title && (
                            <p className="text-danger">
                                {errors.page_title.message}
                            </p>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            {
                roles.length ? (
                    <Row>
                        <Col md="12" xs="12">
                            <label className="txt-light-secondary">Accessible by </label>
                        </Col>
                        {
                            roles.map((role, index) =>
                            {
                                return (
                                    <Col md="3" xs="4" key={role._id}>
                                        <Form.Group controlId="formBasicCheckbox">
                                            <input
                                                type="checkbox"
                                                className="ftc-checkbox"
                                                id={role._id}
                                                checked={role.isChecked}
                                                onChange={(e) =>
                                                {                                                    
                                                    onRoleChange(e, role, index);
                                                }}
                                            />
                                            <Form.Label data-content={roles.rolename} className="position-relative ml-4 pl-2" >{role.rolename}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                );
                            })
                        }
                        
                        {(errors.access && showAccessError) && (
                            <Col md="12">
                                <p className="text-danger">
                                    {errors.access.message}
                                </p>
                            </Col>
                        )}
                    </Row>
            
                ) : ('')
            }
            <Row>
                <Col md="6">
                    <label>
                        <span className="txt-light-secondary">Location </span>
                    </label>
                </Col>
                <Col md="6" />
                {
                    PageLocations.map((location) =>
                    {
                        return (
                            <Col md="3">
                                <Form.Group>
                                    <Button variant="default"
                                        className={getValues('page_location') === location ?
                                            'ftc-radiobutton-border-checked pb-0 btn-border-round' :
                                            'ftc-radiobutton-border-unchecked pb-0 btn-border-round'
                                        }
                                    >
                                        <input
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...register('page_location')}
                                            type="radio"
                                            className="ftc-radiobutton-secondary "
                                            value={location}
                                        />
                                        <Form.Label htmlFor={location} className="position-relative ml-4 pl-2">
                                            {getLocationLabel(location)}
                                        </Form.Label>
                                    </Button>
                                </Form.Group>
                            </Col>
                        );
                    })
                }
                {errors.page_location && (
                    <Col md="12">
                        <p className="text-danger">
                            {errors.page_location.message}
                        </p>
                    </Col>
                )}
            </Row>
            <Row>
                <Col>
                    <div className="border-bottom" />
                </Col>
            </Row>
        </div>

    );
});

export default CustomPageDescriptionForm;

CustomPageDescriptionForm.propTypes = {
  formContent: PropTypes.any.isRequired,
  showAccessError: PropTypes.bool.isRequired,
//   timesheetId: PropTypes.string,
};
