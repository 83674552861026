import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { trackPromise } from 'react-promise-tracker';
import { openDB } from 'idb';
import { TablePagination } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Button, Row, Col, Image, InputGroup, FormControl, Form, } from 'react-bootstrap';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import Sidebar from '../../side-navbar/Sidebar';
import DataTableComponent from '../../../components/DataTableComponent';
import MobileCustomPagesTable from './mobile-layouts/MobileCustomPagesTable';
import CustomPageFilters from './CustomPageFilters';
import Toaster from '../../../components/Toaster';
import SettingsDropdown from '../../../components/SettingsDropdown/SettingsDropdown';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import NoCustomPageComponent from '../../../components/NoCustomPageComponent/NoCustomPageComponent';
import RenderCustomPopup from '../../../components/RenderCustomPopup';

import { deleteCustomPageById, getAllCustomPages } from '../../../services/custom_page.service';
import { getLocationLabel, validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';
import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';
import useQuery from '../../../utils/CustomHooks/useQuery';

const CustomPages = memo(() => {
    const { authUser } = useAuthContext();
    const URLQuery = useQuery();
    const locationHook = useLocation();

    const isIndeterminate = (indeterminate) => indeterminate;
    const selectableRowsComponentProps = { indeterminate: isIndeterminate };

    const history = useHistory();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [filters, setfilters] = useState(null);
    const [query, setquery] = useState('');

    const [isAPICalled, setAPICalled] = useState(false);

    const [customPages, setCustomPages] = useState([]);
    const [pageCount, setPageCount] = useState({
        totalCount: 0,
        filterCount: 0
    });
    const [pageTitle, setPageTitle] = useState(URLQuery.get('title') ?? '');
    const [searchQuery, setsearchQuery] = useState(URLQuery.get('title') ?? '');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [selectedRow, setselectedRow] = useState();
    const [showConfirmAlert, setConfirmAlert] = useState(false);

    const handleCloseConfirm = () => setConfirmAlert(false);

    const onApplyChanges = (filtersData) => {
        handleClose();
        setfilters(filtersData);
    };

    const handleClickDelete = (state) => {
        setselectedRow(state.target.id);
        setConfirmAlert(true);
    };

    const handleEditClicked = (state) => {
        history.push(`/settings/editCustomPage/${state.target.id}`);
    };

    const handleViewClicked = (state) => {
        history.push(`/settings/viewCustomPage/${state.target.id}`);
    };

    const handleRowClicked = (row) => {
        history.push(`/settings/viewCustomPage/${row._id}`);
    };

    const onAddNew = () => {
        history.push('/settings/addNewCustomPage');
    };

    const displayAccess = (roles) => {
        switch (true) {
            case roles.length === 1:
                return roles.map((c) => c.rolename ?? 'N/A');

            case roles.length === 2:
            case roles.length === 3:
                return roles.map((c) => c.rolename ?? 'N/A').join(', ');

            case roles.length >= 4:
                return roles.slice(0, 3).map((c) => c.rolename ?? 'N/A').join(', ');
            default:
                break;
        }
        return null;
    };

    const columns = [
        {
            name: 'Button Label',
            selector: 'button_label',
            sortable: true,
        },
        {
            name: 'Location',
            selector: (row) => getLocationLabel(row.page_location),
            sortable: true,
        },
        {
            name: 'Accessible by',
            selector: (row) => {
                const { access } = row;
                return (
                    <>
                        {displayAccess(access)}
                        {
                            access.length > 3 && (
                                <>
                                    <span className="txt-primary"> +{access.length - 3}</span>{' '}
                                </>
                            )
                        }
                    </>
                )
            },
            sortable: true,
        },
        {
            cell: (row) => {
                const { _id } = row;
                return (<label>
                    <SettingsDropdown
                        identifier={_id}
                        handleViewClick={handleViewClicked}
                        handleEditClicked={handleEditClicked}
                        handleClickDelete={handleClickDelete}
                        drop='left'
                        isEditable={!validatePermission(authUser.user.role.permissions, 'custom_page', 'edit')}
                        isDeletable={!validatePermission(authUser.user.role.permissions, 'custom_page', 'delete')}
                    />
                </label>);
            },
            allowOverflow: true,
            button: true,
            width: '56px', // custom width for icon button
        },
    ];

    async function fetchAllCustomPages() {
        try {
            const response = await trackPromise(
                getAllCustomPages(rowsPerPage, page, searchQuery, query)
            );
            setCustomPages(response.data.data.customPages);
            setPageCount({ totalCount: response.data.data.totalCount, filterCount: response.data.data.filterCount });

            setAPICalled(true);
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    async function onFilterChanges() {
        let queryString = '';

        if (filters?.locations && filters.locations.length) {
            filters.locations.forEach((location) => {
                queryString += `&locations[]=${location.value}`;
            });
        }

        if (filters?.roles && filters.roles.length) {
            filters.roles.forEach((role) => {
                queryString += `&roles[]=${role._id}`;
            });
        }

        setquery(queryString);
        const dbs = await openDB('filters', 1, {
            upgrade(db) {
                // Create a store of objects
                const store = db.createObjectStore('filters_data', {
                    // The 'moduleName' property of the object will be the key.
                    keyPath: 'moduleName',
                    // If it isn't explicitly set, create a value by auto incrementing.
                    // autoIncrement: true,
                });
                // Create an index on the 'date' property of the objects.
                store.createIndex('moduleName', 'moduleName');
            },
        });

        const checkKeyExists = await dbs.getKey(
            'filters_data',
            IDBKeyRange.only('custom_page')
        );

        // will update existing record or else create a new
        if (checkKeyExists !== undefined) {
            if (filters !== null && Object.keys(filters).length) {
                await dbs.put('filters_data', {
                    moduleName: 'custom_page',
                    filterObj: filters,
                });
            } else {
                // if all filters are empty then also remove its row from idb
                await dbs.delete('filters_data', IDBKeyRange.only('custom_page'));
            }
        } else if (filters !== null && Object.keys(filters).length) {
            await dbs.add('filters_data', {
                moduleName: 'custom_page',
                filterObj: filters,
            });
        }

        setPage(0);
    }

    const onDeleteCustomPage = async () => {
        try {
            const response = await trackPromise(
                deleteCustomPageById(selectedRow)
            );
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally {
            setConfirmAlert(false);
            window.location.reload();
            // fetchAllCustomPages();
        }
    };

    async function getFilters() {
        try {
            const dbs = await openDB('filters', 1, {
                upgrade(db) {
                    // Create a store of objects
                    const store = db.createObjectStore('filters_data', {
                        // The 'moduleName' property of the object will be the key.
                        keyPath: 'moduleName',
                        // If it isn't explicitly set, create a value by auto incrementing.
                        // autoIncrement: true,
                    });
                    // Create an index on the 'date' property of the objects.
                    store.createIndex('moduleName', 'moduleName');
                },
            });
            const filtersData = await dbs.get(
                'filters_data',
                IDBKeyRange.only('custom_page')
            );
            if (filtersData?.filterObj) {
                setfilters(filtersData.filterObj);
            }
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={
                        error?.response?.data?.message ?? 'Something went wrong!!!'
                    }
                />
            );
        }
    }

    useEffect(() => {
        if (locationHook.state?.forceReload) {
            window.location.reload();
        }
        getFilters();
    }, []);

    useEffect(() => {
        if (!searchQuery.length) {
            history.push({
                pathname: locationHook.pathname,
                search: '',
            });
        } else {
            history.push({
                pathname: locationHook.pathname,
                search: qs.stringify({ title: searchQuery }),
            });
        }
    }, [searchQuery]);

    useEffect(() => {
        fetchAllCustomPages();
    }, [rowsPerPage, page, query, searchQuery]);

    useEffect(() => {
        onFilterChanges();
    }, [filters]);

    const displayRolesFilter = () => {
        switch (true) {
            case filters.roles.length === 1:
                return filters.roles.map((c) => c.rolename ?? 'N/A');

            case filters.roles.length === 2:
            case filters.roles.length === 3:
                return filters.roles.map((c) => c.rolename ?? 'N/A').join(', ');

            case filters.roles.length >= 4:
                return filters.roles.slice(0, 3).map((c) => c.rolename ?? 'N/A').join(', ');
            default:
                break;
        }
    };

    const clearAllFilters = () => {
        setfilters(null);
    };

    const removeFilter = (filterObj) => {
        delete filters[filterObj];
        setfilters({ ...filters });
    };

    const displaySelectedFilters = () => {
        return filters !== null && Object.keys(filters).length > 0 ? (
            <Row className="group_filter">
                {
                    filters?.locations && filters.locations.length ? (
                        <span className="filter-label">
                            <span className="txt-light-secondary">Locations:</span> {
                                filters.locations.map((location) => location.label).join(', ')
                            }
                            <Image
                                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
                                fluid
                                onClick={() => {
                                    removeFilter('locations')
                                }}
                            />
                        </span>
                    ) : ('')
                }

                {
                    filters?.roles && filters.roles.length ? (
                        <span className="filter-label">
                            <span className="txt-light-secondary">Role:</span> {displayRolesFilter()}
                            {
                                filters.roles.length > 3 && (
                                    <>
                                        <span className="txt-primary"> +{filters.roles.length - 3}</span>{' '}
                                    </>
                                )
                            }
                            <Image
                                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
                                fluid
                                onClick={() => {
                                    removeFilter('roles')
                                }}
                            />
                        </span>
                    ) : ('')
                }

                <Col>
                    <Button variant="link" onClick={clearAllFilters} className="float-right txt-primary">
                        Clear Filter
                    </Button>
                </Col>
            </Row>
        ) : ('')
    }

    return (
        <div>
            <Row className="mr-0">
                <Desktop>
                    <Col md="2">
                        <Sidebar />
                    </Col>
                </Desktop>
                <Mobile>
                    <Sidebar />
                </Mobile>
                {
                    (isAPICalled && !pageCount.totalCount)
                        ?
                        <NoCustomPageComponent
                            title='Custom Pages'
                            onAddNew={onAddNew}
                            addNewText='Start adding your first ”Custom Page"'
                            isAddNew={validatePermission(authUser.user.role.permissions, 'custom_page', 'add')}
                        />
                        :
                        <>
                            <Desktop>
                                <Col md="10">
                                    <div className="container">
                                        <Row className="border-bottom sticky-dashboard-header">
                                            <Col lg={5} md={4}>
                                                <h3>
                                                    <strong>Custom Pages</strong>
                                                </h3>
                                            </Col>
                                            <Col>
                                                <Button
                                                    className="float-right"
                                                    variant="default"
                                                    onClick={handleShow}
                                                >
                                                    {' '}
                                                    <Image
                                                        src={filters !== null && Object.keys(filters).length > 0 ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg` : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`}
                                                        fluid
                                                    />{' '}
                                                    Filter
                                                </Button>
                                            </Col>
                                            <Col md={3} className="pl-0">
                                                <InputGroup className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    placeholder="Search Pages"
                                                    aria-label="Pages"
                                                    aria-describedby="basic-addon1"
                                                    value={pageTitle}
                                                    onChange={(e) => setPageTitle(e.target.value)}
                                                    onKeyPress={(event) => {
                                                        if (event.key === 'Enter') {
                                                            setsearchQuery(event.target.value);
                                                            setPage(0);
                                                        }
                                                    }}
                                                />
                                                </InputGroup>
                                            </Col>
                                            {
                                                validatePermission(authUser.user.role.permissions, 'custom_page', 'add') && (
                                                    <div className="pr-3">
                                                        <button type="button" className="btn btn-ftc-primary float-right"
                                                            onClick={onAddNew}
                                                        >
                                                            Add New
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        </Row>
                                        <div className="container-top-7" />
                                        {displaySelectedFilters()}
                                        <Row>
                                            <Col md="12">
                                                {isAPICalled ? (
                                                    <><DataTableComponent
                                                        highlightOnHover
                                                        pointerOnHover
                                                        noHeader
                                                        columns={columns}
                                                        data={customPages}
                                                        pagination
                                                        selectableRowsComponentProps={selectableRowsComponentProps}
                                                        paginationServer
                                                        paginationTotalRows={pageCount.filterCount}
                                                        handleRowClicked={handleRowClicked}
                                                        paginationPerPage={rowsPerPage}
                                                        onChangePage={(p) => {
                                                            setPage(p - 1);
                                                            // look for better solution later
                                                            // setPage(p)
                                                        }}
                                                        onChangeRowsPerPage={(rws, pg) => {
                                                            setRowsPerPage(rws);
                                                            setPage(pg);
                                                        }}
                                                    />
                                                        <TablePagination
                                                            labelRowsPerPage='Rows per page:'
                                                            component="div"
                                                            count={Number.parseInt(pageCount.filterCount, 10)}
                                                            page={page}
                                                            onChangePage={(event, newPage) => {
                                                                setPage(newPage);
                                                            }}
                                                            defaultPage={0}
                                                            rowsPerPage={rowsPerPage}
                                                            onChangeRowsPerPage={(event) => {
                                                                setRowsPerPage(parseInt(event.target.value, 10));
                                                                setPage(0);
                                                            }}
                                                            ActionsComponent={TablePaginationActions}
                                                        />
                                                    </>) : ('')}
                                            </Col>
                                        </Row>
                                        {/* </div> */}
                                    </div>
                                </Col>
                            </Desktop>
                            <Mobile>
                                <Col>
                                    <div className="container pr-0 container-top-20">
                                        <Row className="border-bottom sticky-dashboard-header-mobile">
                                            <Col xs="9" className="ml-4">
                                                <h3>Custom Pages</h3>
                                            </Col>
                                            <Col xs="2">
                                                <Image
                                                    className="ftc-logo-40"
                                                    src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                                                />{' '}
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col xs="6" className="pl-1 pr-1">
                                                <Button
                                                    variant="default"
                                                    onClick={handleShow}
                                                    className="btn-block"
                                                >
                                                    {' '}
                                                    <Image
                                                        src={filters !== null && Object.keys(filters).length > 0 ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg` : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`}                            
                                                    />{' '}
                                                    Filter
                                                </Button>
                                            </Col>
                                            {
                                                validatePermission(authUser.user.role.permissions, 'custom_page', 'add') && (
                                                    <Col xs="6" className="pl-1 pr-1">
                                                        <button type="button" className="btn btn-ftc-primary btn-block" onClick={onAddNew}>
                                                            Add New
                                                        </button>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                        <Row className="pt-0 pb-0">
                                            <Col xs="12" className="pl-1 pr-1">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search"
                                                    value={pageTitle}
                                                    onChange={(e) => setPageTitle(e.target.value)}
                                                    onKeyPress={(event) => {
                                                        if (event.key === 'Enter') {
                                                            setsearchQuery(event.target.value);
                                                            setPage(0);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>                                        
                                        <Row>
                                            {
                                                customPages.map((customPage) => {
                                                    return (
                                                        <Col md={6} className="mt-3 pl-1 pr-1">
                                                            <MobileCustomPagesTable
                                                                customPage={customPage}
                                                                callback={handleRowClicked}
                                                                handleViewClick={handleViewClicked}
                                                                handleEditClicked={handleEditClicked}
                                                                handleClickDelete={handleClickDelete}
                                                                isEditable={!validatePermission(authUser.user.role.permissions, 'custom_page', 'edit')}
                                                                isDeletable={!validatePermission(authUser.user.role.permissions, 'custom_page', 'delete')}
                                                            />
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                        {
                                            (isAPICalled && !pageCount.filterCount) && (
                                                <div className="text-center">
                                                    <p>There are no records to display.</p>
                                                </div>
                                            )
                                        }
                                        <Row>
                                            <Col xs="12">
                                                <TablePagination
                                                    className="mobile__pagination"
                                                    labelRowsPerPage=''
                                                    ActionsComponent={TablePaginationActions}
                                                    component="div"
                                                    count={Number.parseInt(pageCount.filterCount, 10)}
                                                    page={page}
                                                    // onChangePage={handleChangePage}
                                                    onChangePage={(event, newPage) => {
                                                        setPage(newPage);
                                                    }}
                                                    defaultPage={0}
                                                    rowsPerPage={rowsPerPage}
                                                    onChangeRowsPerPage={(event) => {
                                                        setRowsPerPage(parseInt(event.target.value, 10));
                                                        setPage(0);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Mobile>
                        </>
                }
            </Row>

            {show && <CustomPageFilters
                show={show}
                handleClose={handleClose}
                allFilters={filters}
                applyChanges={onApplyChanges}
                clearAll={clearAllFilters}
            />}

            {showConfirmAlert && <ConfirmAlertModal
                show={showConfirmAlert}
                handleClose={handleCloseConfirm}
                title='Delete Custom Page'
                description='Deleting this page will erase all data inside this record.
                Are you sure you want to delete this Page?'
                callback={onDeleteCustomPage}
                action='Delete it'
                closeAction='Cancel'
            />}
            <RenderCustomPopup />
        </div>
    )
});

export default CustomPages
