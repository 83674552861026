import React, { memo } from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

import IconCheck from '../../assets/images/icon-check.svg';
import IconWarning from '../../assets/images/icon-warning.svg';
import IconAlert from '../../assets/images/icon-alert.svg';

const Toaster = memo(({ message, icon }) => {
  const ShowIcon = () => {
    switch (icon) {
      case 'warning':
        return <Image src={IconWarning} fluid className="px-2 notification" />;

      case 'error':
        return <Image src={IconAlert} fluid className="px-2 notification" />;

      case 'notify':
        return <Image src={IconCheck} fluid className="notification" />;

      default:
        break;
    }
    return null;
  };

  return (
    <div className="notification d-flex align-items-center">
      {ShowIcon()}
      <div>{message}</div>
    </div>
  );
});

Toaster.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default Toaster;
