import React, { memo } from 'react'
import { Col, Row, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

const NoCustomPageComponent = memo(({
    title,
    addNewText,
    onAddNew,
    isAddNew
}) =>
{
    return (
        <div className="container">
            <Row>
                <Col md="12" className="d-flex justify-content-center">
                    <h4><strong>{title}</strong></h4>
                </Col>
            </Row>
            <div className="content-vcenter">
                <Row>
                    <Col md="12" className="d-flex justify-content-center">
                        <Image
                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/empty-data.svg`}
                            fluid
                        />
                    </Col>
                    <Col md="12" className="d-flex justify-content-center mt-4">
                        <h4 className="txt-primary"><strong>No Data Found</strong></h4>
                    </Col>
                    {
                        isAddNew && (
                            <>
                                <Col md="12" className="d-flex justify-content-center">
                                    <p>{addNewText}</p>
                                </Col>
                                <Col md="12" className="d-flex justify-content-center">
                                    <button type="button" className="btn btn-ftc-primary" onClick={onAddNew}>Add New</button>
                                </Col>
                            </>
                        )
                    }
                </Row>
            </div>
        </div>
    );
});

export default NoCustomPageComponent

NoCustomPageComponent.propTypes = {
    title: PropTypes.string.isRequired,
    addNewText: PropTypes.string.isRequired,
    onAddNew: PropTypes.func.isRequired,
    isAddNew: PropTypes.bool.isRequired,

};