import React, { memo } from 'react'
import {
  Row, Col, Image,
} from 'react-bootstrap';

import { usePromiseTracker } from "react-promise-tracker";

import { Desktop, Mobile } from '../../layouts/media-queries/mediaQueries';

const Loading = memo(() => {
  const { promiseInProgress } = usePromiseTracker();
  return promiseInProgress ? (
    <div>
      <Desktop>
        <div className="text-center container-top-12">
          <Row>
            <Col md="12" xs="12" className="mb-5">
              <div>
                <Image
                  src={`${process.env.PUBLIC_URL}/assets/svg-icons/dish-loader.svg`}
                  fluid
                />
                <p className="mt-2">
                  <strong>Loading. . .</strong>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Desktop>
      <Mobile>
        <div className="text-center container-top-20">

          <Col md="12" xs="12" className="mb-5">
            <div>
              <Image
                src={`${process.env.PUBLIC_URL}/assets/svg-icons/dish-loader.svg`}
                fluid
              />
              <p className="mt-2">
                <strong>Loading. . .</strong>
              </p>
            </div>
          </Col>

        </div>

      </Mobile>
    </div>
  ) : (
    ''
  );
});


export default Loading
