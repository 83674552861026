import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Row, Modal, Col, Image, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import { getAllCitiesByName } from '../../../services/city-service';

import Toaster from '../../../components/Toaster';

import { getAllChefsByNames } from '../../../services/users-service';
import { useAuthContext } from '../../../contexts/user-context';

const ClientFilters = memo(({
    show, handleClose, applyChanges, allFilters, clearAll
}) => {
    const { authUser } = useAuthContext();
    const isChefUser = authUser.user.role.rolename === 'Chef';
    const [option, setOption] = useState(isChefUser ? 'city' : 'status');

    const [filters, setfilters] = useState(null);

    const [status, setStatuses] = useState([
        { label: 'Active', value: 'active', isChecked: false },
        { label: 'Inactive', value: 'inactive', isChecked: false },
    ]);

    const [cities, setcities] = useState([]);
    const [selectedCities, setselectedCities] = useState([]);
    const [searchCity, setsearchCity] = useState('');

    const [chefs, setchefs] = useState([]);
    const [selectedChefs, setselectedChefs] = useState([]);
    const [searchChef, setsearchChef] = useState('');

    const [selectedStatus, setSelectedStatus] = useState(
        allFilters?.status ? allFilters.status : null
    );

    async function fetchAllCities() {
        try {
            const response = await trackPromise(getAllCitiesByName());
            setcities(
                response.data.data.cities.map((city) => {
                    const tempCity = city;
                    if (allFilters?.cities?.length > 0) {
                        if (allFilters.cities.find((selected) => selected._id === city._id)) {
                            tempCity.isChecked = true;
                        }
                    }
                    else {
                        tempCity.isChecked = false;
                    }
                    return tempCity;
                })
            );
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    async function fetchAllChefs() {
        try {
            const response = await trackPromise(getAllChefsByNames());
            setchefs(
                response.data.data.users.map((chef) => {
                    const tempChef = chef;
                    if (allFilters?.chefs?.length > 0) {
                        if (allFilters.chefs.find((selected) => selected._id === chef._id)) {
                            tempChef.isChecked = true;
                        }
                    }
                    else {
                        tempChef.isChecked = false;
                    }
                    return tempChef;
                })
            );
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    useEffect(() => {
        fetchAllCities();
        if (!isChefUser) {
            fetchAllChefs();
        }
    }, []);

    useEffect(() => {
        if (allFilters?.cities?.length) {
            setselectedCities(allFilters.cities);
        }

        if (allFilters?.chefs?.length) {
            setselectedChefs(allFilters.chefs);
        }

        if (allFilters?.status) {
            setStatuses(
                status.map((st) => {
                    const tempStatus = st;
                    if (allFilters?.status?.value === st.value) {
                        tempStatus.isChecked = true;
                    }
                    return tempStatus;
                })
            )
        }
    }, [allFilters]);

    useEffect(() => {
        if (selectedStatus !== null) {
            setfilters((oldFilters) => ({ ...oldFilters, status: selectedStatus }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('status'))) {
            delete filters?.status;
            setfilters({ ...filters });
        }
    }, [selectedStatus]);

    useEffect(() => {
        if (selectedCities.length) {
            setfilters((oldFilters) => ({ ...oldFilters, cities: selectedCities }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('cities'))) {
            delete filters?.cities;
            setfilters({ ...filters });
        }
    }, [selectedCities]);

    useEffect(() => {
        if (selectedChefs.length) {
            setfilters((oldFilters) => ({ ...oldFilters, chefs: selectedChefs }));
        }
        else if (filters !== null && (Object.keys(filters).length && Object.keys(filters).includes('chefs'))) {
            delete filters?.chefs;
            setfilters({ ...filters });
        }
    }, [selectedChefs]);

    const filteredCities = cities.filter((city) => {
        return city.cityname.toLowerCase().includes(searchCity.toLowerCase())
    });

    const filteredChefs = chefs.filter((chef) => {
        return chef.username.toLowerCase().includes(searchChef.toLowerCase())
    });

    const onCityChange = (e, city,) => {
        const newCities = [...cities];
        const findSelectedIndex = selectedCities.map((searchRole) => searchRole._id).indexOf(city._id);
        const findCityFromIndex = cities.map((searchRole) => searchRole._id).indexOf(city._id);

        const newSelectedCities = [...selectedCities];

        if (e.target.checked) {
            newCities[findCityFromIndex].isChecked = true;
            newSelectedCities.push(city);
        }
        else {
            newCities[findCityFromIndex].isChecked = false;
            newSelectedCities.splice(findSelectedIndex, 1);
        }
        setcities(newCities);
        setselectedCities(newSelectedCities);
    };

    const onChefChange = (e, chef,) => {
        const newChefs = [...chefs];
        const findSelectedIndex = selectedChefs.map((searchRole) => searchRole._id).indexOf(chef._id);
        const findChefFromIndex = chefs.map((searchRole) => searchRole._id).indexOf(chef._id);

        const newSelectedChefs = [...selectedChefs];

        if (e.target.checked) {
            newChefs[findChefFromIndex].isChecked = true;
            newSelectedChefs.push(chef);
        }
        else {
            newChefs[findChefFromIndex].isChecked = false;
            newSelectedChefs.splice(findSelectedIndex, 1);
        }
        setchefs(newChefs);
        setselectedChefs(newSelectedChefs);
    };

    const onStatusChange = (e, st) => {
        setStatuses(
            status.map((d) => {
                const tempStatus = d;
                if (d.value === st.value) {
                    if (e.target.checked) {
                        tempStatus.isChecked = true;
                        setSelectedStatus(tempStatus);
                    }
                    else {
                        tempStatus.isChecked = false;
                        setSelectedStatus(null);
                    }
                }
                return tempStatus;
            })
        );
    };

    const removeAllStatus = () => {

        setStatuses(
            status.map((d) => {
                const tempStatus = d;
                if (selectedStatus) {
                    tempStatus.isChecked = false;
                    // selectedDurations.splice(selectedDurations.indexOf(d), 1);
                }
                return tempStatus;
            })
        );
        setSelectedStatus(null);
    };

    const removeAllCities = () => {
        setsearchCity('');
        setcities(
            cities.map((c) => {
                const tempCity = c;
                if (selectedCities.find((selected) => selected._id === c._id)) {
                    tempCity.isChecked = false;
                    selectedCities.splice(selectedCities.findIndex((selected) => selected._id === c._id), 1);
                }
                return tempCity;
            })
        );
        setselectedCities([]);
    };

    const removeAllChefs = () => {
        setchefs(
            chefs.map((c) => {
                const tempChef = c;
                if (selectedChefs.find((selected) => selected._id === c._id)) {
                    tempChef.isChecked = false;
                    selectedChefs.splice(selectedChefs.findIndex((selected) => selected._id === c._id), 1);
                }
                return tempChef;
            })
        );
        setselectedChefs([]);
    };

    const onClearAll = () => {
        removeAllCities();
        removeAllChefs();
        removeAllStatus();
        setfilters(null);
        clearAll();
    };

    function filterDetailsByOption() {
        switch (option) {
            case 'status':
                return (
                    <div>
                        <Row>
                            <Col md="3" xs="6">
                                <label>Status</label>
                            </Col>
                            <Col md="9" xs="6">
                                <a
                                    className="txt-primary float-right"
                                    role="button"
                                    onClick={removeAllStatus}
                                    onKeyDown={() => { }}
                                    tabIndex={-1}
                                >Deselect All</a>
                            </Col>
                        </Row>
                        <Row>
                            {
                                status.map((st) => {
                                    return (
                                        <Col md="4" xs="6">
                                            <Form.Group controlId="formBasicCheckbox">
                                                <input
                                                    type="radio"
                                                    className="ftc-radiobutton"
                                                    name='status-radio'
                                                    id={st.value}
                                                    checked={st.isChecked}
                                                    onChange={(e) => {
                                                        onStatusChange(e, st);
                                                    }}
                                                />
                                                <label
                                                    className="position-relative ml-4 pl-2"
                                                    htmlFor={st.value}
                                                >
                                                    {st.label}
                                                </label>
                                            </Form.Group>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </div>

                );

            case 'chef':
                return (
                    <div>
                        <Row>
                            <Col md="3" xs="6">
                                <Form.Label>Chef</Form.Label>
                            </Col>
                            <Col md="9" xs="6">
                                <a className="txt-primary float-right"
                                    role="button"
                                    onClick={removeAllChefs}
                                    onKeyDown={() => { }}
                                    tabIndex={-1}
                                >
                                    Deselect All
                                </a>
                            </Col>
                            <Col md="12" xs="12">
                                <Form.Group controlId="formGroupEmail">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Chef..."
                                        value={searchChef}
                                        onChange={(e) => setsearchChef(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            {
                                filteredChefs.map((chef, index) => {
                                    return (
                                        <Col md="4" xs="12" key={chef._id}>
                                            <Form.Group controlId="formCitiesCheckbox">
                                                <input
                                                    type="checkbox"
                                                    className="ftc-checkbox"
                                                    id={index + 1}
                                                    checked={chef.isChecked}
                                                    onChange={(e) => {
                                                        onChefChange(e, chef, index);
                                                    }}
                                                />
                                                <label className="position-relative ml-4 pl-2" htmlFor={chef._id}>{chef.username}</label>
                                            </Form.Group>
                                        </Col>
                                    );
                                })
                            }

                        </Row>
                    </div>
                );

            case 'city':
                return (
                    <Row>
                        <Col md="3" xs="6">
                            <Form.Label>Cities</Form.Label>
                        </Col>
                        <Col md="9" xs="6">
                            <a
                                className="txt-primary float-right"
                                role="button"
                                onClick={removeAllCities}
                                onKeyDown={() => { }}
                                tabIndex={-1}
                            >Deselect All</a>
                        </Col>
                        <Col md="12" xs="12">
                            <Form.Group controlId="formGroupEmail">
                                <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    value={searchCity}
                                    onChange={(e) => setsearchCity(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        {
                            filteredCities.map((city, index) => {
                                return (
                                    <Col md="4" xs="12" key={city._id}>
                                        <Form.Group controlId="formBasicCheckbox">
                                            <input
                                                type="checkbox"
                                                className="ftc-checkbox"
                                                id={index + 1}
                                                checked={city.isChecked}
                                                onChange={(e) => {
                                                    onCityChange(e, city, index);
                                                }}
                                            />
                                            <label className="position-relative ml-4 pl-2" htmlFor={city._id}>{city.cityname}</label>
                                        </Form.Group>
                                    </Col>
                                );
                            })
                        }

                    </Row>
                );

            default:
                break;
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Filter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="modal__row">
                    <Desktop>
                        <Col md="3" className="modal__Col_3_Padding border__right sticky-top">
                            <ul className="modal__ul">
                                {
                                    !isChefUser && (
                                        <li
                                            className={`${option === 'status' ? 'mb__modal_filter_active' : ''} modal__li`}
                                            onClick={() => setOption('status')}
                                            onKeyPress={() => { }}
                                            role="menuitem"
                                        >
                                            <span className="modal__list__padding">Status</span> {' '}
                                            <Image
                                                className="icon_arrow float-right"
                                                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                                fluid
                                            />
                                        </li>
                                    )
                                }
                                <li
                                    className={`${option === 'city' ? 'mb__modal_filter_active' : ''} modal__li`}
                                    onClick={() => setOption('city')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">City</span>{' '}
                                    <Image
                                        className="icon_arrow float-right"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                        fluid
                                    />{' '}
                                </li>
                                {
                                    !isChefUser && (
                                        <li
                                            className={`${option === 'chef' ? 'mb__modal_filter_active' : ''} modal__li`}
                                            onClick={() => setOption('chef')}
                                            role="menuitem"
                                            onKeyPress={() => { }}
                                        >
                                            <span className="modal__list__padding">Chef</span>{' '}
                                            <Image
                                                className="icon_arrow float-right"
                                                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                                                fluid
                                            />
                                        </li>
                                    )
                                }

                            </ul>
                        </Col>
                    </Desktop>
                    <Mobile>
                        <Col md="3" xs="4" className="modal__Col_3_Padding border__right modal-filters">
                            <ul className="modal__ul">
                                {
                                    !isChefUser && (
                                        <li
                                            className={`${option === 'status' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                            onClick={() => setOption('status')}
                                            onKeyPress={() => { }}
                                            role="menuitem"
                                        >
                                            <span className="modal__list__padding">Status</span> {' '}
                                        </li>
                                    )
                                }
                                <li
                                    className={`${option === 'city' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                    onClick={() => setOption('city')}
                                    role="menuitem"
                                    onKeyPress={() => { }}
                                >
                                    <span className="modal__list__padding">City</span>{' '}
                                </li>
                                {
                                    !isChefUser && (
                                        <li
                                            className={`${option === 'chef' ? 'mb__modal_filter_active' : ''} mb__modal__li`}
                                            onClick={() => setOption('chef')}
                                            role="menuitem"
                                            onKeyPress={() => { }}
                                        >
                                            <span className="modal__list__padding">Chef</span>{' '}
                                        </li>
                                    )
                                }

                            </ul>
                        </Col>
                    </Mobile>
                    <Col md="9" xs="8">
                        {filterDetailsByOption()}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={() => {
                    onClearAll();
                    // handleClose();
                }}>
                    Clear All
                </Button>
                <Button
                    variant="default"
                    className="btn-ftc-border-primary"
                    onClick={() => {
                        applyChanges(filters);
                    }}
                >
                    Apply Changes
                </Button>
            </Modal.Footer>
        </Modal>

    )
});

export default ClientFilters

ClientFilters.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    applyChanges: PropTypes.func.isRequired,
    allFilters: PropTypes.any.isRequired,
    clearAll: PropTypes.func.isRequired,
};