/* eslint-disable react/react-in-jsx-scope */
import moment from 'moment';
import { Image, Button, Alert } from 'react-bootstrap';
import { validatePermission } from "./UserManagement";
import IconTrue from '../assets/images/icon-true.svg';
import { Desktop,Mobile } from '../layouts/media-queries/mediaQueries';
import '../layouts/Timesheet/css/timesheet.css';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const minuteToTimeStr = (minutes) => {
  // let tempMinutes = minutes;
  // let hour = Math.floor(tempMinutes / 60);
  // tempMinutes %= 60;

  // if (hour >= 12) {
  //   hour %= 12;
  //   hour += 12;
  // }

  // if (hour === 0) {
  //   hour = 12;
  // }
  const hour = Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);

  return `${hour}h ${mins}m`;
};

const minuteToTimeFullStr = (minutes) => {
  // let tempMinutes = minutes;
  // let hour = Math.floor(tempMinutes / 60);
  // tempMinutes %= 60;

  // if (hour >= 12) {
  //   hour %= 12;
  //   hour += 12;
  // }

  // if (hour === 0) {
  //   hour = 12;
  // }

  //   return `${hour} Hr ${tempMinutes} Min`;

  const hour = Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);

  return `${hour} Hr ${mins} Min`;
};

const minuteToTime = (minutes) => {
  let tempMinutes = minutes;
  let hour = Math.floor(tempMinutes / 60);
  tempMinutes %= 60;

  let p = 'AM';

  if (hour >= 12) {
    hour %= 12;
    p = 'PM';
  }

  if (hour === 0) {
    hour = 12;
  }

  return `${hour < 10 ? `0${hour}` : hour}:${
    tempMinutes < 10 ? `0${tempMinutes}` : tempMinutes
  } ${p}`;
};

const minuteToDateTime = (minutes) => {
  let tempMinutes = minutes;
  const hour = Math.floor(tempMinutes / 60);
  tempMinutes %= 60;

  // if (hour >= 12) {
  //   hour %= 12;
  // }

  // if (hour === 0) {
  //   hour = 12;
  // }

  const formattedDate = new Date();
  formattedDate.setHours(hour);
  formattedDate.setMinutes(tempMinutes);

  return formattedDate;
};

const timeToMinutes = (time) => {
  const date = new Date(time);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const totalMinutes = hours * 60 + minutes;
  // console.log({totalMinutes, date});
  return totalMinutes;
};

const DateConvert = (date) => {
  // console.log({date});
  return moment(date).utcOffset(0).format('MM/DD/YYYY');
  // return moment.utc(date).format('MM/DD/YYYY');
  // const d = new Date(date);
  // return `${d.toLocaleDateString('en-US', {
  //     day: '2-digit',
  //     month: '2-digit',
  //     year: 'numeric',
  // })}`;
};

const fullDateConvert = (date) => {
  return moment(date).format('MMM DD, YYYY');
};

const holdingFeeNumbers = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
];

const getRatePlans = (chooseRate) => {
  switch (chooseRate) {
    case 'Non-Billable':
      return { label: 'Non-Billable', value: 'non_billable_rate' };

    case 'Basic Rate':
      return { label: 'Basic Rate', value: 'basic_rate' };

    case 'Prime Rate':
      return { label: 'Prime Rate', value: 'prime_rate' };

    case 'Special Rate':
      return { label: 'Special Rate', value: 'special_rate' };

    default:
      break;
  }
};

const calculateTotalTime = (minutes) => {
  const hour = Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);
  const total = hour + mins / 60;
  return total.toFixed(2);
};

const minutesToDecimalHour = (minutes) => {
  if (!minutes) {
    return 0;
  }
  const hours = Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);
  const total = hours + mins / 60;
  return total.toFixed(2);
};

const getPaymentStatusLabel = (status) => {
  switch (status) {
    case 'pending':
      return 'Pending';
    case 'paid':
      return 'Paid';
    default:
      return null;
  }
};

const getAcknowledgeStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'Pending';
    case 'approved':
      return 'Approved';
    case 'denied':
      return 'Denied';
    default:
      return null;
  }
};

const checkShowAcknowledgeButton = (
  roleName,
  permissions,
  status,
  acknoledgeDate,
  acknowledgedBy,
  acknowledge
) => {
  const pendingAlert = (
    <Alert variant="warning" className="d-inline ml-2 mt-2 alertStyle">
      Acknowledge Pending{' '}
      <Image
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-alert.svg`}
      />
    </Alert>
  );
  const acknowledgedTrueIcon = (
    // eslint-disable-next-line react/react-in-jsx-scope
    <>
    <Desktop><Image src={IconTrue} alt="icon" title={acknowledgedBy} className="ml-2" /></Desktop>
    <Mobile>
      <div className='hover-text'>
      <Image src={IconTrue} alt="icon" title={acknowledgedBy} className="ml-2" />
      <span className="tooltip-text" id="bottom">{acknowledgedBy}</span>
      </div>
      
    </Mobile>
    </>
  );
  switch (roleName) {
    case 'Admin':
    case 'Manager':
      if (status === false) {
        return (
          // eslint-disable-next-line react/react-in-jsx-scope
          <Button
            // variant="default"
            className="btn-ftc-primary btn  ml-2"
            onClick={acknowledge}
          >
            Acknowledge
          </Button>
        );
      }
      if (status === true) {
        return acknowledgedTrueIcon;
      }
      break;

    // case 'Manager':
    //   if (status === true) {
    //     return acknowledgedTrueIcon;
    //   }
    //   if (status === false) {
    //     const startOfWeek = moment()
    //       .utc()
    //       .startOf('week')
    //       .utcOffset(0)
    //       .format('YYYY-MM-DD');
    //     const endOfWeek = moment()
    //       .utc()
    //       .endOf('week')
    //       .utcOffset(0)
    //       .format('YYYY-MM-DD');
    //     const isCurrentWeek = moment(
    //       moment(acknoledgeDate).utcOffset(0).format('YYYY-MM-DD')
    //     ).isBetween(startOfWeek, endOfWeek, undefined, '[]');
    //     return isCurrentWeek ? (
    //       // eslint-disable-next-line react/react-in-jsx-scope

    //       <Button className="btn-ftc-primary btn  ml-2" onClick={acknowledge}>
    //         Acknowledge
    //       </Button>
    //     ) : (
    //       pendingAlert
    //     );
    //   }
    //   break;

    case 'Office':
      if (status === true) {
        return acknowledgedTrueIcon;
      }
      if (status === false) {
        const startOfWeek = moment()
          .utc()
          .startOf('week')
          .utcOffset(0)
          .format('YYYY-MM-DD');
        const endOfWeek = moment()
          .utc()
          .endOf('week')
          .utcOffset(0)
          .format('YYYY-MM-DD');
        const isCurrentWeek = moment(
          moment(acknoledgeDate).utcOffset(0).format('YYYY-MM-DD')
        ).isBetween(startOfWeek, endOfWeek, undefined, '[]');
        return isCurrentWeek ? (
          // eslint-disable-next-line react/react-in-jsx-scope
          <Button className="btn-ftc-primary btn  ml-2" onClick={acknowledge}>
            Acknowledge
          </Button>
        ) : (
          pendingAlert
        );
      }
      break;

    case 'Chef': {
      if (status === false) {
        return pendingAlert;
      }
      if (status === true) {
        return acknowledgedTrueIcon;
      }
      break;
    }

    case 'Accountant': {
      if (status === false) {
        return pendingAlert;
      }
      if (status === true) {
        return acknowledgedTrueIcon;
      }
      break;
    }

    default:
      return validatePermission(permissions, 'timesheet', 'edit');
  }
};

const checkTimesheetEditable = (
  roleName,
  permissions,
  status,
  timesheetDate
) => {
  // if(status === 'paid' && roleName === 'Admin') {
  //   return true;
  // }
  // if (status === 'paid' && roleName !== 'Admin')
  // {
  //   return false;
  // }
  // if (status === 'pending')
  // {
  //   return true;
  // }
  // return false;

  switch (roleName) {
    case 'Admin':
      return true;

    case 'Manager':
      if (status === 'pending') {
        return true;
      }
      if (status === 'paid') {
        return false;
      }
      break;

    case 'Office': {
      if (status === 'paid') {
        return false;
      }
      if (status === 'pending') {
        const startOfWeek = moment()
          .utc()
          .startOf('week')
          .utcOffset(0)
          .format('YYYY-MM-DD');
        const endOfWeek = moment()
          .utc()
          .endOf('week')
          .utcOffset(0)
          .format('YYYY-MM-DD');
        const isCurrentWeek = moment(
          moment(timesheetDate).utcOffset(0).format('YYYY-MM-DD')
        ).isBetween(startOfWeek, endOfWeek, undefined, '[]');
        return isCurrentWeek;
      }
      break;
    }

    case 'Chef': {
      if (status === 'paid') {
        return false;
      }
      if (status === 'pending') {
        const startOfWeek = moment()
          .utc()
          .startOf('week')
          .utcOffset(0)
          .format('YYYY-MM-DD');
        const endOfWeek = moment()
          .utc()
          .endOf('week')
          .utcOffset(0)
          .format('YYYY-MM-DD');
        const isCurrentWeek = moment(
          moment(timesheetDate).utcOffset(0).format('YYYY-MM-DD')
        ).isBetween(startOfWeek, endOfWeek, undefined, '[]');

        return isCurrentWeek;
      }
      break;
    }

    case 'Accountant':
      return false;

    default:
      return validatePermission(permissions, 'timesheet', 'edit');
  }
};

const checkTimesheetDeletable = (
  roleName,
  permissions,
  paymentStatus,
  timesheetDate
) => {
  switch (roleName) {
    case 'Admin':
    case 'Manager':
      return true;

    case 'Office':
    case 'Chef': {
      const startOfWeek = moment()
        .utc()
        .startOf('week')
        .utcOffset(0)
        .format('YYYY-MM-DD');
      const endOfWeek = moment()
        .utc()
        .endOf('week')
        .utcOffset(0)
        .format('YYYY-MM-DD');
      const isCurrentWeek =
        paymentStatus === 'pending' &&
        moment(
          moment(timesheetDate).utcOffset(0).format('YYYY-MM-DD')
        ).isBetween(startOfWeek, endOfWeek, undefined, '[]');
      return isCurrentWeek;
    }

    case 'Accountant':
      return false;

    default:
      return validatePermission(permissions, 'timesheet', 'delete');
  }
};

const showExportButton = (roleName) => {
  switch (roleName) {
    case 'Admin':
      return true;
    default:
      return false;
  }
}

const showTimesheetLastUpdated = (roleName) => {
  switch (roleName) {
    case 'Admin':
    case 'Manager':
    case 'Accountant':
      return true;

    case 'Office':
    case 'Chef':
      return false;

    default:
      return false;
  }
};

export {
  minuteToTimeStr,
  minuteToTimeFullStr,
  minuteToTime,
  timeToMinutes,
  DateConvert,
  minuteToDateTime,
  holdingFeeNumbers,
  getRatePlans,
  calculateTotalTime,
  minutesToDecimalHour,
  fullDateConvert,
  getPaymentStatusLabel,
  getAcknowledgeStatus,
  checkTimesheetEditable,
  checkTimesheetDeletable,
  showTimesheetLastUpdated,
  checkShowAcknowledgeButton,
  showExportButton
};
