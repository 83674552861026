import React from 'react';
// import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
// import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

import Image from 'react-bootstrap/Image';
import IconReimbursement from '../../assets/images/icon-reimbursement.svg';
import IconReimbursementBlack from '../../assets/images/icon-reimbursement-black.svg'
import IconReimbursementSetup from '../../assets/images/icon-reimbursement-setup.svg'

import './css/sideBar.css';

const SidebarData = [
  {
    title: 'Timesheet',
    path: '/timesheet',
    icon: (
      <Image
        className="ftc-logo-28"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icons-timesheet.svg`}
        fluid
      />
    ),
    landingIcon: (
      <Image
        className="ftc-logo-30"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icons-timesheet-red.svg`}
      />
    ),
  },
  {
    title: 'Settings',
    icon: (
      <Image
        className="ftc-logo-28"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-settings.svg`}
        fluid
      />
    ),
    landingIcon: (
      <Image
        className="ftc-logo-30"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-setting-red.svg `}
      />
    ),
    iconClosed: (
      <Image
        className="svg__icon__size__arrow__close"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
        fluid
      />
    ),
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Users',
        path: '/settings/users',
        icon: (
          <Image
            className="ftc-logo-28"
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-userrole-dark.svg`}
            fluid
          />
        ),
      },
      {
        title: 'User Roles',
        path: '/settings/userroles',
        icon: (
          <Image
            className="ftc-logo-28"
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-userrole-dark-2.svg`}
            fluid
          />
        ),
      },
      {
        title: 'Non-Billable',
        path: '/settings/nonbillable',
        icon: (
          <Image
            className="ftc-logo-28"
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-non-billable-reason.svg`}
            fluid
          />
        ),
      },
      {
        title: 'Clients',
        path: '/settings/clients',
        icon: (
          <Image
            className="ftc-logo-28"
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-client-dark.svg`}
            fluid
          />
        ),
      },
      {
        title: 'Cities',
        path: '/settings/cities',
        icon: (
          <Image
            className="ftc-logo-28"
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-location.svg`}
            fluid
          />
        ),
      },
      {
        title: 'Reimbursement',
        path: '/settings/reimbursement-setup',
        icon: (
          <Image className="ftc-logo-28" src={IconReimbursementSetup} fluid />
        ),
      },
      {
        title: 'Custom Pages',
        path: '/settings/custompages',
        icon: (
          <Image
            className="ftc-logo-28"
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-add-red.svg`}
            fluid
          />
        ),
      },
      {
        title: 'Custom Pop Ups',
        path: '/settings/custompopups',
        icon: (
          <Image
            className="ftc-logo-28"
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-add-red.svg`}
            fluid
          />
        ),
      },
      {
        title: 'Pay Period Setup',
        path: '/settings/pay-calendar',
        icon: (
          <Image
            className="ftc-logo-28"
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-calendar.svg`}
            fluid
          />
        ),
      },
    ],
  },
  {
    title: 'Reports',
    icon: (
      <Image
        className="ftc-logo-28"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-report.svg`}
        fluid
      />
    ),
    landingIcon: (
      <Image
        className="ftc-logo-30"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-report-red.svg `}
      />
    ),
    iconClosed: (
      <Image
        className="svg__icon__size__arrow__close"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
        fluid
      />
    ),
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Hours',
        path: '/reports/hours',
        icon: (
          <Image
            className="ftc-logo-28"
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icons-timesheet.svg`}
            fluid
          />
        ),
      },
      {
        title: 'Payroll',
        path: '/reports/payroll',
        icon: (
          <Image
            className="ftc-logo-30"
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-money.svg`}
            fluid
          />
        ),
      },
      // {
      //   title: 'Custom Page',
      //   path: '/reports/custompage',
      //   icon: (
      //     <Image
      //       className="svg__sub-icon__size"
      //       src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
      //       fluid
      //     />
      //   ),
      // },
    ],
  },
  {
    title: 'Forms',
    icon: (
      <Image
        className="ftc-logo-28"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-form.svg`}
        fluid
      />
    ),
    landingIcon: (
      <Image
        className="ftc-logo-30"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-form-red.svg `}
      />
    ),
    iconClosed: (
      <Image
        className="svg__icon__size__arrow__close"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
        fluid
      />
    ),
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      // {
      //   title: 'Custom Page',
      //   path: '/forms/custompage',
      //   icon: (
      //     <Image
      //       className="svg__sub-icon__size"
      //       src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-star-custom.svg`}
      //       fluid
      //     />
      //   ),
      // },
    ],
  },
  {
    title: 'Tools',
    // path: '/tools',
    icon: (
      <Image
        className="ftc-logo-28"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-tools.svg`}
        fluid
      />
    ),
    landingIcon: (
      <Image
        className="ftc-logo-30"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-tool-red.svg `}
      />
    ),
    iconClosed: (
      <Image
        className="svg__icon__size__arrow__close"
        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
        fluid
      />
    ),
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [],
  },
  {
    title: 'Reimbursement',
    path: '/reimbursement',
    icon: <Image className="ftc-logo-28" src={IconReimbursementBlack} fluid />,
    landingIcon: <Image className="ftc-logo-30" src={IconReimbursement} />,
  },
];

export default SidebarData;
