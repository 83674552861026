import React, { memo, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useFormContext, } from 'react-hook-form';
import PropTypes from 'prop-types';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { getContentTypeLabel } from '../../../utils/UserManagement';

const CustomPageContentForm = memo(({
    formContent,
}) =>
{
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const { register, formState: { errors, isSubmitted }, getValues, watch, setValue } = useFormContext();

    const ContentTypes = [
        'text',
        'html',
        'markup'
    ];
    const [selectedContent, setselectedContent] = useState('');

    const contentType = watch('contentType');
    const content = watch('content');

    useEffect(() =>
    {
        if(!formContent?.two?.contentType) {
            setValue('contentType', 'text', { shouldDirty: true, shouldValidate: true });
        }
    }, [formContent]);

    const changeContentType = () =>
    {
        setValue('contentType', selectedContent, { shouldDirty: true, shouldValidate: true });
        setValue('content', '', { shouldDirty: true, shouldValidate: true });
        handleClose();
    }

    return (
        <div>
            <Row>
                <Col md="12">
                    <label className="txt-light-secondary">
                        Choose Content Type
                    </label>
                </Col>
                {
                    ContentTypes.map((location) =>
                    {
                        return (
                            <Col md="3">
                                <Form.Group>
                                    <Button variant="default"
                                        className={getValues('contentType') === location ?
                                            'ftc-radiobutton-border-checked pb-0 btn-border-round' :
                                            'ftc-radiobutton-border-unchecked pb-0 btn-border-round'
                                        }
                                    >
                                        <input
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...register('contentType')}
                                            onChange={(e) =>
                                            {
                                                setselectedContent(e.target.value);
                                                setShow(true);
                                            }}
                                            type="radio"
                                            className="ftc-radiobutton-secondary "
                                            value={location}
                                        />
                                        <Form.Label htmlFor={location} className="position-relative ml-4 pl-2">
                                            {getContentTypeLabel(location)}
                                        </Form.Label>
                                    </Button>
                                </Form.Group>
                            </Col>
                        );
                    })
                }
                {errors.contentType && (
                    <Col md="12">
                        <p className="text-danger">
                            {errors.contentType.message}
                        </p>
                    </Col>
                )}                
            </Row>
            <Row>
                <Col md="12">
                    <label className="txt-light-secondary">
                        Content
                    </label>

                    {
                        contentType === 'text' && (
                            <CKEditor
                                editor={ClassicEditor}                                
                                data={content}
                                onReady={(editor) =>
                                {
                                    console.log( 'Editor is ready to use!', editor );
                                }}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    console.log({ event, editor, data });
                                    setValue('content', data, { shouldDirty: true, shouldValidate: true });
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                        )
                    }

                    {
                        contentType === 'html'  && (
                            <textarea
                                {...register('content')}
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="12"
                            />
                        )
                    }                                    
                    
                    {
                        contentType === 'markup' && (
                            <textarea
                                {...register('content')}
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="2"
                            />
                        )
                    }
                </Col>
                {(errors.content && isSubmitted) && (
                    <Col md="12">
                        <p className="text-danger">
                            {errors.content.message}
                        </p>
                    </Col>
                )}  
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Switch to Other Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Switching from current editor to other editor, would discard all the changes and
                    content added in current content area. Are you sure you want to change it?
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-ftc-border-primary" onClick={handleClose}>
                        Cancel
                    </button>
                    <button type="button" className="btn btn-ftc-primary" onClick={changeContentType}>
                        Switch to Content
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
                
    )
});

export default CustomPageContentForm

CustomPageContentForm.propTypes = {
  formContent: PropTypes.any.isRequired,
//   timesheetId: PropTypes.string,
//   isAddMode: PropTypes.bool.isRequired,
};