import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import qs from 'query-string';
import { openDB } from 'idb';

import moment from 'moment';
import './css/timesheet.css';
import TablePagination from '@material-ui/core/TablePagination';

import { Button, Row, Col, Image, InputGroup, Form } from 'react-bootstrap';
import { Dialog, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';
import OfflineDataTable from 'react-data-table-component';

import Toaster from '../../components/Toaster';
import DataTableComponent from '../../components/DataTableComponent';
import TablePaginationActions from '../../components/TablePaginationActions/TablePaginationActions';
import ConfirmAlertModal from '../../components/ConfirmAlertModal/ConfirmAlertModal';
import Sidebar from '../side-navbar/Sidebar';
import TimesheetFilters from './TimesheetFilters';
import SettingsDropdown from '../../components/SettingsDropdown/SettingsDropdown';
import RenderCustomPopup from '../../components/RenderCustomPopup';

import TableMobileView from './mobile-layouts/TableMobileView';
import { Desktop, Mobile } from '../media-queries/mediaQueries';

import {
  getAllTimesheets,
  deleteTimesheetById,
} from '../../services/timesheet.service';
import {
  checkTimesheetDeletable,
  checkTimesheetEditable,
  DateConvert,
  getPaymentStatusLabel,
  minuteToTimeStr,
  showExportButton,
  minuteToTime,
} from '../../utils/TimesheetManagement';
import { validatePermission } from '../../utils/UserManagement';
import { useAuthContext } from '../../contexts/user-context';
import { useOfflineTimesheetSyncContext } from '../../contexts/offlineTimesheetContext';
import useNavigator from '../../utils/CustomHooks/useNavigator';
import useQuery from '../../utils/CustomHooks/useQuery';
import exportIcon from '../../assets/images/exportIcon.svg';
// import Loading from '../../components/Loading/Loading';

import NoCustomPageComponent from '../../components/NoCustomPageComponent/NoCustomPageComponent';
import { getAllNonBillablesByAccess } from '../../services/non_billable.service';
import { getClientsByChef } from '../../services/client-service';
import { exportToCSV } from '../../utils/ExportDataToCSV';
import Loader from '../../assets/images/loader.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Timesheet = memo(() => {
  const history = useHistory();
  const URLQuery = useQuery();
  const location = useLocation();
  const { authUser } = useAuthContext();
  const { isSyncing, reload, sync } = useOfflineTimesheetSyncContext();

  const [open, setOpen] = React.useState(false);
  const [sidebarState, setsidebarState] = useState();
  const [customPopupRender, setcustomPopupRender] = useState(false);

  // custom hook for checking network status
  const isOnline = useNavigator();

  const [filtersFetched, setFiltersFetched] = useState(false);
  const [triggerTSFetch, setTriggerTSFetch] = useState(false);

  const [show, setShow] = useState(false);

  const [showConfirmAlert, setConfirmAlert] = useState(false);

  const isIndeterminate = (indeterminate) => indeterminate;
  const selectableRowsComponentProps = { indeterminate: isIndeterminate };

  const [isAPICalled, setAPICalled] = useState(false);
  const [exportAPICalled, setExportAPICalled] = useState(false);
  const [timesheets, setTimesheets] = useState([]);
  const [timesheetCount, setTimesheetCount] = useState({
    totalCount: 0,
    filterCount: 0,
  });

  const [offlineTimesheets, setOfflineTimesheets] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filters, setfilters] = useState(null);
  const [query, setquery] = useState('');

  const [selectedRow, setselectedRow] = useState();

  const [employee, setemployee] = useState(URLQuery.get('employee') ?? '');
  const [client, setclient] = useState(URLQuery.get('client') ?? '');
  const [searchQuery, setsearchQuery] = useState({
    employee: URLQuery.get('employee') ?? '',
    client: URLQuery.get('client') ?? '',
  });

  const isMobileView = useMediaQuery({ maxWidth: 767 });

  const handleClickDelete = (state) => {
    setselectedRow(state.target.id);
    setConfirmAlert(true);
  };

  const handleRowClicked = (row) => {
    history.push(`/viewTimesheet/${row.timesheetId}`);
  };

  const onAddNew = () => {
    history.push('/addNewTimesheet');
  };

  const handleViewClick = (state) => {
    history.push(`/viewTimesheet/${state.target.id}`);
  };

  const handleEditClicked = (state) => {
    history.push(`/editTimesheet/${state.target.id}`);
  };

  const handleOfflineRowClicked = async (row) => {
    history.push(`/viewTimesheet/${row.uniqueId}`);
  };

  const handleOfflineViewClick = (state) => {
    history.push(`/viewTimesheet/${state.target.id}`);
  };

  const handleOfflineEditClick = (state) => {
    history.push(`/editTimesheet/${state.target.id}`);
  };

  const columns = [
    {
      name: 'Date',
      selector: (row) => DateConvert(row.ts_date),
      sortable: true,
    },
    {
      name: 'Employee',
      selector: 'employee.username',
      sortable: true,
      wrap: true,
    },
    {
      name: 'City',
      selector: (row) => row.service_city?.cityname ?? 'N/A',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Client',
      selector: (row) => row.client?.username ?? 'N/A',
      sortable: true,
      wrap: true,
      omit: authUser.user.role.rolename === 'Office',
    },
    {
      name: 'Time (hh:mm)',
      selector: (row) => minuteToTimeStr(row.totalTime),
      sortable: true,
    },
    {
      name: 'Total Due',
      selector: (row) => `$${row.totalDue}`,
      sortable: true,
      minWidth: '50px',
    },
    {
      name: 'Payment',
      selector: (row) => `$${row.paymentStatus}`,
      sortable: true,
      minWidth: '120px',
      cell: (row) => {
        // const status = 'active'
        const { payment_status: status } = row;
        return (
          <div>
            <label
              className={
                status === 'pending' ? 'label-pending' : 'label-active'
              }
            >
              <span
                className={
                  status === 'pending' ? 'pending-ellipse' : 'active-ellipse'
                }
              />
              {` `}
              {getPaymentStatusLabel(status)}
            </label>
          </div>
        );
      },
    },
    {
      // eslint-disable-next-line no-underscore-dangle
      cell: (row) => {
        const {
          timesheetId,
          payment_status: paymentStatus,
          ts_date: tsDate,
        } = row;
        return (
          <label>
            <SettingsDropdown
              identifier={timesheetId}
              handleViewClick={handleViewClick}
              handleEditClicked={handleEditClicked}
              handleClickDelete={handleClickDelete}
              drop="left"
              isEditable={
                !checkTimesheetEditable(
                  authUser.user.role.rolename,
                  authUser.user.role.permissions,
                  paymentStatus,
                  tsDate
                )
              }
              isDeletable={
                !checkTimesheetDeletable(
                  authUser.user.role.rolename,
                  authUser.user.role.permissions,
                  paymentStatus,
                  tsDate
                )
              }
            />
          </label>
        );
      },
      allowOverflow: true,
      button: true,
      width: '56px', // custom width for icon button
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.isAcknowledged === false &&
        row.adjustment !== null &&
        row.adjustment !== 0,
      style: {
        backgroundColor: '#fff4f4',
        border: '1px solid #a50808',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: '#fff4f4',
        },
      },
    },
  ];

  const columnsOffline = [
    {
      name: 'Date',
      selector: (row) => DateConvert(row.ts_date),
      sortable: true,
    },
    {
      name: 'Employee',
      selector: 'employee.username',
      sortable: true,
      wrap: true,
    },
    {
      name: 'City',
      selector: (row) => row.service_city?.cityname ?? 'N/A',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Client',
      selector: (row) => row.client?.username ?? 'N/A',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Time (hh:mm)',
      selector: (row) => minuteToTimeStr(row.totalTime),
      sortable: true,
    },
    {
      name: 'Total Due',
      selector: (row) => `$${row.totalDue}`,
      sortable: true,
      minWidth: '50px',
    },
    {
      name: 'Payment',
      selector: (row) => `$${row.paymentStatus}`,
      sortable: true,
      minWidth: '120px',
      cell: (row) => {
        // const status = 'active'
        const { payment_status: status } = row;
        return (
          <div>
            <label
              className={
                status === 'pending' ? 'label-pending' : 'label-active'
              }
            >
              <span
                className={
                  status === 'pending' ? 'pending-ellipse' : 'active-ellipse'
                }
              />
              {` `}
              {getPaymentStatusLabel(status)}
            </label>
          </div>
        );
      },
    },
    {
      cell: () => {
        // const status = 'active'
        return (
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-switch-off.svg`}
              fluid
            />
          </div>
        );
      },
      width: '65px',
    },
    {
      // eslint-disable-next-line no-underscore-dangle
      cell: (row) => {
        const {
          uniqueId,
          payment_status: paymentStatus,
          ts_date: tsDate,
        } = row;
        return (
          <label>
            <SettingsDropdown
              identifier={uniqueId}
              handleViewClick={handleOfflineViewClick}
              handleEditClicked={handleOfflineEditClick}
              handleClickDelete={handleClickDelete}
              drop="left"
              isEditable={
                !checkTimesheetEditable(
                  authUser.user.role.rolename,
                  authUser.user.role.permissions,
                  paymentStatus,
                  tsDate
                )
              }
              isDeletable={
                !checkTimesheetDeletable(
                  authUser.user.role.rolename,
                  authUser.user.role.permissions,
                  paymentStatus,
                  tsDate
                )
              }
            />
          </label>
        );
      },
      allowOverflow: true,
      button: true,
      width: '56px', // custom width for icon button
    },
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseConfirm = () => setConfirmAlert(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    localStorage.setItem('popup_state', 'shown');
    setcustomPopupRender(true);
  };

  const openSidebarMenu = () => {
    setOpen(false);
    setsidebarState(true);
    localStorage.setItem('popup_state', 'shown');
  };

  const onCloseSidebar = () => {
    setsidebarState(false);
    setcustomPopupRender(true);
  };

  async function fetchAllPagesTimeSheet() {
    try {
      setExportAPICalled(true);
      const response = await trackPromise(
        getAllTimesheets(
          timesheetCount.totalCount,
          0,
          searchQuery.employee,
          searchQuery.client,
          query
        )
      );
      const transformedData = response.data.data.timesheets.map((item) => ({
        employeename: item.employee ? item.employee.username : 'N/A', // Assuming employee has a name field
        cityname: item.service_city ? item.service_city.cityname : 'N/A',
        clientname: item.client ? item.client.username : 'N/A', // Assuming client has a name field
        date: DateConvert(item.ts_date),
        driveTime: item.driveTime ? `${item.driveTime} Minutes` : 'N/A',
        flexPayHours: item.flexPayHours ? `${item.flexPayHours} Hrs` : 'N/A',
        arriveTime:
          item?.arriveTime || item.arriveTime === 0
            ? minuteToTime(item.arriveTime)
            : 'N/A',
        departTime:
          item?.departureTime || item.departureTime === 0
            ? minuteToTime(item.departureTime)
            : 'N/A',
        totalTime: minuteToTimeStr(item.totalTime),
        holdingFeeNumber: item.holding_fee_number,
        tip: item?.tip ? item.tip : '0.00',
        currentHoldingFee: item.current_holding_fee,
        ratePlan: item.choose_rate,
        rate: item.rate,
        adjustment: item.adjustment ?? '0.00',
        totaldue: item.totalDue,
        comment: item.comments,
        paymentStatus: item.payment_status,
      }));
      exportToCSV(transformedData, `timesheet_data_${DateConvert(new Date())}`);
      setExportAPICalled(false);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
      setExportAPICalled(false);
    }
  }

  async function fetchAllTimesheets() {
    try {
      // check if online then fetch from API
      if (isOnline === true) {
        const response = await trackPromise(
          getAllTimesheets(
            rowsPerPage,
            page,
            searchQuery.employee,
            searchQuery.client,
            query
          )
        );
        setTimesheets(response.data.data.timesheets);
        setTimesheetCount({
          filterCount: response.data.data.filterCount,
          totalCount: response.data.data.totalCount,
        });
        setAPICalled(true);
      }

      const dbs = await openDB('order', 1, {
        upgrade(db) {
          // Create a store of objects
          const store = db.createObjectStore('order_requests', {
            // The 'uniqueId' property of the object will be the key.
            keyPath: 'uniqueId',
            // If it isn't explicitly set, create a value by auto incrementing.
            // autoIncrement: true,
          });
          // Create an index on the 'date' property of the objects.
          store.createIndex('uniqueId', 'uniqueId');
        },
      });
      const value = await dbs.getAll('order_requests');
      setOfflineTimesheets(value);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const onDeleteTimesheet = async () => {
    try {
      if (isOnline) {
        const response = await trackPromise(deleteTimesheetById(selectedRow));
        toast.dark(
          <Toaster icon="notify" message={`${response.data.message}`} />,
          { autoClose: 3000, hideProgressBar: true }
        );
      } else {
        const db = await openDB('order');
        await db.delete('order_requests', IDBKeyRange.only(selectedRow));
        toast.dark(
          <Toaster
            icon="notify"
            message="Timesheet details removed from local data."
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setConfirmAlert(false);
      // history.push('/timesheet');
      fetchAllTimesheets();
    }
  };

  async function getFilters() {
    try {
      const dbs = await openDB('filters', 1, {
        upgrade(db) {
          // Create a store of objects
          const store = db.createObjectStore('filters_data', {
            // The 'moduleName' property of the object will be the key.
            keyPath: 'moduleName',
            // If it isn't explicitly set, create a value by auto incrementing.
            // autoIncrement: true,
          });
          // Create an index on the 'date' property of the objects.
          store.createIndex('moduleName', 'moduleName');
        },
      });
      const filtersData = await dbs.get(
        'filters_data',
        IDBKeyRange.only('timesheet')
      );

      setFiltersFetched(true);

      if (filtersData) {
        setfilters(filtersData.filterObj);
      } else {
        setFiltersFetched((val) => {
          setTriggerTSFetch(true);
          return val;
        });
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function prefetchAPIs() {
    try {
      switch (authUser.user.role.rolename) {
        case 'Chef': {
          const [nonbillablesRes, clientRes] = await Promise.all([
            trackPromise(getAllNonBillablesByAccess(authUser.user.role._id)),
            trackPromise(getClientsByChef(authUser.user._id)),
          ]);

          if (clientRes.status === 204) {
            localStorage.setItem('clients', JSON.stringify([]));
          } else {
            localStorage.setItem(
              'clients',
              JSON.stringify(clientRes.data.data.clients)
            );
          }

          localStorage.setItem(
            'nonBillables',
            JSON.stringify(nonbillablesRes.data.data.non_billables)
          );

          localStorage.setItem('prefetchAPI', 'called');

          break;
        }

        case 'Office': {
          const nonbillablesRes = await trackPromise(
            getAllNonBillablesByAccess(authUser.user.role._id)
          );

          localStorage.setItem(
            'nonBillables',
            JSON.stringify(nonbillablesRes.data.data.non_billables)
          );
          localStorage.setItem('prefetchAPI', 'called');
          break;
        }

        default:
          break;
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  useEffect(() => {
    // display popup only in mobile for Chef Role
    if (isMobileView && authUser?.user.role.rolename === 'Chef') {
      if (localStorage.getItem('popup_state') !== 'shown') {
        handleClickOpen();
      } else {
        setcustomPopupRender(true);
      }
    }

    if (
      (authUser?.user.role.rolename === 'Chef' ||
        authUser?.user.role.rolename === 'Office') &&
      localStorage.getItem('prefetchAPI') !== 'called'
    ) {
      prefetchAPIs();
    }
  }, []);

  useEffect(() => {
    getFilters();
  }, []);

  /*
    useEffect(() => {
      const channel = new BroadcastChannel('sw-messages');
      channel.addEventListener('message', (event) => {
        console.log('Received', event.data);
        if (event.data.type === 'IndexedDbUpdate') {
          console.log('calling all timesheet synced');
          // fetchAllTimesheets();        
          setPage(0);
        }
      });
    }, []);
  */
  useEffect(() => {
    if (!searchQuery.client.length && !searchQuery.employee.length) {
      history.push({
        pathname: location.pathname,
        search: '',
      });
    } else {
      history.push({
        pathname: location.pathname,
        search: qs.stringify(searchQuery),
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (filtersFetched) {
      fetchAllTimesheets();
    }
  }, [rowsPerPage, page, query, searchQuery, isOnline, reload, triggerTSFetch]);

  async function onFilterChanges() {
    let queryString = '';

    if (filters?.cities && filters.cities.length) {
      filters.cities.forEach((city) => {
        queryString += `&cities[]=${city._id}`;
      });
    }

    if (filters?.rates && filters.rates.length) {
      filters.rates.forEach((rate) => {
        queryString += `&rates[]=${rate.value}`;
      });
    }

    if (filters?.nonBillableReasons && filters.nonBillableReasons.length) {
      filters.nonBillableReasons.forEach((nonBillableReason) => {
        queryString += `&nonBillableReasons[]=${nonBillableReason._id}`;
      });
    }

    if (filters?.paymentStatus && filters.paymentStatus) {
      queryString += `&paymentStatus[]=${filters.paymentStatus.value}`;
    }

    if (filters?.holdingFee && filters.holdingFee.length) {
      filters.holdingFee.forEach((holdingNumber) => {
        queryString += `&holdingFeeNumber[]=${holdingNumber.value}`;
      });
    }

    if (filters?.tipAmounts) {
      if (filters.tipAmounts.minimumTipAmount.length) {
        queryString += `&minimumTipAmount=${filters.tipAmounts.minimumTipAmount}`;
      }
      if (filters.tipAmounts.maximumTipAmount.length) {
        queryString += `&maximumTipAmount=${filters.tipAmounts.maximumTipAmount}`;
      }
    }

    if (filters?.dueAmounts) {
      if (filters.dueAmounts.minimumDueAmount.length) {
        queryString += `&minimumDueAmount=${filters.dueAmounts.minimumDueAmount}`;
      }
      if (filters.dueAmounts.maximumDueAmount.length) {
        queryString += `&maximumDueAmount=${filters.dueAmounts.maximumDueAmount}`;
      }
    }

    if (filters?.durations) {
      if (filters.durations.startDuration.length) {
        const start = Number.parseInt(filters.durations.startDuration, 10) * 60;
        queryString += `&startDuration=${start}`;
      }
      if (filters.durations.endDuration.length) {
        const end = Number.parseInt(filters.durations.endDuration, 10) * 60;
        queryString += `&endDuration=${end}`;
      }
    }

    if (filters?.adjustment) {
      if (filters.adjustment.minValue.length) {
        const start = Number.parseInt(filters.adjustment.minValue, 10);
        queryString += `&minAdjustmentVal=${start}`;
      }
      if (filters.adjustment.maxValue.length) {
        const end = Number.parseInt(filters.adjustment.maxValue, 10);
        queryString += `&maxAdjustmentVal=${end}`;
      }
    }

    if (filters?.driveTime) {
      if (filters.driveTime.minValue.length) {
        const start = Number.parseInt(filters.driveTime.minValue, 10);
        queryString += `&minDriveTime=${start}`;
      }
      if (filters.driveTime.maxValue.length) {
        const end = Number.parseInt(filters.driveTime.maxValue, 10);
        queryString += `&maxDriveTime=${end}`;
      }
    }

    if (filters?.trainer) {
      queryString += `&trainer=true`;
    }

    if (filters?.dateObj) {
      if (
        filters?.dateObj?.type === 'This Period' ||
        filters?.dateObj?.type === 'Last Period' ||
        filters?.dateObj?.type === 'Last 2 Period' ||
        filters?.dateObj?.type === 'Today'
      ) {
        if (filters?.dateObj?.dateValues.startDate !== '') {
          queryString += `&startDate=${filters?.dateObj?.dateValues.startDate}`;
        }
        if (filters?.dateObj?.dateValues.endDate !== '') {
          queryString += `&endDate=${filters?.dateObj?.dateValues.endDate}`;
        }
      } else {
        if (filters?.dateObj?.dateValues.startDate !== '') {
          queryString += `&startDate=${moment(
            filters?.dateObj?.dateValues.startDate
          )
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate()}`;
        }
        if (filters?.dateObj?.dateValues.endDate !== '') {
          queryString += `&endDate=${moment(
            filters?.dateObj?.dateValues.endDate
          )
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate()}`;
        }
      }
    }
    setquery(queryString);

    try {
      const dbs = await openDB('filters', 1, {
        upgrade(db) {
          // Create a store of objects
          const store = db.createObjectStore('filters_data', {
            // The 'moduleName' property of the object will be the key.
            keyPath: 'moduleName',
            // If it isn't explicitly set, create a value by auto incrementing.
            // autoIncrement: true,
          });
          // Create an index on the 'date' property of the objects.
          store.createIndex('moduleName', 'moduleName');
        },
      });

      const checkKeyExists = await dbs.getKey(
        'filters_data',
        IDBKeyRange.only('timesheet')
      );

      // will update existing record or else create a new
      if (checkKeyExists !== undefined) {
        if (filters !== null && Object.keys(filters).length) {
          await dbs.put('filters_data', {
            moduleName: 'timesheet',
            filterObj: filters,
          });
        } else {
          // if all filters are empty then also remove its row from idb
          await dbs.delete('filters_data', IDBKeyRange.only('timesheet'));
        }
      } else if (filters !== null && Object.keys(filters).length) {
        await dbs.add('filters_data', {
          moduleName: 'timesheet',
          filterObj: filters,
        });
      }
      setPage(0);
    } catch (error) {
      console.log(error);
    }
  }

  const onSearchClick = () => {
    setsearchQuery({ employee, client });
    setPage(0);
  };

  useEffect(() => {
    onFilterChanges();
  }, [filters]);

  const onApplyChanges = (filtersData) => {
    handleClose();
    setfilters(filtersData);
  };

  const clearFilters = () => {
    setfilters(null);
  };

  const clearAllFilters = () => {
    setfilters(null);
    setemployee('');
    setclient('');
    setsearchQuery({ employee: '', client: '' });
    setPage(0);
  };

  const displayCities = () => {
    switch (true) {
      case filters.cities.length === 1:
        return filters.cities.map((c) => c.cityname ?? 'N/A');

      case filters.cities.length >= 2:
        return filters.cities
          .slice(0, 1)
          .map((c) => c.cityname ?? 'N/A')
          .join(', ');
      default:
        break;
    }
  };

  const displayRatePlans = () => {
    switch (true) {
      case filters.rates.length === 1:
        return filters.rates.map((c) => c.value ?? 'N/A');

      case filters.rates.length === 2:
        return filters.rates.map((c) => c.value ?? 'N/A').join(', ');

      case filters.rates.length >= 3:
        return filters.rates
          .slice(0, 2)
          .map((c) => c.value ?? 'N/A')
          .join(', ');
      default:
        break;
    }
  };

  const displayNonBillablePlans = () => {
    switch (true) {
      case filters.nonBillableReasons.length === 1:
        return filters.nonBillableReasons.map((c) => c.title ?? 'N/A');

      case filters.nonBillableReasons.length === 2:
        return filters.nonBillableReasons
          .map((c) => c.title ?? 'N/A')
          .join(', ');

      case filters.nonBillableReasons.length >= 3:
        return filters.nonBillableReasons
          .slice(0, 2)
          .map((c) => c.title ?? 'N/A')
          .join(', ');
      default:
        break;
    }
  };

  const displayHoldingFees = () => {
    switch (true) {
      case filters.holdingFee.length === 1:
        return filters.holdingFee.map((c) => c.value ?? 'N/A');

      case filters.holdingFee.length === 2:
        return filters.holdingFee.map((c) => c.value ?? 'N/A').join(', ');

      case filters.holdingFee.length === 3:
        return filters.holdingFee.map((c) => c.value ?? 'N/A').join(', ');

      case filters.holdingFee.length >= 4:
        return filters.holdingFee
          .slice(0, 3)
          .map((c) => c.value ?? 'N/A')
          .join(', ');
      default:
        break;
    }
  };

  const displayTipAmounts = () => {
    let result;
    if (
      filters.tipAmounts.minimumTipAmount.length &&
      filters.tipAmounts.maximumTipAmount.length
    ) {
      result = `$ ${filters.tipAmounts.minimumTipAmount} - $ ${filters.tipAmounts.maximumTipAmount}`;
    } else if (
      filters.tipAmounts.minimumTipAmount.length &&
      !filters.tipAmounts.maximumTipAmount.length
    ) {
      result = `Min $ ${filters.tipAmounts.minimumTipAmount}`;
    } else if (
      filters.tipAmounts.maximumTipAmount.length &&
      !filters.tipAmounts.minimumTipAmount.length
    ) {
      result = `Max $ ${filters.tipAmounts.maximumTipAmount}`;
    }

    return result;
  };

  const displayDueAmounts = () => {
    let result;
    if (
      filters.dueAmounts.minimumDueAmount?.length &&
      filters.dueAmounts.maximumDueAmount?.length
    ) {
      result = `$ ${filters.dueAmounts.minimumDueAmount} - $ ${filters.dueAmounts.maximumDueAmount}`;
    } else if (
      filters.dueAmounts.minimumDueAmount?.length &&
      !filters.dueAmounts.maximumDueAmount?.length
    ) {
      result = `Min $ ${filters.dueAmounts.minimumDueAmount}`;
    } else if (
      filters.dueAmounts.maximumDueAmount?.length &&
      !filters.dueAmounts.minimumTipAmount?.length
    ) {
      result = `Max $ ${filters.dueAmounts.maximumDueAmount}`;
    }

    return result;
  };

  const displayDurations = () => {
    let result;
    if (
      filters.durations.startDuration.length &&
      filters.durations.endDuration.length
    ) {
      result = `${filters.durations.startDuration} - ${filters.durations.endDuration} Hrs`;
    } else if (
      filters.durations.startDuration.length &&
      !filters.durations.endDuration.length
    ) {
      result = `Min ${filters.durations.startDuration} Hrs`;
    } else if (
      filters.durations.endDuration.length &&
      !filters.durations.startDuration.length
    ) {
      result = `Max ${filters.durations.endDuration} Hrs`;
    }
    return result;
  };

  const displayAdjustments = () => {
    let result;
    if (
      filters.adjustment.minValue.length &&
      filters.adjustment.maxValue.length
    ) {
      result = `$${filters.adjustment.minValue} - $${filters.adjustment.maxValue}`;
    } else if (
      filters.adjustment.minValue.length &&
      !filters.adjustment.maxValue.length
    ) {
      result = `Min $${filters.adjustment.minValue}`;
    } else if (
      filters.adjustment.maxValue.length &&
      !filters.adjustment.minValue.length
    ) {
      result = `Max $${filters.adjustment.maxValue}`;
    }
    return result;
  };

  const displayDriveTime = () => {
    let result;
    if (
      filters.driveTime.minValue.length &&
      filters.driveTime.maxValue.length
    ) {
      result = `${filters.driveTime.minValue} - ${filters.driveTime.maxValue} Minutes`;
    } else if (
      filters.driveTime.minValue.length &&
      !filters.driveTime.maxValue.length
    ) {
      result = `Min ${filters.driveTime.minValue} Minutes`;
    } else if (
      filters.driveTime.maxValue.length &&
      !filters.driveTime.minValue.length
    ) {
      result = `Max ${filters.driveTime.maxValue} Minutes`;
    }
    return result;
  };

  const displayDate = () => {
    switch (filters?.dateObj?.type) {
      case 'Custom Date':
        return `${moment(filters.dateObj.dateValues.startDate).format(
          'MM/DD/YYYY'
        )} - 
        ${moment(filters.dateObj.dateValues.endDate).format('MM/DD/YYYY')}`;

      default:
        return filters.dateObj.type;
    }
  };

  const removeFilter = (filterObj) => {
    delete filters[filterObj];
    setfilters({ ...filters });
  };

  const displaySelectedFilters = () => {
    return filters !== null && Object.keys(filters).length > 0 ? (
      <Row className="group_filter">
        {filters?.cities && filters.cities.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">City:</span> {displayCities()}
            {filters.cities.length > 1 && (
              <>
                <span className="txt-primary">
                  {' '}
                  +{filters.cities.length - 1}
                </span>{' '}
              </>
            )}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('cities');
              }}
            // onClick={removeFilter('cities')}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.rates && filters.rates.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Rate Plan: </span>
            {displayRatePlans()}
            {filters.rates.length > 2 && (
              <>
                <span className="txt-primary">
                  {' '}
                  +{filters.rates.length - 2}
                </span>{' '}
              </>
            )}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('rates');
                removeFilter('nonBillableReasons');
              }}
            // onClick={removeFilter('rates')}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.nonBillableReasons && filters.nonBillableReasons.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Non Billable Reason: </span>
            {displayNonBillablePlans()}
            {filters.nonBillableReasons.length > 2 && (
              <>
                <span className="txt-primary">
                  {' '}
                  +{filters.nonBillableReasons.length - 2}
                </span>{' '}
              </>
            )}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('nonBillableReasons');
              }}
            // onClick={removeFilter('rates')}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.holdingFee && filters.holdingFee.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Holding Fee Number: </span>
            {displayHoldingFees()}
            {filters.holdingFee.length > 3 && (
              <>
                <span className="txt-primary">
                  {' '}
                  +{filters.holdingFee.length - 3}
                </span>{' '}
              </>
            )}{' '}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('holdingFee');
              }}
            // onClick={removeFilter('holdingFee')}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.paymentStatus ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Payment:</span>{' '}
            {filters.paymentStatus.label}{' '}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('paymentStatus');
              }}
            // onClick={removeFilter('paymentStatus')}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.tipAmounts?.minimumTipAmount?.length ||
          filters?.tipAmounts?.maximumTipAmount?.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Tip Amount:</span>{' '}
            {displayTipAmounts()}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('tipAmounts');
              }}
            // onClick={removeFilter('tipAmounts')}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.dueAmounts?.minimumDueAmount?.length ||
          filters?.dueAmounts?.maximumDueAmount?.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Amount Due:</span>{' '}
            {displayDueAmounts()}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('dueAmounts');
              }}
            // onClick={removeFilter('dueAmounts')}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.durations?.startDuration.length ||
          filters?.durations?.endDuration.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Duration:</span>{' '}
            {displayDurations()}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('durations');
              }}
            // onClick={removeFilter('durations')}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.adjustment?.minValue.length ||
          filters?.adjustment?.maxValue.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Adjustment:</span>{' '}
            {displayAdjustments()}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('adjustment');
              }}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.driveTime?.minValue.length ||
          filters?.driveTime?.maxValue.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Drive Time:</span>{' '}
            {displayDriveTime()}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('driveTime');
              }}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.trainer ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Employee:</span> {'Trainer '}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('trainer');
              }}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.dateObj?.type?.length > 0 ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Date:</span> {displayDate()}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('dateObj');
              }}
            // onClick={removeFilter('dateObj')}
            />
          </span>
        ) : (
          ''
        )}
        <span>
          <Button
            variant="link"
            onClick={clearFilters}
            className="float-right txt-primary"
          >
            Clear Filter
          </Button>
        </span>
      </Row>
    ) : (
      ''
    );
  };

  return (
    <div className="timesheet loader">
      {/* <Loading />      */}
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar isSidebarOpen={sidebarState} closeSidebar={onCloseSidebar} />
        </Mobile>
        {isAPICalled && !timesheetCount.totalCount ? (
          <NoCustomPageComponent
            title="Timesheet"
            onAddNew={onAddNew}
            addNewText='Start adding your first ”Timesheet"'
            isAddNew={validatePermission(
              authUser.user.role.permissions,
              'timesheet',
              'add'
            )}
          />
        ) : (
          <>
            <Desktop>
              <Col md="10">
                <div className="container">
                  <Row className="sticky-dashboard-header">
                    <Col
                      md={2}
                      xl={authUser.user.role.rolename === 'Admin' ? 2 : 3}
                      className="pl-1"
                    >
                      <h3>
                        <strong>Timesheet</strong>
                      </h3>
                    </Col>
                    <Col>
                      <Button
                        variant="default"
                        className="float-right"
                        onClick={handleShow}
                      >
                        {' '}
                        <Image
                          src={
                            filters !== null && Object.keys(filters).length > 0
                              ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                              : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                          }
                          fluid
                        />{' '}
                        Filter
                      </Button>
                    </Col>
                    <Col md="5" className="pl-0 pr-0">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Search Employees"
                          aria-label="Employees"
                          aria-describedby="basic-addon1"
                          value={employee}
                          onChange={(e) => setemployee(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setsearchQuery({
                                ...searchQuery,
                                employee: event.target.value,
                              });
                              setPage(0);
                            }
                          }}
                        />
                        <Form.Control
                          placeholder="Search Clients"
                          aria-label="Clients"
                          aria-describedby="basic-addon1"
                          value={client}
                          onChange={(e) => setclient(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setsearchQuery({
                                ...searchQuery,
                                client: event.target.value,
                              });
                              setPage(0);
                            }
                          }}
                        />
                        <InputGroup.Prepend>
                          <Button
                            variant="default"
                            className="btn-ftc-primary-light"
                            onClick={onSearchClick}
                          >
                            Search
                          </Button>
                        </InputGroup.Prepend>
                      </InputGroup>
                    </Col>
                    <Col xl="1/2">
                      <Button
                        variant="link"
                        onClick={clearAllFilters}
                        className="float-right txt-primary pr-3 clear-all-btn"
                      >
                        Clear All
                      </Button>
                    </Col>

                    {showExportButton(authUser.user.role.rolename) && (
                      <Col xl={1 / 2}>
                        <span className="float-left">
                          <button
                            type="button"
                            className="btn btn-ftc-primary-light-border float-right"
                            onClick={() => fetchAllPagesTimeSheet()}
                          >
                            {exportAPICalled ? (
                              <>
                                <Image
                                  src={Loader}
                                  height="18px"
                                  width="18px"
                                />
                              </>
                            ) : (
                              <>
                                <Image src={exportIcon} /> Export
                              </>
                            )}
                          </button>
                        </span>
                      </Col>
                    )}

                    {validatePermission(
                      authUser.user.role.permissions,
                      'timesheet',
                      'add'
                    ) && (
                        <Col className="pl-0 ml-3">
                          <span className="float-left">
                            <button
                              type="button"
                              className="btn btn-ftc-primary float-right"
                              onClick={onAddNew}
                            >
                              Add New
                            </button>
                          </span>
                        </Col>
                      )}
                  </Row>

                  <div className="container-top-7">
                    {isOnline && offlineTimesheets.length > 0 && (
                      <Row className="pt-0 px-0 py-0">
                        <Col className="text-right py-0">
                          <Image
                            src={`${process.env.PUBLIC_URL}/assets/sync-icon.png`}
                            className={
                              isSyncing
                                ? 'sync-icon-spin mb-1'
                                : 'sync-icon mb-1'
                            }
                          />
                          <button
                            type="button"
                            disabled={isSyncing}
                            onClick={sync}
                            className="pl-1 txt-primary shadow-none border-0 bg-transparent"
                          >
                            {isSyncing ? 'Sync in progress' : 'Sync Data'}
                          </button>
                        </Col>
                      </Row>
                    )}
                    {displaySelectedFilters()}
                    <div className="container p-0">
                      {!isOnline && (
                        <div>
                          <p className="offline__text">
                            <strong>You are viewing offline timesheets.</strong>
                          </p>
                        </div>
                      )}

                      {/* Show offline data */}
                      {!isOnline && (
                        <OfflineDataTable
                          className="offline__table"
                          highlightOnHover
                          pointerOnHover
                          noHeader
                          pagination
                          columns={columnsOffline}
                          data={offlineTimesheets}
                          selectableRowsComponentProps={
                            selectableRowsComponentProps
                          }
                          onRowClicked={handleOfflineRowClicked}
                        />
                      )}

                      {/* Show online data */}
                      {isOnline &&
                        (isAPICalled ? (
                          <>
                            <DataTableComponent
                              highlightOnHover
                              pointerOnHover
                              noHeader
                              columns={columns}
                              data={timesheets}
                              // defaultSortField="createdAt"
                              handleRowClicked={handleRowClicked}
                              pagination
                              selectableRowsComponentProps={
                                selectableRowsComponentProps
                              }
                              paginationServer
                              paginationTotalRows={Number.parseInt(
                                timesheetCount.filterCount,
                                10
                              )}
                              paginationPerPage={rowsPerPage}
                              onChangePage={(p) => {
                                setPage(p - 1);
                                // look for better solution later
                                // setPage(p)
                              }}
                              onChangeRowsPerPage={(rws, pg) => {
                                //
                                setRowsPerPage(rws);
                                // setPage(pg - 1);
                                setPage(pg);
                                // setPage(1);
                              }}
                              conditionalRowStyles={conditionalRowStyles}
                            />
                            <TablePagination
                              labelRowsPerPage="Rows per page:"
                              component="div"
                              count={Number.parseInt(
                                timesheetCount.filterCount,
                                10
                              )}
                              page={page}
                              // onChangePage={handleChangePage}
                              onChangePage={(event, newPage) => {
                                setPage(newPage);
                              }}
                              // defaultPage={0}
                              rowsPerPage={rowsPerPage}
                              onChangeRowsPerPage={(event) => {
                                setRowsPerPage(
                                  parseInt(event.target.value, 10)
                                );
                                setPage(0);
                              }}
                              ActionsComponent={TablePaginationActions}
                            />
                          </>
                        ) : (
                          ''
                        ))}
                    </div>
                  </div>
                </div>
              </Col>
            </Desktop>
            <Mobile>
              <Col>
                <div className="container">
                  <Row className="border-bottom sticky-dashboard-header-mobile">
                    <Col xs="9" className="ml-4">
                      <h3>Timesheet</h3>
                    </Col>
                    <Col xs="1" sm="2">
                      <Image
                        className="ftc-logo-40"
                        src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                      />{' '}
                    </Col>
                  </Row>
                  <div className="container-top-20">
                    <Row className="pr-0 pb-0">
                      <Col xs="6" className="pr-1 pl-1">
                        <Button
                          variant="default"
                          onClick={handleShow}
                          className="float-right btn-block"
                        >
                          {' '}
                          <Image
                            src={
                              filters !== null &&
                                Object.keys(filters).length > 0
                                ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                                : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                            }
                          />{' '}
                          Filter
                        </Button>
                      </Col>

                      {validatePermission(
                        authUser.user.role.permissions,
                        'timesheet',
                        'add'
                      ) && (
                          <Col xs="6" className="pl-1 pr-0">
                            <button
                              type="button"
                              className="btn btn-ftc-primary btn-block float-right"
                              onClick={onAddNew}
                            >
                              Add New
                            </button>
                          </Col>
                        )}
                    </Row>

                    <Row className="pl-0 pr-0">
                      <Col xs="12" className="pr-0">
                        {/* <Form.Control type="text" placeholder="Search" /> */}
                        <InputGroup className="mb-0">
                          <Form.Control
                            placeholder="Employee"
                            aria-label="Employees"
                            aria-describedby="basic-addon1"
                            value={employee}
                            onChange={(e) => setemployee(e.target.value)}
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                setsearchQuery({
                                  ...searchQuery,
                                  employee: event.target.value,
                                });
                                setPage(0);
                              }
                            }}
                          />
                          <Form.Control
                            placeholder="Client"
                            aria-label="Clients"
                            aria-describedby="basic-addon1"
                            value={client}
                            onChange={(e) => setclient(e.target.value)}
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                setsearchQuery({
                                  ...searchQuery,
                                  client: event.target.value,
                                });
                                setPage(0);
                              }
                            }}
                          />
                          <InputGroup.Prepend>
                            <Button
                              variant="default"
                              className="btn-ftc-primary-light"
                              onClick={onSearchClick}
                            >
                              <Image
                                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-search-red.svg`}
                                fluid
                              />
                            </Button>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="pr-0 pb-0 pt-0">
                      {showExportButton(authUser.user.role.rolename) && (
                        <>
                          <Col xs="6" className="pr-1 pl-1">
                            <></>
                          </Col>
                          <Col xs="6" className="pr-1 pl-1">
                            <span>
                              <button
                                type="button"
                                className="btn btn-ftc-primary-light-border btn-block float-right"
                                onClick={() => fetchAllPagesTimeSheet()}
                              >
                                {exportAPICalled ? (
                                  <>
                                    <Image
                                      src={Loader}
                                      height="18px"
                                      width="18px"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Image src={exportIcon} /> Export
                                  </>
                                )}
                              </button>
                            </span>
                          </Col>
                        </>
                      )}
                    </Row>

                    {!isOnline && (
                      <div>
                        <p className="offline__text mb-0">
                          <strong>You are viewing offline timesheets.</strong>
                        </p>
                      </div>
                    )}
                    {isOnline && offlineTimesheets.length > 0 && (
                      <Row className="pl-0 pr-0 pb-0">
                        <Col className="pr-0 py-0 text-center ">
                          <Image
                            src={`${process.env.PUBLIC_URL}/assets/sync-icon.png`}
                            className={
                              isSyncing
                                ? 'sync-icon-spin mb-1'
                                : 'sync-icon mb-1'
                            }
                          />
                          <button
                            type="button"
                            disabled={isSyncing}
                            onClick={sync}
                            className="pl-1 txt-primary shadow-none border-0 bg-transparent"
                          >
                            {isSyncing ? 'Sync in progress' : 'Sync Data'}
                          </button>
                        </Col>
                      </Row>
                    )}

                    <Row className="pl-0 pr-0">
                      {/* Show if we're online */}
                      {isOnline &&
                        timesheets.map((timesheet) => {
                          return (
                            <Col md={6} className="mt-3 pr-0">
                              <TableMobileView
                                timesheet={timesheet}
                                callback={handleRowClicked}
                                handleViewClick={handleViewClick}
                                handleEditClicked={handleEditClicked}
                                handleClickDelete={handleClickDelete}
                                handleOfflineRowClick={handleOfflineRowClicked}
                                handleOfflineViewClick={handleOfflineViewClick}
                                handleOfflineEditClick={handleOfflineEditClick}
                                isEditable={
                                  !checkTimesheetEditable(
                                    authUser.user.role.rolename,
                                    authUser.user.role.permissions,
                                    timesheet.payment_status,
                                    timesheet.ts_date
                                  )
                                }
                                isDeletable={
                                  !checkTimesheetDeletable(
                                    authUser.user.role.rolename,
                                    authUser.user.role.permissions,
                                    timesheet.payment_status,
                                    timesheet.ts_date
                                  )
                                }
                              />
                            </Col>
                          );
                        })}
                      {/* Show if we are offline */}
                      {!isOnline && (
                        <>
                          {!offlineTimesheets.length ? (
                            <div className="col text-center">
                              <p>There are no records to display.</p>
                            </div>
                          ) : (
                            ''
                          )}
                          {offlineTimesheets.map((timesheet) => {
                            return (
                              <Col md={6} className="mt-3 pr-0">
                                <TableMobileView
                                  timesheet={timesheet}
                                  callback={handleRowClicked}
                                  handleViewClick={handleViewClick}
                                  handleEditClicked={handleEditClicked}
                                  handleClickDelete={handleClickDelete}
                                  handleOfflineRowClick={
                                    handleOfflineRowClicked
                                  }
                                  handleOfflineViewClick={
                                    handleOfflineViewClick
                                  }
                                  handleOfflineEditClick={
                                    handleOfflineEditClick
                                  }
                                  isEditable={
                                    !checkTimesheetEditable(
                                      authUser.user.role.rolename,
                                      authUser.user.role.permissions,
                                      timesheet.payment_status,
                                      timesheet.ts_date
                                    )
                                  }
                                  isDeletable={
                                    !checkTimesheetDeletable(
                                      authUser.user.role.rolename,
                                      authUser.user.role.permissions,
                                      timesheet.payment_status,
                                      timesheet.ts_date
                                    )
                                  }
                                />
                              </Col>
                            );
                          })}
                        </>
                      )}
                    </Row>
                    {/* If no Data Text */}

                    {isOnline && (
                      <>
                        {isAPICalled && !timesheetCount.filterCount ? (
                          <div className="text-center">
                            <p>There are no records to display.</p>
                          </div>
                        ) : (
                          ''
                        )}

                        <Row>
                          <Col xs="12">
                            <TablePagination
                              className="mobile__pagination"
                              ActionsComponent={TablePaginationActions}
                              labelRowsPerPage=""
                              component="div"
                              count={Number.parseInt(
                                timesheetCount.filterCount,
                                10
                              )}
                              page={page}
                              // onChangePage={handleChangePage}
                              onChangePage={(event, newPage) => {
                                setPage(newPage);
                              }}
                              defaultPage={0}
                              rowsPerPage={rowsPerPage}
                              onChangeRowsPerPage={(event) => {
                                setRowsPerPage(
                                  parseInt(event.target.value, 10)
                                );
                                setPage(0);
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Mobile>
          </>
        )}
      </Row>
      <Desktop>
        <RenderCustomPopup />
      </Desktop>

      <Mobile>{customPopupRender && <RenderCustomPopup />}</Mobile>

      {show && (
        <TimesheetFilters
          show={show}
          handleClose={handleClose}
          allFilters={filters}
          applyChanges={onApplyChanges}
          clearAll={clearFilters}
        />
      )}

      {showConfirmAlert && (
        <ConfirmAlertModal
          show={showConfirmAlert}
          handleClose={handleCloseConfirm}
          title="Delete Timesheet"
          description="Deleting this timesheet will erase all data inside this record.
                Are you sure you want to delete this timesheet?"
          callback={onDeleteTimesheet}
          action="Delete it"
          closeAction="Cancel"
        />
      )}

      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth="true"
          className="mobile__timesheet__dialog"
        >
          <DialogTitle className="pb-0">
            <div className="float-right">
              <Image
                onClick={handleCloseDialog}
                className="ftc-logo-24"
                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
                fluid
              />
            </div>
          </DialogTitle>
          <DialogContent>
            <div>
              <>
                <Row>
                  <Col xs="12" className="text-center">
                    <label className="font-24">
                      <strong>Do you wish to record a new Timesheet?</strong>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="pl-0 pr-2">
                    <button
                      type="button"
                      onClick={handleCloseDialog}
                      className="btn btn-ftc-primary btn-block"
                    >
                      Record New Timesheet
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="pl-0 pr-2">
                    <button
                      type="button"
                      onClick={openSidebarMenu}
                      className="btn btn-ftc-border-primary btn-block"
                    >
                      Go to Main Menu
                    </button>
                  </Col>
                </Row>
              </>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
});

// Sidebar.propTypes = {
//   sidebarRes: PropTypes.string.isRequired,
// };

export default Timesheet;
