import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const generateHoursReport = (body) =>
{
    return API.post(API_ENDPOINTS.HOURS_REPORTS.HoursReport, body);
};

const createHoursCategory = () => { };

export
{
    generateHoursReport,
    createHoursCategory
}