import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const generatePayrollReport = (body) =>
{
    return API.post(API_ENDPOINTS.PAYROLL_REPORTS.PayrollReport, body);
};

const createHoursCategory = () => { };

export
{
    generatePayrollReport,
    createHoursCategory
}