import React, { memo, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

import { Button, Row, Col, Image, NavDropdown, Card, } from 'react-bootstrap';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import Ellipses from '../../../components/Ellipses';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import Toaster from '../../../components/Toaster';

import { DateConvert } from '../../../utils/TimesheetManagement';
import { deleteCustomPopupById, getCustomPopupById } from '../../../services/custom_popup.service';
import { getContentTypeLabel, validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';

const ViewCustomPopup = memo(() =>
{
    const { authUser } = useAuthContext();
    const { popupId } = useParams();
    const history = useHistory();

    const [isAPICalled, setAPICalled] = useState(false);
    const [customPopup, setCustomPopup] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    async function getCustomPopupDetails()
    {
        try
        {
            const response = await trackPromise(getCustomPopupById(popupId));
            setCustomPopup(response.data.data.customPopup);
            setAPICalled(true);
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    useEffect(() =>
    {
        getCustomPopupDetails();
    }, [popupId]);    

    const onEditCustomPopup = () =>
    {
        history.push(`/settings/editCustomPopup/${customPopup._id}`)
    }

    const SettingsDropdown = () =>
    {
        return (<div>
            <NavDropdown
                title={<Ellipses />}
                id="collasible-nav-dropdown"
            >
                <NavDropdown.Item
                    onClick={handleShow}
                    disabled={!validatePermission(authUser.user.role.permissions, 'custom_popup', 'delete')}
                >Delete</NavDropdown.Item>
            </NavDropdown>
        </div>);
    };

    const additionalDate = new Date(customPopup?.expiresOn);
    additionalDate.setDate(additionalDate.getDate() + 1);

    const onDeleteCustomPopup = async () =>
    {
        try
        {
            const response = await trackPromise(deleteCustomPopupById(popupId));
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally
        {
            setShow(false);
            history.replace('/settings/custompopups');
        }
    };

    return isAPICalled ? (
        <div>
            <div className="container">
                <Desktop>
                    <Row className="ml-5">
                        <Col md="1">
                            <Link to='/settings/custompopups'>
                                <a>
                                    <Image
                                        className="imageArrow"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                        fluid
                                    />
                                </a>
                            </Link>
                        </Col>
                        <Col md="8">
                            <h5 className="mb-0">
                                <strong>{customPopup.title}</strong>
                            </h5>
                            <label className="txt-light-secondary mb-0">Last Updated on {DateConvert(customPopup.updatedAt)}</label>
                        </Col>
                        <Col md="1" className="pr-0 ml-5">
                            <Button
                                variant="default"
                                className="btn-ftc-border-primary"
                                disabled={!validatePermission(authUser.user.role.permissions, 'custom_popup', 'edit')}
                                onClick={onEditCustomPopup}
                            >
                                Edit
                            </Button>
                        </Col>
                        <Col md="1">
                            <div className="ml-0">
                                <SettingsDropdown />
                            </div>
                        </Col>
                    </Row>
                </Desktop>
                <Mobile>
                    <div className="container">
                        <Row className="pb-0">
                            <Col xs="1" className="p-0">
                                <Link to="/settings/custompopups">
                                    <Image
                                        className="imageArrowMobile mt-1"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                        fluid
                                    />
                                </Link>
                            </Col>
                            <Col xs="9" className="mt-1">
                                <h6 className="m-auto"><strong>{customPopup.title}</strong> </h6>
                                <p className="txt-light-secondary mx-auto">
                                    Last Updated on {DateConvert(customPopup.updatedAt)}
                                </p>
                            </Col>
                            <Col xs="1">
                                <div>
                                    <SettingsDropdown />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Mobile>
            </div>
            <div className="border-bottom" />
            <div className="container">
                <Row>
                    <Col md="8" className="mx-auto">

                        <Card className="mt-3">
                            <Card.Body className="pt-0 pb-2">
                                <Row>
                                    <Col md="12" xs="12">
                                        <h5 className="txt-primary"><strong>Pop Up Description</strong></h5>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Pop Up Title</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>{customPopup.title}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Accessible to</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right">
                                            <strong>
                                                {/* {displayAccessRoles(customPopup.access)} */}
                                                {customPopup?.access?.map((c) => c.rolename ?? 'N/A').join(' | ')}
                                            </strong>
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="12">
                                        <label className="txt-light-secondary">Location</label>
                                    </Col>
                                    <Col md="6" xs="12" className="text-truncate  d-inline-block">
                                        <label className="float-right"><strong>{customPopup.popup_location}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="pt-3 pb-2">
                                    <Col md="6" xs="12">
                                        <label className="txt-light-secondary">Expiers On</label>
                                    </Col>
                                    <Col md="6" xs="12" className="text-truncate  d-inline-block">
                                        <label className="float-right"><strong>{customPopup?.expiresOn===null || customPopup?.expiresOn===undefined ? 'N/A' : DateConvert(additionalDate)}</strong></label>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="mt-3">
                            <Card.Body className="pt-0 pb-2">
                                <Row>
                                    <Col md="12" xs="12">
                                        <h5 className="txt-primary"><strong>Pop Up Content</strong></h5>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Content Type</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>{getContentTypeLabel(customPopup.contentType)}</strong></label>
                                    </Col>
                                </Row>
                                {/* <Row className="pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Content</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>Preview</strong></label>
                                    </Col>
                                </Row> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Mobile>
                    <Row>
                        <Col xs={12} className="pr-3">
                            <Button
                                variant="default"
                                onClick={onEditCustomPopup}
                                disabled={!validatePermission(authUser.user.role.permissions, 'custom_popup', 'edit')}
                                className="float-right btn-ftc-border-primary ml-5"
                            >
                                Edit
                            </Button>
                        </Col>
                    </Row>
                </Mobile>
            </div>
            {show && <ConfirmAlertModal
                show={show}
                handleClose={handleClose}
                title='Delete Custom Popup'
                description='Deleting this popup will erase all data inside this record.
                    Are you sure you want to delete this Popup?'
                callback={onDeleteCustomPopup}
                action='Delete it'
                closeAction='Cancel'
            />}
        </div>
    ) : ('')
});

export default ViewCustomPopup
