import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Form } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import moment from 'moment';

import Sidebar from '../../side-navbar/Sidebar';
import Toaster from '../../../components/Toaster';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import DataTableComponent from '../../../components/DataTableComponent';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';
import {
  createPeriodForYear,
  deletePeriodsByYear,
  getAllPeriods,
} from '../../../services/period-service';
import { DateConvert } from '../../../utils/TimesheetManagement';

const PeriodDate = () => {
  const validationSchema = yup.object().shape({
    year: yup
      .number()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .test(
        'len',
        'Enter a valid year.',
        (val) => val && val.toString().length === 4
      )
      .min(
        new Date().getFullYear(),
        'Year must be current year or greater than current year.'
      )
      .integer('Year must be an integer')
      .required('Year is required.'),
    date: yup.string().required('Please select a date.'),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    shouldFocusError: true,
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, control },
    reset,
  } = methods;

  const isIndeterminate = (indeterminate) => indeterminate;
  const selectableRowsComponentProps = { indeterminate: isIndeterminate };

  const [periods, setperiods] = useState([]);
  const [isAPICalled, setAPICalled] = useState(false);

  const [selectedRow, setselectedRow] = useState();
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const [isChecked,setIsChecked] = useState(false);

  const handleCloseConfirm = () => setConfirmAlert(false);

  async function fetchAllPeriods() {
    try {
      const response = await trackPromise(getAllPeriods());
      setperiods(response.data.data.periods);
      setAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const onSubmit = async (data) => {
    try {
      const response = await trackPromise(createPeriodForYear({ year: data.year, date: moment.utc(data.date).format('MM/DD/YYYY'), isFifthWeek:isChecked }));
      if (response.status === 201) {
        toast.dark(<Toaster icon="notify" message={response.data.message} />);
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      reset({});
      setIsChecked(false);
      fetchAllPeriods();
    }
  };

  const onDeletePeriods = async () => {
    try {
      const response = await trackPromise(deletePeriodsByYear(selectedRow));
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setConfirmAlert(false);
      fetchAllPeriods();
    }
  };

  const handleClickDelete = (rowObj) => {
    setselectedRow(rowObj.year);
    setConfirmAlert(true);
  };

  useEffect(() => {
    fetchAllPeriods();
  }, []);

  const columns = [
    {
      name: 'Year',
      selector: (row) => `${row.year}`,
      sortable: true,
    },
    {
      name: 'Period Start Date',
      selector: (row) => DateConvert(row.start_date),
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <button
          type="button"
          className="btn btn-ftc-primary float-right"
          onClick={() => {
            handleClickDelete(row);
          }}
        >
          Delete
        </button>
      ),
      button: true,
    },
  ];

  return (
    <div>
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar />
        </Mobile>
        <Desktop>
          <Col md="10">
            <div className="container">
              <Row className="border-bottom sticky-dashboard-header pb-3">
                <Col md={4} lg={5}>
                  <h3>
                    <strong>Pay Period Setup</strong>
                  </h3>
                </Col>
              </Row>
              <div className="container-top-7" />
            </div>
          </Col>
        </Desktop>
        <Mobile>
          <Col>
            <div className="container pr-0">
              <Row className="border-bottom pr-0 sticky-dashboard-header-mobile">
                <Col xs="9" className="ml-4">
                  <h3>Pay Period Setup</h3>
                </Col>
                <Col xs="2">
                  <Image
                    className="ftc-logo-40"
                    src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                  />{' '}
                </Col>
              </Row>
              <div className="container-top-20" />
            </div>
          </Col>
        </Mobile>
        <div className="container">
          <FormProvider {...methods}>
            <Form
            // onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Row>
                  <Col md="2" />
                  <Col md="10">
                    <div>
                      <Row>
                        <Col md="12">
                          <h6>
                            Please enter Year and Start Date to generate a
                            Period Calendar for a particular year.
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="pb-2">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="txt-light-secondary">
                              Year
                            </Form.Label>
                            <Form.Control
                              {...register('year')}
                              type="number"
                              min="0"
                              pattern="/^[0-9\b]+$/"
                              step="1"
                              placeholder="Enter Year"
                            />
                            {errors.year && (
                              <p className="text-danger">
                                {errors.year.message}
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="6" className="pb-2">
                          <Form.Group controlId="formBasicYear">
                            <Form.Label className="txt-light-secondary">
                              Start Date
                            </Form.Label>
                            <Controller
                              render={({
                                field: { ref, name, value, onChange },
                              }) => (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePickerInput
                                    name={name}
                                    ref={ref}
                                    handleDateChange={(e) =>
                                      onChange(
                                        moment(e)
                                          // .utc()
                                          // .utcOffset(0)
                                          .set({
                                            hour: 0,
                                            minute: 0,
                                            second: 0,
                                            millisecond: 0,
                                          })
                                          .toDate()
                                      )
                                    }
                                    selectedDate={value}
                                  />
                                </MuiPickersUtilsProvider>
                              )}
                              name="date"
                              control={control}
                            />
                            {errors.date && (
                              <p className="text-danger">
                                {errors.date.message}
                              </p>
                            )}
                          </Form.Group>
                        </Col>

                        <Col md="12" className='pb-2'>
                          <Form.Group controlId="formBasicCheckbox">
                            <input
                              type="checkbox"
                              className="ftc-checkbox"
                              // id={role._id}
                              checked={isChecked}
                              onChange={() => {
                                setIsChecked(!isChecked)
                              }}
                            />
                            <Form.Label
                              // data-content={roles.rolename}
                              className="position-relative ml-4 pl-2"
                            >
                              <h6>Tick to indicate that Period 13 contains 5 weeks this year</h6>
                            </Form.Label>
                          </Form.Group>
                        </Col>



                        <Col md="2" className="d-flex align-items-center pt-1">
                          <button
                            // type="submit"
                            type="button"
                            onClick={handleSubmit(onSubmit)}
                            disabled={isSubmitting}
                            className="btn btn-ftc-primary float-right"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="border-bottom" />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" className="mx-auto">
                          {isAPICalled ? (
                            <>
                              <DataTableComponent
                                // title="Movie List"
                                highlightOnHover
                                pointerOnHover
                                noHeader
                                columns={columns}
                                data={periods}
                                pagination
                                selectableRowsComponentProps={
                                  selectableRowsComponentProps
                                }
                                handleRowClicked={() => {}}
                              />
                            </>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </FormProvider>
        </div>
      </Row>
      {showConfirmAlert && (
        <ConfirmAlertModal
          show={showConfirmAlert}
          handleClose={handleCloseConfirm}
          title="Delete Periods"
          description="Deleting this Period Year will erase all data inside this record.
                Are you sure you want to delete this Year?"
          callback={onDeletePeriods}
          action="Delete it"
          closeAction="Cancel"
        />
      )}
    </div>
  );
};

export default PeriodDate;
