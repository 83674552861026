const Errors = {
    ANDROID: {
        "com.squareup.pos.ERROR_CUSTOMER_MANAGEMENT_NOT_SUPPORTED": 'The Square account used does not support customer management.',
        "com.squareup.pos.ERROR_DISABLED": 'The Point of Sale API is not currently available.',
        "com.squareup.pos.ERROR_ILLEGAL_LOCATION_ID": 'The provided location ID does not correspond to the location currently logged in to Square Point of Sale.',
        "com.squareup.pos.ERROR_INVALID_CUSTOMER_ID": 'The provided customer ID is invalid.',
        "com.squareup.pos.ERROR_INVALID_REQUEST": 'The information provided in this transaction request is invalid(such as, a required field is missing or malformed).',
        "com.squareup.pos.ERROR_NO_EMPLOYEE_LOGGED_IN": 'Employee management is enabled but no employee is logged in to Square Point of Sale.',
        "com.squareup.pos.ERROR_NO_NETWORK": 'Square Point of Sale was unable to validate the Point of Sale API request because the Android device did not have an active network connection.',
        "com.squareup.pos.ERROR_NO_RESULT": 'Square Point of Sale did not return a transaction result.',
        "com.squareup.pos.ERROR_TRANSACTION_ALREADY_IN_PROGRESS": 'Another Square Point of Sale transaction is already in progress.',
        "com.squareup.pos.ERROR_TRANSACTION_CANCELED": 'The transaction was canceled in Square Point of Sale.',
        "com.squareup.pos.ERROR_UNAUTHORIZED_CLIENT_ID": 'The application with the provided client ID is not authorized to use the Point of Sale API.',
        "com.squareup.pos.ERROR_UNEXPECTED": 'An unexpected error occurs.',
        "com.squareup.pos.ERROR_UNSUPPORTED_API_VERSION": 'The installed version of Square Point of Sale does not support this version of Point of Sale SDK.',
        "com.squareup.pos.ERROR_UNSUPPORTED_WEB_API_VERSION": 'The Web API used is not supported in the supplied API version, which must be version 1.3 or later.',
        "com.squareup.pos.ERROR_USER_NOT_ACTIVATED": 'The Square Point of Sale tried to process a credit card transaction, but the associated Square account is not activated for card processing.',
        "com.squareup.pos.ERROR_USER_NOT_LOGGED_IN": 'No user is currently logged in to Square Point of Sale.',
    },
    IOS: {
        amount_invalid_format: 'The request has a missing or invalid amount to charge.',
        amount_too_large: 'The request amount to charge was too large.',
        amount_too_small: 'The request amount to charge was too small.',
        could_not_perform: 'The request could not be performed.This is usually because there is an unfinished transaction pending in Square Point of Sale.The seller must open Square Point of Sale and complete the transaction before initiating a new request.',
        currency_code_mismatch: 'The currency code provided in the request does not match the currency associated with the current business.',
        currency_code_missing: 'The currency code provided in the request is missing or invalid.',
        customer_management_not_supported: 'This seller account does not support customer management and therefore cannot associate transactions with customers.',
        data_invalid: "The URL sent to Square Point of Sale had missing or invalid information.",
        invalid_customer_id: "The customer ID provided in the request does not correspond to a customer signed in to the seller's Customer Directory.",
        invalid_tender_type: 'The request included an invalid tender type.',
        no_network_connection: "The transaction failed because the device has no network connection.",
        not_logged_in: 'A seller is not currently logged in to Square Point of Sale.',
        payment_canceled: 'The seller canceled the payment in Square Point of Sale.',
        unsupported_api_version: 'The installed version of Square Point of Sale does not support the specified version of the Point of Sale API.',
        unsupported_currency_code: 'The currency code provided in the request is not currently supported by the Point of Sale API.',
        unsupported_tender_type: 'The request included a tender type that is not currently supported by the Point of Sale API.',
        user_id_mismatch: 'The business location currently signed in to Square Point of Sale does not match the location represented by the location_id you provided in your request.',
        user_not_active: 'The currently signed in location has not activated card processing.'
    }
}

export default Errors