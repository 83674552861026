import React, { memo, useEffect, useState } from 'react';
import { Link, Prompt, useHistory, useParams, } from 'react-router-dom';
// import * as Scroll from 'react-scroll';


import { Row, Col, Image, Button, Form, NavDropdown, } from 'react-bootstrap';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Controller, useForm, } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

import Toaster from '../../../components/Toaster';
import Ellipses from '../../../components/Ellipses';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';

import '../css/viewUserDetails.css';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import { DateConvert } from '../../../utils/TimesheetManagement';
import { getNonBillableById, createNonBillable, updateNonBillable, deleteNonBillableById } from '../../../services/non_billable.service';
import { getAllHoursCategories } from '../../../services/hours-categories.service';
import { getAllRolesByNames } from '../../../services/role-service';
import { validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';

const animatedComponents = makeAnimated();

const ManageNonbillable = memo(() => {
    const { authUser } = useAuthContext();
    const history = useHistory();

    // const { scroller } = Scroll;

    const { nonBillId } = useParams();
    const isAddMode = !nonBillId;
    const [isAPICalled, setIsAPICalled] = useState(false);

    const [nonBillable, setNonBillable] = useState(null);

    const [hoursCategories, sethoursCategories] = useState([]);
    const [selectedCategory, setselectedCategory] = useState(null);

    const [roles, setRoles] = useState([]);
    const [selectedRoles, setselectedRoles] = useState([]);
    const [rolesAPI, setrolesAPI] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPrompt, setshowPrompt] = useState(false);

    const validationSchema = yup.object({
        title: yup.string().required('Non Billable title is required.'),
        hours_category: yup.mixed().required('Hours category is required.').nullable(),
        access: yup.array().min(1, 'Please select at least one access Level').required('Access Level is required').nullable()
    });

    const { handleSubmit, register,
        formState: { errors, isValid, isSubmitted, isSubmitting, dirtyFields },
        reset, control, setValue, watch } =
        useForm({
            resolver: yupResolver(validationSchema),
            mode: 'onChange',
        });

    const form = watch();


    async function getNonBillableDetails() {
        try {
            const response = await trackPromise(getNonBillableById(nonBillId));
            setNonBillable(response.data.data.non_billable);
            reset(response.data.data.non_billable);
            setselectedCategory(response.data.data.non_billable.hours_category);
            setIsAPICalled(true);
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    async function fetchAllHoursCategories() {
        try {
            const response = await trackPromise(getAllHoursCategories());
            sethoursCategories(response.data.data.hours_categories);
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    async function fetchAllRoles() {
        try {
            const response = await trackPromise(getAllRolesByNames());
            setRoles(
                response.data.data.roles.map((role) => {
                    const tempRole = role;
                    // if (nonBillable?.access?.length > 0)
                    // {
                    //     if (nonBillable.access.find((selected) => selected._id === role._id))
                    //     {
                    //         tempRole.isChecked = true;
                    //     }
                    // }
                    // else
                    // {
                    tempRole.isChecked = false;
                    // }
                    return tempRole;

                })
            );
            setrolesAPI(true);
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    useEffect(() => {
        (async () => {
            await fetchAllHoursCategories();
            await fetchAllRoles();
        })();
    }, []);

    useEffect(() => {
        if (Object.keys(dirtyFields).length) {
            setshowPrompt(true);
        }
        else {
            setshowPrompt(false);
        }
    }, [form, errors]);

    useEffect(() => {
        if (!isAddMode) {
            getNonBillableDetails();
        }
        else {
            setIsAPICalled(true);
        }
    }, [isAddMode]);

    useEffect(() => {
        if (nonBillable && rolesAPI) {
            setRoles(
                roles.map((role) => {
                    const tempRole = role;
                    if (nonBillable?.access?.length > 0) {
                        if (nonBillable.access.find((selected) => selected._id === role._id)) {
                            tempRole.isChecked = true;
                            selectedRoles.push(role);
                        }
                    }
                    else {
                        tempRole.isChecked = false;
                    }
                    return tempRole;
                })
            );
            setselectedRoles(selectedRoles);
        }
    }, [nonBillable, rolesAPI]);

    useEffect(() => {
        if (selectedCategory) {
            setValue('hours_category', selectedCategory, { shouldDirty: true, shouldValidate: true });
        }
        else {
            setValue('hours_category', null, { shouldDirty: false, shouldValidate: true });
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (selectedRoles.length) {
            setValue('access', selectedRoles, { shouldDirty: true, shouldValidate: true });
        }
        else {
            setValue('access', null, { shouldDirty: false, shouldValidate: true });
        }
    }, [selectedRoles]);

    const handleReset = () => {
        reset({});
        setselectedCategory(null);
        setRoles(
            roles.map((role) => {
                const tempRole = role;
                if (selectedRoles.find((selected) => selected._id === role._id)) {
                    tempRole.isChecked = false;
                    selectedRoles.splice(selectedRoles.findIndex((selected) => selected._id === role._id), 1);
                }
                return tempRole;
            })
        );
        setselectedRoles([]);
    }

    const onSubmit = async (data) => {
        try {
            if (isValid) {
                if (isAddMode) {
                    const response = await trackPromise(
                        createNonBillable(data)
                    );
                    if (response.status === 201) {
                        toast.dark(<Toaster
                            icon="notify"
                            message={response.data.message}
                        />);
                        setshowPrompt(false);
                        history.push(`/settings/viewNonbillable/${response.data.data.non_billable._id}`);
                    }
                }
                else {
                    const response = await trackPromise(
                        updateNonBillable(nonBillId, data)
                    );
                    if (response.status === 200) {
                        toast.dark(<Toaster
                            icon="notify"
                            message={response.data.message}
                        />);
                        setshowPrompt(false);
                        history.push(`/settings/viewNonbillable/${response.data.data.non_billable._id}`);
                    }
                }
            }
        } catch (error) {
            if (error.response?.data?.data?.error?.errors) {
                if (error.response?.data?.data?.error?.errors?.title) {
                    toast.dark(
                        <Toaster
                            icon="warning"
                            message={`${error.response.data.data.error.errors.title.message}`}
                        />,
                        { autoClose: 5000, hideProgressBar: true }
                    );
                }
            }
            else {
                toast.dark(
                    <Toaster
                        icon="error"
                        message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                    />
                );
            }
        }
    };

    const onRoleChange = (e, role) => {
        setRoles(
            roles.map((c) => {
                const tempRole = c;
                if (c._id === role._id) {
                    if (e.target.checked && selectedRoles.indexOf(role) === -1) {
                        tempRole.isChecked = true;
                        selectedRoles.push(role);
                    }
                    else {
                        tempRole.isChecked = false;
                        selectedRoles.splice(selectedRoles.indexOf(role), 1);
                    }
                }
                return tempRole;
            })
        );
        setselectedRoles([].concat(selectedRoles));
    }

    const onDeleteNonBillable = async () => {
        try {
            const response = await trackPromise(
                deleteNonBillableById(nonBillId)
            );
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
            history.replace('/settings/nonbillable');
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally {
            handleClose();
        }
    }

    // const onMenuOpen = (elementName) => {

    //     scroller.scrollTo(elementName, {
    //         duration: 1500,
    //         delay: 100,
    //         smooth: true,
    //         offset: 50, // Scrolls to element + 50 pixels down the page      
    //     });
    // };

    return isAPICalled ? (
        <div>
            <div className="sticky-page-header">
                <div className="container">
                    <Desktop>
                        <Row className="ml-5">
                            <Col md="1">
                                <Link to="/settings/nonbillable">
                                    <a
                                        onKeyPress={() => { }}
                                        role="link"
                                        tabIndex="0"
                                    >
                                        <Image
                                            className="imageArrow"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </a>
                                </Link>
                            </Col>
                            <Col md="8" >
                                {
                                    isAddMode ? (
                                        <h5 className="mt-1">
                                            Untitled Non Billable
                                        </h5>
                                    ) : (
                                        <>
                                            <h5 className="mb-0">
                                                <strong>{nonBillable.title}</strong>
                                            </h5>
                                            <p className="txt-light-secondary mb-0">Last Updated on {DateConvert(nonBillable.updatedAt)}</p>
                                        </>
                                    )
                                }
                            </Col>
                            <Col md="1" className="pr-0 ml-5">
                                <Button
                                    variant="default"
                                    onClick={handleReset}
                                    className="float-right btn-ftc-border-primary "
                                >
                                    Reset
                                </Button>
                            </Col>
                            {
                                !isAddMode ? (
                                    <Col md="1">
                                        <div className="ml-0">
                                            <NavDropdown title={<Ellipses />} id="collasible-nav-dropdown">
                                                <NavDropdown.Item
                                                    onClick={handleShow}
                                                    disabled={!validatePermission(authUser.user.role.permissions, 'non_billable', 'delete')}
                                                >
                                                    Delete</NavDropdown.Item>
                                            </NavDropdown>
                                        </div>
                                    </Col>
                                ) : ('')
                            }
                        </Row>
                    </Desktop>
                    <Mobile>
                        <div className="container">
                            <Row className="pb-1">
                                <Col xs="1" className="p-0">
                                    <Link to="/settings/nonbillable">
                                        <Image
                                            className="imageArrowMobile mt-1"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </Link>
                                </Col>
                                <Col xs="8" className="mt-1">
                                    {
                                        isAddMode ? (
                                            <h6 className="m-auto mb-1"><strong>Untitled Non Billable</strong> </h6>
                                        ) : (
                                            <>
                                                <h6 className="m-auto"><strong>{nonBillable.title}</strong> </h6>
                                                <span className="txt-light-secondary mx-auto">Last Updated on {DateConvert(nonBillable.updatedAt)}
                                                </span>
                                            </>
                                        )
                                    }
                                </Col>

                                <Col xs="1">
                                    <Image
                                        onClick={handleReset}
                                        className="ftc-logo-24  mt-1"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-reset.svg`}
                                    />
                                </Col>
                                {
                                    !isAddMode ? (
                                        <Col xs="1">
                                            <div>
                                                <NavDropdown title={<Ellipses />} id="collasible-nav-dropdown">
                                                    <NavDropdown.Item
                                                        onClick={handleShow}
                                                        disabled={!validatePermission(authUser.user.role.permissions, 'non_billable', 'delete')}
                                                    >
                                                        Delete</NavDropdown.Item>
                                                </NavDropdown>
                                            </div>
                                        </Col>
                                    ) : ('')
                                }
                            </Row>
                        </div>
                    </Mobile>
                </div>
            </div>
            <Desktop>
                <div className="container-top-8" />
            </Desktop>
            <Mobile>
                <div className="content-vcenter" />
            </Mobile>
            <div className="container">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <Row>
                            <Col md="8" className="mx-auto">
                                <div>
                                    <Row>
                                        <Col md="12">
                                            <h5><strong>Non-Billable Reason</strong></h5>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md="12">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label className="txt-light-secondary">Title for Non-billable Reason</Form.Label>
                                                <Form.Control
                                                    {...register('title')}
                                                    type="text"
                                                    placeholder=""
                                                />
                                                {errors.title && (
                                                    <p className="text-danger">
                                                        {errors.title.message}
                                                    </p>
                                                )}
                                            </Form.Group>

                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md="12">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label className="txt-light-secondary">Assign to Hours Category </Form.Label>
                                                <Controller
                                                    render={({ field: { name } }) => (
                                                        <Select
                                                            name={name}
                                                            id='categoryName'
                                                            isMulti={false}
                                                            closeMenuOnSelect
                                                            components={animatedComponents}
                                                            options={hoursCategories}
                                                            getOptionLabel={option => option.catgory_name}
                                                            getOptionValue={option => option._id}
                                                            value={selectedCategory}
                                                            onChange={(option) => {
                                                                setselectedCategory(option);
                                                            }}
                                                            styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                                                            onMenuOpen={() => {
                                                                // onMenuOpen('categoryName');
                                                            }}
                                                            menuShouldScrollIntoView
                                                        // onBlur={() => {
                                                        //     onBlurEmployee();
                                                        // }}
                                                        />
                                                    )}
                                                    name="hours_category"
                                                    control={control}
                                                />
                                                {(errors.hours_category && isSubmitted) && (
                                                    <p className="text-danger">
                                                        {errors.hours_category.message}
                                                    </p>
                                                )}
                                            </Form.Group>

                                        </Col>
                                    </Row>
                                    {
                                        roles.length ? <Row>
                                            <Col md="12" xs="12">
                                                <Form.Label className="txt-light-secondary">Accessible by </Form.Label>
                                            </Col>
                                            {
                                                roles.map((role) => {
                                                    return (
                                                        <Col md="3" xs="4" key={role._id}>
                                                            <Form.Group controlId="formBasicCheckbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="ftc-checkbox"
                                                                    id={role._id}
                                                                    checked={role.isChecked}
                                                                    onChange={(e) => {
                                                                        onRoleChange(e, role);
                                                                    }}
                                                                />
                                                                <label data-content={roles.rolename} className="position-relative ml-4 pl-2" >{role.rolename}</label>
                                                            </Form.Group>
                                                        </Col>
                                                    );
                                                })
                                            }
                                            {(errors.access && isSubmitted) && (
                                                <Col md="12">
                                                    <p className="text-danger">
                                                        {errors.access.message}
                                                    </p>
                                                </Col>
                                            )}
                                        </Row> : ''
                                    }
                                    <Row>
                                        <Col>
                                            <div className="border-bottom" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <button type="submit" disabled={isSubmitting} className="btn btn-ftc-primary float-right ">
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            {show && <ConfirmAlertModal
                show={show}
                handleClose={handleClose}
                title='Delete Non Billable'
                description='Deleting this Non-Billable Reason will erase all data inside this record.
                Are you sure you want to delete this Non-Billable Reason?'
                callback={onDeleteNonBillable}
                action='Delete it'
                closeAction='Cancel'
            />}

            <Prompt
                when={showPrompt}
                message='You have unsaved changes, are you sure you want to leave?'
            />
        </div>
    ) : ('')
});

export default ManageNonbillable
