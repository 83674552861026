import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const getAllRoles = (pagination, page, rolename,) =>
{
    return API.get(`${API_ENDPOINTS.ROLES.Roles}?pagination=${pagination}&page=${page}&rolename=${rolename}`);
}

const getAllRolesByNames = () =>
{
    return API.get(API_ENDPOINTS.ROLES.AllRolesByName);
};

const createRole = (body) =>
{
    return API.post(API_ENDPOINTS.ROLES.Roles, body);
};

const getRoleByID = (roleId) =>
{
    return API.get(`${API_ENDPOINTS.ROLES.Roles}/${roleId}`);
}

const updateRoleByID = (roleId, body) => {
    return API.put(`${API_ENDPOINTS.ROLES.Roles}/${roleId}`, body);
}

const deleteRoleByID = (roleId) => {
    return API.del(`${API_ENDPOINTS.ROLES.Roles}/${roleId}`);
}

export
{
    getAllRoles,
    createRole,
    getAllRolesByNames,
    getRoleByID,
    updateRoleByID,
    deleteRoleByID
};