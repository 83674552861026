import React, { memo, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Row, Col, Image, NavDropdown, Card, } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';

import Ellipses from '../../../components/Ellipses';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import Toaster from '../../../components/Toaster';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import { deleteNonBillableById, getNonBillableById } from '../../../services/non_billable.service';
import { DateConvert } from '../../../utils/TimesheetManagement';
import { validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';

const ViewNonbillable = memo(() =>
{
    const { authUser } = useAuthContext();
    const history = useHistory();
    const { nonBillId } = useParams();

    const [isAPICalled, setAPICalled] = useState(false);
    const [nonBillable, setNonBillable] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    async function getNonBillableDetails()
    {
        try
        {
            const response = await trackPromise(getNonBillableById(nonBillId));
            setNonBillable(response.data.data.non_billable);
            setAPICalled(true);
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    useEffect(() =>
    {
        getNonBillableDetails()
    }, [nonBillId]);    

    const onEditNonBillable = () =>
    {
        history.push(`/settings/editNonbillable/${nonBillable._id}`)
    }

    const onDeleteNonBillable = async () =>
    {
        try
        {            
            const response = await trackPromise(
              deleteNonBillableById(nonBillId)
            );
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
            history.replace('/settings/nonbillable');
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }      
        finally {
            handleClose();
        }
    }

    const SettingsDropdown = () =>
    {
        return (<div>
            <NavDropdown
                title={<Ellipses />}
                id="collasible-nav-dropdown"
            >
                <NavDropdown.Item
                    onClick={handleShow}                    
                    disabled={!validatePermission(authUser.user.role.permissions, 'non_billable', 'delete')}
                >Delete
          </NavDropdown.Item>
            </NavDropdown>
        </div>);
    };

    return isAPICalled ? (
        <div>
            <div className="sticky-page-header">
            <div className="container">
                <Desktop>
                    <Row className="ml-5">
                        <Col md="1">
                            <Link to='/settings/nonbillable'>
                                <a>
                                    <Image
                                        className="imageArrow"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                        fluid
                                    />
                                </a>
                            </Link>
                        </Col>
                        <Col md="8">
                            <h5 className="mb-0">
                                <strong>{nonBillable.title}</strong>
                            </h5>
                            <label className="txt-light-secondary mb-0">Last Updated on {DateConvert(nonBillable.updatedAt)}</label>
                        </Col>
                        <Col md="1" className="pr-0">
                            <Button
                                variant="default"
                                className="btn-ftc-border-primary ml-5"
                                onClick={onEditNonBillable}
                                disabled={!validatePermission(authUser.user.role.permissions, 'non_billable', 'edit')}
                            >
                                Edit
                            </Button>
                        </Col>
                        <Col md="1">
                            <div className="ml-5">
                                <SettingsDropdown />
                            </div>
                        </Col>
                    </Row>
                </Desktop>
                <Mobile>
                    <div className="container">
                        <Row className="pb-0">
                            <Col xs="1" className="p-0">
                                <Link to="/settings/nonbillable">
                                    <Image
                                        className="imageArrowMobile mt-1"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                        fluid
                                    />
                                </Link>
                            </Col>
                            <Col xs="9" className="mt-1">
                                <h6 className="m-auto"><strong>{nonBillable.title}</strong> </h6>
                                <p className="txt-light-secondary mx-auto mb-1">Last Updated on {DateConvert(nonBillable.updatedAt)}
                                </p>
                            </Col>
                            <Col xs="1">
                                <div>
                                    <SettingsDropdown />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Mobile>
            </div>
            </div>
            <Desktop>
                <div className="container-top-8" />
            </Desktop>
            <Mobile>
                <div className="container-top-20" />
            </Mobile>
            <div className="container">
                <Row>
                    <Col md="8" className="mx-auto">
                        <Card className="mt-3">
                            <Card.Body className="pt-0 pb-2">
                                <Row>
                                    <Col md="12" xs="12" className="pl-0 pr-0">
                                        <h5 className="txt-primary"><strong>Non-Billable Reason</strong></h5>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6" className="pl-0 pr-0">
                                        <label className="txt-light-secondary">Title</label>
                                    </Col>
                                    <Col md="6" xs="6" className="pl-0 pr-0">
                                        <label className="float-right"><strong>{nonBillable.title}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6" className="pl-0 pr-0">
                                        <label className="txt-light-secondary">Assign to Hours Category</label>
                                    </Col>
                                    <Col md="6" xs="6" className="pl-0 pr-0">
                                        <label className="float-right"><strong>{nonBillable?.hours_category?.catgory_name ?? 'Not Specified'}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="pt-3 pb-2" >
                                    <Col md="6" xs="5" className="pl-0 pr-0">
                                        <label className="txt-light-secondary">Accessible to</label>
                                    </Col>
                                    <Col md="6" xs="7" className="pl-0 pr-0">
                                        <label className="float-right">
                                            <strong>
                                                {nonBillable?.access?.map((c) => c.rolename ?? 'N/A').join(' | ')}
                                            </strong>
                                        </label>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Mobile>
                    <Row style={{marginTop: "115%"}} className="border-top-shadow">
                        <Col xs={12} className="pr-3">
                            <Button
                                variant="default"
                                onClick={onEditNonBillable}
                                disabled={!validatePermission(authUser.user.role.permissions, 'non_billable', 'edit')}
                                className="float-right btn-ftc-border-primary col-3"
                            >
                                Edit
                            </Button>
                        </Col>
                    </Row>
                </Mobile>
            </div>
            {show && <ConfirmAlertModal
                show={show}
                handleClose={handleClose}
                title='Delete Non Billable'
                description='Deleting this Non-Billable Reason will erase all data inside this record.
                Are you sure you want to delete this Non-Billable Reason?'
                callback={onDeleteNonBillable}
                action='Delete it'
                closeAction='Cancel'
            />}
        </div>
    ) : ('')
});

export default ViewNonbillable
