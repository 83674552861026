import React from 'react';
import { Row, Form, Col, Image } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';

import SettingsDropdown from '../../../../components/SettingsDropdown/SettingsDropdown';

// import Typography from '@material-ui/core/Typography';
import './UsersTableMobileView.css';
import { getUserStatusLabel } from '../../../../utils/UserManagement';
import AvatarImage from '../../../../assets/images/avatar.svg';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    overflow: 'visible',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const TableMobileView = ({
  user,
  handleViewClick,
  handleEditClicked,
  handleClickDelete,
  callback,
  isEditable,
  isDeletable,
}) => {
  const classes = useStyles();

  return (
    <div
      onClick={(event) => {
        const settingsDropDown = event.target.closest('#settings-dropdown');
        if (!settingsDropDown) {
          callback(user);
        }
      }}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      <Card className={classes.root}>
        <CardContent className="pt-0">
          <Row className="pr-0 pl-0">
            {/* <span><SettingsDropdown /></span> */}
            <Col className="border-bottom pt-2" xs="7">
              <Form.Group className="mb-0" controlId="formBasicServiceCity">
                {/* <Image
                                    className="mr-2 align-top"
                                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-user-red.svg`}
                                /> */}
                {user.profile_picture === '' ? (
                  <Image
                    className="mr-2 align-top"
                    src={AvatarImage}
                    style={{
                      height: '45px',
                      width: '45px',
                      borderRadius: '50%',
                    }}
                    onError={(e) => {
                      e.target.src = AvatarImage; // Set fallback image if src image fails to load
                    }}
                  />
                ) : (
                  <Image
                    className="mr-2 align-top"
                    src={user.profile_picture_url}
                    style={{
                      height: '45px',
                      width: '45px',
                      borderRadius: '50%',
                    }}
                    onError={(e) => {
                      e.target.src = AvatarImage; // Set fallback image if src image fails to load
                    }}
                  />
                )}
                <label>
                  <strong>{user.username}</strong>
                  <p>{user.role.rolename}</p>
                </label>
              </Form.Group>
            </Col>
            <Col className="pt-2 border-bottom" xs="5">
              <Form.Group className="mb-0" controlId="formBasicServiceCity">
                <Form.Label
                  className={
                    user.status === 'active' ? 'label-active' : 'label-inactive'
                  }
                >
                  <div
                    className={
                      user.status === 'active'
                        ? 'active-ellipse'
                        : 'inactive-ellipse'
                    }
                  />
                  {` `}
                  {getUserStatusLabel(user.status)}
                </Form.Label>
              </Form.Group>
              <span className="ellipse__mobile float-right">
                <SettingsDropdown
                  identifier={user.employeeId}
                  handleViewClick={handleViewClick}
                  handleEditClicked={handleEditClicked}
                  handleClickDelete={handleClickDelete}
                  isEditable={isEditable}
                  isDeletable={isDeletable}
                />
              </span>
            </Col>
            <Col className="pt-2" xs="12">
              <Form.Group className="mb-0" controlId="formBasicTotaldue">
                <Image
                  className="mr-2"
                  src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-location.svg`}
                />
                <Form.Label>
                  {user.service_city[0]?.cityname ?? 'N/A'}
                  {user.service_city.length > 1 && (
                    <>
                      <span className="txt-primary">
                        {' '}
                        +{user.service_city.length - 1}
                      </span>{' '}
                    </>
                  )}
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </CardContent>
      </Card>
    </div>
  );
};

TableMobileView.propTypes = {
  user: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  handleViewClick: PropTypes.func.isRequired,
  handleEditClicked: PropTypes.func.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isDeletable: PropTypes.bool.isRequired,
};

export default TableMobileView;
