import React, { useEffect, useState, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
// import { usePromiseTracker } from 'react-promise-tracker';
import { openDB } from 'idb';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import routes from './routes';

import Toaster from './components/Toaster';
import DynamicComponent from './components/DynamicComponent';
import CheckNetworkStatus from './components/CheckNetworkStatus';
import Loading from './components/Loading/Loading';

import { AuthContext, useAuthContext } from './contexts/user-context';
import { getAllPages } from './services/custom_page.service';
import { validatePermission } from './utils/UserManagement';
import { OfflineTimesheetSyncContextProvider } from './contexts/offlineTimesheetContext';
import { getHomePageOfUser } from './utils/SidebarManagement';

toast.configure();

function PrivateRoute({ children, exact, path, action, moduleName, type }) {
  const { authUser } = useAuthContext();
  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) => {
        // If not logged in, redirect to /login
        if (!authUser.token) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { referer: location } }}
            />
          );
        }

        // check if it was custom page or Landing page return children without checking

        if (
          type === 'custom' ||
          moduleName === 'landing' ||
          moduleName === 'pay-period'
        ) {
          return children;
        }

        // Check if the current user role has access to this route or not
        // If not able to access, redirect to their homepage

        const isAbleToAccess = validatePermission(
          authUser.user.role.permissions,
          moduleName,
          action
        );
        if (!isAbleToAccess) {
          return (
            <Redirect
              to={{
                pathname: getHomePageOfUser(authUser.user.role),
              }}
            />
          );
        }

        if (path === '/') {
          return (
            <Redirect
              to={{
                pathname: getHomePageOfUser(authUser.user.role),
              }}
            />
          );
        }

        // If able to access, move forward
        return children;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  exact: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  type: PropTypes.string,
  // ableToAccess: PropTypes.array.isRequired,
};

PrivateRoute.defaultProps = {
  type: '',
};

function App() {
  const existingToken = localStorage.getItem('token');
  const existingUser = JSON.parse(localStorage.getItem('user'));
  // Initialize App state with existing token, if exists.
  const [authUser, setAuthUser] = useState({
    user: existingUser,
    token: existingToken,
  });

  const [customPages, setcustomPages] = useState([]);

  async function fetchAllPages() {
    try {
      const response = await getAllPages();
      setcustomPages(response.data.data.customPages);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const setAuthUserExtended = async (user, token) => {
    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));

      try {
        await openDB('filters', 1, {
          upgrade(db) {
            // Create a store of objects
            const store = db.createObjectStore('filters_data', {
              // The 'uniqueId' property of the object will be the key.
              keyPath: 'moduleName',
              // If it isn't explicitly set, create a value by auto incrementing.
              // autoIncrement: true,
            });
            // Create an index on the 'date' property of the objects.
            store.createIndex('moduleName', 'moduleName');
          },
        });
      } catch (error) {
        console.log(error);
      }

      fetchAllPages();
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
    setAuthUser({ user, token });
  };

  useEffect(() => {
    if (authUser?.token) {
      fetchAllPages();
    }
  }, [authUser]);

  return (
    <>
      <AuthContext.Provider
        value={{ authUser, setAuthUser: setAuthUserExtended }}
      >
        <OfflineTimesheetSyncContextProvider>
          <Suspense fallback={<Loading />}>
            <Router>
              <div className="App">
                <div className="main">
                  <Switch>
                    {routes.publicRoutes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                      >
                        <route.component />
                      </Route>
                    ))}
                    {routes.privateRoutes.map((route) => (
                      <PrivateRoute
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        ableToAccess={route.ableToAccess}
                        action={route.action}
                        moduleName={route.moduleName}
                      >
                        <route.component tabs={route.tabs} />
                      </PrivateRoute>
                    ))}
                    {customPages.map((customPage) => (
                      <PrivateRoute
                        key={customPage._id}
                        path={customPage.route}
                        exact
                        ableToAccess={[]}
                        action="view"
                        moduleName="custom_page"
                        type="custom"
                      >
                        <DynamicComponent customPage={customPage} />
                      </PrivateRoute>
                    ))}
                    <Route path="*">
                      <Redirect to={{ pathname: '/page-not-found' }} />
                    </Route>
                  </Switch>
                </div>
              </div>
              <Loading />
            </Router>
          </Suspense>
          <ToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
          />
          {/* <PrefetchApi /> */}
        </OfflineTimesheetSyncContextProvider>
      </AuthContext.Provider>
      <CheckNetworkStatus />
    </>
  );
}

export default App;
