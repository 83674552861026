import React, { memo, useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import { trackPromise } from 'react-promise-tracker';
import { Button, Row, Col, Image, NavDropdown, Card, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Ellipses from '../../../components/Ellipses';
import Toaster from '../../../components/Toaster';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';
import { deleteCityById, getCityById, updateCityById } from '../../../services/city-service';

import { DateConvert } from '../../../utils/TimesheetManagement';
import { validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';

const ViewCity = memo(() =>
{
    const { authUser } = useAuthContext();
    const { cityId } = useParams();
    const history = useHistory();

    const [isAPICalled, setIsAPICalled] = useState(false);

    const [city, setCity] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const updateCityStatus = async (status) =>
    {
        try
        {
            const body = {
                status,
            };
            setIsAPICalled(false);
            const response = await trackPromise(updateCityById(cityId, body));
            setCity(response.data.data.city);
            if (response.status === 200)
            {
                toast.dark(<Toaster
                    icon="notify"
                    message={response.data.message}
                />)
            }
            setIsAPICalled(true);
        } catch (error)
        {            
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };
    
    async function getCityDetails()
    {
        try
        {
            const response = await trackPromise(getCityById(cityId));
            setCity(response.data.data.city);
            setIsAPICalled(true);
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    const onEditCity = () =>
    {
        history.push(`/settings/editCity/${cityId}`);
    }

    const onDeleteCity = async () =>
    {
        try
        {
            const response = await trackPromise(deleteCityById(cityId));
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally
        {
            setShow(false);
            history.replace('/settings/cities');
        }
    };

    useEffect(() =>
    {
        getCityDetails();
    }, [cityId]);

    const SettingsDropdown = () =>
    {
        return (
            <div>
                <NavDropdown
                    title={<Ellipses />}
                    id="collasible-nav-dropdown"
                >
                    {city.status === 'active' ? (
                        <NavDropdown.Item
                            disabled={!validatePermission(authUser.user.role.permissions, 'city', 'edit')}
                            onClick={() =>
                            {
                                updateCityStatus('inactive')
                            }}>Deactivate</NavDropdown.Item>
                    ) : ('')}
                    {city.status === 'inactive' ? (
                        <NavDropdown.Item
                            disabled={!validatePermission(authUser.user.role.permissions, 'city', 'edit')}
                            onClick={() =>
                            {
                                updateCityStatus('active')
                            }}>Activate</NavDropdown.Item>
                    ) : ('')}
                    <NavDropdown.Item
                        onClick={handleShow}
                        disabled={!validatePermission(authUser.user.role.permissions, 'city', 'delete')}
                    >
                        Delete
                    </NavDropdown.Item>
                    
                </NavDropdown>
            </div>
        );
    };

    return isAPICalled ? (
        <div>
            <div className="sticky-page-header">
                <div className="container">
                    <Desktop>
                        <Row className="ml-5"> 
                            <Col md="1">
                                <Link to='/settings/cities'>
                                    <a>
                                        <Image
                                            className="imageArrow"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </a>
                                </Link>
                            </Col>
                            <Col md="8">
                                <h5 className="mb-0">
                                    <strong>{city.cityname}</strong>
                                </h5>
                                <label className="txt-light-secondary mb-0">Last Updated on {DateConvert(city.updatedAt)}</label>
                            </Col>
                            <Col md="1" className="pr-0">
                                <Button
                                    variant="default"
                                    className="btn-ftc-border-primary ml-5"
                                    onClick={onEditCity}
                                    disabled={city.status === 'inactive' || !validatePermission(authUser.user.role.permissions, 'city', 'edit')}
                                >
                                    Edit
                            </Button>
                            </Col>
                            <Col md="1">
                                <div className="ml-5">
                                    <SettingsDropdown />
                                </div>
                            </Col>
                        </Row>
                    </Desktop>
                    <Mobile>
                        <div className="container">
                            <Row className="pb-0">
                                <Col xs="1" className="p-0">
                                    <Link to="/settings/cities">
                                        <Image
                                            className="imageArrowMobile mt-1"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </Link>
                                </Col>
                                <Col xs="9" className="mt-1">
                                    <h6 className="m-auto"><strong>{city.cityname}</strong> </h6>
                                    <p className="txt-light-secondary mx-auto">
                                        Last Updated on {DateConvert(city.updatedAt)}
                                    </p>
                                </Col>
                                <Col xs="1">
                                    <div>
                                        <SettingsDropdown />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Mobile>
                </div>
            </div>
            <Desktop>
                <div className="container-top-8" />
            </Desktop>
            <Mobile>
                <div className="container-top-20" />
            </Mobile>
            <div className="container">
                <Row>
                    <Col md="8" className="mx-auto">
                        <Card className="mt-3">
                            <Card.Body className="pt-0 pb-2">
                                <Row>
                                    <Col md="12" xs="12">
                                        <h5 className="txt-primary"><strong>City Rates</strong></h5>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">City Name</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>{city.cityname}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Non Billable Rate</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>$ {city.non_billable_rate.toFixed(2)}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Basic Rate</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>$ {city.basic_rate.toFixed(2)}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Prime Rate</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>$ {city.prime_rate.toFixed(2)}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Special Rate</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>$ {city.special_rate.toFixed(2)}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">Holding Fee</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>$ {city.holding_fee.toFixed(2)}</strong></label>
                                    </Col>
                                </Row>
                                <Row className="pt-3 pb-2">
                                    <Col md="6" xs="6">
                                        <label className="txt-light-secondary">EMPL Share</label>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label className="float-right"><strong>$ {city?.empl_share?.toFixed(2) ?? '0.00'}</strong></label>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Row>
                            <Col md="12" className="pr-0 pl-0">
                                {
                                    city.status === 'active' && (
                                        <Alert variant='success'>
                                            <Col md="12">
                                                <span>
                                                    <Image
                                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`}
                                                        fluid
                                                    />
                                                    {` `}Active City
                                            </span>
                                            </Col>
                                        </Alert>
                                    )
                                }
                                
                                {
                                    city.status === 'inactive' && (
                                        <Alert variant="secondary">
                                            <Col md="12">
                                                <span>
                                                    <Image
                                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-alert-grey.svg`}
                                                        fluid
                                                    />
                                                    {' '}Inactive City
                                            </span>
                                            </Col>
                                        </Alert>
                                    )
                                }

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Mobile>
                    <Row className="border-top-shadow  container-top-50">
                        <Col xs={12} className="pr-2">
                            <Button
                                variant="default"
                                onClick={onEditCity}
                                disabled={
                                    city.status === 'inactive' ||
                                    !validatePermission(authUser.user.role.permissions, 'city', 'edit')
                                }
                                className="float-right btn-ftc-border-primary col-3"
                            >
                                Edit
                            </Button>
                        </Col>
                    </Row>
                </Mobile>
            </div>
            {show && <ConfirmAlertModal
                show={show}
                handleClose={handleClose}
                title='Delete City'
                description='Deleting this City will erase all data inside this record.
                Are you sure you want to delete this City?'
                callback={onDeleteCity}
                action='Delete it'
                closeAction='Cancel'
            />}
        </div >
    ) : ('');
});

export default ViewCity
