import React, { memo } from 'react';
import { Row, Form, Col, Image } from 'react-bootstrap';
// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import SettingsDropdown from '../../../../components/SettingsDropdown/SettingsDropdown';

import './NonbillableTable.css';
import { DateConvert } from '../../../../utils/TimesheetManagement';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        overflow: 'visible'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const NonbillableTable = memo(({
    nonBill,
    handleViewClick,
    handleEditClicked,
    handleClickDelete,
    callback,
    isEditable,
    isDeletable,
}) => {

    const classes = useStyles();

    return (
        <div onClick={(event) => {
            const settingsDropDown = event.target.closest('#settings-dropdown');
            if (!settingsDropDown) {
                callback(nonBill)
            }
        }} onKeyDown={() => { }} role="button" tabIndex={0}>
            <Card className={`${classes.root} mt-2`}

            >
                <CardContent>
                    <Row className="border-bottom p-0" >
                        <Col xs="12"
                            onClick={() => {
                                callback(nonBill);
                            }}
                        >
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <Image
                                    className="mr-2 align-top ftc-logo-24 float-left"
                                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-nonbillable-red.svg`}
                                />
                                {/* <label > */}
                                <strong>{nonBill?.title}</strong>
                                <p className="mb-1">{DateConvert(nonBill?.updatedAt)}</p>
                                {/* </label> */}
                            </Form.Group>

                        </Col>
                        <Col xs="12" className="pr-0 ml-2">
                            <span className="ellipse__nbr__mobile float-right">
                                <SettingsDropdown
                                    identifier={nonBill._id}
                                    handleViewClick={handleViewClick}
                                    handleEditClicked={handleEditClicked}
                                    handleClickDelete={handleClickDelete}
                                    isEditable={isEditable}
                                    isDeletable={isDeletable}
                                />
                            </span>
                        </Col>
                    </Row>
                    <Row className="pl-0 pb-2">
                        <Col xs="12">
                            <Form.Group className="mb-0" controlId="formBasicHours">
                                <Image
                                    className="mr-2 align-top ftc-logo-24"
                                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-time.svg`}
                                />
                                <Form.Label>{nonBill?.hours_category.catgory_name}</Form.Label>

                            </Form.Group>
                        </Col>
                    </Row>
                </CardContent>
            </Card >
        </div>
    )
});

export default NonbillableTable

NonbillableTable.propTypes = {
    nonBill: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    handleViewClick: PropTypes.func.isRequired,
    handleEditClicked: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isDeletable: PropTypes.bool.isRequired,
}
