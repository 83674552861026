import React from 'react';

import { Row, Col, Image, Form } from 'react-bootstrap';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import {
  DateConvert,
  getPaymentStatusLabel,
  minuteToTimeStr,
} from '../../../utils/TimesheetManagement';

import './TableMobileView.css';
import SettingsDropdown from '../../../components/SettingsDropdown/SettingsDropdown';
import { useAuthContext } from '../../../contexts/user-context';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  background: {
    background: '#FFF4F4',
    border: '1px solid red !important',
  },
});

const TableMobileView = ({
  timesheet,
  handleViewClick,
  handleEditClicked,
  handleClickDelete,
  callback,
  isEditable,
  isDeletable,
  handleOfflineRowClick,
  handleOfflineViewClick,
  handleOfflineEditClick,
}) => {
  const classes = useStyles();
  const { authUser } = useAuthContext();

  const isOfficeUser = authUser.user.role.rolename !== 'Office';
  return (
    <>
      {navigator.onLine ? (
        <div
          className=""
          onClick={(event) => {
            const settingsDropDown = event.target.closest('#settings-dropdown');
            if (!settingsDropDown) {
              callback(timesheet);
            }
          }}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <Card
            className={`${classes.root} ${
              timesheet.isAcknowledged === false && timesheet.adjustment != null
                ? `${classes.background}`
                : ''
            }`}
          >
            <CardContent>
              <Row className="p-0">
                <Col className="border-bottom" xs="6">
                  <Form.Group className="mb-0" controlId="ts-employee">
                    <Form.Label className="mb-0 txt-light-secondary">
                      Employee
                    </Form.Label>
                    <Form.Label className="d-flex">
                      {timesheet.employee.username}
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col className="border-bottom" xs="6">
                  {isOfficeUser && (
                    <Form.Group className="mb-0" controlId="ts-client">
                      <Form.Label className="mb-0 txt-light-secondary">
                        Client
                      </Form.Label>
                      <Form.Label className="d-flex">
                        {timesheet?.client?.username ?? 'N/A'}
                      </Form.Label>
                    </Form.Group>
                  )}
                </Col>
                <Col className="border-bottom pt-2" xs="12">
                  <span className="ellipse__mobile__ts float-right">
                    <SettingsDropdown
                      identifier={timesheet.timesheetId}
                      handleViewClick={handleViewClick}
                      handleEditClicked={handleEditClicked}
                      handleClickDelete={handleClickDelete}
                      isEditable={isEditable}
                      isDeletable={isDeletable}
                    />
                  </span>
                  <Form.Group className="mb-0" controlId="ts-servicecityname">
                    <Image
                      className="mr-2"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-location.svg`}
                    />
                    <Form.Label>{timesheet.service_city.cityname}</Form.Label>
                  </Form.Group>
                </Col>
                <Col className="border-bottom pt-2" xs="6">
                  <Form.Group className="mb-0" controlId="ts-totaldue">
                    <Image
                      className="mr-2"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-money.svg`}
                    />
                    <Form.Label className="mt-1">
                      <span>${timesheet.totalDue} Due</span>
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col className="border-bottom pt-2" xs="6">
                  <Form.Group className="mb-0" controlId="formBasicServiceCity">
                    <Form.Label
                      className={
                        timesheet.payment_status === 'pending'
                          ? 'label-pending-sm'
                          : 'label-active-sm'
                      }
                    >
                      <div
                        className={
                          timesheet.payment_status === 'pending'
                            ? 'pending-ellipse'
                            : 'active-ellipse'
                        }
                      />
                      {` `}
                      {getPaymentStatusLabel(timesheet.payment_status)}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col className="pt-2" xs="5">
                  <Form.Group className="mb-0" controlId="ts-date">
                    <Form.Label>{DateConvert(timesheet.ts_date)}</Form.Label>
                  </Form.Group>
                </Col>
                <Col className="pt-2" xs="7">
                  <Form.Group className="mb-0" controlId="formBasicPassword">
                    <Form.Label className="float-right">
                      {minuteToTimeStr(timesheet.totalTime)}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
            </CardContent>
          </Card>
        </div>
      ) : (
        <div
          className="offline-Card"
          onClick={(event) => {
            const settingsDropDown = event.target.closest('#settings-dropdown');
            if (!settingsDropDown) {
              handleOfflineRowClick(timesheet);
            }
          }}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <div className="offline-card">
            <Card className={classes.root}>
              <CardContent>
                <Row className="p-0">
                  <Col className="border-bottom " xs="6">
                    <Form.Group className="mb-0" controlId="ts-employee">
                      <Form.Label className="mb-0 txt-light-secondary">
                        Employee
                      </Form.Label>
                      <Form.Label className="d-flex">
                        {timesheet.employee.username}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col className="border-bottom " xs="6">
                    <Form.Group className="mb-0" controlId="ts-client">
                      <Form.Label className="mb-0 txt-light-secondary">
                        Client
                      </Form.Label>
                      <Form.Label className="d-flex">
                        {timesheet?.client?.username ?? 'N/A'}
                      </Form.Label>
                    </Form.Group>
                    <span className="ellipse__mobile__ts float-right">
                      <SettingsDropdown
                        identifier={timesheet.uniqueId}
                        handleViewClick={handleOfflineViewClick}
                        handleEditClicked={handleOfflineEditClick}
                        handleClickDelete={handleClickDelete}
                        isEditable={isEditable}
                        isDeletable={isDeletable}
                      />
                    </span>
                  </Col>
                  <Col className="border-bottom  pt-2" xs="12">
                    <Form.Group className="mb-0" controlId="ts-servicecityname">
                      <Image
                        className="mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-location.svg`}
                      />
                      <Form.Label>{timesheet.service_city.cityname}</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col className="border-bottom  pt-2" xs="6">
                    <Form.Group className="mb-0" controlId="ts-totaldue">
                      <Image
                        className="mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-money.svg`}
                      />
                      <Form.Label className="mt-1">
                        <span>{timesheet.totalDue} Due</span>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col className="border-bottom  pt-2" xs="6">
                    <Form.Group
                      className="mb-0"
                      controlId="formBasicServiceCity"
                    >
                      <Form.Label
                        className={
                          timesheet.payment_status === 'pending'
                            ? 'label-pending-sm'
                            : 'label-active-sm'
                        }
                      >
                        <div
                          className={
                            timesheet.payment_status === 'pending'
                              ? 'pending-ellipse'
                              : 'active-ellipse'
                          }
                        />
                        {` `}
                        {getPaymentStatusLabel(timesheet.payment_status)}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col className="border-bottom  pt-2" xs="5">
                    <Form.Group className="mb-0" controlId="ts-date">
                      <Form.Label>{DateConvert(timesheet.ts_date)}</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col className="border-bottom  pt-2" xs="7">
                    <Form.Group className="mb-0" controlId="formBasicPassword">
                      <Form.Label className="float-right">
                        {minuteToTimeStr(timesheet.totalTime)}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col className="pt-2 offline-white-bg" xs="12">
                    <Form.Group className="mb-0" controlId="ts-totaldue">
                      <p className="txt-light-secondary mb-1">
                        <Image
                          className="mr-2"
                          src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-switch-off.svg`}
                        />
                        Submitted Offline
                      </p>
                    </Form.Group>
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

TableMobileView.propTypes = {
  timesheet: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  handleViewClick: PropTypes.func.isRequired,
  handleEditClicked: PropTypes.func.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  handleOfflineRowClick: PropTypes.func.isRequired,
  handleOfflineViewClick: PropTypes.func.isRequired,
  handleOfflineEditClick: PropTypes.func.isRequired,
};

export default TableMobileView;
