import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { toast } from 'react-toastify';
import { TablePagination } from '@material-ui/core';
import { Row, Col, 
    // FormControl, 
    Image, Form } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import Sidebar from '../../side-navbar/Sidebar';
import Toaster from '../../../components/Toaster';
import DataTableComponent from '../../../components/DataTableComponent';
import SettingsDropdown from '../../../components/SettingsDropdown/SettingsDropdown';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import TableMobileView from './mobile-layouts/NonbillableTable';
import RenderCustomPopup from '../../../components/RenderCustomPopup';
import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';

import { deleteNonBillableById, getAllNonBillables } from '../../../services/non_billable.service';
import { DateConvert,showExportButton } from '../../../utils/TimesheetManagement';
import { validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';
import useQuery from '../../../utils/CustomHooks/useQuery';
import NoCustomPageComponent from '../../../components/NoCustomPageComponent/NoCustomPageComponent';
import exportIcon from '../../../assets/images/exportIcon.svg';
import { exportToCSV } from '../../../utils/ExportDataToCSV';
import Loader from '../../../assets/images/loader.svg';

const NonBillable = memo(() => {
    const { authUser } = useAuthContext();
    const history = useHistory();
    const URLQuery = useQuery();
    const location = useLocation();

    const isIndeterminate = (indeterminate) => indeterminate;
    const selectableRowsComponentProps = { indeterminate: isIndeterminate };

    const [isAPICalled, setAPICalled] = useState(false);
    const [nonBillables, setNonBillables] = useState([]);
    const [nonBillableCount, setNonBillableCount] = useState({
        totalCount: 0,
        filterCount: 0
    });
    const [reasonName, setReasonName] = useState(URLQuery.get('reason') ?? '');
    const [searchQuery, setsearchQuery] = useState(
        URLQuery.get('reason') ?? ''
    );

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [selectedRow, setselectedRow] = useState();
    const [showConfirmAlert, setConfirmAlert] = useState(false);
    const [exportAPICalled,setExportAPICalled] = useState(false);

    const handleCloseConfirm = () => setConfirmAlert(false);

    const handleClickDelete = (state) => {
        setselectedRow(state.target.id);
        setConfirmAlert(true);
    };

    const handleEditClicked = (state) => {
        history.push(`/settings/editNonbillable/${state.target.id}`);
    };

    const handleViewClicked = (state) => {
        history.push(`/settings/viewNonbillable/${state.target.id}`);
    };

    const handleRowClicked = (row) => {
        history.push(`/settings/viewNonbillable/${row._id}`);
    };

    const onAddNew = () => {
        history.push('/settings/addNewNonbillable');
    };

    const columns = [
        {
            name: 'Non-Billable Reason',
            selector: 'title',
            sortable: true,
        },
        {
            name: 'Modified on',
            selector: (row) => DateConvert(row.updatedAt),
            sortable: true,
        },
        {
            name: 'Hours Category',
            selector: (row) => row.hours_category?.catgory_name ?? 'N/A',
            sortable: true,
        },
        {
            cell: (row) => {
                const { _id } = row;
                return (
                    <SettingsDropdown
                        identifier={_id}
                        handleViewClick={handleViewClicked}
                        handleEditClicked={handleEditClicked}
                        handleClickDelete={handleClickDelete}
                        drop='left'
                        isEditable={!validatePermission(authUser.user.role.permissions, 'non_billable', 'edit')}
                        isDeletable={!validatePermission(authUser.user.role.permissions, 'non_billable', 'delete')}
                    />
                );
            },
            allowOverflow: true,
            button: true,
            width: '56px', // custom width for icon button
        },
    ];

    async function fetchAllPagesNonBillables() {
        try {
            setExportAPICalled(true);
            const response = await trackPromise(
              getAllNonBillables(nonBillableCount.totalCount, 0, searchQuery)
            );
            const transformedData = response.data.data.non_billables.map(item => ({
                nonBillableReason: item.title,
                modifiedOn: DateConvert(item.updatedAt),
                hoursCategory: item.hours_category?.catgory_name,
                accessibleTo: item?.access?.map((c) => c.rolename ?? 'N/A').join(' | ')
          }));
          exportToCSV(transformedData,`non_billable_data_${DateConvert(new Date())}`);
          setExportAPICalled(false);
          } catch (error) {
            toast.dark(
              <Toaster
                icon="error"
                message={error?.response?.data?.message ?? 'Something went wrong!!!'}
              />
            );
            setExportAPICalled(false);
          }
      }

    async function fetchAllNonBillables() {
        try {
            const response = await trackPromise(
                getAllNonBillables(rowsPerPage, page, searchQuery)
            );
            setNonBillables(response.data.data.non_billables);
            setNonBillableCount({ totalCount: response.data.data.totalCount, filterCount: response.data.data.filterCount });
            setAPICalled(true);
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    const onDeleteNonBillable = async () => {
        try {
            const response = await trackPromise(
                deleteNonBillableById(selectedRow)
            );
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
        } catch (error) {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally {
            setConfirmAlert(false);
            fetchAllNonBillables();
        }
    };

    useEffect(() => {
        if (!searchQuery.length) {
            history.push({
                pathname: location.pathname,
                search: '',
            });
        } else {
            history.push({
                pathname: location.pathname,
                search: qs.stringify({ reason: searchQuery }),
            });
        }
    }, [searchQuery]);

    useEffect(() => {
        fetchAllNonBillables();
    }, [rowsPerPage, page, searchQuery]);

    return (
        <div>
            <Row className="mr-0">
                <Desktop>
                    <Col md="2">
                        <Sidebar />
                    </Col>
                </Desktop>
                <Mobile>
                    <Sidebar />
                </Mobile>
                {
                    (isAPICalled && !nonBillableCount.totalCount)
                        ?
                        <NoCustomPageComponent
                            title='Non Billable'
                            onAddNew={onAddNew}
                            addNewText='Start adding your first ”Non Billable"'
                            isAddNew={validatePermission(authUser.user.role.permissions, 'non_billable', 'add')}
                        />
                        :
                        <>
                            <Desktop>
                                <Col md="10">
                                    <div className="container">                                                                      
                                        <Row className="border-bottom sticky-dashboard-header">
                                            <Col lg={authUser.user.role.rolename=== "Admin" ?  6 : 7} md={4}>
                                                <h3>
                                                    <strong>Non Billable</strong>
                                                </h3>
                                            </Col>
                                            <Col lg={3} md={2} className="pr-0">
                                                <Form.Group controlId="exampleForm.ControlInput1">
                                                    <Form.Control
                                                        type="text"
                                                        className="ml-0"
                                                        placeholder="Search Reasons"
                                                        aria-label="resaons"
                                                        aria-describedby="basic-addon1"
                                                        value={reasonName}
                                                        onChange={(e) => setReasonName(e.target.value)}
                                                        onKeyPress={(event) => {
                                                            if (event.key === 'Enter') {
                                                                setsearchQuery(event.target.value);
                                                                setPage(0);
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {
                      showExportButton(authUser.user.role.rolename) && (
                        <Col xl={1/2} className='ml-3'>
                    <span className="float-left">
                          <button
                            type="button"
                            className="btn btn-ftc-primary-light-border float-right"
                            onClick={() => fetchAllPagesNonBillables()}
                          >
                           {
                              exportAPICalled ? (
                                <><Image src={Loader} height="18px" width="18px" /></>
                              ) : (<><Image
                                src={exportIcon}
                              />{' '}
                                  Export</>)
                            }
                          </button>
                        </span>
                    </Col>
                      )
                    }
                                            {
                                                validatePermission(authUser.user.role.permissions, 'non_billable', 'add') && (
                                                    <Col md={1.5} className="pr-3 ml-3">
                                                        <button type="button" className="btn btn-ftc-primary float-right"
                                                            onClick={onAddNew}
                                                        >
                                                            Add New
                                                        </button>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                        <div className="container-top-7" />
                                        <Row>
                                            <Col md="12">
                                                {isAPICalled ?
                                                    (<><DataTableComponent
                                                        highlightOnHover
                                                        pointerOnHover
                                                        noHeader
                                                        columns={columns}
                                                        data={nonBillables}
                                                        pagination
                                                        selectableRowsComponentProps={selectableRowsComponentProps}
                                                        paginationServer
                                                        paginationTotalRows={nonBillableCount.filterCount}
                                                        handleRowClicked={handleRowClicked}
                                                        paginationPerPage={rowsPerPage}
                                                        onChangePage={(p) => {
                                                            setPage(p - 1);
                                                            // look for better solution later
                                                            // setPage(p)
                                                        }}
                                                        onChangeRowsPerPage={(rws, pg) => {
                                                            setRowsPerPage(rws);
                                                            setPage(pg);
                                                        }}
                                                    />
                                                        <TablePagination
                                                            labelRowsPerPage='Rows per page:'
                                                            component="div"
                                                            count={Number.parseInt(nonBillableCount.filterCount, 10)}
                                                            page={page}
                                                            onChangePage={(event, newPage) => {
                                                                setPage(newPage);
                                                            }}
                                                            defaultPage={0}
                                                            rowsPerPage={rowsPerPage}
                                                            onChangeRowsPerPage={(event) => {
                                                                setRowsPerPage(parseInt(event.target.value, 10));
                                                                setPage(0);
                                                            }}
                                                            ActionsComponent={TablePaginationActions}
                                                        />
                                                    </>) : ('')
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Desktop>
                            <Mobile>
                                <Col>
                                    <div className="container pr-0">
                                        <Row className="border-bottom sticky-dashboard-header-mobile">
                                            <Col xs="9" className="ml-4">
                                                <h3>Non Billable</h3>
                                            </Col>
                                            <Col xs="1" sm="2">
                                                <Image
                                                    className="ftc-logo-40"
                                                    src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                                                />{' '}
                                            </Col>
                                        </Row>
                                        <div className="container-top-20">
                                            <Row className="pt-3">
                                                <Col xs="8" className="pl-1 pr-1">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search"
                                                        value={reasonName}
                                                        onChange={(e) => setReasonName(e.target.value)}
                                                        onKeyPress={(event) => {
                                                            if (event.key === 'Enter') {
                                                                setsearchQuery(event.target.value);
                                                                setPage(0);
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                {
                                                    validatePermission(authUser.user.role.permissions, 'non_billable', 'add') && (
                                                        <Col xs="4" className=" pl-1 pr-1">
                                                            <button
                                                                type="button"
                                                                className="btn btn-ftc-primary float-right"
                                                                onClick={onAddNew}
                                                            >
                                                                Add New
                                                            </button>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                            <Row className="pr-0 pb-0 pr-2">
                      {showExportButton(authUser.user.role.rolename) && (
                        <>
                          <Col xs="8" className="pr-1 pl-1">
                            <></>
                          </Col>
                          <Col xs="4" className="pr-1 pl-1">
                            <span>
                              <button
                                type="button"
                                className="btn btn-ftc-primary-light-border btn-block float-right"
                                onClick={() => fetchAllPagesNonBillables()}
                              >
                                {
                              exportAPICalled ? (
                                <><Image src={Loader} height="18px" width="18px" /></>
                              ) : (<><Image
                                src={exportIcon}
                              />{' '}
                                  Export</>)
                            }
                              </button>
                            </span>
                          </Col>
                        </>
                      )}
                    </Row>
                                            <Row className="pt-0">
                                                {
                                                    nonBillables.map((nonBill) => {
                                                        return (<Col md={6} className="mt-3 pl-1 pr-1">
                                                            <TableMobileView
                                                                nonBill={nonBill}
                                                                callback={handleRowClicked}
                                                                handleViewClick={handleViewClicked}
                                                                handleEditClicked={handleEditClicked}
                                                                handleClickDelete={handleClickDelete}
                                                                isEditable={!validatePermission(authUser.user.role.permissions, 'non_billable', 'edit')}
                                                                isDeletable={!validatePermission(authUser.user.role.permissions, 'non_billable', 'delete')}
                                                            />
                                                        </Col>);
                                                    })
                                                }
                                            </Row>
                                            {
                                                (isAPICalled && !nonBillableCount.filterCount) && (
                                                    <div className="text-center">
                                                        <p>There are no records to display.</p>
                                                    </div>
                                                )
                                            }
                                            <Row>
                                                <Col xs="12">
                                                    <TablePagination
                                                        className="mobile__pagination"
                                                        labelRowsPerPage=''
                                                        ActionsComponent={TablePaginationActions}
                                                        component="div"
                                                        count={Number.parseInt(nonBillableCount.filterCount, 10)}
                                                        page={page}
                                                        // onChangePage={handleChangePage}
                                                        onChangePage={(event, newPage) => {
                                                            setPage(newPage);
                                                        }}
                                                        defaultPage={0}
                                                        rowsPerPage={rowsPerPage}
                                                        onChangeRowsPerPage={(event) => {
                                                            setRowsPerPage(parseInt(event.target.value, 10));
                                                            setPage(0);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Mobile>
                        </>
                }
            </Row>
            {
                showConfirmAlert && <ConfirmAlertModal
                    show={showConfirmAlert}
                    handleClose={handleCloseConfirm}
                    title='Delete Non Billable'
                    description='Deleting this Non Billable will erase all data inside this record.
                Are you sure you want to delete this Non Billable?'
                    callback={onDeleteNonBillable}
                    action='Delete it'
                    closeAction='Cancel'
                />
            }
            <RenderCustomPopup />
        </div >
    )
});

export default NonBillable
