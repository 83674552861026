import React, { memo, useState, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Image,
  InputGroup,
  FormControl,
  Form,
} from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import { TablePagination } from '@material-ui/core';
import makeAnimated from 'react-select/animated';
// import * as Scroll from 'react-scroll';
import Toaster from '../../components/Toaster';
import { Desktop, Mobile } from '../media-queries/mediaQueries';
import { useAuthContext } from '../../contexts/user-context';
import { validatePermission } from '../../utils/UserManagement';
import NoCustomPageComponent from '../../components/NoCustomPageComponent/NoCustomPageComponent';
import DataTableComponent from '../../components/DataTableComponent';
import RenderCustomPopup from '../../components/RenderCustomPopup';
import {
  DateConvert,
  getPaymentStatusLabel,
  getAcknowledgeStatus,
  showExportButton,
} from '../../utils/TimesheetManagement';
import useQuery from '../../utils/CustomHooks/useQuery';
import {
  getAllReimbursements,
  deleteReimbursementById,
} from '../../services/reimbursement-service';
import TablePaginationActions from '../../components/TablePaginationActions/TablePaginationActions';
import {
  getPeriodsLtYear,
  getPeriodsByYear,
} from '../../services/period-service';
import ConfirmAlertModal from '../../components/ConfirmAlertModal/ConfirmAlertModal';
import TableMobileView from './mobile-layouts/ReimbursementMobileTable';
import exportIcon from '../../assets/images/exportIcon.svg';
// import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';
import { exportToCSV } from '../../utils/ExportDataToCSV';
import Sidebar from '../side-navbar/Sidebar';
import Loader from '../../assets/images/loader.svg';
import constant from '../../constant';

const reimbursement = memo(() => {
  const { authUser } = useAuthContext();
  const history = useHistory();
  // const { scroller } = Scroll;
  const animatedComponents = makeAnimated();
  const isIndeterminate = (indeterminate) => indeterminate;
  const selectableRowsComponentProps = { indeterminate: isIndeterminate };
  const URLQuery = useQuery();
  const [isAPICalled, setAPICalled] = useState(false);
  const [reimbursementCount, setReimbursementCount] = useState({
    totalCount: 0,
    filterCount: 0,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [reimbursements, setReimbursements] = useState([]);
  const [employee, setemployee] = useState(URLQuery.get('employee') ?? '');
  const [searchQuery, setsearchQuery] = useState(
    URLQuery.get('employee') ?? ''
  );

  const [allYears, setallYears] = useState([]);
  const [selectedYear, setselectedYear] = useState(null);

  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setselectedPeriod] = useState(null);
  const [selectedRow, setselectedRow] = useState();
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const [exportAPICalled, setExportAPICalled] = useState(false);


  const handleCloseConfirm = () => setConfirmAlert(false);

  const [query, setQuery] = useState('');

  const columns = [
    {
      name: 'Name',
      selector: 'employee.username',
      sortable: true,
    },
    {
      name: 'Reason',
      selector: (row) => {
        const reason = row?.reason;
        return reason?.reason
      },
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => DateConvert(row.reimbursement_date),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => `$${row?.total_amount}`,
      sortable: true,
    },
    {
      name: 'Status',
      minWidth: '120px',
      cell: (row) => {
        // const status = 'active'
        const { reimbursement_status: rstatus } = row;

        return (
          // eslint-disable-next-line react/destructuring-assignment
          <div title={row?.reimbursementAcknowledgedBy?.username}>
            <label
              className={
                rstatus === 'pending'
                  ? 'label-pending'
                  : rstatus === 'denied'
                    ? 'label-deny'
                    : 'label-active'
              }
            >
              <span
                className={
                  // eslint-disable-next-line no-nested-ternary
                  rstatus === 'pending'
                    ? 'pending-ellipse'
                    : rstatus === 'denied'
                      ? 'deny-ellipse'
                      : 'active-ellipse'
                }
              />
              {` `}
              {getAcknowledgeStatus(rstatus)}
            </label>
          </div>
        );
      },
    },
    {
      name: 'Payment',
      minWidth: '120px',
      cell: (row) => {
        // const status = 'active'
        const { payment_status: status } = row;
        return (
          // eslint-disable-next-line react/destructuring-assignment
          <div title={row?.markAsPaidBy?.username}>
            <label
              className={
                status === 'pending' ? 'label-pending' : 'label-active'
              }
            >
              <span
                className={
                  status === 'pending' ? 'pending-ellipse' : 'active-ellipse'
                }
              />
              {` `}
              {getPaymentStatusLabel(status)}
            </label>
          </div>
        );
      },
    },
  ];

  const handleRowClicked = (row) => {
    history.push(`/viewReimbursement/${row.reimbursementId}`);
  };

  const onAddNew = () => {
    history.push('/addNewReimbursement');
    // history.push(`/viewReimbursement/1`);
  };

  const handleViewClick = (state) => {
    history.push(`/viewReimbursement/${state.target.id}`);
  };

  const handleClickDelete = (state) => {
    setselectedRow(state.target.id);
    setConfirmAlert(true);
  };
  const onSearchClick = () => {
    setsearchQuery(employee);
    setPage(0);
  };
  async function fetchAllPagesReimbursements() {

    try {
      setExportAPICalled(true);
      const response = await trackPromise(
        getAllReimbursements(reimbursementCount.totalCount, 0, searchQuery, query)
      );
      const transformedData = response.data.data.reimbursements.map(item => ({
        Name: item.employee.username,
        Reason: item.reason.reason,
        date: DateConvert(item.reimbursement_date),
        Amount: item.total_amount,
        reimbursementStatus: item.reimbursement_status,
        paymentStatus: item.payment_status,
      }));
      exportToCSV(transformedData, `reimbursement_data_${DateConvert(new Date())}`);
      setExportAPICalled(false);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
      setExportAPICalled(false);
    }

  }

  async function fetchAllReimbursements() {
    try {
      const response = await trackPromise(
        getAllReimbursements(rowsPerPage, page, searchQuery, query)
      );
      setReimbursements(response.data.data.reimbursements);
      setReimbursementCount({
        totalCount: response.data.data.totalCount,
        filterCount: response.data.data.filterCount,
      });
      setAPICalled(true);
      // fetchAllPagesReimbursements(response.data.data.getAllPagesReimbursements);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const onDeleteReimbursement = async () => {
    try {
      const response = await trackPromise(deleteReimbursementById(selectedRow));
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setConfirmAlert(false);
      fetchAllReimbursements();
      // history.replace('/reimbursement');
    }
  };

  // const onMenuOpen = (elementName) => {
  //   scroller.scrollTo(elementName, {
  //     duration: 1500,
  //     delay: 100,
  //     smooth: true,
  //     offset: 50, // Scrolls to element + 50 pixels down the page
  //   });
  // };

  async function fetchAllPeriodsYears() {
    try {
      const response = await trackPromise(
        getPeriodsLtYear(new Date().getFullYear())
      );
      setallYears(response.data.data.periods);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }
  async function fetchAllPeriods() {
    try {
      let response;
      if (selectedYear) {
        response = await trackPromise(
          getPeriodsByYear(selectedYear.year, new Date())
        );
      }
      const totalPeriods = response.data.data.periods.length;
      setPeriods(
        response.data.data.periods.map((period, index) => {
          const tempPeriod = period;
          tempPeriod.label = `Period ${totalPeriods - index} (${DateConvert(
            period.start_date
          )} - ${DateConvert(period.end_date)})`;
          tempPeriod.value = period.period_index;
          return tempPeriod;
        })
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }
  const displaySelectedFilters = () => {
    return (
      <Row className="pb-0">
        <Col md="2">
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="txt-light-secondary">Period Year</Form.Label>
            <Select
              name="period_year_options"
              id="periodYearOptionScroll"
              isMulti={false}
              closeMenuOnSelect
              components={animatedComponents}
              options={allYears}
              value={selectedYear}
              getOptionLabel={(option) => option.year}
              getOptionValue={(option) => option.year}
              onChange={(option) => {
                setselectedYear(option);
              }}
              styles={{
                menu: (styles) => ({ ...styles, zIndex: 999 }),
              }}
              onMenuOpen={() => {
                // onMenuOpen('periodYearOptionScroll');
              }}
              menuShouldScrollIntoView
            />
          </Form.Group>
        </Col>
        <Col md="3">
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="txt-light-secondary">Period</Form.Label>
            <Select
              name="period_options"
              id="periodOptionScroll"
              isMulti={false}
              closeMenuOnSelect
              components={animatedComponents}
              options={periods}
              value={selectedPeriod}
              onChange={(option) => {
                setselectedPeriod(option);
              }}
              styles={{
                menu: (styles) => ({ ...styles, zIndex: 999 }),
              }}
              onMenuOpen={() => {
                // onMenuOpen('periodOptionScroll');
              }}
              menuShouldScrollIntoView
            />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    fetchAllPeriodsYears();
  }, []);
  useEffect(() => {
    if (selectedYear) {
      setselectedPeriod(null);
      fetchAllPeriods();
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedPeriod) {
      let queryString = '';
      if (selectedPeriod.start_date !== '') {
        queryString += `&startDate=${selectedPeriod.start_date}`;
      }
      if (selectedPeriod.end_date !== '') {
        queryString += `&endDate=${selectedPeriod.end_date}`;
      }
      setQuery(queryString);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    fetchAllReimbursements();
  }, [rowsPerPage, page, searchQuery, query]);

  return (
    <div>
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar />
        </Mobile>
        {isAPICalled && !reimbursementCount.totalCount ? (
          <NoCustomPageComponent
            title="Reimbursements"
            onAddNew={onAddNew}
            addNewText='Start adding your first ”Reimbursement"'
            isAddNew={validatePermission(
              authUser.user.role.permissions,
              'reimbursement',
              'add'
            )}
          />
        ) : (
          <>
            <Desktop>
              <Col md="10">
                <div className="container">
                  <Row className="border-bottom sticky-dashboard-header">
                    <Col md={4} lg={5}>
                      <h3>
                        <strong>Reimbursement</strong>
                      </h3>
                    </Col>
                    <Col />
                    <Col md={3} className="pl-0">
                      <InputGroup className="mb-3">
                        <FormControl
                          placeholder="Search Employees"
                          aria-label="Employees"
                          aria-describedby="basic-addon1"
                          value={employee}
                          onChange={(e) => setemployee(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setsearchQuery(event.target.value);
                              setPage(0);
                            }
                          }}
                        />
                        <InputGroup.Prepend>
                          <Button
                            variant="default"
                            className="btn-ftc-primary-light"
                            onClick={onSearchClick}
                          >
                            Search
                          </Button>
                        </InputGroup.Prepend>
                      </InputGroup>
                    </Col>
                    {showExportButton(authUser.user.role.rolename) && (
                      <Col xl={1 / 2} className="mr-3">
                        <span className="float-left">
                          <button
                            type="button"
                            className="btn btn-ftc-primary-light-border float-right"
                            onClick={() => fetchAllPagesReimbursements()}
                          >
                            {
                              exportAPICalled ? (
                                <><Image src={Loader} height="18px" width="18px" /></>
                              ) : (<><Image
                                src={exportIcon}
                              />{' '}
                                Export</>)
                            }
                          </button>
                        </span>
                      </Col>
                    )}

                    {validatePermission(
                      authUser.user.role.permissions,
                      'reimbursement',
                      'add'
                    ) && (
                        <div className="pr-4">
                          <button
                            type="button"
                            className="btn btn-ftc-primary float-right"
                            onClick={onAddNew}
                          >
                            Add New
                          </button>
                        </div>
                      )}
                  </Row>
                  <div className="container-top-7">
                    {displaySelectedFilters()}
                    <div>
                      <Row className="pt-0">
                        <Col md="12" className="pr-0">
                          {isAPICalled ? (
                            <>
                              <DataTableComponent
                                // title="Movie List"
                                highlightOnHover
                                pointerOnHover
                                noHeader
                                columns={columns}
                                data={reimbursements}
                                pagination
                                selectableRowsComponentProps={
                                  selectableRowsComponentProps
                                }
                                paginationServer
                                paginationTotalRows={
                                  reimbursementCount.filterCount
                                }
                                handleRowClicked={handleRowClicked}
                                paginationPerPage={rowsPerPage}
                                onChangePage={(p) => {
                                  setPage(p - 1);
                                  // look for better solution later
                                  // setPage(p)
                                }}
                                onChangeRowsPerPage={(rws, pg) => {
                                  setRowsPerPage(rws);
                                  setPage(pg);
                                }}
                              />
                              <TablePagination
                                labelRowsPerPage="Rows per page:"
                                component="div"
                                count={Number.parseInt(
                                  reimbursementCount.filterCount,
                                  10
                                )}
                                page={page}
                                onChangePage={(event, newPage) => {
                                  setPage(newPage);
                                }}
                                defaultPage={0}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={(event) => {
                                  setRowsPerPage(
                                    parseInt(event.target.value, 10)
                                  );
                                  setPage(0);
                                }}
                                ActionsComponent={TablePaginationActions}
                              />
                            </>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Desktop>
            <Mobile>
              <Col>
                <div className="container pr-0">
                  <Row className="border-bottom pr-0 sticky-dashboard-header-mobile">
                    <Col xs="9" className="ml-4">
                      <h3>Reimbursement</h3>
                    </Col>
                    <Col xs="2">
                      <Image
                        className="ftc-logo-40"
                        src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                      />{' '}
                    </Col>
                  </Row>

                  <div className="container-top-20">
                    <Row>
                      {showExportButton(authUser.user.role.rolename) && (
                        <Col xl={1 / 2} className="mr-3">
                          <span className="float-left">
                            <button
                              type="button"
                              className="btn btn-ftc-primary-light-border float-right"
                              onClick={() => fetchAllPagesReimbursements()}
                            >
                              {
                                exportAPICalled ? (
                                  <><Image src={Loader} height="18px" width="18px" /></>
                                ) : (<><Image
                                  src={exportIcon}
                                />{' '}
                                  Export</>)
                              }
                            </button>
                          </span>
                        </Col>
                      )}

                      <Col xs="6" className="pr-1 pl-1">
                        <button
                          type="button"
                          className="btn btn-ftc-primary btn-block"
                          onClick={onAddNew}
                        >
                          Add New
                        </button>
                      </Col>
                    </Row>
                    <Row className="pt-0 pb-0">
                      <Col xs="12" className="pr-1 pl-1">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          value={employee}
                          // onChange={(e) => setCityName(e.target.value)}
                          onChange={(e) => setemployee(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setsearchQuery(event.target.value);
                              setPage(0);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {reimbursements.map((reimbursementDetail) => {
                        return (
                          <Col
                            md={6}
                            key={reimbursementDetail._id}
                            className="pr-1 pl-1"
                          >
                            <TableMobileView
                              reimbursement={reimbursementDetail}
                              callback={handleRowClicked}
                              handleViewClick={handleViewClick}
                              handleClickDelete={handleClickDelete}
                              isEditable
                              isDeletable={false}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    {isAPICalled && !reimbursementCount.filterCount && (
                      <div className="text-center">
                        <p>There are no records to display.</p>
                      </div>
                    )}
                    <Row>
                      <Col xs="12">
                        <TablePagination
                          className="mobile__pagination"
                          labelRowsPerPage=""
                          ActionsComponent={TablePaginationActions}
                          component="div"
                          count={Number.parseInt(
                            reimbursementCount.filterCount,
                            10
                          )}
                          page={page}
                          // onChangePage={handleChangePage}
                          onChangePage={(event, newPage) => {
                            setPage(newPage);
                          }}
                          defaultPage={0}
                          rowsPerPage={rowsPerPage}
                          onChangeRowsPerPage={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Mobile>
          </>
        )}
      </Row>
      {showConfirmAlert && (
        <ConfirmAlertModal
          show={showConfirmAlert}
          handleClose={handleCloseConfirm}
          title="Delete Reimbursement"
          description={constant.DELETE_REIMBURSEMENT_POPUP_MESSAGE}
          callback={onDeleteReimbursement}
          action="Delete it"
          closeAction="Cancel"
        />
      )}
      <RenderCustomPopup />
    </div>
  );
});

export default reimbursement;
