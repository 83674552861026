import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const crateCustomPage = (body) =>
{
    return API.post(API_ENDPOINTS.CUSTOM_PAGES.CustomPage, body);
};

const getAllCustomPages = (pagination, page, pageTitle, queryString) =>
{
    return API.get(`${API_ENDPOINTS.CUSTOM_PAGES.CustomPage}?pagination=${pagination}&page=${page}&pageTitle=${pageTitle}${queryString}`);
};

const getCustomPageById = (pageId) =>
{
    return API.get(`${API_ENDPOINTS.CUSTOM_PAGES.CustomPage}/${pageId}`);
};

const updateCustomPageById = (pageId, body) =>
{
    return API.put(`${API_ENDPOINTS.CUSTOM_PAGES.CustomPage}/${pageId}`, body);
};

const deleteCustomPageById = (pageId) =>
{
    return API.del(`${API_ENDPOINTS.CUSTOM_PAGES.CustomPage}/${pageId}`);
};

const getCustomPageByLocation = () =>
{
    return API.get(`${API_ENDPOINTS.CUSTOM_PAGES.ByLocation}`);
}

const getAllPages = () => {
    return API.get(`${API_ENDPOINTS.CUSTOM_PAGES.GetAllPages}`);
}

export
{
    crateCustomPage,
    getAllCustomPages,
    getCustomPageById,
    updateCustomPageById,
    deleteCustomPageById,
    getCustomPageByLocation,
    getAllPages
};