import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { toast } from 'react-toastify';
import { TablePagination } from '@material-ui/core';
// import { openDB } from 'idb';
import { trackPromise } from 'react-promise-tracker';

import { Button, Row, Col, Image, InputGroup, FormControl, Form } from 'react-bootstrap';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import Sidebar from '../../side-navbar/Sidebar';
import Toaster from '../../../components/Toaster';
import DataTableComponent from '../../../components/DataTableComponent';
import MobileCustomPopups from './mobile-layouts/MobileCustomPopups';
import NoCustomPageComponent from '../../../components/NoCustomPageComponent/NoCustomPageComponent';
import CustomPopupFilters from './CustomPopupFilters';
import SettingsDropdown from '../../../components/SettingsDropdown/SettingsDropdown';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import RenderCustomPopup from '../../../components/RenderCustomPopup';
import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';

import { deleteCustomPopupById, getAllCustomPopups } from '../../../services/custom_popup.service';
import { validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';
import useQuery from '../../../utils/CustomHooks/useQuery';
import { DateConvert } from '../../../utils/TimesheetManagement';

const CustomPopup = memo(() =>
{
    const { authUser } = useAuthContext();
    const URLQuery = useQuery();
    const locationHook = useLocation();

    const isIndeterminate = (indeterminate) => indeterminate;
    const selectableRowsComponentProps = { indeterminate: isIndeterminate };

    const history = useHistory();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [filters, setfilters] = useState(null);
    const [query, setquery] = useState('');

    const [isAPICalled, setAPICalled] = useState(false);

    const [customPopups, setCustomPopups] = useState([]);
    const [pageCount, setPageCount] = useState({
        totalCount: 0,
        filterCount: 0
    });
    const [popupTitle, setPopupTitle] = useState(URLQuery.get('title') ?? '');
    const [searchQuery, setsearchQuery] = useState(URLQuery.get('title') ?? '');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [selectedRow, setselectedRow] = useState();
    const [showConfirmAlert, setConfirmAlert] = useState(false);

    const handleCloseConfirm = () => setConfirmAlert(false);

    const onApplyChanges = (filtersData) =>
    {
        handleClose();
        setfilters(filtersData);
    };

    const handleClickDelete = (state) =>
    {
        setselectedRow(state.target.id);
        setConfirmAlert(true);
    };

    const handleEditClicked = (state) =>
    {
        history.push(`/settings/editCustomPopup/${state.target.id}`);
    };

    const handleViewClicked = (state) =>
    {
        history.push(`/settings/viewCustomPopup/${state.target.id}`);
    };

    const handleRowClicked = (row) =>
    {        
        history.push(`/settings/viewCustomPopup/${row._id}`);
    };

    const onAddNew = () =>
    {
        history.push('/settings/addNewCustomPopup');
    };

    const displayAccess = (roles) =>
    {
        switch (true)
        {
            case roles.length === 1:
                return roles.map((c) => c.rolename ?? 'N/A');

            case roles.length === 2:
            case roles.length === 3:
                return roles.map((c) => c.rolename ?? 'N/A').join(', ');

            case roles.length >= 4:
                return roles.slice(0, 3).map((c) => c.rolename ?? 'N/A').join(', ');
            default:
                break;
        }
        return null;
    };

    const columns = [
        {
            name: 'Pop Up Title',
            selector: 'title',
            sortable: true,
            width: "320px"
        },
        {
            name: 'Popup Location',
            selector: 'popup_location',
            sortable: true,
        },
        {
            name: 'Expires On',
            selector: (row) =>
            {
                const {expiresOn} = row;
                const additionalDate = new Date(expiresOn);
                additionalDate.setDate(additionalDate.getDate() + 1);
                return (
                    <>{expiresOn===null || expiresOn===undefined ? 'N/A' :  DateConvert(additionalDate)}</>
                )
            },
            sortable: true,
        },
        {
            name: 'Accessible by',
            selector: (row) =>
            {
                const { access } = row;
                return (
                    <>
                        {displayAccess(access)}
                        {
                            access.length > 3 && (
                                <>
                                    <span className="txt-primary"> +{access.length - 3}</span>{' '}
                                </>
                            )
                        }
                    </>
                )
            },
            sortable: true,
        },
        {
            cell: (row) =>
            {
                const { _id } = row;
                return (<label>
                    <SettingsDropdown
                        identifier={_id}
                        handleViewClick={handleViewClicked}
                        handleEditClicked={handleEditClicked}
                        handleClickDelete={handleClickDelete}
                        drop='left'
                        isEditable={!validatePermission(authUser.user.role.permissions, 'custom_popup', 'edit')}
                        isDeletable={!validatePermission(authUser.user.role.permissions, 'custom_popup', 'delete')}
                    />
                </label>);
            },
            allowOverflow: true,
            button: true,
            width: '56px', // custom width for icon button
        },
    ];

    async function fetchAllCustomPopups()
    {
        try
        {
            const response = await trackPromise(
                getAllCustomPopups(rowsPerPage, page, searchQuery, query)
            );
            setCustomPopups(response.data.data.customPopups);
            setPageCount({ totalCount: response.data.data.totalCount, filterCount: response.data.data.filterCount });
            setAPICalled(true);
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    async function onFilterChanges()
    {
        try
        {
            
        
            let queryString = '';

            if (filters?.locations && filters.locations.length)
            {
                filters.locations.forEach((location) =>
                {
                    queryString += `&locations[]=${location.value}`;
                })
            };

            if (filters?.roles && filters.roles.length)
            {
                filters.roles.forEach((role) =>
                {
                    queryString += `&roles[]=${role._id}`;
                })
            };
        
            setquery(queryString);
            
            setPage(0);
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    const onDeleteCustomPopup = async () =>
    {
        try
        {
            const response = await trackPromise(
                deleteCustomPopupById(selectedRow)
            );
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally
        {
            setConfirmAlert(false);
            fetchAllCustomPopups();
        }
    };

    useEffect(() =>
    {
        if (!searchQuery.length)
        {
            history.push({
                pathname: locationHook.pathname,
                search: '',
            });
        } else
        {
            history.push({
                pathname: locationHook.pathname,
                search: qs.stringify({ title: searchQuery }),
            });
        }
    }, [searchQuery]);

    useEffect(() =>
    {
        fetchAllCustomPopups();
    }, [rowsPerPage, page, query, searchQuery]);

    useEffect(() =>
    {
        onFilterChanges();
    }, [filters]);

    const displayRolesFilter = () =>
    {
        switch (true)
        {
            case filters.roles.length === 1:
                return filters.roles.map((c) => c.rolename ?? 'N/A');

            case filters.roles.length === 2:
            case filters.roles.length === 3:
                return filters.roles.map((c) => c.rolename ?? 'N/A').join(', ');

            case filters.roles.length >= 4:
                return filters.roles.slice(0, 3).map((c) => c.rolename ?? 'N/A').join(', ');
            default:
                break;
        }
    };

    const displayLocationsFilter = () =>
    {
        switch (true)
        {
            case filters.locations.length === 1:
                return filters.locations.map((location) =>
                {
                    return (
                        <>
                            {location.label}
                        </>
                    );
                });


            case filters.locations.length === 2:
            case filters.locations.length === 3:
                return filters.locations.map((location, index) =>
                {
                    return (
                        <>
                            {location.label}
                            {index <= filters.locations.length - 2 ? ' | ' : ''}
                        </>
                    );
                });


            case filters.locations.length >= 4:
                return filters.locations.slice(0, 3).map((location, index) =>
                {
                    return (
                        <>
                            {location.label}
                            {index <= filters.locations.length - 2 ? ' | ' : ''}
                        </>
                    );
                });

            default:
                break;
        }
    }

    const clearAllFilters = () =>
    {
        setfilters(null);
    };

    const removeFilter = (filterObj) =>
    {
        delete filters[filterObj];
        setfilters({ ...filters });
    };

    const displaySelectedFilters = () =>
    {
        return filters !== null && Object.keys(filters).length > 0 ? (
            <Row className="group_filter">
                {
                    filters?.locations && filters.locations?.length ? (
                        <span className="filter-label">
                            <span className="txt-light-secondary">Locations:</span>
                            {displayLocationsFilter()}
                            {
                                filters.locations.length > 3 && (
                                    <>
                                        <span className="txt-primary"> +{filters.locations.length - 3}</span>{' '}
                                    </>
                                )
                            }
                            <Image
                                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
                                fluid
                                onClick={() =>
                                {
                                    removeFilter('locations')
                                }}
                            />
                        </span>
                    ) : ('')
                }

                {
                    filters?.roles && filters.roles.length ? (
                        <span className="filter-label">
                            <span className="txt-light-secondary">Role:</span>
                            {displayRolesFilter()}
                            {
                                filters.roles.length > 3 && (
                                    <>
                                        <span className="txt-primary"> +{filters.roles.length - 3}</span>{' '}
                                    </>
                                )
                            }
                            <Image
                                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
                                fluid
                                onClick={() =>
                                {
                                    removeFilter('roles')
                                }}
                            />
                        </span>
                    ) : ('')
                }

                <Col>
                    <Button variant="link" onClick={clearAllFilters} className="float-right txt-primary">
                        Clear Filter
                    </Button>
                </Col>
            </Row>
        ) : ('')
    }

    return (
        <div>
            <Row className="mr-0">
                <Desktop>
                    <Col md="2">
                        <Sidebar />
                    </Col>
                </Desktop>
                <Mobile>
                    <Sidebar />
                </Mobile>
                {
                    (isAPICalled && !pageCount.totalCount)
                        ?
                        <NoCustomPageComponent
                            title='Custom Popups'
                            onAddNew={onAddNew}
                            addNewText='Start adding your first ”Custom Popup".'
                            isAddNew={validatePermission(authUser.user.role.permissions, 'custom_popup', 'add')}
                        />
                        :
                        <>

                            <Desktop>
                                <Col md="10">
                                    <div className="container">
                                        <Row className="border-bottom sticky-dashboard-header">
                                            <Col md={4} lg={5}>
                                                <h3>
                                                    <strong>Custom Pop Ups</strong>
                                                </h3>
                                            </Col>
                                            <Col >
                                                <Button
                                                    className="float-right"
                                                    variant="default"
                                                    onClick={handleShow}
                                                >
                                                    {' '}
                                                    <Image
                                                        src={filters !== null && Object.keys(filters).length > 0 ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg` : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`}
                                                        fluid
                                                    />{' '}
                                                    Filter
                                                </Button>
                                            </Col>
                                            <Col md="3" className="pl-0">
                                                <InputGroup className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    placeholder="Search Pages"
                                                    aria-label="Pages"
                                                    aria-describedby="basic-addon1"
                                                    value={popupTitle}
                                                    onChange={(e) => setPopupTitle(e.target.value)}
                                                    onKeyPress={(event) =>
                                                    {
                                                        if (event.key === 'Enter')
                                                        {
                                                            setsearchQuery(event.target.value);
                                                            setPage(0);
                                                        }
                                                    }}
                                                />
                                                </InputGroup>
                                            </Col>
                                            {
                                                validatePermission(authUser.user.role.permissions, 'custom_popup', 'add') && (
                                                    <div className="pr-3">
                                                        <button type="button" className="btn btn-ftc-primary float-right"
                                                            onClick={onAddNew}
                                                        >
                                                            Add New
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        </Row>
                                        <div className="container-top-7" />
                                        {displaySelectedFilters()}
                                        <Row>
                                            <Col md="12">
                                                {isAPICalled ? (
                                                    <>
                                                        <DataTableComponent
                                                            highlightOnHover
                                                            pointerOnHover
                                                            noHeader
                                                            columns={columns}
                                                            data={customPopups}
                                                            pagination
                                                            selectableRowsComponentProps={selectableRowsComponentProps}
                                                            paginationServer
                                                            paginationTotalRows={pageCount.filterCount}
                                                            handleRowClicked={handleRowClicked}
                                                            paginationPerPage={rowsPerPage}
                                                            onChangePage={(p) =>
                                                            {
                                                                setPage(p - 1);
                                                                // look for better solution later
                                                                // setPage(p)
                                                            }}
                                                            onChangeRowsPerPage={(rws, pg) =>
                                                            {
                                                                setRowsPerPage(rws);
                                                                setPage(pg);
                                                            }}
                                                        />
                                                        <TablePagination
                                                            labelRowsPerPage='Rows per page:'
                                                            component="div"
                                                            count={Number.parseInt(pageCount.filterCount, 10)}
                                                            page={page}
                                                            onChangePage={(event, newPage) =>
                                                            {
                                                                setPage(newPage);
                                                            }}
                                                            defaultPage={0}
                                                            rowsPerPage={rowsPerPage}
                                                            onChangeRowsPerPage={(event) =>
                                                            {
                                                                setRowsPerPage(parseInt(event.target.value, 10));
                                                                setPage(0);
                                                            }}
                                                            ActionsComponent={TablePaginationActions}
                                                        />
                                                    </>) : ('')}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Desktop>
                            <Mobile>
                                <Col>
                                    <div className="container pr-0 container-top-20">
                                        <Row className="border-bottom sticky-dashboard-header-mobile">
                                            <Col xs="9" className="ml-4">
                                                <h3>Custom Pop Ups</h3>
                                            </Col>
                                            <Col xs="2">
                                                <Image
                                                    className="ftc-logo-40"
                                                    src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                                                />{' '}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="6" className="pl-1 pr-1">
                                                <Button
                                                    variant="default"
                                                    onClick={handleShow}
                                                    className="btn-block"
                                                >
                                                    {' '}
                                                    <Image
                                                        src={filters !== null && Object.keys(filters).length > 0 ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg` : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`}                            
                                                    />{' '}
                                                    Filter
                                                </Button>
                                            </Col>
                                            {
                                                validatePermission(authUser.user.role.permissions, 'custom_popup', 'add') && (
                                                    <Col xs="6" className="pl-1 pr-1">
                                                        <button type="button" className="btn btn-ftc-primary btn-block" onClick={onAddNew}>
                                                            Add New
                                                        </button>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                        <Row className="pt-0 pb-0">
                                            <Col xs="12" className="pl-1 pr-1" >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search"
                                                    value={popupTitle}
                                                    onChange={(e) => setPopupTitle(e.target.value)}
                                                    onKeyPress={(event) =>
                                                    {
                                                        if (event.key === 'Enter')
                                                        {
                                                            setsearchQuery(event.target.value);
                                                            setPage(0);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                customPopups.map((customPopup) =>
                                                {
                                                    return (<Col md={6} className="mt-3 pl-1 pr-1">
                                                        <MobileCustomPopups
                                                            customPopup={customPopup}
                                                            callback={handleRowClicked}
                                                            handleViewClick={handleViewClicked}
                                                            handleEditClicked={handleEditClicked}
                                                            handleClickDelete={handleClickDelete}
                                                            isEditable={!validatePermission(authUser.user.role.permissions, 'custom_popup', 'edit')}
                                                            isDeletable={!validatePermission(authUser.user.role.permissions, 'custom_popup', 'delete')}
                                                        />
                                                    </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                        {
                                            (isAPICalled && !pageCount.filterCount) && (
                                                <div className="text-center">
                                                    <p>There are no records to display.</p>
                                                </div>
                                            )
                                        }
                                        <Row>
                                            <Col xs="12">
                                                <TablePagination
                                                    className="mobile__pagination"
                                                    labelRowsPerPage=''
                                                    ActionsComponent={TablePaginationActions}
                                                    component="div"
                                                    count={Number.parseInt(pageCount.filterCount, 10)}
                                                    page={page}
                                                    // onChangePage={handleChangePage}
                                                    onChangePage={(event, newPage) =>
                                                    {
                                                        setPage(newPage);
                                                    }}
                                                    defaultPage={0}
                                                    rowsPerPage={rowsPerPage}
                                                    onChangeRowsPerPage={(event) =>
                                                    {
                                                        setRowsPerPage(parseInt(event.target.value, 10));
                                                        setPage(0);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Mobile>
                        </>
                }

            </Row>

            {
                show && <CustomPopupFilters
                    show={show}
                    handleClose={handleClose}
                    allFilters={filters}
                    applyChanges={onApplyChanges}
                    clearAll={clearAllFilters}
                />
            }

            {
                showConfirmAlert && <ConfirmAlertModal
                    show={showConfirmAlert}
                    handleClose={handleCloseConfirm}
                    title='Delete Custom Popup'
                    description='Deleting this popup will erase all data inside this record.
                Are you sure you want to delete this Popup?'
                    callback={onDeleteCustomPopup}
                    action='Delete it'
                    closeAction='Cancel'
                />
            }
            <RenderCustomPopup />
        </div >
    )
});

export default CustomPopup
