import React, { useEffect, useState, memo } from 'react';
import { toast } from 'react-toastify';

import Toaster from '../Toaster';

const CheckNetworkStatus = memo(() =>
{
    
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showToast, setShowToast] = useState(false);

  const offlineToastId = 'offline';
  const onlineToastId = 'online';

  function updateStatus() {
    setIsOnline(navigator.onLine);
  }

  useEffect(() => {
    window.addEventListener('online', updateStatus);
    window.addEventListener('offline', () => {
      setShowToast(true);
      updateStatus();
    });

    return () => {
      window.removeEventListener('online', updateStatus);
      window.removeEventListener('offline', updateStatus);
    };
  }, []);

  useEffect(() => {    
    if (!isOnline && showToast && !toast.isActive(offlineToastId)) {
      if (toast.isActive(onlineToastId)) {
        toast.dismiss(onlineToastId);
      }
      toast.dark(
        <Toaster icon="warning" message='You are offline now.' />,
        {
          toastId: offlineToastId,
        }
      );
    } else if (isOnline && showToast && !toast.isActive(onlineToastId)) {
      if (toast.isActive(offlineToastId)) {
        toast.dismiss(offlineToastId);
      }
      toast.dark(<Toaster icon="notify" message={`You're now connected!`} />, {
        toastId: onlineToastId,        
      });
      // adding timeout to remove it after 2s if it's still active
      setTimeout(() => {
        if (toast.isActive(onlineToastId))
        {
          toast.dismiss(onlineToastId);
        }
      }, 2000);
    }
  }, [isOnline]);
  return null;
});

export default CheckNetworkStatus;
