import React, { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Image,
  Button,
  Card,
  Badge,
  NavDropdown,
  Alert,
  Form,
} from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

import Toaster from '../../../components/Toaster';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import Ellipses from '../../../components/Ellipses';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';
import '../css/viewUserDetails.css';
import AvatarImage from '../../../assets/images/avatar.svg';
import {
  deleteUserByEmployeeId,
  getUserByEmployeeId,
  updateUserByEmployeeId,
} from '../../../services/users-service';

import { DateConvert } from '../../../utils/TimesheetManagement';
import { useAuthContext } from '../../../contexts/user-context';
import { validatePermission } from '../../../utils/UserManagement';

const ViewUserDetails = memo(() => {
  const { authUser } = useAuthContext();

  const history = useHistory();
  const { employeeId } = useParams();

  const [isAPICalled, setAPICalled] = useState(false);
  const [user, setUser] = useState(null);

  const onHandleBack = () => {
    history.push('/settings/users');
  };

  const onEditUser = () => {
    history.push(`/settings/editUser/${employeeId}`);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isFtc = process.env.REACT_APP_PROJECT_NAME;

  async function getUserDetails() {
    try {
      const response = await trackPromise(getUserByEmployeeId(employeeId));
      setUser(response.data.data.user);
      setAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const updateUserStatus = async (status) => {
    try {
      const body = {
        status,
        deactivatedAt: new Date(),
      };
      setAPICalled(false);
      const response = await trackPromise(
        updateUserByEmployeeId(employeeId, body)
      );
      setUser(response.data.data.user);
      if (response.status === 200) {
        toast.dark(<Toaster icon="notify" message={response.data.message} />);
        history.push(
          `/settings/viewUser/${response.data.data.user.employeeId}`
        );
      }
      setAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  };

  const onDeleteUser = async () => {
    try {
      const response = await trackPromise(deleteUserByEmployeeId(employeeId));
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setShow(false);
      history.replace('/settings/users');
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [employeeId]);
  return isAPICalled ? (
    <div>
      <div>
        <Desktop>
          <div className="sticky-page-header">
            <div className="container ">
              <Row className="ml-5">
                <Col md="1">
                  <a
                    onKeyPress={() => { }}
                    role="link"
                    tabIndex="0"
                    onClick={onHandleBack}
                  >
                    <Image
                      className="imageArrow"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </a>
                </Col>
                <Col md="8">
                  <h5>{user.username}</h5>
                  <span className="txt-light-secondary">
                    Last Updated on {DateConvert(user.updatedAt)}
                  </span>
                </Col>
                <Col md="1" className="pr-0">
                  <Button
                    variant="default"
                    onClick={onEditUser}
                    disabled={
                      user.status === 'inactive' ||
                      !validatePermission(
                        authUser.user.role.permissions,
                        'user',
                        'edit'
                      )
                    }
                    className="float-right btn-ftc-border-primary ml-5"
                  >
                    Edit
                  </Button>
                </Col>
                <Col md="1">
                  <div className="ml-5">
                    <NavDropdown
                      title={<Ellipses />}
                      id="collasible-nav-dropdown"
                    >
                      {user.status === 'active' ? (
                        <NavDropdown.Item
                          disabled={
                            !validatePermission(
                              authUser.user.role.permissions,
                              'user',
                              'edit'
                            )
                          }
                          onClick={() => {
                            updateUserStatus('inactive');
                          }}
                        >
                          Deactivate
                        </NavDropdown.Item>
                      ) : (
                        ''
                      )}
                      {user.status === 'inactive' ? (
                        <NavDropdown.Item
                          disabled={
                            !validatePermission(
                              authUser.user.role.permissions,
                              'user',
                              'edit'
                            )
                          }
                          onClick={() => {
                            updateUserStatus('active');
                          }}
                        >
                          Activate
                        </NavDropdown.Item>
                      ) : (
                        ''
                      )}
                      <NavDropdown.Item
                        onClick={handleShow}
                        disabled={
                          !validatePermission(
                            authUser.user.role.permissions,
                            'user',
                            'delete'
                          )
                        }
                      >
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="sticky-page-header">
            <div className="container">
              <Row className="pb-0 ">
                <Col xs="1" className="p-0 ">
                  <a
                    onKeyPress={() => { }}
                    role="link"
                    tabIndex="0"
                    onClick={onHandleBack}
                  >
                    <Image
                      className="imageArrowMobile ml-2"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </a>
                </Col>
                <Col xs="9" className="mt-1">
                  <h6 className="m-auto">
                    <strong>{user.username}</strong>{' '}
                  </h6>
                  <p className="txt-light-secondary mx-auto">
                    Last Updated on
                    {DateConvert(user.updatedAt)}
                  </p>
                </Col>
                <Col xs="1" className="mobile__top__margin">
                  <div>
                    <NavDropdown
                      title={<Ellipses />}
                      id="collasible-nav-dropdown"
                    >
                      {user.status === 'active' ? (
                        <NavDropdown.Item
                          disabled={
                            !validatePermission(
                              authUser.user.role.permissions,
                              'user',
                              'edit'
                            )
                          }
                          onClick={() => {
                            updateUserStatus('inactive');
                          }}
                        >
                          Deactivate
                        </NavDropdown.Item>
                      ) : (
                        ''
                      )}
                      {user.status === 'inactive' ? (
                        <NavDropdown.Item
                          disabled={
                            !validatePermission(
                              authUser.user.role.permissions,
                              'user',
                              'edit'
                            )
                          }
                          onClick={() => {
                            updateUserStatus('active');
                          }}
                        >
                          Activate
                        </NavDropdown.Item>
                      ) : (
                        ''
                      )}
                      <NavDropdown.Item
                        onClick={handleShow}
                        disabled={
                          !validatePermission(
                            authUser.user.role.permissions,
                            'user',
                            'delete'
                          )
                        }
                      >
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Mobile>
      </div>
      {/* <div className="border-bottom" /> */}
      <Desktop>
        <div className="container-top-8">{ }</div>
      </Desktop>
      <Mobile>
        <div className="container-top-20">{ }</div>
      </Mobile>
      <div className="container">
        <Row>
          <Col md="8" className="mx-auto">
            <Card>
              <Card.Body>
                <Row>
                  <Col md="1" xs="1" className="p-0">
                    <h5>
                      <Badge pill variant="light">
                        1
                      </Badge>
                    </h5>
                  </Col>
                  <Col md="8" lg="9" xs="7">
                    <strong>
                      <h5 className="txt-primary">Basic Details</h5>
                    </strong>
                  </Col>
                  <Col md="1" xs="2" className="ml-4">
                    {user.profile_picture ? (
                      <Image
                        src={user.profile_picture_url}
                        alt="Profile Picture"
                        className="profile__picture"
                        onError={(e) => {
                          e.target.src = AvatarImage; // Set fallback image if src image fails to load
                        }}
                      />
                    ) : (
                      <Image src={AvatarImage} className="avatar__image" />
                    )}
                  </Col>
                </Row>
                <Row className="row_border_bottom">
                  <Col md="5" xs="6" className="p-0">
                    <Form.Label className="txt-light-secondary">
                      Employee ID
                    </Form.Label>
                  </Col>
                  <Col md="7" xs="6" className="pr-0">
                    <Form.Label className="float-right">
                      <strong>{user.employeeId}</strong>
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="row_border_bottom">
                  <Col md="5" xs="6" className="p-0">
                    <Form.Label className="txt-light-secondary">
                      Employee Name
                    </Form.Label>
                  </Col>
                  <Col md="7" xs="6" className="pr-0">
                    <Form.Label className="float-right">
                      <strong>{user.username}</strong>
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="row_border_bottom">
                  <Col md="5" xs="6" className="p-0">
                    <Form.Label className="txt-light-secondary">
                      Email
                    </Form.Label>
                  </Col>
                  <Col md="7" xs="6" className="pr-0">
                    <Form.Label className="float-right">
                      <strong>{user.email}</strong>
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="row_border_bottom">
                  <Col md="5" xs="6" className="p-0">
                    <Form.Label className="txt-light-secondary">
                      This User is Trainer.
                    </Form.Label>
                  </Col>
                  <Col md="7" xs="6" className="pr-0">
                    <Form.Label className="float-right">
                      <span>
                        <strong>{user.trainer ? 'Yes' : 'No'}</strong>
                      </span>
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="row_border_bottom">
                  <Col md="5" xs="6" className="p-0">
                    <Form.Label className="txt-light-secondary">
                      Role
                    </Form.Label>
                  </Col>
                  <Col md="7" xs="6" className="pr-0">
                    <Form.Label className="float-right">
                      <span>
                        <strong>{user.role.rolename}</strong>
                      </span>
                    </Form.Label>
                  </Col>
                </Row>
                {user.service_city.map((city, index) => {
                  return (
                    <Row
                      className={
                        index < user.service_city.length - 1
                          ? 'row_border_bottom'
                          : ''
                      }
                    >
                      <Col md="5" xs="3" className="p-0">
                        <Form.Label className="txt-light-secondary">
                          Service City {index + 1}
                        </Form.Label>
                      </Col>
                      <Col md="7" xs="9" className="pr-0">
                        <Form.Label className="float-right">
                          <strong>{city.cityname}</strong>
                        </Form.Label>
                      </Col>
                    </Row>
                  );
                })}
                {!user.service_city.length && (
                  <Row>
                    <Col md="5" xs="3" className="p-0">
                      <Form.Label className="txt-light-secondary">
                        Service City
                      </Form.Label>
                    </Col>
                    <Col md="7" xs="9" className="pr-0">
                      <Form.Label className="float-right">
                        <p className="txt-light-secondary">Not Specified</p>
                      </Form.Label>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
            <br />
            <Card>
              <Card.Body>
                <Row>
                  <Col md="1" xs="1" className="p-0">
                    <h5>
                      <Badge pill variant="light">
                        2
                      </Badge>
                    </h5>
                  </Col>
                  <Col md="11" xs="8">
                    <strong>
                      <h5 className="txt-primary float-left">Work History</h5>
                    </strong>
                  </Col>
                </Row>
                <Row className="row_border_bottom">
                  <Col md="5" xs="6" className="p-0">
                    <Form.Label className="txt-light-secondary">
                      Hire Date
                    </Form.Label>
                  </Col>
                  <Col md="7" xs="6" className="pr-0">
                    <Form.Label className="float-right">
                      <strong>
                        {moment(user.work_history.hire_date).format(
                          'MM/DD/YYYY'
                        )}
                      </strong>
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="row_border_bottom">
                  <Col md="5" xs="6" className="p-0">
                    <Form.Label className="txt-light-secondary">
                      Last Raise Date
                    </Form.Label>
                  </Col>
                  <Col md="7" xs="6" className="pr-0">
                    <Form.Label className="float-right">
                      {user.work_history?.last_raise_date ? (
                        <strong>
                          {moment(user.work_history.last_raise_date).format(
                            'MM/DD/YYYY'
                          )}
                        </strong>
                      ) : (
                        <p className="txt-light-secondary">Not Specified</p>
                      )}
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="row_border_bottom">
                  <Col md="5" xs="6" className="p-0">
                    <Form.Label className="txt-light-secondary">
                      Termination Date
                    </Form.Label>
                  </Col>
                  <Col md="7" xs="6" className="pr-0">
                    <Form.Label className="float-right">
                      {user.work_history?.termination_date ? (
                        <strong>
                          {moment(user.work_history.termination_date).format(
                            'MM/DD/YYYY'
                          )}
                        </strong>
                      ) : (
                        <p className="txt-light-secondary">Not Specified</p>
                      )}
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="row_border_bottom">
                  <Col md="5" xs="6" className="p-0">
                    <Form.Label className="txt-light-secondary">
                      Current Wage/Hour
                    </Form.Label>
                  </Col>
                  <Col md="7" xs="6" className="pr-0">
                    <Form.Label className="float-right">
                      <strong>
                        $ {user.work_history?.wage_per_hr?.toFixed(2) ?? '0.00'}
                      </strong>
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="row_border_bottom">
                  <Col md="5" xs="6" className="p-0">
                    <Form.Label className="txt-light-secondary">
                      Current Salary/Week
                    </Form.Label>
                  </Col>
                  <Col md="7" xs="6" className="pr-0">
                    <Form.Label className="float-right">
                      {user.work_history?.salary_per_wk ? (
                        <strong>
                          $ {user.work_history.salary_per_wk.toFixed(2)}
                        </strong>
                      ) : (
                        <p className="txt-light-secondary">Not Specified</p>
                      )}
                    </Form.Label>
                  </Col>
                </Row>
                {
                  isFtc && <>
                    <Row className="row_border_bottom">
                      <Col md="5" xs="6" className="p-0">
                        <Form.Label className="txt-light-secondary">
                          Equip Reimbursement Limit
                        </Form.Label>
                      </Col>
                      <Col md="7" xs="6" className="pr-0">
                        <Form.Label className="float-right">
                          {user.work_history?.equip_reimbursement_limit ? (
                            <strong>
                              $ {user.work_history.equip_reimbursement_limit.toFixed(2)}
                            </strong>
                          ) : (
                            <p className="txt-light-secondary">Not Specified</p>
                          )}
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="5" xs="6" className="p-0">
                        <Form.Label className="txt-light-secondary">
                          Flex Pay Hours Limit
                        </Form.Label>
                      </Col>
                      <Col md="7" xs="6" className="pr-0">
                        <Form.Label className="float-right">
                          {user.work_history?.flex_pay_hours_limit ? (
                            <strong>
                              {user.work_history.flex_pay_hours_limit.toFixed(2)} Hrs
                            </strong>
                          ) : (
                            <p className="txt-light-secondary">Not Specified</p>
                          )}
                        </Form.Label>
                      </Col>
                    </Row>
                  </>
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="8" xs="12" className="mx-auto">
            {user.status === 'active' && (
              <Alert variant="success">
                <Image
                  className="float-right"
                  src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`}
                  fluid
                />
                {` `}Active User
              </Alert>
            )}
            {user.status === 'inactive' && (
              <Alert variant="secondary">
                <Image
                  className="float-right"
                  src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-alert-grey.svg`}
                  fluid
                />{' '}
                <span>
                  This user has been Inactive since{' '}
                  {DateConvert(user.deactivatedAt)}
                </span>
              </Alert>
            )}
          </Col>
        </Row>
        <Mobile>
          <Row className="border-top-shadow">
            <Col xs={12} className="pr-3">
              <Button
                variant="default"
                onClick={onEditUser}
                disabled={
                  user.status === 'inactive' ||
                  !validatePermission(
                    authUser.user.role.permissions,
                    'user',
                    'edit'
                  )
                }
                className="float-right btn-ftc-border-primary col-3"
              >
                Edit
              </Button>
            </Col>
          </Row>
        </Mobile>
      </div>
      {show && (
        <ConfirmAlertModal
          show={show}
          handleClose={handleClose}
          title="Delete User"
          description="Deleting this User will erase all data inside this record.
                Are you sure you want to delete this User?"
          callback={onDeleteUser}
          action="Delete it"
          closeAction="Cancel"
        />
      )}
    </div>
  ) : (
    ''
  );
});

export default ViewUserDetails;
