import React, { memo } from 'react';
import { Row, Form, Col, Image } from 'react-bootstrap';
// import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';

import SettingsDropdown from '../../../../components/SettingsDropdown/SettingsDropdown';

import './ClientMobileTable.css'
import { getUserStatusLabel } from '../../../../utils/UserManagement';
import { useAuthContext } from '../../../../contexts/user-context';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
        height: '100%'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const ClientMobileTable = memo(({
    client,
    handleViewClick,
    handleEditClicked,
    handleClickDelete,
    callback,
    isEditable,
    isDeletable,
}) => {
    const { authUser } = useAuthContext();
    const classes = useStyles();

    const isChefUser = authUser.user.role.rolename === 'Chef';

    return (
        <div className="h-100" onClick={(event) => {
            const settingsDropDown = event.target.closest('#settings-dropdown');
            if (!settingsDropDown) {
                callback(client)
            }
        }} onKeyDown={() => { }} role="button" tabIndex={0}>
            <Card className={`${classes.root} mt-2`}>
                <CardContent>
                    <Row className="border-bottom p-0" >
                        <Col xs="5"
                        >
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <label className="mb-0 txt-light-secondary" >
                                    Client Name
                                </label>
                                <p className="mb-1">{client.username}</p>
                            </Form.Group>

                        </Col>
                        {
                            !isChefUser && (
                                <Col xs="6"
                                >
                                    <div className="float-right">
                                        <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                            <Form.Label className={client.status === 'active' ? 'label-active' : 'label-inactive'}>
                                                <div className={client.status === 'active' ? 'active-ellipse' : 'inactive-ellipse'} />
                                                {` `}{getUserStatusLabel(client.status)}
                                            </Form.Label>
                                        </Form.Group>
                                    </div>
                                </Col>
                            )
                        }

                        <Col xs="12" className="ml-2">
                            <span className="ellipse__client__mobile float-right">
                                <SettingsDropdown
                                    identifier={client.clientId}
                                    handleViewClick={handleViewClick}
                                    handleEditClicked={handleEditClicked}
                                    handleClickDelete={handleClickDelete}
                                    isEditable={isEditable}
                                    isDeletable={isDeletable}
                                />
                            </span>
                        </Col>
                    </Row>
                    <Row className="border-bottom pl-0 pb-0" >
                        <Col xs="12"
                        // onClick={() => {
                        //     callback(role);
                        // }}
                        >
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <Form.Label className="mb-0 txt-light-secondary" >
                                    Assigned Employee
                                </Form.Label>
                                <p className="mb-1">{client?.chef[0]?.username}
                                    {
                                        client?.chef?.length > 1 && (
                                            <>
                                                <span className="txt-primary"> +{client?.chef?.length - 1}</span>{' '}
                                            </>
                                        )
                                    }</p>
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row className="pl-0 pb-2">
                        <Col xs="12">
                            <Form.Group className="mb-0" controlId="formBasicServiceCity">
                                <Image
                                    className="mr-2 align-top ftc-logo-24"
                                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-location.svg`}
                                />
                                <Form.Label> {client?.service_city?.cityname}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </CardContent>
            </Card >
        </div>
    )
});

export default ClientMobileTable

ClientMobileTable.propTypes = {
    client: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    handleViewClick: PropTypes.func.isRequired,
    handleEditClicked: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isDeletable: PropTypes.bool.isRequired,
}