import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { toast } from 'react-toastify';
import { TablePagination } from '@material-ui/core';
import {
  Button,
  Row,
  Col,
  Image,
  InputGroup,
  FormControl,
  Form,
} from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import Sidebar from '../../side-navbar/Sidebar';
import Toaster from '../../../components/Toaster';
import DataTableComponent from '../../../components/DataTableComponent';
import RenderCustomPopup from '../../../components/RenderCustomPopup';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import SettingsDropdown from '../../../components/SettingsDropdown/SettingsDropdown';
import CityFilters from './CityFilters';
import TableMobileView from './mobile-layouts/CitiesMobileTable';
import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';
import { deleteCityById, getAllCities } from '../../../services/city-service';
import {
  getUserStatusLabel,
  validatePermission,
} from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';
import useQuery from '../../../utils/CustomHooks/useQuery';
import NoCustomPageComponent from '../../../components/NoCustomPageComponent/NoCustomPageComponent';
import { showExportButton,DateConvert } from '../../../utils/TimesheetManagement';
import exportIcon from '../../../assets/images/exportIcon.svg';
import { exportToCSV } from '../../../utils/ExportDataToCSV';
import Loader from '../../../assets/images/loader.svg';


const Cities = memo(() => {
  const { authUser } = useAuthContext();
  const URLQuery = useQuery();
  const location = useLocation();

  const isIndeterminate = (indeterminate) => indeterminate;
  const selectableRowsComponentProps = { indeterminate: isIndeterminate };

  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isAPICalled, setAPICalled] = useState(false);

  const [cities, setCities] = useState([]);
  // const [allPagesCities,setAllPagesCities] = useState([]);
  const [cityCount, setCityCount] = useState({
    totalCount: 0,
    filterCount: 0,
  });
  const [cityName, setCityName] = useState(URLQuery.get('city') ?? '');
  const [searchQuery, setsearchQuery] = useState(URLQuery.get('city') ?? '');

  const [selectedRow, setselectedRow] = useState();
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const [exportAPICalled,setExportAPICalled] = useState(false);

  const handleCloseConfirm = () => setConfirmAlert(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [query, setquery] = useState('');

  const [filters, setfilters] = useState(null);

  const onAddNew = () => {
    history.push('/settings/addNewCity');
  };

  const handleEditClicked = (state) => {
    history.push(`/settings/editCity/${state.target.id}`);
  };

  const handleViewClick = (state) => {
    history.push(`/settings/viewCity/${state.target.id}`);
  };

  const handleClickDelete = (state) => {
    setselectedRow(state.target.id);
    setConfirmAlert(true);
  };

  const handleRowClicked = (row) => {
    history.push(`/settings/viewCity/${row._id}`);
  };

  const columns = [
    {
      name: 'City',
      selector: 'cityname',
      sortable: true,
    },
    {
      name: 'Basic Rate',
      selector: (row) => `$ ${row.basic_rate.toFixed(2)}` ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Prime Rate',
      selector: (row) => `$ ${row.prime_rate.toFixed(2)}` ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Special Rate',
      selector: (row) => `$ ${row.special_rate.toFixed(2)}` ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Holding Fee',
      selector: (row) => `$ ${row.holding_fee.toFixed(2)}` ?? 'N/A',
      sortable: true,
    },
    {
      name: 'EMPL Share',
      selector: (row) => `$ ${row?.empl_share?.toFixed(2) ?? '0.00'}` ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => {
        const { status } = row;
        return (
          <div>
            <label
              className={
                status === 'active' ? 'label-active' : 'label-inactive'
              }
            >
              <div
                className={
                  status === 'active' ? 'active-ellipse' : 'inactive-ellipse'
                }
              />
              {` `}
              {getUserStatusLabel(status)}
            </label>
          </div>
        );
      },
    },
    {
      cell: (row) => {
        const { _id, status } = row;
        return (
          <label>
            <SettingsDropdown
              identifier={_id}
              handleViewClick={handleViewClick}
              handleEditClicked={handleEditClicked}
              handleClickDelete={handleClickDelete}
              drop="left"
              isEditable={
                status === 'inactive' ||
                !validatePermission(
                  authUser.user.role.permissions,
                  'city',
                  'edit'
                )
              }
              isDeletable={
                !validatePermission(
                  authUser.user.role.permissions,
                  'city',
                  'delete'
                )
              }
            />
          </label>
        );
      },
      allowOverflow: true,
      button: true,
      width: '56px', // custom width for icon button
    },
  ];

  async function onFilterChanges() {
    let queryString = '';

    // basicRate
    if (filters?.basicRate) {
      if (filters.basicRate.minimumBasicRate.length) {
        queryString += `&minimumBasicRate=${filters.basicRate.minimumBasicRate}`;
      }
      if (filters.basicRate.maximumBasicRate.length) {
        queryString += `&maximumBasicRate=${filters.basicRate.maximumBasicRate}`;
      }
    }

    // primeRate
    if (filters?.primeRate) {
      if (filters.primeRate.minimumPrimeRate.length) {
        queryString += `&minimumPrimeRate=${filters.primeRate.minimumPrimeRate}`;
      }
      if (filters.primeRate.maximumPrimeRate.length) {
        queryString += `&maximumPrimeRate=${filters.primeRate.maximumPrimeRate}`;
      }
    }

    // specialRate
    if (filters?.specialRate) {
      if (filters.specialRate.minimumSpecialRate.length) {
        queryString += `&minimumSpecialRate=${filters.specialRate.minimumSpecialRate}`;
      }
      if (filters.specialRate.maximumSpecialRate.length) {
        queryString += `&maximumSpecialRate=${filters.specialRate.maximumSpecialRate}`;
      }
    }

    // holdingFee
    if (filters?.holdingFee) {
      if (filters.holdingFee.minimumHoldingFee.length) {
        queryString += `&minimumHoldingFee=${filters.holdingFee.minimumHoldingFee}`;
      }
      if (filters.holdingFee.maximumHoldingFee.length) {
        queryString += `&maximumHoldingFee=${filters.holdingFee.maximumHoldingFee}`;
      }
    }

    if (filters?.status && filters.status) {
      queryString += `&status[]=${filters.status.value}`;
    }

    setquery(queryString);
    setPage(0);
  }

  const onApplyChanges = (filtersData) => {
    handleClose();
    setfilters(filtersData);
  };

  const clearAllFilters = () => {
    setfilters(null);
  };

  const removeFilter = (filterObj) => {
    delete filters[filterObj];
    setfilters({ ...filters });
  };

  async function fetchAllPagesCities(){
    try {
        setExportAPICalled(true);
        const response = await trackPromise(
          getAllCities(cityCount.totalCount, 0, searchQuery, query)
        );
        const transformedData = response.data.data.cities.map(item => ({
            cityname: item.cityname,
            nonBillableRate: item.non_billable_rate.toFixed(2),
            basicRate: item.basic_rate.toFixed(2),
            primeRate: item.prime_rate.toFixed(2),
            specialRate: item.special_rate.toFixed(2),
            holdingFee: item.holding_fee.toFixed(2),
            emplShare: item.empl_share?.toFixed(2),
            status: item.status,
      }));
      exportToCSV(transformedData,`cities_data__${DateConvert(new Date())}`);
      setExportAPICalled(false);
      } catch (error) {
        toast.dark(
          <Toaster
            icon="error"
            message={error?.response?.data?.message ?? 'Something went wrong!!!'}
          />
        );
        setExportAPICalled(false);
      }
    // console.log(data);
  }

  async function fetchAllCities() {
    try {
      const response = await trackPromise(
        getAllCities(rowsPerPage, page, searchQuery, query)
      );
      setCities(response.data.data.cities);
      setCityCount({
        totalCount: response.data.data.totalCount,
        filterCount: response.data.data.filterCount,
      });
      setAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const onDeleteCity = async () => {
    try {
      const response = await trackPromise(deleteCityById(selectedRow));
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setConfirmAlert(false);
      fetchAllCities();
    }
  };

  useEffect(() => {
    if (!searchQuery.length) {
      history.push({
        pathname: location.pathname,
        search: '',
      });
    } else {
      history.push({
        pathname: location.pathname,
        search: qs.stringify({ city: searchQuery }),
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    fetchAllCities();
  }, [rowsPerPage, page, query, searchQuery]);

  useEffect(() => {
    onFilterChanges();
  }, [filters]);

  const displayBasicRate = () => {
    let result;
    if (
      filters?.basicRate?.minimumBasicRate?.length &&
      filters?.basicRate?.maximumBasicRate?.length
    ) {
      result = `$ ${filters.basicRate.minimumBasicRate} - $ ${filters.basicRate?.maximumBasicRate}`;
    } else if (
      filters.basicRate.minimumBasicRate?.length &&
      !filters.basicRate.maximumBasicRate?.length
    ) {
      result = `Min $ ${filters.basicRate.minimumBasicRate}`;
    } else if (
      filters.basicRate.maximumBasicRate?.length &&
      !filters.basicRate.minimumTipAmount?.length
    ) {
      result = `Max $ ${filters.basicRate.maximumBasicRate}`;
    }

    return result;
  };

  const displayPrimeRate = () => {
    let result;
    if (
      filters.primeRate.minimumPrimeRate?.length &&
      filters.primeRate.maximumPrimeRate?.length
    ) {
      result = `$ ${filters.primeRate.minimumPrimeRate} - $ ${filters.primeRate.maximumPrimeRate}`;
    } else if (
      filters.primeRate.minimumPrimeRate?.length &&
      !filters.primeRate.maximumPrimeRate?.length
    ) {
      result = `Min $ ${filters.primeRate.minimumPrimeRate}`;
    } else if (
      filters.primeRate.maximumPrimeRate?.length &&
      !filters.primeRate.minimumTipAmount?.length
    ) {
      result = `Max $ ${filters.primeRate.maximumPrimeRate}`;
    }

    return result;
  };

  const displaySpecialRate = () => {
    let result;
    if (
      filters.specialRate.minimumSpecialRate.length &&
      filters.specialRate.maximumSpecialRate.length
    ) {
      result = `$ ${filters.specialRate.minimumSpecialRate} - $ ${filters.specialRate.maximumSpecialRate}`;
    } else if (
      filters.specialRate.minimumSpecialRate?.length &&
      !filters.specialRate.maximumSpecialRate?.length
    ) {
      result = `Min $ ${filters.specialRate.minimumSpecialRate}`;
    } else if (
      filters.specialRate.maximumSpecialRate?.length &&
      !filters.specialRate.minimumTipAmount?.length
    ) {
      result = `Max $ ${filters.specialRate.maximumSpecialRate}`;
    }

    return result;
  };

  const displayHoldingFee = () => {
    let result;
    if (
      filters.holdingFee.minimumHoldingFee?.length &&
      filters.holdingFee.maximumHoldingFee?.length
    ) {
      result = `$ ${filters.holdingFee.minimumHoldingFee} - $ ${filters.holdingFee.maximumHoldingFee}`;
    } else if (
      filters.holdingFee.minimumHoldingFee?.length &&
      !filters.holdingFee.maximumHoldingFee?.length
    ) {
      result = `Min $ ${filters.holdingFee.minimumHoldingFee}`;
    } else if (
      filters.holdingFee.maximumHoldingFee?.length &&
      !filters.holdingFee.minimumTipAmount?.length
    ) {
      result = `Max $ ${filters.holdingFee.maximumHoldingFee}`;
    }

    return result;
  };

  const displaySelectedFilters = () => {
    return filters !== null && Object.keys(filters).length > 0 ? (
      <Row className="group_filter">
        {filters?.status ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Status:</span>{' '}
            {filters.status.label}{' '}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('status');
              }}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.basicRate?.minimumBasicRate?.length ||
        filters?.basicRate?.maximumBasicRate?.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Basic Rate:</span>{' '}
            {displayBasicRate()}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('basicRate');
              }}
            />
          </span>
        ) : (
          ''
        )}

        {/* Prime rate */}
        {filters?.primeRate?.minimumPrimeRate?.length ||
        filters?.primeRate?.maximumPrimeRate?.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Prime Rate:</span>{' '}
            {displayPrimeRate()}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('primeRate');
              }}
            />
          </span>
        ) : (
          ''
        )}

        {/* Special rate */}
        {filters?.specialRate?.minimumSpecialRate?.length ||
        filters?.specialRate?.maximumSpecialRate?.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Special Rate:</span>{' '}
            {displaySpecialRate()}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('specialRate');
              }}
            />
          </span>
        ) : (
          ''
        )}

        {/* Holding Fees */}
        {filters?.holdingFee?.minimumHoldingFee?.length ||
        filters?.holdingFee?.maximumHoldingFee?.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Holding Fee:</span>{' '}
            {displayHoldingFee()}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('holdingFee');
              }}
            />
          </span>
        ) : (
          ''
        )}

        <Col>
          <Button
            variant="link"
            onClick={clearAllFilters}
            className="float-right txt-primary"
          >
            Clear Filter
          </Button>
        </Col>
      </Row>
    ) : (
      ''
    );
  };

  return (
    <div>
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar />
        </Mobile>
        {isAPICalled && !cityCount.totalCount ? (
          <NoCustomPageComponent
            title="Cities"
            onAddNew={onAddNew}
            addNewText='Start adding your first ”City"'
            isAddNew={validatePermission(
              authUser.user.role.permissions,
              'city',
              'add'
            )}
          />
        ) : (
          <>
            <Desktop>
              <Col md="10">
                <div className="container">
                  <Row className="border-bottom sticky-dashboard-header">
                    <Col md={4} lg={5}>
                      <h3>
                        <strong>Cities</strong>
                      </h3>
                    </Col>
                    <Col>
                      <Button
                        className="float-right"
                        variant="default"
                        onClick={handleShow}
                      >
                        {' '}
                        <Image
                          src={
                            filters !== null && Object.keys(filters).length > 0
                              ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                              : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                          }
                          fluid
                        />{' '}
                        Filter
                      </Button>
                    </Col>
                    <Col md="3" className="pl-0">
                      <InputGroup className="mb-3">
                        <FormControl
                          type="text"
                          placeholder="Search Cities"
                          aria-label="Cities"
                          aria-describedby="basic-addon1"
                          value={cityName}
                          onChange={(e) => setCityName(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setsearchQuery(event.target.value);
                              setPage(0);
                            }
                          }}
                        />
                      </InputGroup>
                    </Col>
                    {showExportButton(authUser.user.role.rolename) && (
                      <Col xl={1 / 2} className="mr-3">
                        <span className="float-left">
                          <button
                            type="button"
                            className="btn btn-ftc-primary-light-border float-right"
                            onClick={() => fetchAllPagesCities()}
                          >
                            {
                              exportAPICalled ? (
                                <><Image src={Loader} height="18px" width="18px" /></>
                              ) : (<><Image
                                src={exportIcon}
                              />{' '}
                                  Export</>)
                            }
                          </button>
                        </span>
                      </Col>
                    )}
                    {validatePermission(
                      authUser.user.role.permissions,
                      'city',
                      'add'
                    ) && (
                      <div className="pr-4">
                        <button
                          type="button"
                          className="btn btn-ftc-primary float-right"
                          onClick={onAddNew}
                        >
                          Add New
                        </button>
                      </div>
                    )}
                  </Row>
                  <div className="container-top-7" />
                  {displaySelectedFilters()}
                  <Row>
                    <Col md="12" className="pr-0">
                      {isAPICalled ? (
                        <>
                          <DataTableComponent
                            // title="Movie List"
                            highlightOnHover
                            pointerOnHover
                            noHeader
                            columns={columns}
                            data={cities}
                            pagination
                            selectableRowsComponentProps={
                              selectableRowsComponentProps
                            }
                            paginationServer
                            paginationTotalRows={cityCount.filterCount}
                            handleRowClicked={handleRowClicked}
                            paginationPerPage={rowsPerPage}
                            onChangePage={(p) => {
                              setPage(p - 1);
                              // look for better solution later
                              // setPage(p)
                            }}
                            onChangeRowsPerPage={(rws, pg) => {
                              setRowsPerPage(rws);
                              setPage(pg);
                            }}
                          />
                          <TablePagination
                            labelRowsPerPage="Rows per page:"
                            component="div"
                            count={Number.parseInt(cityCount.filterCount, 10)}
                            page={page}
                            onChangePage={(event, newPage) => {
                              setPage(newPage);
                            }}
                            defaultPage={0}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={(event) => {
                              setRowsPerPage(parseInt(event.target.value, 10));
                              setPage(0);
                            }}
                            ActionsComponent={TablePaginationActions}
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Desktop>
            <Mobile>
              <Col>
                <div className="container pr-0">
                  <Row className="border-bottom pr-0 sticky-dashboard-header-mobile">
                    <Col xs="9" className="ml-4">
                      <h3>Cities</h3>
                    </Col>
                    <Col xs="2">
                      <Image
                        className="ftc-logo-40"
                        src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                      />{' '}
                    </Col>
                  </Row>
                  <div className="container-top-20">
                    <Row>
                      <Col xs="6" className="pl-1 pr-1">
                        <Button
                          variant="default"
                          onClick={handleShow}
                          className="btn-block"
                        >
                          {' '}
                          <Image
                            src={
                              filters !== null &&
                              Object.keys(filters).length > 0
                                ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                                : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                            }
                          />{' '}
                          Filter
                        </Button>
                      </Col>
                      {validatePermission(
                        authUser.user.role.permissions,
                        'city',
                        'add'
                      ) && (
                        <Col xs="6" className="pr-1 pl-1">
                          <button
                            type="button"
                            className="btn btn-ftc-primary btn-block"
                            onClick={onAddNew}
                          >
                            Add New
                          </button>
                        </Col>
                      )}
                    </Row>
                    <Row className="pt-0 pb-0">
                      <Col xs="12" className="pr-1 pl-1">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          value={cityName}
                          onChange={(e) => setCityName(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setsearchQuery(event.target.value);
                              setPage(0);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="pr-0 pb-0 pr-2">
                      {showExportButton(authUser.user.role.rolename) && (
                        <>
                          <Col xs="6" className="pr-1 pl-1">
                            <></>
                          </Col>
                          <Col xs="6" className="pr-1 pl-1">
                            <span>
                              <button
                                type="button"
                                className="btn btn-ftc-primary-light-border btn-block float-right"
                                onClick={() => fetchAllPagesCities()}
                              >
                                {
                              exportAPICalled ? (
                                <><Image src={Loader} height="18px" width="18px" /></>
                              ) : (<><Image
                                src={exportIcon}
                              />{' '}
                                  Export</>)
                            }
                              </button>
                            </span>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      {cities.map((city) => {
                        return (
                          <Col md={6} key={city._id} className="pr-1 pl-1">
                            <TableMobileView
                              city={city}
                              callback={handleRowClicked}
                              handleViewClick={handleViewClick}
                              handleEditClicked={handleEditClicked}
                              handleClickDelete={handleClickDelete}
                              isEditable={
                                city.status === 'inactive' ||
                                !validatePermission(
                                  authUser.user.role.permissions,
                                  'city',
                                  'edit'
                                )
                              }
                              isDeletable={
                                !validatePermission(
                                  authUser.user.role.permissions,
                                  'city',
                                  'delete'
                                )
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    {isAPICalled && !cityCount.filterCount && (
                      <div className="text-center">
                        <p>There are no records to display.</p>
                      </div>
                    )}
                    <Row>
                      <Col xs="12">
                        <TablePagination
                          className="mobile__pagination"
                          labelRowsPerPage=""
                          ActionsComponent={TablePaginationActions}
                          component="div"
                          count={Number.parseInt(cityCount.filterCount, 10)}
                          page={page}
                          // onChangePage={handleChangePage}
                          onChangePage={(event, newPage) => {
                            setPage(newPage);
                          }}
                          defaultPage={0}
                          rowsPerPage={rowsPerPage}
                          onChangeRowsPerPage={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Mobile>
          </>
        )}
      </Row>
      {show && (
        <CityFilters
          show={show}
          handleClose={handleClose}
          allFilters={filters}
          applyChanges={onApplyChanges}
          clearAll={clearAllFilters}
        />
      )}

      {showConfirmAlert && (
        <ConfirmAlertModal
          show={showConfirmAlert}
          handleClose={handleCloseConfirm}
          title="Delete City"
          description="Deleting this City will erase all data inside this record.
                Are you sure you want to delete this City?"
          callback={onDeleteCity}
          action="Delete it"
          closeAction="Cancel"
        />
      )}
      <RenderCustomPopup />
    </div>
  );
});

export default Cities;
