import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { TablePagination } from '@material-ui/core';
import { openDB } from 'idb';
import { toast } from 'react-toastify';
import {
  Button,
  Row,
  Col,
  Image,
  InputGroup,
  FormControl,
  Form,
} from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import Sidebar from '../../side-navbar/Sidebar';
import Toaster from '../../../components/Toaster';
import DataTableComponent from '../../../components/DataTableComponent';
import SettingsDropdown from '../../../components/SettingsDropdown/SettingsDropdown';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import ClientFilters from './ClientFilters';
import TableMobileView from './mobile-layouts/ClientMobileTable';
import RenderCustomPopup from '../../../components/RenderCustomPopup';
import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';

import {
  deleteClientByClientId,
  getAllClients,
} from '../../../services/client-service';
import {
  getUserStatusLabel,
  validatePermission,
} from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';
import useQuery from '../../../utils/CustomHooks/useQuery';
import NoCustomPageComponent from '../../../components/NoCustomPageComponent/NoCustomPageComponent';
import {
  showExportButton,
  DateConvert
} from '../../../utils/TimesheetManagement';
import exportIcon from '../../../assets/images/exportIcon.svg';
import { exportToCSV } from '../../../utils/ExportDataToCSV';
import Loader from '../../../assets/images/loader.svg';

const Clients = memo(() => {
  const { authUser } = useAuthContext();
  const URLQuery = useQuery();
  const location = useLocation();
  const isChefUser = authUser.user.role.rolename === 'Chef';

  const isIndeterminate = (indeterminate) => indeterminate;
  const selectableRowsComponentProps = { indeterminate: isIndeterminate };

  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const [selectedRow, setselectedRow] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseConfirm = () => setConfirmAlert(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isAPICalled, setAPICalled] = useState(false);

  const [clients, setClients] = useState([]);
  const [clientCount, setClientCount] = useState({
    totalCount: 0,
    filterCount: 0,
  });
  const [clientName, setClientName] = useState(URLQuery.get('client') ?? '');
  const [searchQuery, setsearchQuery] = useState(URLQuery.get('client') ?? '');

  const [filters, setfilters] = useState(null);
  const [query, setquery] = useState('');
  const [exportAPICalled,setExportAPICalled] = useState(false);

  const handleClickDelete = (state) => {
    setselectedRow(state.target.id);
    setConfirmAlert(true);
  };

  const handleEditClicked = (state) => {
    history.push(`/settings/editClient/${state.target.id}`);
  };

  const handleViewClick = (state) => {
    history.push(`/settings/viewClient/${state.target.id}`);
  };

  const onAddNew = () => {
    history.push('/settings/addNewClient');
  };

  const handleRowClicked = (row) => {
    history.push(`/settings/viewClient/${row.clientId}`);
  };

  const displayChefs = (chefs) => {
    switch (true) {
      case chefs.length === 1:
        return chefs.map((c) => c.username ?? 'N/A');

      case chefs.length === 2:
        return chefs.map((c) => c.username ?? 'N/A').join(', ');

      case chefs.length >= 3:
        return chefs
          .slice(0, 2)
          .map((c) => c.username ?? 'N/A')
          .join(', ');
      default:
        break;
    }
    return null;
  };

  const columns = [
    {
      name: 'Client Name',
      selector: 'username',
      sortable: true,
    },
    {
      name: 'City',
      selector: (row) => row?.service_city?.cityname ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Assigned Employees',
      selector: (row) => {
        const { chef } = row;
        return (
          <>
            {displayChefs(chef)}
            {chef.length > 2 && (
              <>
                <span className="txt-primary"> +{chef.length - 2}</span>{' '}
              </>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => {
        const { status } = row;
        return (
          <div>
            <label
              className={
                status === 'active' ? 'label-active' : 'label-inactive'
              }
            >
              <div
                className={
                  status === 'active' ? 'active-ellipse' : 'inactive-ellipse'
                }
              />
              {` `}
              {getUserStatusLabel(status)}
            </label>
          </div>
        );
      },
      omit: isChefUser,
    },
    {
      cell: (row) => {
        const { clientId, status } = row;
        return (
          <label>
            <SettingsDropdown
              identifier={clientId}
              handleViewClick={handleViewClick}
              handleEditClicked={handleEditClicked}
              handleClickDelete={handleClickDelete}
              drop="left"
              isEditable={
                status === 'inactive' ||
                !validatePermission(
                  authUser.user.role.permissions,
                  'client',
                  'edit'
                )
              }
              isDeletable={
                !validatePermission(
                  authUser.user.role.permissions,
                  'client',
                  'delete'
                )
              }
            />
          </label>
        );
      },
      allowOverflow: true,
      button: true,
      width: '56px', // custom width for icon button
    },
  ];

  async function fetchAllPagesClients() {
    try {
        setExportAPICalled(true);
        const response = await trackPromise(
          getAllClients(clientCount.totalCount, 0, searchQuery, query)
        );
        const transformedData = response.data.data.clients.map(item => ({
            clientId: item.clientId,
            clientName: item.username,
            clientEmail: item.email,
            city: item?.service_city?.cityname,
            assignedEmployees: displayChefs(item.chef),
            status: item.status,
      }));
      exportToCSV(transformedData,`client_data_${DateConvert(new Date())}`);
      setExportAPICalled(false);
      } catch (error) {
        toast.dark(
          <Toaster
            icon="error"
            message={error?.response?.data?.message ?? 'Something went wrong!!!'}
          />
        );
        setExportAPICalled(false);
      }
  }

  async function fetchAllClients() {
    try {
      const response = await trackPromise(
        getAllClients(rowsPerPage, page, searchQuery, query)
      );
      setClients(response.data.data.clients);
      setClientCount({
        totalCount: response.data.data.totalCount,
        filterCount: response.data.data.filterCount,
      });
      setAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function onFilterChanges() {
    let queryString = '';

    if (filters?.cities && filters.cities.length) {
      filters.cities.forEach((city) => {
        queryString += `&cities[]=${city._id}`;
      });
    }

    if (filters?.chefs && filters.chefs.length) {
      filters.chefs.forEach((chef) => {
        queryString += `&chefs[]=${chef._id}`;
      });
    }

    if (filters?.status && filters.status) {
      queryString += `&status[]=${filters.status.value}`;
    }

    setquery(queryString);
    const dbs = await openDB('filters', 1, {
      upgrade(db) {
        // Create a store of objects
        const store = db.createObjectStore('filters_data', {
          // The 'moduleName' property of the object will be the key.
          keyPath: 'moduleName',
          // If it isn't explicitly set, create a value by auto incrementing.
          // autoIncrement: true,
        });
        // Create an index on the 'date' property of the objects.
        store.createIndex('moduleName', 'moduleName');
      },
    });

    const checkKeyExists = await dbs.getKey(
      'filters_data',
      IDBKeyRange.only('client')
    );

    // will update existing record or else create a new
    if (checkKeyExists !== undefined) {
      if (filters !== null && Object.keys(filters).length) {
        await dbs.put('filters_data', {
          moduleName: 'client',
          filterObj: filters,
        });
      } else {
        // if all filters are empty then also remove its row from idb
        await dbs.delete('filters_data', IDBKeyRange.only('client'));
      }
    } else if (filters !== null && Object.keys(filters).length) {
      await dbs.add('filters_data', {
        moduleName: 'client',
        filterObj: filters,
      });
    }
    setPage(0);
  }

  const onApplyChanges = (filtersData) => {
    handleClose();
    setfilters(filtersData);
  };

  const clearAllFilters = () => {
    setfilters(null);
  };

  const removeFilter = (filterObj) => {
    delete filters[filterObj];
    setfilters({ ...filters });
  };

  const displayCitiesFilter = () => {
    switch (true) {
      case filters.cities.length === 1:
        return filters.cities.map((c) => c.cityname ?? 'N/A');

      // case filters.cities.length === 2:
      //   return filters.cities.map((c) => c.cityname ?? 'N/A').join(', ');

      case filters.cities.length >= 2:
        return filters.cities
          .slice(0, 1)
          .map((c) => c.cityname ?? 'N/A')
          .join(', ');
      default:
        break;
    }
  };

  const displayChefsFilter = () => {
    switch (true) {
      case filters.chefs.length === 1:
        return filters.chefs.map((c) => c.username ?? 'N/A');

      case filters.chefs.length >= 2:
        return filters.chefs
          .slice(0, 1)
          .map((c) => c.username ?? 'N/A')
          .join(', ');
      default:
        break;
    }
  };

  const onDeleteClient = async () => {
    try {
      const response = await trackPromise(deleteClientByClientId(selectedRow));
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setConfirmAlert(false);
      fetchAllClients();
    }
  };

  async function getFilters() {
    try {
      const dbs = await openDB('filters', 1, {
        upgrade(db) {
          // Create a store of objects
          const store = db.createObjectStore('filters_data', {
            // The 'moduleName' property of the object will be the key.
            keyPath: 'moduleName',
            // If it isn't explicitly set, create a value by auto incrementing.
            // autoIncrement: true,
          });
          // Create an index on the 'date' property of the objects.
          store.createIndex('moduleName', 'moduleName');
        },
      });
      const filtersData = await dbs.get(
        'filters_data',
        IDBKeyRange.only('client')
      );
      if (filtersData?.filterObj) {
        setfilters(filtersData.filterObj);
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    if (!searchQuery.length) {
      history.push({
        pathname: location.pathname,
        search: '',
      });
    } else {
      history.push({
        pathname: location.pathname,
        search: qs.stringify({ client: searchQuery }),
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    fetchAllClients();
  }, [rowsPerPage, page, query, searchQuery]);

  useEffect(() => {
    onFilterChanges();
  }, [filters]);

  const displaySelectedFilters = () => {
    return filters !== null && Object.keys(filters).length > 0 ? (
      <Row className="group_filter">
        {filters?.cities && filters.cities.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">City:</span>{' '}
            {displayCitiesFilter()}
            {filters.cities.length > 1 && (
              <>
                <span className="txt-primary">
                  {' '}
                  +{filters.cities.length - 1}
                </span>{' '}
              </>
            )}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('cities');
              }}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.chefs && filters.chefs.length ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Chef:</span>{' '}
            {displayChefsFilter()}
            {filters.chefs.length > 1 && (
              <>
                <span className="txt-primary">
                  {' '}
                  +{filters.chefs.length - 1}
                </span>{' '}
              </>
            )}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('chefs');
              }}
            />
          </span>
        ) : (
          ''
        )}

        {filters?.status ? (
          <span className="filter-label">
            <span className="txt-light-secondary">Status:</span>{' '}
            {filters.status.label}{' '}
            <Image
              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
              fluid
              onClick={() => {
                removeFilter('status');
              }}
            />
          </span>
        ) : (
          ''
        )}

        <Col>
          <Button
            variant="link"
            onClick={clearAllFilters}
            className="float-right txt-primary"
          >
            Clear Filter
          </Button>
        </Col>
      </Row>
    ) : (
      ''
    );
  };

  return (
    <div>
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar />
        </Mobile>
        {isAPICalled && !clientCount.totalCount ? (
          <NoCustomPageComponent
            title="Clients"
            onAddNew={onAddNew}
            addNewText='Start adding your first ”Client"'
            isAddNew={validatePermission(
              authUser.user.role.permissions,
              'client',
              'add'
            )}
          />
        ) : (
          <>
            <Desktop>
              <Col md="10">
                <div className="container">
                  <Row className="border-bottom sticky-dashboard-header">
                    <Col md={4} lg={5}>
                      <h3>
                        <strong>Clients</strong>
                      </h3>
                    </Col>
                    <Col>
                      <Button
                        className="float-right"
                        variant="default"
                        onClick={handleShow}
                      >
                        {' '}
                        <Image
                          src={
                            filters !== null && Object.keys(filters).length > 0
                              ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                              : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                          }
                          fluid
                        />{' '}
                        Filter
                      </Button>
                    </Col>
                    <Col md={3} className="pl-0">
                      <InputGroup className="mb-3">
                        <FormControl
                          type="text"
                          placeholder="Search Clients"
                          aria-label="Clients"
                          aria-describedby="basic-addon1"
                          value={clientName}
                          onChange={(e) => setClientName(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setsearchQuery(event.target.value);
                              setPage(0);
                            }
                          }}
                        />
                      </InputGroup>
                    </Col>
                    {showExportButton(authUser.user.role.rolename) && (
                      <Col xl={1 / 2} className="mr-3">
                        <span className="float-left">
                          <button
                            type="button"
                            className="btn btn-ftc-primary-light-border float-right"
                            onClick={() => fetchAllPagesClients()}
                          >
                            {
                              exportAPICalled ? (
                                <><Image src={Loader} height="18px" width="18px" /></>
                              ) : (<><Image
                                src={exportIcon}
                              />{' '}
                                  Export</>)
                            }
                          </button>
                        </span>
                      </Col>
                    )}
                    {validatePermission(
                      authUser.user.role.permissions,
                      'client',
                      'add'
                    ) && (
                      <div className="pr-4">
                        <button
                          type="button"
                          className="btn btn-ftc-primary float-right"
                          onClick={onAddNew}
                        >
                          Add New
                        </button>
                      </div>
                    )}
                  </Row>
                  <div className="container-top-7" />
                  <div>
                    {displaySelectedFilters()}
                    <Row>
                      <Col md="12" className="pr-0">
                        {isAPICalled ? (
                          <>
                            <DataTableComponent
                              // title="Movie List"
                              highlightOnHover
                              pointerOnHover
                              noHeader
                              columns={columns}
                              data={clients}
                              pagination
                              selectableRowsComponentProps={
                                selectableRowsComponentProps
                              }
                              paginationServer
                              paginationTotalRows={clientCount.filterCount}
                              handleRowClicked={handleRowClicked}
                              paginationPerPage={rowsPerPage}
                              onChangePage={(p) => {
                                setPage(p - 1);
                                // look for better solution later
                                // setPage(p)
                              }}
                              onChangeRowsPerPage={(rws, pg) => {
                                setRowsPerPage(rws);
                                setPage(pg);
                              }}
                            />
                            <TablePagination
                              labelRowsPerPage="Rows per page:"
                              component="div"
                              count={Number.parseInt(
                                clientCount.filterCount,
                                10
                              )}
                              page={page}
                              onChangePage={(event, newPage) => {
                                setPage(newPage);
                              }}
                              defaultPage={0}
                              rowsPerPage={rowsPerPage}
                              onChangeRowsPerPage={(event) => {
                                setRowsPerPage(
                                  parseInt(event.target.value, 10)
                                );
                                setPage(0);
                              }}
                              ActionsComponent={TablePaginationActions}
                            />
                          </>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Desktop>
            <Mobile>
              <Col>
                <div className="container pr-0">
                  <Row className="border-bottom sticky-dashboard-header-mobile">
                    <Col xs="9" className="ml-4">
                      <h3>Clients</h3>
                    </Col>
                    <Col xs="1" sm="2">
                      <Image
                        className="ftc-logo-40"
                        src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                      />{' '}
                    </Col>
                  </Row>
                  <div className="container-top-20">
                    <Row>
                      <Col xs="6" className="pl-1 pr-1">
                        <Button
                          variant="default"
                          onClick={handleShow}
                          className="btn-block"
                        >
                          {' '}
                          <Image
                            src={
                              filters !== null &&
                              Object.keys(filters).length > 0
                                ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                                : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                            }
                          />{' '}
                          Filter
                        </Button>
                      </Col>
                      {validatePermission(
                        authUser.user.role.permissions,
                        'client',
                        'add'
                      ) && (
                        <Col xs="6" className="pl-1 pr-1">
                          <button
                            type="button"
                            className="btn btn-ftc-primary btn-block"
                            onClick={onAddNew}
                          >
                            Add New
                          </button>
                        </Col>
                      )}
                    </Row>
                    <Row className="pt-0 pb-0">
                      <Col xs="12" className="pr-1 pl-1">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          value={clientName}
                          onChange={(e) => setClientName(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setsearchQuery(event.target.value);
                              setPage(0);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="pr-0 pb-0 pr-2">
                      {showExportButton(authUser.user.role.rolename) && (
                        <>
                          <Col xs="6" className="pr-1 pl-1">
                            <></>
                          </Col>
                          <Col xs="6" className="pr-1 pl-1">
                            <span>
                              <button
                                type="button"
                                className="btn btn-ftc-primary-light-border btn-block float-right"
                                onClick={() => fetchAllPagesClients()}
                              >
                                {
                              exportAPICalled ? (
                                <><Image src={Loader} height="18px" width="18px" /></>
                              ) : (<><Image
                                src={exportIcon}
                              />{' '}
                                  Export</>)
                            }
                              </button>
                            </span>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      {clients.map((client) => {
                        return (
                          <Col md={6} className="mt-3 pl-1 pr-1">
                            <TableMobileView
                              client={client}
                              callback={handleRowClicked}
                              handleViewClick={handleViewClick}
                              handleEditClicked={handleEditClicked}
                              handleClickDelete={handleClickDelete}
                              isEditable={
                                client.status === 'inactive' ||
                                !validatePermission(
                                  authUser.user.role.permissions,
                                  'client',
                                  'edit'
                                )
                              }
                              isDeletable={
                                !validatePermission(
                                  authUser.user.role.permissions,
                                  'client',
                                  'delete'
                                )
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    {isAPICalled && !clientCount.filterCount && (
                      <div className="text-center">
                        <p>There are no records to display.</p>
                      </div>
                    )}
                    <Row>
                      <Col xs="12">
                        <TablePagination
                          className="mobile__pagination"
                          labelRowsPerPage=""
                          ActionsComponent={TablePaginationActions}
                          component="div"
                          count={Number.parseInt(clientCount.filterCount, 10)}
                          page={page}
                          // onChangePage={handleChangePage}
                          onChangePage={(event, newPage) => {
                            setPage(newPage);
                          }}
                          defaultPage={0}
                          rowsPerPage={rowsPerPage}
                          onChangeRowsPerPage={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Mobile>
          </>
        )}
      </Row>

      {show && (
        <ClientFilters
          show={show}
          handleClose={handleClose}
          allFilters={filters}
          applyChanges={onApplyChanges}
          clearAll={clearAllFilters}
        />
      )}

      {showConfirmAlert && (
        <ConfirmAlertModal
          show={showConfirmAlert}
          handleClose={handleCloseConfirm}
          title="Delete Client"
          description="Deleting this Client will erase all data inside this record.
                Are you sure you want to delete this Client?"
          callback={onDeleteClient}
          action="Delete it"
          closeAction="Cancel"
        />
      )}
      <RenderCustomPopup />
    </div>
  );
});

export default Clients;
