import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Col, Modal, Row, Image, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Desktop, Mobile } from '../media-queries/mediaQueries';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';

import { getAllCitiesByName } from '../../services/city-service';
import { getAllNonBillablesName } from '../../services/non_billable.service';
import Toaster from '../../components/Toaster';
import { getPeriodByDate } from '../../services/period-service';
import { useAuthContext } from '../../contexts/user-context';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const TimesheetFilters = memo(
  ({ show, handleClose, applyChanges, allFilters, clearAll }) => {
    const [option, setOption] = useState('date');

    const [cities, setcities] = useState([]);
    const [selectedCities, setselectedCities] = useState([]);
    const [searchCity, setsearchCity] = useState('');
    const [filteredCities, setfilteredCities] = useState([]);

    const dateList = [
      'Custom Date',
      'Today',
      'This Week',
      'Last Week',
      'Last 2 Week',
      'This Period',
      'Last Period',
      'Last 2 Period',
    ];

    const { authUser } = useAuthContext();

    const [selectedDate, setselectedDate] = useState(
      allFilters?.dateObj?.type ? allFilters?.dateObj.type : null
    );
    const [dateValues, setdateValues] = useState({
      startDate: allFilters?.dateObj?.dateValues.startDate
        ? moment(allFilters?.dateObj?.dateValues.startDate).format('MM/DD/YYYY')
        : '',
      endDate: allFilters?.dateObj?.dateValues.endDate
        ? moment(allFilters?.dateObj?.dateValues.endDate).format('MM/DD/YYYY')
        : '',
    });

    const [rates, setRates] = useState([
      { value: 'Non-Billable', isChecked: false },
      { value: 'Basic Rate', isChecked: false },
      { value: 'Prime Rate', isChecked: false },
      { value: 'Special Rate', isChecked: false },
    ]);
    const [selectedRates, setSelectedRates] = useState(
      allFilters?.rates?.length ? allFilters.rates : []
    );

    const [nonBillableReasons, setNonBillableReasons] = useState([]);
    const [selectedNonBillableReasons, setSelectedNonBillableReasons] =
      useState(
        allFilters?.nonBillableReasons?.length
          ? allFilters.nonBillableReasons
          : []
      );
    const [showNonBillableReasons, setShowNonBillableReasons] = useState(false);

    const [durations, setdurations] = useState({
      startDuration: allFilters?.durations?.startDuration || '',
      endDuration: allFilters?.durations?.endDuration || '',
    });

    const [dueAmounts, setdueAmounts] = useState({
      minimumDueAmount: allFilters?.dueAmounts?.minimumDueAmount || '',
      maximumDueAmount: allFilters?.dueAmounts?.maximumDueAmount || '',
    });

    const [paymentStatuses] = useState([
      { label: 'Paid', value: 'paid' },
      { label: 'Pending', value: 'pending' },
    ]);
    const [selectedPaymentStatus, setselectedPaymentStatus] = useState(
      allFilters?.paymentStatus ? allFilters.paymentStatus : null
    );

    const [holdingFees, setHoldingFees] = useState([
      { label: 0, value: 0, isChecked: false },
      { label: 1, value: 1, isChecked: false },
      { label: 2, value: 2, isChecked: false },
      { label: 3, value: 3, isChecked: false },
      { label: 4, value: 4, isChecked: false },
      { label: 5, value: 5, isChecked: false },
      { label: 6, value: 6, isChecked: false },
      { label: 7, value: 7, isChecked: false },
      { label: 8, value: 8, isChecked: false },
      { label: 9, value: 9, isChecked: false },
    ]);
    const [selectedHolding, setSelectedHolding] = useState(
      allFilters?.holdingFee?.length ? allFilters.holdingFee : []
    );
    const [tipAmounts, setTipAmounts] = useState({
      minimumTipAmount: allFilters?.tipAmounts?.minimumTipAmount || '',
      maximumTipAmount: allFilters?.tipAmounts?.maximumTipAmount || '',
    });

    const [adjustment, setAdjustment] = useState({
      minValue: allFilters?.adjustment?.minValue || '',
      maxValue: allFilters?.adjustment?.maxValue || '',
    });

    const [driveTime, setDriveTime] = useState({
      minValue: allFilters?.driveTime?.minValue || '',
      maxValue: allFilters?.driveTime?.maxValue || '',
    });

    const [isTrainer, setIsTrainer] = useState(allFilters?.trainer === true);

    const [period, setPeriod] = useState(null);

    const [filters, setfilters] = useState(null);
    // {
    //   cities: selectedCities,
    //   rates: selectedRates,
    //   durations,
    //   dueAmounts,
    //   tipAmounts,
    //   paymentStatus: selectedPaymentStatus,
    //   holdingFee: selectedHolding,
    //   dateObj: {
    //     type: selectedDate,
    //     dateValues
    //   },
    // }

    async function getCurrentPeriod() {
      try {
        const response = await trackPromise(
          getPeriodByDate({ date: new Date() })
        );
        if (response.data?.data?.periods.length === 1) {
          setPeriod(response.data.data.periods[0]);
        } else {
          setPeriod(null);
        }
      } catch (error) {
        toast.dark(
          <Toaster
            icon="error"
            message={
              error?.response?.data?.message ?? 'Something went wrong!!!'
            }
          />
        );
      }
    }

    useEffect(() => {
      getCurrentPeriod();
    }, []);

    useLayoutEffect(() => {
      if (allFilters?.cities?.length) {
        // setselectedCities(prevCities => [...prevCities, ...allFilters.cities]);
        setselectedCities(allFilters.cities);
      }

      if (allFilters?.nonBillableReasons?.length) {
        setNonBillableReasons(allFilters.nonBillableReasons);
      }

      if (allFilters?.holdingFee?.length) {
        setHoldingFees(
          holdingFees.map((holding) => {
            const tempHolding = holding;
            if (
              allFilters?.holdingFee.find(
                (selected) => selected.value === holding.value
              )
            ) {
              tempHolding.isChecked = true;
            }
            return tempHolding;
          })
        );
      }

      if (allFilters?.rates?.length) {
        setRates(
          rates.map((rate) => {
            const tempRate = rate;
            if (
              allFilters?.rates.find(
                (selected) => selected.value === rate.value
              )
            ) {
              tempRate.isChecked = true;
            }
            return tempRate;
          })
        );
      }
    }, [allFilters]);

    async function fetchAllCities() {
      try {
        const response = await trackPromise(getAllCitiesByName());
        setcities(
          response.data.data.cities.map((city) => {
            const tempCity = city;
            if (allFilters?.cities?.length > 0) {
              if (
                allFilters?.cities.find((selected) => selected._id === city._id)
              ) {
                tempCity.isChecked = true;
              }
            } else {
              tempCity.isChecked = false;
            }
            return tempCity;
          })
        );
        // setcities([].concat(response.data.data.cities));
      } catch (error) {
        toast.dark(
          <Toaster
            icon="error"
            message={
              error?.response?.data?.message ?? 'Something went wrong!!!'
            }
          />
        );
      }
    }

    async function fetchAllNonBillableReasons() {
      try {
        const response = await trackPromise(getAllNonBillablesName());
        // setNonBillableReasons(response.data.data.non_billables);
        setNonBillableReasons(
          response.data.data.non_billables.map((nonBillable) => {
            const tempNonBillable = nonBillable;
            if (allFilters?.nonBillableReasons?.length > 0) {
              if (
                allFilters?.nonBillableReasons.find(
                  (selected) => selected._id === nonBillable._id
                )
              ) {
                tempNonBillable.isChecked = true;
              }
            } else {
              tempNonBillable.isChecked = false;
            }
            return tempNonBillable;
          })
        );
      } catch (error) {
        toast.dark(
          <Toaster
            icon="error"
            message={
              error?.response?.data?.message ?? 'Something went wrong!!!'
            }
          />
        );
      }
    }

    useEffect(() => {
      fetchAllCities();
      fetchAllNonBillableReasons();
    }, []);

    useEffect(() => {
      setfilteredCities(
        cities.filter((city) => {
          return city.cityname.toLowerCase().includes(searchCity.toLowerCase());
        })
      );
    }, [cities]);

    useEffect(() => {
      setfilteredCities(
        cities.filter((city) => {
          return city.cityname.toLowerCase().includes(searchCity.toLowerCase());
        })
      );
    }, [searchCity]);

    useEffect(() => {
      if (selectedCities.length) {
        setfilters((oldFilters) => ({ ...oldFilters, cities: selectedCities }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('cities')
      ) {
        delete filters?.cities;
        setfilters({ ...filters });
      }
    }, [selectedCities]);

    useEffect(() => {
      if (durations.startDuration || durations.endDuration) {
        setfilters((oldFilters) => ({
          ...oldFilters,
          durations: { ...durations },
        }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('durations')
      ) {
        delete filters?.durations;
        setfilters({ ...filters });
      }
    }, [durations]);

    useEffect(() => {
      if (adjustment.minValue || adjustment.maxValue) {
        setfilters((oldFilters) => ({
          ...oldFilters,
          adjustment: { ...adjustment },
        }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('adjustment')
      ) {
        delete filters?.adjustment;
        setfilters({ ...filters });
      }
    }, [adjustment]);

    useEffect(() => {
      if (isTrainer === true) {
        setfilters((oldFilters) => ({
          ...oldFilters,
          trainer: isTrainer,
        }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('trainer')
      ) {
        delete filters?.trainer;
        setfilters({ ...filters });
      }
    }, [isTrainer]);

    useEffect(() => {
      if (driveTime.minValue || driveTime.maxValue) {
        setfilters((oldFilters) => ({
          ...oldFilters,
          driveTime: { ...driveTime },
        }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('driveTime')
      ) {
        delete filters?.driveTime;
        setfilters({ ...filters });
      }
    }, [driveTime]);

    useEffect(() => {
      if (selectedRates.length) {
        setfilters((oldFilters) => ({
          ...oldFilters,
          rates: [...selectedRates],
        }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('rates')
      ) {
        delete filters?.rates;
        delete filters?.nonBillableReasons;
        setfilters({ ...filters });
      }
      setShowNonBillableReasons(
        selectedRates.some((r) => r.value === 'Non-Billable')
      );
    }, [selectedRates]);

    useEffect(() => {
      if (selectedNonBillableReasons.length) {
        setfilters((oldFilters) => ({
          ...oldFilters,
          nonBillableReasons: [...selectedNonBillableReasons],
        }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('nonBillableReasons')
      ) {
        delete filters?.nonBillableReasons;
        setfilters({ ...filters });
      }
    }, [selectedNonBillableReasons]);

    useEffect(() => {
      if (dueAmounts.minimumDueAmount || dueAmounts.maximumDueAmount) {
        setfilters((oldFilters) => ({
          ...oldFilters,
          dueAmounts: { ...dueAmounts },
        }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('dueAmounts')
      ) {
        delete filters?.dueAmounts;
        setfilters({ ...filters });
      }
    }, [dueAmounts]);

    useEffect(() => {
      if (tipAmounts.minimumTipAmount || tipAmounts.maximumTipAmount) {
        setfilters((oldFilters) => ({
          ...oldFilters,
          tipAmounts: { ...tipAmounts },
        }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('tipAmounts')
      ) {
        delete filters?.tipAmounts;
        setfilters({ ...filters });
      }
    }, [tipAmounts]);

    useEffect(() => {
      if (selectedPaymentStatus !== null) {
        setfilters((oldFilters) => ({
          ...oldFilters,
          paymentStatus: { ...selectedPaymentStatus },
        }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('paymentStatus')
      ) {
        delete filters?.paymentStatus;
        setfilters({ ...filters });
      }
    }, [selectedPaymentStatus]);

    useEffect(() => {
      if (selectedHolding.length) {
        setfilters((oldFilters) => ({
          ...oldFilters,
          holdingFee: selectedHolding,
        }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('holdingFee')
      ) {
        delete filters?.holdingFee;
        setfilters({ ...filters });
      }
    }, [selectedHolding]);

    useEffect(() => {
      if (
        selectedDate !== null ||
        (dateValues.startDate && dateValues.endDate)
      ) {
        setfilters((oldFilters) => ({
          ...oldFilters,
          dateObj: { dateValues: { ...dateValues }, type: selectedDate },
        }));
      } else if (
        filters !== null &&
        Object.keys(filters).length &&
        Object.keys(filters).includes('dateObj')
      ) {
        delete filters?.dateObj;
        setfilters({ ...filters });
      }
    }, [selectedDate, dateValues]);

    const onCityChange = (e, city) => {
      const newCities = [...cities];
      const findSelectedIndex = selectedCities
        .map((searchRole) => searchRole._id)
        .indexOf(city._id);
      const findCityFromIndex = cities
        .map((searchRole) => searchRole._id)
        .indexOf(city._id);

      const newSelectedCities = [...selectedCities];

      if (e.target.checked) {
        newCities[findCityFromIndex].isChecked = true;
        newSelectedCities.push(city);
      } else {
        newCities[findCityFromIndex].isChecked = false;
        newSelectedCities.splice(findSelectedIndex, 1);
      }
      setcities(newCities);
      setselectedCities(newSelectedCities);
    };

    const onDateSelection = (e) => {
      setselectedDate(e.target.value);
      setdateValues({
        startDate: '',
        endDate: '',
      });
      let startOfWeek;
      let endOfWeek;
      switch (e.target.value) {
        case 'Custom Date':
          break;

        case 'Today': {
          // const today = moment().startOf('day').toDate();

          startOfWeek = `${moment()
            .utc()
            .utcOffset(0)
            .format('YYYY-MM-DD')}T00:00:00.000Z`;
          endOfWeek = `${moment()
            .utc()
            .utcOffset(0)
            .format('YYYY-MM-DD')}T00:00:00.000Z`;
          setdateValues({ startDate: startOfWeek, endDate: endOfWeek });
          break;
        }

        case 'This Week': {
          startOfWeek = moment()
            .startOf('week')
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          endOfWeek = moment()
            .endOf('week')
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          setdateValues({ startDate: startOfWeek, endDate: endOfWeek });
          break;
        }

        case 'Last Week':
          startOfWeek = moment()
            .subtract(1, 'weeks')
            .startOf('week')
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          endOfWeek = moment()
            .subtract(1, 'weeks')
            .endOf('week')
            // .utcOffset(0)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          setdateValues({ startDate: startOfWeek, endDate: endOfWeek });
          break;

        case 'Last 2 Week':
          startOfWeek = moment()
            .subtract(2, 'weeks')
            .startOf('week')
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          endOfWeek = moment()
            .subtract(1, 'weeks')
            .endOf('week')
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate();
          setdateValues({ startDate: startOfWeek, endDate: endOfWeek });
          break;

        case 'This Period':
          if (period) {
            startOfWeek = period.start_date;
            endOfWeek = period.end_date;
            setdateValues({ startDate: startOfWeek, endDate: endOfWeek });
          }
          break;

        case 'Last Period':
          if (period) {
            startOfWeek = `${moment(period.start_date)
              .utc()
              .subtract(28, 'days')
              .utcOffset(0)
              .format('YYYY-MM-DD')}T00:00:00.000Z`;
            endOfWeek = `${moment(period.end_date)
              .utc()
              .subtract(28, 'days')
              .utcOffset(0)
              .format('YYYY-MM-DD')}T00:00:00.000Z`;

            setdateValues({ startDate: startOfWeek, endDate: endOfWeek });
          }
          break;

        case 'Last 2 Period':
          if (period) {
            startOfWeek = `${moment(period.start_date)
              .utc()
              .subtract(56, 'days')
              .utcOffset(0)
              .format('YYYY-MM-DD')}T00:00:00.000Z`;
            endOfWeek = `${moment(period.end_date)
              .utc()
              .subtract(28, 'days')
              .utcOffset(0)
              .format('YYYY-MM-DD')}T00:00:00.000Z`;

            setdateValues({ startDate: startOfWeek, endDate: endOfWeek });
          }
          break;

        default:
          break;
      }
    };

    const onRatePlanChange = (e, rate, indexKey) => {
      const newRatePlans = [...rates];
      const findSelectedIndex = selectedRates
        .map((searchRole) => searchRole.value)
        .indexOf(rate.value);

      const newSelectedRates = [...selectedRates];

      if (e.target.checked) {
        newRatePlans[indexKey].isChecked = true;
        newSelectedRates.push(rate);
      } else {
        newRatePlans[indexKey].isChecked = false;
        newSelectedRates.splice(findSelectedIndex, 1);
      }
      setRates(newRatePlans);
      setSelectedRates(newSelectedRates);
    };

    useEffect(() => {
      if (!selectedRates.some(rate => rate.value === "Non-Billable")) {
        setSelectedNonBillableReasons([]);
      }
    }, [selectedRates]);
    

    const onNonBillableChange = (e, nonBillableReason, indexKey) => {
      const newNonBillableReason = [...nonBillableReasons];
      const findSelectedIndex = selectedNonBillableReasons
        .map((searchRole) => searchRole._id)
        .indexOf(nonBillableReason._id);

      const newSelectedNonBillableReasons = [...selectedNonBillableReasons];

      if (e.target.checked) {
        newNonBillableReason[indexKey].isChecked = true;
        newSelectedNonBillableReasons.push(nonBillableReason);
      } else {
        newNonBillableReason[indexKey].isChecked = false;
        newSelectedNonBillableReasons.splice(findSelectedIndex, 1);
      }
      setNonBillableReasons(newNonBillableReason);
      setSelectedNonBillableReasons(newSelectedNonBillableReasons);
    };

    const onIsTrainerChange = () => {
      setIsTrainer((val) => !val);
    };

    const onHoldingChange = (e, holding, indexKey) => {
      const newHoldingFees = [...holdingFees];
      const findSelectedIndex = selectedHolding
        .map((searchRole) => searchRole.value)
        .indexOf(holding.value);

      const newSelectedHolding = [...selectedHolding];

      if (e.target.checked) {
        newHoldingFees[indexKey].isChecked = true;
        newSelectedHolding.push(holding);
      } else {
        newHoldingFees[indexKey].isChecked = false;
        newSelectedHolding.splice(findSelectedIndex, 1);
      }
      setHoldingFees(newHoldingFees);
      setSelectedHolding(newSelectedHolding);
    };

    const onPaymentChanges = (_e, status) => {
      setselectedPaymentStatus(status);
    };

    const removeAllCities = () => {
      setsearchCity('');
      setcities(
        cities.map((c) => {
          const tempCity = c;
          if (selectedCities.find((selected) => selected._id === c._id)) {
            tempCity.isChecked = false;
            selectedCities.splice(
              selectedCities.findIndex((selected) => selected._id === c._id),
              1
            );
          }
          return tempCity;
        })
      );
      setselectedCities([]);
    };

    const removeAllPayments = () => {
      setselectedPaymentStatus(null);
    };

    const removeAllRatePlans = () => {
      setRates(
        rates.map((c) => {
          const tempRate = c;
          if (selectedRates.find((selected) => selected.value === c.value)) {
            tempRate.isChecked = false;
            selectedRates.splice(
              selectedRates.findIndex((selected) => selected.value === c.value),
              1
            );
          }
          return tempRate;
        })
      );
      setSelectedRates([]);
      setShowNonBillableReasons(false);
    };

    const removeAllHoldingFees = () => {
      setHoldingFees(
        holdingFees.map((c) => {
          const tempNumber = c;
          if (selectedHolding.find((selected) => selected.value === c.value)) {
            tempNumber.isChecked = false;
            selectedHolding.splice(
              selectedHolding.findIndex(
                (selected) => selected.value === c.value
              ),
              1
            );
          }
          return tempNumber;
        })
      );
      setSelectedHolding([]);
    };

    const removeDueAmounts = () => {
      setdueAmounts({
        minimumDueAmount: '',
        maximumDueAmount: '',
      });
    };

    const removeTipAmounts = () => {
      setTipAmounts({
        minimumTipAmount: '',
        maximumTipAmount: '',
      });
    };

    const removeAllDurations = () => {
      setdurations({
        startDuration: '',
        endDuration: '',
      });
    };

    const removeDateSelection = () => {
      setselectedDate(null);
      setdateValues({
        startDate: '',
        endDate: '',
      });
    };

    const resetAdjustment = () => {
      setAdjustment({
        minValue: '',
        maxValue: '',
      });
    };

    const resetDriveTime = () => {
      setDriveTime({
        minValue: '',
        maxValue: '',
      });
    };

    // const filteredCities = cities.filter((city) => {
    //   return city.cityname.toLowerCase().includes(searchCity.toLowerCase())
    // });

    const onClearAll = () => {
      removeDateSelection();
      removeAllCities();
      removeAllRatePlans();
      removeDueAmounts();
      removeAllHoldingFees();
      removeTipAmounts();
      removeAllPayments();
      setfilters(null);
      clearAll();
    };

    function filterDetailsByOption() {
      switch (option) {
        case 'city':
          return (
            <div>
              <Row key="city">
                <Col md="3" xs="6">
                  <label>Cities</label>
                </Col>
                <Col md="9" xs="6">
                  <a
                    className="txt-primary float-right"
                    role="button"
                    onClick={removeAllCities}
                    onKeyDown={() => {}}
                    tabIndex={-1}
                  >
                    Deselect All
                  </a>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Form.Group controlId="formGroupEmail">
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      value={searchCity}
                      onChange={(e) => setsearchCity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                {filteredCities.map((city, index) => {
                  return (
                    <Col md="4">
                      <Form.Group controlId="formBasicCheckbox">
                        <input
                          type="checkbox"
                          className="ftc-checkbox"
                          id={city._id}
                          checked={city.isChecked}
                          onChange={(e) => {
                            onCityChange(e, city, index);
                          }}
                        />
                        <label
                          className="position-relative ml-4 pl-2"
                          htmlFor={city._id}
                        >
                          {city.cityname}
                        </label>
                      </Form.Group>
                    </Col>
                  );
                })}
              </Row>
            </div>
          );

        case 'date':
          return (
            <div>
              <Row>
                <Col md="3" xs="6">
                  <label>Date</label>
                </Col>
                <Col md="9" xs="6">
                  <a
                    className="txt-primary float-right"
                    role="button"
                    onClick={removeDateSelection}
                    onKeyDown={() => {}}
                    tabIndex={-1}
                  >
                    Deselect All
                  </a>
                </Col>
              </Row>
              <Row>
                {dateList.map((dateValue) => {
                  return (
                    <Col md="4" key={dateValue}>
                      <Form.Group>
                        <input
                          type="radio"
                          className="ftc-radiobutton"
                          id={dateValue}
                          name="date"
                          value={dateValue}
                          checked={dateValue === selectedDate}
                          disabled={
                            (dateValue === 'This Period' ||
                              dateValue === 'Last Period' ||
                              dateValue === 'Last 2 Period') &&
                            !period
                          }
                          onChange={onDateSelection}
                        />
                        <label
                          className="position-relative ml-4 pl-2"
                          htmlFor={dateValue}
                        >
                          {dateValue}
                        </label>
                      </Form.Group>
                    </Col>
                  );
                })}
              </Row>
              <hr />
              {selectedDate === 'Custom Date' && (
                <Row>
                  <Col md="12">
                    <label>Select Custom Date</label>
                  </Col>
                  <Col md="6" className="mb-3">
                    <label>From</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePickerInput
                        selectedDate={dateValues.startDate}
                        handleDateChange={(val) => {
                          setdateValues({
                            ...dateValues,
                            startDate: moment(val)
                              .set({
                                hour: 0,
                                minute: 0,
                                second: 0,
                                millisecond: 0,
                              })
                              .toDate(),
                          });
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Col>
                  <Col md="6" className="mb-3">
                    <label>To</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePickerInput
                        selectedDate={dateValues.endDate}
                        handleDateChange={(val) => {
                          setdateValues({
                            ...dateValues,
                            endDate: moment(val)
                              .set({
                                hour: 0,
                                minute: 0,
                                second: 0,
                                millisecond: 0,
                              })
                              .toDate(),
                          });
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Col>
                </Row>
              )}
            </div>
          );

        case 'duration':
          return (
            <div>
              <Row key="duration">
                <Col md="3" xs="6">
                  <label>Duration</label>
                </Col>
                <Col md="9" xs="6">
                  <a
                    className="txt-primary float-right"
                    role="button"
                    onClick={removeAllDurations}
                    onKeyDown={() => {}}
                    tabIndex={-1}
                  >
                    Reset
                  </a>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <Form.Group controlId="exampleForm.SelectCustomMin">
                    <Form.Label>Enter Minimum Hours</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="0"
                      value={durations.startDuration}
                      min={0}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setdurations({
                            ...durations,
                            startDuration: e.target.value,
                          });
                        }
                      }}
                      onKeyDown={(e) =>
                        (e.keyCode === 69 ||
                          e.keyCode === 190 ||
                          e.keyCode === 189) &&
                        e.preventDefault()
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <Form.Group controlId="exampleForm.SelectCustomMax">
                    <Form.Label>Enter Maximum Hours</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="0"
                      min={0}
                      value={durations.endDuration}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setdurations({
                            ...durations,
                            endDuration: e.target.value,
                          });
                        }
                      }}
                      onKeyDown={(e) =>
                        (e.keyCode === 69 ||
                          e.keyCode === 190 ||
                          e.keyCode === 189) &&
                        e.preventDefault()
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          );

        case 'charges':
          return (
            <div>
              <Row key="charges">
                <Col md="3" xs="6">
                  <label>Rate Plan</label>
                </Col>
                <Col md="9" xs="6">
                  <a
                    className="txt-primary float-right"
                    role="button"
                    onClick={removeAllRatePlans}
                    onKeyDown={() => {}}
                    tabIndex={-1}
                  >
                    Deselect All
                  </a>
                </Col>
              </Row>
              <Row>
                {rates.map((rate, index) => {
                  return (
                    <Col md="4">
                      <Form.Group controlId="formBasicCheckbox">
                        <input
                          type="checkbox"
                          className="ftc-checkbox"
                          id={index + 1}
                          name={`cargescheckbox-${index}`}
                          onChange={(e) => onRatePlanChange(e, rate, index)}
                          checked={rate.isChecked}
                        />
                        <label
                          className="position-relative ml-4 pl-2"
                          htmlFor={index + 1}
                        >
                          {rate.value}
                        </label>
                      </Form.Group>
                    </Col>
                  );
                })}
              </Row>
              <hr />
              {showNonBillableReasons && (
                <>
                  <Row key="charges">
                    <Col>
                      <label>Non Billable Reason</label>
                    </Col>
                  </Row>
                  <Row>
                    {nonBillableReasons?.map((nonBillableReason, index) => {
                      return (
                        <Col md="4">
                          <Form.Group controlId="formBasicCheckbox">
                            <input
                              type="checkbox"
                              className="ftc-checkbox"
                              id={`nonBillable${index + 1}`}
                              name={`nonbillablecheckbox-${index}`}
                              onChange={(e) =>
                                onNonBillableChange(e, nonBillableReason, index)
                              }
                              checked={nonBillableReason.isChecked}
                            />
                            <label
                              className="position-relative ml-4 pl-2"
                              htmlFor={`nonBillable${index + 1}`}
                            >
                              {nonBillableReason.title}
                            </label>
                          </Form.Group>
                        </Col>
                      );
                    })}
                  </Row>
                  <hr />
                </>
              )}

              <Row>
                <Col md="3" xs="6">
                  <label>Amount Due</label>
                </Col>
                <Col md="9" xs="6">
                  <a
                    className="txt-primary float-right"
                    role="button"
                    onClick={removeDueAmounts}
                    onKeyDown={removeDueAmounts}
                    tabIndex={-1}
                  >
                    Deselect All
                  </a>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <Form.Group controlId="exampleForm.SelectCustomMin">
                    <Form.Label>Enter Minimum Amount</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="0"
                      value={dueAmounts.minimumDueAmount}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setdueAmounts((oldDueAmounts) => ({
                            ...oldDueAmounts,
                            minimumDueAmount: e.target.value,
                          }));
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <Form.Group controlId="exampleForm.SelectCustomMax">
                    <Form.Label>Enter Maximum Amount</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="0"
                      value={dueAmounts.maximumDueAmount}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setdueAmounts((oldDueAmounts) => ({
                            ...oldDueAmounts,
                            maximumDueAmount: e.target.value,
                          }));
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          );

        case 'extraCharges':
          return (
            <div>
              <Row>
                <Col md="4" xs="6">
                  <label>Holding Fee Number</label>
                </Col>
                <Col md="8" xs="6">
                  <a
                    className="txt-primary float-right"
                    role="button"
                    onClick={removeAllHoldingFees}
                    onKeyDown={() => {}}
                    tabIndex={-1}
                  >
                    Deselect All
                  </a>
                </Col>
              </Row>
              <Row>
                {holdingFees.map((holdingNumber, index) => {
                  return (
                    <Col md="2" xs="4">
                      <Form.Group controlId="formBasicCheckbox">
                        <input
                          type="checkbox"
                          className="ftc-checkbox"
                          id={holdingNumber.value}
                          checked={holdingNumber.isChecked}
                          onChange={(e) => {
                            onHoldingChange(e, holdingNumber, index);
                          }}
                        />
                        <label
                          className="position-relative ml-4 pl-2"
                          htmlFor={holdingNumber.value}
                        >
                          {holdingNumber.label}
                        </label>
                      </Form.Group>
                    </Col>
                  );
                })}
              </Row>
              <hr />
              <Row>
                <Col md="3" xs="6">
                  <label>Tip Amount</label>
                </Col>
                <Col md="9" xs="6">
                  <a
                    className="txt-primary float-right"
                    role="button"
                    onClick={removeTipAmounts}
                    onKeyDown={() => {}}
                    tabIndex={-1}
                  >
                    Reset
                  </a>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <Form.Group controlId="minimumTipAmount">
                    <Form.Label>Enter Minimum Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="0"
                      value={tipAmounts.minimumTipAmount}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setTipAmounts((oldTipAmounts) => ({
                            ...oldTipAmounts,
                            minimumTipAmount: e.target.value,
                          }));
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <Form.Group controlId="maximumTipAmount">
                    <Form.Label>Enter Maximum Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="0"
                      value={tipAmounts.maximumTipAmount}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setTipAmounts((oldTipAmounts) => ({
                            ...oldTipAmounts,
                            maximumTipAmount: e.target.value,
                          }));
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          );

        case 'paymentStatus':
          return (
            <div>
              <Row>
                <Col md="4" xs="6">
                  <label>Payment</label>
                </Col>
                <Col md="8" xs="6">
                  <a
                    className="txt-primary float-right"
                    role="button"
                    onClick={removeAllPayments}
                    onKeyDown={() => {}}
                    tabIndex={-1}
                  >
                    Deselect All
                  </a>
                </Col>
              </Row>
              <Row>
                {paymentStatuses.map((payment) => {
                  return (
                    <Col md="4">
                      <Form.Group controlId="formBasicCheckbox">
                        <input
                          name="payment-radio"
                          type="radio"
                          className="ftc-radiobutton"
                          id={payment.value}
                          onChange={(e) => {
                            onPaymentChanges(e, payment);
                          }}
                          checked={
                            payment.value === selectedPaymentStatus?.value
                          }
                        />
                        <label
                          className="position-relative ml-4 pl-2"
                          htmlFor={payment.value}
                        >
                          {payment.label}
                        </label>
                      </Form.Group>
                    </Col>
                  );
                })}
              </Row>
            </div>
          );

        case 'adjustment':
          return (
            <div>
              <Row key="adjustment">
                <Col md="3" xs="6">
                  <label>Adjustment</label>
                </Col>
                <Col md="9" xs="6">
                  <a
                    className="txt-primary float-right"
                    role="button"
                    onClick={resetAdjustment}
                    onKeyDown={() => {}}
                    tabIndex={-1}
                  >
                    Reset
                  </a>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <Form.Group controlId="exampleForm.SelectCustomMin">
                    <Form.Label>Enter Minimum Value</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="0"
                      value={adjustment.minValue}
                      min={0}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setAdjustment({
                            ...adjustment,
                            minValue: e.target.value,
                          });
                        }
                      }}
                      onKeyDown={(e) =>
                        (e.keyCode === 69 ||
                          e.keyCode === 190 ||
                          e.keyCode === 189) &&
                        e.preventDefault()
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <Form.Group controlId="exampleForm.SelectCustomMax">
                    <Form.Label>Enter Maximum Value</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="0"
                      min={0}
                      value={adjustment.maxValue}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setAdjustment({
                            ...adjustment,
                            maxValue: e.target.value,
                          });
                        }
                      }}
                      onKeyDown={(e) =>
                        (e.keyCode === 69 ||
                          e.keyCode === 190 ||
                          e.keyCode === 189) &&
                        e.preventDefault()
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          );

        case 'driveTime':
          return (
            <div>
              <Row key="driveTime">
                <Col md="3" xs="6">
                  <label>Drive Time</label>
                </Col>
                <Col md="9" xs="6">
                  <a
                    className="txt-primary float-right"
                    role="button"
                    onClick={resetDriveTime}
                    onKeyDown={() => {}}
                    tabIndex={-1}
                  >
                    Reset
                  </a>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <Form.Group controlId="exampleForm.SelectCustomMin">
                    <Form.Label>Enter Minimum Minutes</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="0"
                      value={driveTime.minValue}
                      min={0}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setDriveTime({
                            ...driveTime,
                            minValue: e.target.value,
                          });
                        }
                      }}
                      onKeyDown={(e) =>
                        (e.keyCode === 69 ||
                          e.keyCode === 190 ||
                          e.keyCode === 189) &&
                        e.preventDefault()
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <Form.Group controlId="exampleForm.SelectCustomMax">
                    <Form.Label>Enter Maximum Minutes</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="0"
                      min={0}
                      value={driveTime.maxValue}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setDriveTime({
                            ...driveTime,
                            maxValue: e.target.value,
                          });
                        }
                      }}
                      onKeyDown={(e) =>
                        (e.keyCode === 69 ||
                          e.keyCode === 190 ||
                          e.keyCode === 189) &&
                        e.preventDefault()
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          );

        case 'trainer':
          return (
            <div>
              <Row key="driveTime">
                <Col md="3" xs="6">
                  <label>Status</label>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <Form.Group controlId="formBasicCheckbox">
                    <input
                      type="checkbox"
                      className="ftc-checkbox"
                      id="trainercheckbox1"
                      name="trainercheckbox"
                      onChange={() => onIsTrainerChange()}
                      checked={isTrainer}
                    />
                    <label className="position-relative ml-4 pl-2">
                      This User is a <b>Trainer</b>
                    </label>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          );

        default:
          break;
      }
      return null;
    }

    return (
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <FilterMobileNav /> */}
          {/* <hr className="hr__row" /> */}
          <Row className="modal__row">
            <Desktop>
              <Col
                md="3"
                xs="4"
                className="modal__Col_3_Padding border__right sticky-top"
              >
                <ul className="modal__ul">
                  <li
                    className={`${
                      option === 'date' ? 'mb__modal_filter_active' : ''
                    } modal__li`}
                    onClick={() => setOption('date')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Date</span>{' '}
                    <Image
                      className="icon_arrow float-right"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                      fluid
                    />
                  </li>
                  <li
                    className={`${
                      option === 'city' ? 'mb__modal_filter_active' : ''
                    } modal__li`}
                    onClick={() => setOption('city')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">City</span>{' '}
                    <Image
                      className="icon_arrow float-right"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                      fluid
                    />{' '}
                  </li>
                  <li
                    className={`${
                      option === 'duration' ? 'mb__modal_filter_active' : ''
                    } modal__li`}
                    onClick={() => setOption('duration')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Duration</span>{' '}
                    <Image
                      className="icon_arrow float-right"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                      fluid
                    />
                  </li>
                  <li
                    className={`${
                      option === 'charges' ? 'mb__modal_filter_active' : ''
                    } modal__li`}
                    onClick={() => setOption('charges')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Charges</span>{' '}
                    <Image
                      className="icon_arrow float-right"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                      fluid
                    />
                  </li>
                  <li
                    className={`${
                      option === 'extraCharges' ? 'mb__modal_filter_active' : ''
                    } modal__li`}
                    onClick={() => setOption('extraCharges')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Extra Charges</span>{' '}
                    <Image
                      className="icon_arrow float-right"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                      fluid
                    />
                  </li>
                  <li
                    className={`${
                      option === 'paymentStatus'
                        ? 'mb__modal_filter_active'
                        : ''
                    } modal__li`}
                    onClick={() => setOption('paymentStatus')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Payment Status</span>
                    <Image
                      className="icon_arrow float-right"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                      fluid
                    />
                  </li>
                  <li
                    className={`${
                      option === 'adjustment' ? 'mb__modal_filter_active' : ''
                    } modal__li`}
                    onClick={() => setOption('adjustment')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Adjustment</span>
                    <Image
                      className="icon_arrow float-right"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                      fluid
                    />
                  </li>
                  <li
                    className={`${
                      option === 'driveTime' ? 'mb__modal_filter_active' : ''
                    } modal__li`}
                    onClick={() => setOption('driveTime')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Drive Time</span>
                    <Image
                      className="icon_arrow float-right"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                      fluid
                    />
                  </li>
                  {['Admin', 'Manager', 'Accountant'].includes(
                    authUser?.user.role.rolename
                  ) && (
                    <li
                      className={`${
                        option === 'trainer' ? 'mb__modal_filter_active' : ''
                      } modal__li`}
                      onClick={() => setOption('trainer')}
                      role="menuitem"
                      onKeyPress={() => {}}
                    >
                      <span className="modal__list__padding">Trainer</span>
                      <Image
                        className="icon_arrow float-right"
                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                        fluid
                      />
                    </li>
                  )}
                </ul>
              </Col>
            </Desktop>
            <Mobile>
              <Col
                md="3"
                xs="4"
                className="modal__Col_3_Padding border__right  modal-filters"
              >
                <ul className="modal__ul">
                  <li
                    className={`${
                      option === 'date' ? 'mb__modal_filter_active' : ''
                    } mb__modal__li`}
                    onClick={() => setOption('date')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Date</span>{' '}
                  </li>
                  <li
                    className={`${
                      option === 'city' ? 'mb__modal_filter_active' : ''
                    } mb__modal__li`}
                    onClick={() => setOption('city')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">City</span>{' '}
                  </li>
                  <li
                    className={`${
                      option === 'duration' ? 'mb__modal_filter_active' : ''
                    } mb__modal__li`}
                    onClick={() => setOption('duration')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Duration</span>{' '}
                  </li>
                  <li
                    className={`${
                      option === 'charges' ? 'mb__modal_filter_active' : ''
                    } mb__modal__li`}
                    onClick={() => setOption('charges')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Charges</span>{' '}
                  </li>
                  <li
                    className={`${
                      option === 'extraCharges' ? 'mb__modal_filter_active' : ''
                    } mb__modal__li`}
                    onClick={() => setOption('extraCharges')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Extra Charges</span>{' '}
                  </li>
                  <li
                    className={`${
                      option === 'paymentStatus'
                        ? 'mb__modal_filter_active'
                        : ''
                    } mb__modal__li`}
                    onClick={() => setOption('paymentStatus')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Payment Status</span>
                  </li>
                  <li
                    className={`${
                      option === 'adjustment' ? 'mb__modal_filter_active' : ''
                    } mb__modal__li`}
                    onClick={() => setOption('adjustment')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Adjustment</span>
                  </li>
                  <li
                    className={`${
                      option === 'driveTime' ? 'mb__modal_filter_active' : ''
                    } mb__modal__li`}
                    onClick={() => setOption('driveTime')}
                    role="menuitem"
                    onKeyPress={() => {}}
                  >
                    <span className="modal__list__padding">Drive Time</span>
                  </li>
                  {['Admin', 'Manager', 'Accountant'].includes(
                    authUser?.user.role.rolename
                  ) && (
                    <li
                      className={`${
                        option === 'trainer' ? 'mb__modal_filter_active' : ''
                      } mb__modal__li`}
                      onClick={() => setOption('trainer')}
                      role="menuitem"
                      onKeyPress={() => {}}
                    >
                      <span className="modal__list__padding">Trainer</span>
                    </li>
                  )}
                </ul>
              </Col>
            </Mobile>
            <Col md="9" xs="8">
              {filterDetailsByOption()}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="default"
            onClick={() => {
              onClearAll();
              // handleClose();
            }}
          >
            Clear All
          </Button>
          <Button
            variant="default"
            className="btn-ftc-border-primary"
            onClick={() => {
              applyChanges(filters);
            }}
          >
            Apply Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default TimesheetFilters;

TimesheetFilters.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  applyChanges: PropTypes.func.isRequired,
  allFilters: PropTypes.any.isRequired,
  clearAll: PropTypes.func.isRequired,
};
