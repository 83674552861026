// import lazyWithPreload from './utils/lazyWithPreload'

import Login from './layouts/login/Login';

import Timesheet from './layouts/Timesheet/timesheet';
import ViewTimesheet from './layouts/Timesheet/viewTimesheet';
import EditTimeSheet from './layouts/Timesheet/editTimeSheet';
import Reimbursement from './layouts/Reimbursement/reimbursement';
import ManageReimbursement from './layouts/Reimbursement/manageReimbursement';
import ViewReimbursement from './layouts/Reimbursement/viewReimbursement';
import Users from './layouts/settings/Users/users';
import ManageUser from './layouts/settings/Users/manageUser';
import ViewUserDetails from './layouts/settings/Users/viewUserDetails';
import UserRole from './layouts/settings/UserRoles/userRole';
import ManageUserRoles from './layouts/settings/UserRoles/manageUserRoles';
import ViewUserRole from './layouts/settings/UserRoles/viewUserRole';
import Clients from './layouts/settings/Clients/clients';
import ManageClient from './layouts/settings/Clients/manageClient';
import Cities from './layouts/settings/Cities/cities';
import ManageCities from './layouts/settings/Cities/manageCities';
import ViewCity from './layouts/settings/Cities/viewCity';
import ViewClient from './layouts/settings/Clients/viewClient';
import NonBillable from './layouts/settings/non-billable/nonBillable';
import ManageNonbillable from './layouts/settings/non-billable/manageNonbillable';
import ViewNonbillable from './layouts/settings/non-billable/viewNonbillable';
import CustomPopup from './layouts/settings/custom-popups/customPopup';
import ManageCustomPopup from './layouts/settings/custom-popups/manageCustomPopup';
import ViewCustomPopup from './layouts/settings/custom-popups/viewCustomPopup';
import CustomPages from './layouts/settings/custom-pages/customPages';
import ManageCustomPages from './layouts/settings/custom-pages/manageCustomPages';
import ViewCustomPages from './layouts/settings/custom-pages/viewCustomPages';
import Hours from './layouts/reports/Hours/Hours';
import Payroll from './layouts/reports/Payroll/Payroll';
import PaymentHoldingPage from './layouts/Timesheet/mobile-layouts/PaymentHoldingPage';
import PageNotFound from './layouts/error-pages/404';
import SomethingWentWrong from './layouts/error-pages/500';
import LandingPage from './layouts/landing-page/landingPage';
import PeriodDate from './layouts/settings/PeriodDate/periodDate';
import ReimbursementSetup from './layouts/settings/ReimbursementSetup/reimbursementSetup';
import ManageReimbursementSetup from './layouts/settings/ReimbursementSetup/manageReimbursementSetup';
import PrivacyPolicy from './layouts/app-terms-policy/PrivacyPolicy';
import TermsofService from './layouts/app-terms-policy/TermsofService';

// const ViewTimesheet = lazyWithPreload(() => import('./layouts/Timesheet/viewTimesheet'));
// const Timesheet = lazyWithPreload(() => import('./layouts/Timesheet/timesheet'));
// const EditTimeSheet = lazyWithPreload(() => import('./layouts/Timesheet/editTimeSheet'));

// ViewTimesheet.preload();
// Timesheet.preload();
// EditTimeSheet.preload();

const routes = {
  publicRoutes: [
    {
      path: '/login',
      exact: true,
      component: Login,
    },
    {
      path: '/page-not-found',
      exact: true,
      component: PageNotFound,
    },
    {
      path: '/something-went-wrong',
      exact: true,
      component: SomethingWentWrong,
    },
    {
      path: '/privacy-policy',
      exact: true,
      component: PrivacyPolicy,
    },
    {
      path: '/terms-of-service',
      exact: true,
      component: TermsofService,
    },
    // {
    //     path: '/page-not-found',
    //     exact: true,
    //     component: PageNotFound,
    // },
  ],
  privateRoutes: [
    {
      path: '/',
      exact: true,
      component: Timesheet,
      moduleName: 'timesheet',
      action: 'view',
      // component: LandingPage,
      // moduleName: 'landing',
    },
    {
      path: '/landingPage',
      exact: true,
      component: LandingPage,
      moduleName: 'landing',
      action: 'view',
    },
    {
      path: '/settings/pay-calendar',
      exact: true,
      component: PeriodDate,
      moduleName: 'pay-period',
      action: 'view',
    },
    {
      path: '/settings/reimbursement-setup',
      exact: true,
      component: ReimbursementSetup,
      moduleName: 'pay-period',
      action: 'view',
    },
    {
      path: '/settings/editReimbursementReason/:reimbursementReasonId',
      exact: true,
      component: ManageReimbursementSetup,
      moduleName: 'pay-period',
      action: 'edit',
    },
    {
      path: '/settings/manageReimbursementReason',
      exact: true,
      component: ManageReimbursementSetup,
      moduleName: 'pay-period',
      action: 'view',
    },
    {
      path: '/timesheet',
      exact: true,
      component: Timesheet,
      moduleName: 'timesheet',
      action: 'view',
    },
    {
      path: '/addNewTimesheet',
      exact: true,
      component: EditTimeSheet,
      moduleName: 'timesheet',
      action: 'add',
    },
    {
      path: '/editTimesheet/:timesheetId',
      exact: true,
      component: EditTimeSheet,
      moduleName: 'timesheet',
      action: 'edit',
    },
    {
      path: '/viewTimesheet/:timesheetId',
      exact: true,
      component: ViewTimesheet,
      moduleName: 'timesheet',
      action: 'view',
    },
    {
      path: '/reimbursement',
      exact: true,
      component: Reimbursement,
      moduleName: 'reimbursement',
      action: 'view',
    },
    {
      path: '/addNewReimbursement',
      exact: true,
      component: ManageReimbursement,
      moduleName: 'reimbursement',
      action: 'add',
    },
    {
      path: '/viewReimbursement/:reimbursementId',
      exact: true,
      component: ViewReimbursement,
      moduleName: 'reimbursement',
      action: 'view',
    },
    {
      path: '/timesheet/paymentHolding',
      exact: true,
      component: PaymentHoldingPage,
      moduleName: 'timesheet',
      action: 'edit',
    },
    {
      path: '/settings/users',
      exact: true,
      component: Users,
      moduleName: 'user',
      action: 'view',
    },
    {
      path: '/settings/addNewUser',
      exact: true,
      component: ManageUser,
      moduleName: 'user',
      action: 'add',
    },
    {
      path: '/settings/editUser/:employeeId',
      exact: true,
      component: ManageUser,
      moduleName: 'user',
      action: 'edit',
    },
    {
      path: '/settings/viewUser/:employeeId',
      exact: true,
      component: ViewUserDetails,
      moduleName: 'user',
      action: 'view',
    },
    {
      path: '/settings/userroles',
      exact: true,
      component: UserRole,
      moduleName: 'user_access',
      action: 'view',
    },
    {
      path: '/settings/addNewUserRole',
      exact: true,
      component: ManageUserRoles,
      moduleName: 'user_access',
      action: 'add',
    },
    {
      path: '/settings/editUserRole/:roleId',
      exact: true,
      component: ManageUserRoles,
      moduleName: 'user_access',
      action: 'edit',
    },
    {
      path: '/settings/viewUserRole/:roleId',
      exact: true,
      component: ViewUserRole,
      moduleName: 'user_access',
      action: 'view',
    },
    {
      path: '/settings/clients',
      exact: true,
      component: Clients,
      moduleName: 'client',
      action: 'view',
    },
    {
      path: '/settings/addNewClient',
      exact: true,
      component: ManageClient,
      moduleName: 'client',
      action: 'add',
    },
    {
      path: '/settings/editClient/:clientId',
      exact: true,
      component: ManageClient,
      moduleName: 'client',
      action: 'edit',
    },
    {
      path: '/settings/viewClient/:clientId',
      exact: true,
      component: ViewClient,
      moduleName: 'client',
      action: 'view',
    },
    {
      path: '/settings/cities',
      exact: true,
      component: Cities,
      moduleName: 'city',
      action: 'view',
    },
    {
      path: '/settings/addNewCity',
      exact: true,
      component: ManageCities,
      moduleName: 'city',
      action: 'add',
    },
    {
      path: '/settings/editCity/:cityId',
      exact: true,
      component: ManageCities,
      moduleName: 'city',
      action: 'edit',
    },
    {
      path: '/settings/viewCity/:cityId',
      exact: true,
      component: ViewCity,
      moduleName: 'city',
      action: 'view',
    },
    {
      path: '/settings/nonbillable',
      exact: true,
      component: NonBillable,
      moduleName: 'non_billable',
      action: 'view',
    },
    {
      path: '/settings/addNewNonbillable',
      exact: true,
      component: ManageNonbillable,
      moduleName: 'non_billable',
      action: 'add',
    },
    {
      path: '/settings/editNonbillable/:nonBillId',
      exact: true,
      component: ManageNonbillable,
      moduleName: 'non_billable',
      action: 'edit',
    },
    {
      path: '/settings/viewNonbillable/:nonBillId',
      exact: true,
      component: ViewNonbillable,
      moduleName: 'non_billable',
      action: 'view',
    },
    {
      path: '/settings/custompopups',
      exact: true,
      component: CustomPopup,
      moduleName: 'custom_popup',
      action: 'view',
    },
    {
      path: '/settings/addNewCustomPopup',
      exact: true,
      component: ManageCustomPopup,
      moduleName: 'custom_popup',
      action: 'add',
    },
    {
      path: '/settings/editCustomPopup/:popupId',
      exact: true,
      component: ManageCustomPopup,
      moduleName: 'custom_popup',
      action: 'edit',
    },
    {
      path: '/settings/viewCustomPopup/:popupId',
      exact: true,
      component: ViewCustomPopup,
      moduleName: 'custom_popup',
      action: 'view',
    },
    {
      path: '/settings/custompages',
      exact: true,
      component: CustomPages,
      moduleName: 'custom_page',
      action: 'view',
    },
    {
      path: '/settings/addNewCustomPage',
      exact: true,
      component: ManageCustomPages,
      moduleName: 'custom_page',
      action: 'add',
    },
    {
      path: '/settings/editCustomPage/:pageId',
      exact: true,
      component: ManageCustomPages,
      moduleName: 'custom_page',
      action: 'edit',
    },
    {
      path: '/settings/viewCustomPage/:pageId',
      exact: true,
      component: ViewCustomPages,
      moduleName: 'custom_page',
      action: 'view',
    },
    {
      path: '/reports/hours',
      exact: true,
      component: Hours,
      moduleName: 'hours_report',
      action: 'view',
    },
    {
      path: '/reports/payroll',
      exact: true,
      component: Payroll,
      moduleName: 'payroll_report',
      action: 'view',
    },
  ],
};

export default routes;
