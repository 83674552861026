import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const getAllClients = (pagination, page, clientName, queryString) =>
{
  return API.get(`${API_ENDPOINTS.CLIENTS.Clients}?pagination=${pagination}&page=${page}&client=${clientName}${queryString}`);
};

const getClientByClientId = (clientId) =>
{
  return API.get(`${API_ENDPOINTS.CLIENTS.Clients}/${clientId}`);
}

const createClient = (body) =>
{
  return API.post(API_ENDPOINTS.CLIENTS.Clients, body);
};

const updateClientByClientId = (clientId, body) =>
{
  return API.put(`${API_ENDPOINTS.CLIENTS.Clients}/${clientId}`, body);
}

const deleteClientByClientId = (clientId) =>
{
  return API.del(`${API_ENDPOINTS.CLIENTS.Clients}/${clientId}`);
}

const getClientsByChef = (chefId) =>
{
    return API.get(`${API_ENDPOINTS.CLIENTS.ClientsByChef}/${chefId}`);
}

const getClientsByCity = (cityId) =>
{
  return API.get(`${API_ENDPOINTS.CLIENTS.ClientsByCity}/${cityId}`);
}

export
{
  getAllClients,
  getClientsByChef,
  getClientsByCity,
  getClientByClientId,
  createClient,
  updateClientByClientId,
  deleteClientByClientId
};