import React, { memo, useEffect, useState } from 'react';

import {
    useHistory,
    Link,
    useParams,
    Prompt
} from 'react-router-dom';

import { Button, Row, Col, Image, NavDropdown, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { trackPromise } from 'react-promise-tracker';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import { Desktop, Mobile } from '../../media-queries/mediaQueries';
import Ellipses from '../../../components/Ellipses';
import CustomPopupDescriptionForm from './CustomPopupDescriptionForm';
import CustomPopupContentForm from './CustomPopupContentForm';
import Toaster from '../../../components/Toaster';

import { createCustomPopup, deleteCustomPopupById, getCustomPopupById, updateCustomPopupById } from '../../../services/custom_popup.service';
import { htmlDecode, htmlEncode } from '../../../utils/CustomPageManagement';
import { DateConvert } from '../../../utils/TimesheetManagement';
import { validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';
import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const ManageCustomPopup = memo(() =>
{
    const { authUser } = useAuthContext();

    const classes = useStyles();
    const history = useHistory();    
    
    const [activeStep, setActiveStep] = useState(0);

    const [compiledForm, setCompiledForm] = React.useState({});

    const { popupId } = useParams();
    const isAddMode = !popupId;
    const [isAPICalled, setIsAPICalled] = useState(false);

    const [customPopup, setCustomPopup] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isSubmitSuccessful, setisSubmitSuccessful] = useState(false);
    const [showAccessError, setShowAccessError] = useState(false);

    const validationSchema = [
        // validation for step1
        yup.object({
            title: yup.string().required('Pop Up Title is required.'),
            access: yup.array().min(1, 'Please select at least one access Level.').required('Access Level is required.').nullable(),
            popup_location: yup.mixed().required('Popup Location is required.').nullable(),
        }),
        // validation for step2
        yup.object({
            contentType: yup.string('text').required('Content Type is required.').default('text'),
            content: yup.string().required('Page content is required'),
        }),
    ];

    const currentValidationSchema = validationSchema[activeStep];

    const methods = useForm({
        resolver: yupResolver(currentValidationSchema),
        shouldUnregister: false,
        mode: 'onChange'
    });

    const { handleSubmit, reset, trigger, formState: { isValid, errors, isSubmitting, dirtyFields }, watch, } = methods;
    const form = watch();

    const getSteps = () =>
    {
        return ['Pop Up Description', 'Pop Up Content'];
    };
    
    const steps = getSteps();

    const handleNext = async () =>
    {
        const isStepValid = await trigger();
        setShowAccessError(true);
        if (isStepValid || isValid)
        {
            switch (activeStep)
            {
                case 0:
                    setCompiledForm({ ...compiledForm, one: form });
                    setActiveStep(prevActiveStep => prevActiveStep + 1);
                    break;
                case 1:
                    setCompiledForm({ ...compiledForm, two: form });
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    break;
                default:
                    return null;
            }

        }
    };

    const handleBack = () =>
    {
        if (activeStep > 0)
        {
            setActiveStep(prevActiveStep => prevActiveStep - 1);
            switch (activeStep)
            {
                case 1:
                    setCompiledForm({ ...compiledForm, two: form });
                    break;
                // case 2:
                //   setCompiledForm({ ...compiledForm, three: form });
                //   break;
                default:
                    return "not a valid step";
            }
        }
    };

    const handleReset = () =>
    {
        setActiveStep(0);
        reset({});
        setCompiledForm({
            one: null,
            two: null,
        });
    };
    

    const getStepContent = (stepIndex, formContent) =>
    {
        switch (stepIndex)
        {
            case 0:
                return (
                    <CustomPopupDescriptionForm
                        {...{ formContent }}
                        showAccessError={showAccessError}
                    />
                );
            case 1:
                return (
                    <CustomPopupContentForm {...{ formContent }} />
                );
            default:
                return 'Unknown stepIndex';
        }
    }
    
    const onSubmitForm = async (data) =>
    {
        try
        {
            const body = data;
            body.popup_location = data.popup_location.value;

            if (body.contentType === 'text' || body.contentType === 'html')
            {
                body.content = htmlEncode(body.content);
            }
            if (isAddMode)
            {
                const response = await trackPromise(createCustomPopup(body));
                if (response.status === 201)
                {
                    toast.dark(<Toaster
                        icon="notify"
                        message={response.data.message}
                    />);
                    setisSubmitSuccessful(true);
                    history.push(`/settings/viewCustomPopup/${response.data.data.customPopup._id}`);
                }
            }
            else
            {
                
                const response = await trackPromise(
                    updateCustomPopupById(popupId, body)
                );
                if (response.status === 200)
                {
                    toast.dark(<Toaster
                        icon="notify"
                        message={response.data.message}
                    />);
                    setisSubmitSuccessful(true);
                    history.push(`/settings/viewCustomPopup/${response.data.data.customPopup._id}`);
                }
            }
        } catch (error)
        {

            if (error?.response?.status === 400)
            {
                if (error.response.data.message)
                {
                    toast.dark(
                        <Toaster
                            icon="error"
                            message={`${error.response.data.message}`}
                        />,
                        { autoClose: 5000, hideProgressBar: true }
                    );
                }
            }
            else
            {
                toast.dark(
                    <Toaster
                        icon="error"
                        message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                    />
                );
            }
        }
    };

    const onDeleteCustomPopup = async () =>
    {
        try
        {
            const response = await trackPromise(deleteCustomPopupById(popupId));
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally
        {
            setShow(false);
            history.replace('/settings/custompopups');
        }
    };

    async function getCustomPopupDetails()
    {
        try
        {
            const response = await trackPromise(getCustomPopupById(popupId));
            setCustomPopup(response.data.data.customPopup);
            const pageObj = response.data.data.customPopup;

            const decodedContent = htmlDecode(pageObj.content);
                                    
            reset({
                ...pageObj,
                content: decodedContent,
            });
            setCompiledForm({
                one: {
                    title: pageObj.title,
                    access: pageObj.access,
                    popup_location: pageObj.popup_location,
                    expiresOn: pageObj.expiresOn
                },
                two: {
                    contentType: pageObj.contentType,
                    content: decodedContent,
                },
            });
            setIsAPICalled(true);
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    useEffect(() =>
    {
        if (!isAddMode)
        {
            getCustomPopupDetails();
        }
        else
        {
            setIsAPICalled(true);
        }
    }, [isAddMode]);

    useEffect(() =>
    {
    }, [form, errors]);

    const SettingsDropdown = () =>
    {
        return (<div>
            <NavDropdown
                title={<Ellipses />}
                id="collasible-nav-dropdown"
            >
                <NavDropdown.Item
                    onClick={handleShow}
                    disabled={!validatePermission(authUser.user.role.permissions, 'custom_popup', 'delete')}
                >Delete</NavDropdown.Item>
            </NavDropdown>
        </div>);
    };

    return isAPICalled ? (
        <div>
            <div className=" sticky-page-header">
            <div className="container">
                <Desktop>
                    <Row className="ml-5">
                        <Col md="1">
                            <Link to='/settings/custompopups'>
                                <a>
                                    <Image
                                        className="imageArrow"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                        fluid
                                    />
                                </a>
                            </Link>
                        </Col>
                        <Col md="8">
                            {
                                isAddMode ? (
                                    <h5 className="mt-1"><strong>Untitled Pop up</strong></h5>
                                ) : (
                                    <>
                                        <h5 className="mb-0"><strong>Edit Custom Pop up</strong></h5>
                                        <label className="txt-light-secondary mb-0">Last Updated on {DateConvert(customPopup.updatedAt)}</label>
                                    </>
                                )
                            }
                        </Col>
                        <Col md="1" className="pr-0 ml-5">
                            <Button
                                variant="default"
                                className="btn-ftc-border-primary"
                                onClick={handleReset}
                            >
                                Reset
                            </Button>
                        </Col>
                        {
                            !isAddMode ? (
                                <Col md="1">
                                    <div>
                                        <SettingsDropdown />
                                    </div>
                                </Col>
                            ) : ('')
                        }
                        
                    </Row>
                </Desktop>
                <Mobile>
                    <div className="container">
                        <Row className="pb-0">
                            <Col xs="1" className="p-0">
                                <Link to="/settings/custompopups">
                                    <Image
                                        className="imageArrowMobile mt-1"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                        fluid
                                    />
                                </Link>
                            </Col>
                            <Col xs="8" className="mt-1">
                                {
                                    isAddMode ? (
                                        <h6 className="mt-1"><strong>Untitled Pop up</strong></h6>
                                    ) : (
                                        <>
                                            <h5 className="mb-0"><strong>Edit Custom Pop up</strong></h5>
                                            <p className="txt-light-secondary mx-auto">
                                                Last Updated on {DateConvert(customPopup.updatedAt)}
                                            </p>
                                        </>
                                    )
                                }
                            </Col>
                            <Col xs="1">
                                <Image
                                    onClick={handleReset}
                                    className="ftc-logo-24  mt-2"
                                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-reset.svg`}
                                />
                            </Col>
                            {
                                !isAddMode ? (
                                    <Col xs="1">
                                        <div>
                                            <SettingsDropdown />
                                        </div>
                                    </Col>
                                ) : ('')
                            }
                            
                        </Row>
                    </div>
                </Mobile>
            </div>
            </div>
             <Desktop>
                <div className="container-top-5" />
            </Desktop>
            <Mobile>
                <div className="container-top-20" />
            </Mobile>
            <div className="container">
                <Row>
                    <Col md="8" className="mx-auto">
                        <Stepper activeStep={activeStep}>
                            {steps.map((label) =>
                            {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div>
                            {/* {activeStep === steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Button onClick={handleReset} className={classes.button}>
                                        Reset
                                    </Button>
                                </div>
                            ) : ( */}
                            <FormProvider {...methods}>
                                <Form>
                                    <div>
                                        <Typography className={classes.instructions}>
                                            {getStepContent(activeStep, compiledForm)}
                                        </Typography>
                                        <div>

                                            {activeStep !== 0 ? (
                                                <Button
                                                    variant="default"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className="btn-ftc-border-primary"
                                                >
                                                    Back
                                                </Button>
                                            ) : (
                                                ''
                                            )}

                                            {activeStep === steps.length - 1 ? (
                                                <button
                                                    type="button"
                                                    className={`${classes.button} float-right btn btn-ftc-primary`}
                                                    onClick={handleSubmit(onSubmitForm)}
                                                    disabled={isSubmitting}
                                                // type="submit"
                                                >
                                                    Submit
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    onClick={handleNext}
                                                    className={`${classes.button} float-right btn btn-ftc-primary`}
                                                >
                                                    Continue
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </FormProvider>
                            {/* )} */}
                        </div>
                    </Col>
                </Row>
            </div>
            {show && <ConfirmAlertModal
                show={show}
                handleClose={handleClose}
                title='Delete Custom Popup'
                description='Deleting this popup will erase all data inside this record.
                    Are you sure you want to delete this Popup?'
                callback={onDeleteCustomPopup}
                action='Delete it'
                closeAction='Cancel'
            />}
            {
                isAddMode ? (

                    <Prompt
                        when={isSubmitSuccessful ? false : Object.keys(dirtyFields).length}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                ) : ('')
            }
        </div >
    ) : ('')
});

export default ManageCustomPopup
