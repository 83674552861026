import { useState } from "react";

function useBoolean(initialValue) {
  const [value, setValue] = useState(initialValue);

  const setTrue = () => setValue(true);
  const setFalse = () => setValue(false);

  return [value, setTrue, setFalse];
}

export default useBoolean;

// how to use
// const [isVisible, showModal, hideModal] = useBoolean(initialValue);
