import React, { memo } from 'react'
import { Col, Image } from 'react-bootstrap';

const NoReportsData = memo(() => {
    return (
        <div>
            <Col md="12">
                <div className="content-vcenter">
                    <div className="text-center mb-3">
                        <Image
                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/no-reports-image.svg`}
                        />
                    </div>
                    <h4 className="d-flex justify-content-center txt-primary">
                        <strong>No reports to show.</strong>
                    </h4>
                    <label className="d-flex justify-content-center ">
                        Please fill the parameters to generate the Hours Report.
                    </label>
                </div>
            </Col>
        </div>
    )
});

export default NoReportsData
