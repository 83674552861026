import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const createCustomPopup = (body) =>
{
    return API.post(API_ENDPOINTS.CUSTOM_POPUPS.CustomPopup, body);
};

const getAllCustomPopups = (pagination, page, popupTitle, queryString) =>
{
    return API.get(`${API_ENDPOINTS.CUSTOM_POPUPS.CustomPopup}?pagination=${pagination}&page=${page}&popupTitle=${popupTitle}${queryString}`);
};

const getCustomPopupById = (pageId) =>
{
    return API.get(`${API_ENDPOINTS.CUSTOM_POPUPS.CustomPopup}/${pageId}`);
};

const updateCustomPopupById = (pageId, body) =>
{
    return API.put(`${API_ENDPOINTS.CUSTOM_POPUPS.CustomPopup}/${pageId}`, body);
};

const deleteCustomPopupById = (pageId) =>
{
    return API.del(`${API_ENDPOINTS.CUSTOM_POPUPS.CustomPopup}/${pageId}`);
};

const getCustomPopupByLocation = (body) =>
{
    return API.post(`${API_ENDPOINTS.CUSTOM_POPUPS.ByLocation}`, body);
}

const getAllPopups = () => {
    return API.get(`${API_ENDPOINTS.CUSTOM_POPUPS.GetAllPages}`);
}

export
{
    createCustomPopup,
    getAllCustomPopups,
    getCustomPopupById,
    updateCustomPopupById,
    deleteCustomPopupById,
    getCustomPopupByLocation,
    getAllPopups,
};