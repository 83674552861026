import React, { memo } from 'react';
import DataTable from 'react-data-table-component';
import SvgIcon from '@material-ui/core/SvgIcon';
import './dataTableComponent.css';

import PropTypes from 'prop-types';

const DataTableComponent = memo(
  ({
    columns,
    data,
    selectableRowsComponentProps,
    handleRowClicked,
    defaultSortField,
    conditionalRowStyles,
    // paginationServer,
    // paginationTotalRows,
    // paginationPerPage,
    // onChangePage,
    // onChangeRowsPerPage,
    // pagination,
  }) => {
    // const sortIcon = <SvgIcon component={IconDownArrow} />;
    const sortIcon = (
      <SvgIcon viewBox="0 0 16 16">
        <path
          fill="#b4b4b4"
          d="M19757 15564.52l1.41-1.518 3.594 3.877V15559h1.996v7.887l3.6-3.883 1.4 1.518-6 6.479z"
          transform="translate(-19754.998 -15556.998)"
        />
      </SvgIcon>
    );
    return (
      <DataTable
        highlightOnHover
        pointerOnHover
        noHeader
        columns={columns}
        data={data}
        defaultSortField={defaultSortField}
        selectableRowsComponentProps={selectableRowsComponentProps}
        onRowClicked={handleRowClicked}
        defaultSortAsc={false} // to display rows in descending order
        sortIcon={sortIcon}
        // trClassName={getRowClassName}
        conditionalRowStyles={conditionalRowStyles}
        // pagination={pagination}
        // paginationServer={paginationServer}
        // paginationTotalRows={paginationTotalRows}
        // paginationPerPage={paginationPerPage}
        // onChangePage={onChangePage}
        // onChangeRowsPerPage={onChangeRowsPerPage}
        // sortIcon={<SvgIcon component={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.jpg`} />}
      />
    );
  }
);

export default DataTableComponent;

DataTableComponent.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  selectableRowsComponentProps: PropTypes.any.isRequired,
  handleRowClicked: PropTypes.func.isRequired,
  // paginationServer: PropTypes.bool,
  // paginationTotalRows: PropTypes.number.isRequired,
  // paginationPerPage: PropTypes.number.isRequired,
  // onChangePage: PropTypes.func.isRequired,
  // onChangeRowsPerPage: PropTypes.func.isRequired,
  // pagination: PropTypes.bool.isRequired,
};

DataTableComponent.defaultProps = {
  // paginationServer: false,
  defaultSortField: '',
};
