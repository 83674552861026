import axios from 'axios';

import { trackPromise } from 'react-promise-tracker';
import API_ENDPOINTS from '../constants/api_endpoints';

const api = axios.create({
  baseURL: API_ENDPOINTS.baseURL,
  timeout: 300000,
});

api.interceptors.request.use(
  (config) => {
    const options = { ...config };
    if (localStorage.getItem('token')) {
      options.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
    options.headers['Content-Type'] = 'application/json';
    return options;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response &&
      [401].indexOf(error.response.status) !== -1 &&
      error.response.request.responseURL.startsWith(API_ENDPOINTS.baseURL)
      // 403
    ) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.clear();

      const allDBs = await window.indexedDB.databases();
      allDBs.forEach(db => { window.indexedDB.deleteDatabase(db.name) });          

      if (error.response.data?.data?.error !== 'LoginError') {
        window.location.reload();
        // console.log(error);
      }
    } else if (error.response && error.response.status === 404) {      
      if (error.response.data?.data?.error !== 'LoginError')
      {
        window.location.href = '/page-not-found';
      }
    } else if (error.response && error.response.status === 500) {
      window.location.href = '/something-went-wrong';
    }
    return Promise.reject(error);
  }
);

const get = (param) => api.get(param);

const post = (param, body) => trackPromise(api.post(param, body));

const put = (param, body) => trackPromise(api.put(param, body));

//   const patch = (param, body) => trackPromise(api.patch(param, body));

const del = (param) => trackPromise(api.delete(param));

export default {
  get,
  post,
  put,
  del
};
