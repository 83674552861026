import React from 'react';
import { Image } from 'react-bootstrap';
import './MobileSidebarIcon.css'

const MobileSidebarIcon = () => {
    return (
        <Image className="image__sidebar__logo"
            // onClick={onToggleSidebar}
            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-menu-lines.svg`}
        />
    );
};

export default MobileSidebarIcon;
